import React, { useEffect } from "react";
import EmployeeAttendancDashboardeWrapper from "../EmployeeAttendancDashboardeWrapper";
import TopNav from "../components/TopNav";
import totalEmp from "../../../imgs/total-employee.png";
import onLeave from "../../../imgs/user.png";
import workingEmp from "../../../imgs/working-employee.png";
import pendingTasks from "../../../imgs/pending.png";
import ProfileNav from "../components/ProfileNav";
import arrowIcon from "../../../imgs/left-arrow.png";
import EditProfile from "./EditProfile.jsx/EditProfile";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import profile from "../../../imgs/profile-img.png";

const EmployeeProfile = () => {
  const dispatch = useDispatch();
  const router = useNavigate();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "2" });
  }, []);

  return (
    <EmployeeAttendancDashboardeWrapper>
      <div className="w-full">
        {/* <h2 className="text-xl font-semibold">Edit Profile</h2> */}
        <div className="flex items-center gap-2">
          {/* <img
            src={arrowIcon}
            alt="arrow-left"
            onClick={() => router("/EmployeeDashboard")}
            className="h-4 cursor-pointer hover:opacity-80"
          /> */}
          <h2 className="text-xl font-semibold">Edit Profile</h2>
        </div>

        <div className="flex justify-between mt-4 w-full">
          <div className="flex w-full flex-row items-center gap-6 px-5 py-8">
            <div className="w-16 h-16">
              <img className="w-full h-full object-contain" src={profile} alt={`Profile-img`} />
            </div>
            <div className="flex flex-col justify-between h-full gap-1">
              <div className="">
                <h2 className="text-3xl font-semibold">Raymond Ali</h2>
                <p className="text-lg font-normal text-[#1ba397]">Software Engineer</p>
              </div>
              <p className="text-base font-normal mt-2">
                <span className="font-bold text-[#1ba397]">Dept:</span> Development
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4 justify-center items-end w-full px-5 py-8">
            <div className="grid grid-cols-2 items-center">
              <h6 className="text-base font-semibold whitespace-nowrap">Joining Date:</h6>
              <h4 className="text-base whitespace-nowrap">12 Aug 2024</h4>
            </div>
            <div className="grid grid-cols-2 items-center">
              <h6 className="text-base font-semibold whitespace-nowrap">Employee ID:</h6>
              <h4 className="text-base whitespace-nowrap">12312397971</h4>
            </div>
          </div>
        </div>
        <div className="">
          <EditProfile />
        </div>
      </div>
    </EmployeeAttendancDashboardeWrapper>
  );
};

export default EmployeeProfile;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../Modals/errorModal";
import axios from "axios";
const Card = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  // const merchantStores = useSelector((state) => state.merchantStores);
  const err = "";
  const [showErrorModal, setShowErrorModal] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  // const [selectedStore1, setSelectedStore] = useState(null);
  const token = useSelector((state) => state.token);
  const [stores, setStores] = useState([]);
  const employeeHeadQuarterPrivileges = useSelector(
    (state) => state.employeeHeadQuarterPrivileges
  );
  const selectedStore = useSelector((state) => state.selectedStore);
  console.log(selectedStore);

  //get Stores
  useEffect(() => {
    dispatch({
      type: "SELECTED_STORE",
      num: null,
    });
    const api =
      user === "merchant-owner" || user === "merchant-admin"
        ? "v1/merch-store"
        : "v1/merch-employee-store/my";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        if (user === "merchant-owner" || user === "merchant-admin") {
          setStores(data?.data?.rows);
        } else {
          const store = data?.data?.map((item) => item.merchstore);
          setStores(store);
        }
      })
      .catch(() => {});
    // setStores(merchantStores)
  }, []);

  return (
    <div className="px-6">
      <div
        className={`grid  gap-8 sm:p-6  py-4 cursor-pointer ${
          user === "merchant-employee"
            ? "sm:grid-cols-3  grid-cols-1"
            : "sm:grid-cols-3  grid-cols-1"
        }`}
      >
        <div
          className={`p-3  rounded-md ${
            selectedStore?.type === "HQ"
              ? "bg-primary text-white"
              : "bg-gray-100 text-gray-700"
          } 
          ${
            user === "merchant-employee" &&
            employeeHeadQuarterPrivileges.length <= 0
              ? "hidden"
              : ""
          }
          `}
          onClick={() => {
            // setSelectedStore();
            dispatch({
              type: "SELECTED_STORE",
              num: {
                type: "HQ",
                value: dataUser?.data?.merchuser?.merchCompany,
              },
            });
            router("/Merchant/PensionerProfile/Verify");
          }}
        >
          <div className="flex flex-row justify-center items-center gap-5">
            {/* Image */}
            <div className="w-[4rem] h-[4rem] mb-4 overflow-hidden rounded-full ">
              <img
                src={dataUser?.data?.merchuser?.merchCompany?.logo}
                alt={dataUser?.data?.merchuser?.merchCompany?.logo}
                className="w-full h-full object-cover"
              />
            </div>
            {/* Text beside image */}
            <div>
              <div className="font-bold text-xl mb-1 text-center">
                {dataUser?.data?.merchuser?.merchCompany?.name}
              </div>
              <p className="text-sm">Company HeadQuarter</p>
            </div>
          </div>
        </div>

        {/* Stores */}
        {stores?.map((item, index) => (
          <div
            key={index}
            className={`p-3  rounded-md ${
              selectedStore?.type === "store" &&
              selectedStore?.value?.id === item?.id
                ? "bg-primary text-white"
                : "bg-gray-100 text-gray-700"
            }`}
            onClick={() => {
              dispatch({
                type: "SELECTED_STORE",
                num: {
                  type: "store",
                  value: item,
                },
              });
              router("/Merchant/PensionerProfile/Verify");
            }}
          >
            <div className="flex flex-row justify-center items-center gap-5">
              {/* Image */}
              <div className="w-[4rem] h-[4rem] mb-4 overflow-hidden rounded-full">
                <img
                  src={item?.image}
                  alt={item?.customStoreName}
                  className="w-full h-full object-cover"
                />
              </div>
              {/* Text beside image */}
              <div>
                <div className="font-semibold text-xl mb-1 text-md">
                  {item?.customStoreName}
                </div>
                <p className=" text-sm">
                  {item?.region}
                  {","} {item?.city}
                </p>
              </div>
            </div>
          </div>
        ))}

        {/* Buttons */}
      </div>
      <div className="w-full flex justify-end mt-4 gap-5 px-6">
        {/* <button
          type="button"
          className="lg:text-[1.1rem] bg-gradient-to-tr bg-gray-200 text-gray-700 rounded-[0.5rem] p-2  pr-8 pl-8"
        >
          Skip
        </button> */}
        {/* <button
          type="button"
          onClick={() => {
            if (employeeHeadQuarterPrivileges.length <= 0) {
              if (stores.length > 0) {
                if (
                  selectedStore === undefined ||
                  selectedStore === null ||
                  selectedStore === ""
                ) {
                  setErr("Please select a store");
                  setShowErrorModal(true);
                  return;
                }
              }
            }
            router("/Merchant/PensionerProfile/Verify");
          }}
          className="lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8"
        >
          Next
        </button> */}
      </div>
      {showErrorModal && <ErrorModal setShow={setShowErrorModal} err={err} />}
    </div>
  );
};

export default Card;

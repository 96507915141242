import { DatePicker, Pagination, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import moment from "moment/moment";
import axios from "axios";
import profile from "../../../../imgs/profile.png";

const PensionerTable = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const [pensioner, setPensioner] = useState([]);
  const user = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [show, setShow] = useState("");
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [search, setSearch] = useState("");
  const [dob, setDob] = useState("");
  const [prompt, setPrompt] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [searchDob, setSearchDob] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  useEffect(() => {
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 6,
        skip:
          search || searchDob || countryCode || phoneNoo ? 0 : (page - 1) * 6,
        page: search ? pagewithsearch : page, // Separate page for search and non-search scenarios
        sortBy: "createdAt:desc",
        ...(search && { search }),
        ...(searchDob && { dateOfBirth: searchDob }),
        ...(countryCode &&
          phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
        status: "ceased",
      };

      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page, search, searchDob, countryCode, phoneNoo, pagewithsearch]);

  const fetchEmployees = (params, cancelTokenSource) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/users/pensioners`, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        setPensioner(data.data.rows);
        setTotalPages(data.data.totalPages);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const columnHeaders = [
    "ID",
    "Name",
    "Pensioner ID",
    "GID",
    "Gender",
    "Phone",
    "DOB",
    "Booklet No.",
    "Status",
    "Created Date/Time",
  ];

  const pageHandler = (e) => {
    if (search) {
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };

  const showHandler = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
    } else {
      setState(event.target.value);
    }
  };

  return (
    <section className="p-2 sm:p-5">
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12 ">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-gray-50 ">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            {/* search */}
            <div className="w-full md:w-1/2">
              <form className="flex items-center">
                <label className="sr-only">Search</label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                    placeholder="Search by GID , Email , Last Name "
                    required=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </form>
            </div>
            {/* filter */}

            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="flex items-center space-x-3 w-full md:w-auto">
                <div className="relative">
                  <button
                    id="filterDropdownButton"
                    data-dropdown-toggle="filterDropdown"
                    className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200      "
                    type="button"
                    onClick={showHandler}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="h-4 w-4 mr-2 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Filter By
                    <svg
                      className="-mr-1 ml-1.5 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        clipRule="evenodd"
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      />
                    </svg>
                  </button>

                  {show && (
                    <div
                      id="filterDropdown"
                      className="z-20 absolute top-full md:right-0 mt-2 sm:w-[18rem] w-[15rem] p-3 bg-white rounded-lg shadow "
                    >
                      <div className="mt-5">
                        <DatePicker
                          onChange={(e, f) => setDob(f)}
                          value={dob !== "" ? moment(dob) : null}
                          placeholder="Date of birth"
                          // value={wow !== "" ? set : null}
                          // onChange={(e, f) => change1Handler(e, f)}
                        />
                      </div>

                      <PhoneInput
                        placeholder="Enter phone number"
                        defaultCountry="GY"
                        value={prompt}
                        onChange={handleChange(setPrompt, "tt")}
                        international
                        countryCallingCodeEditable={false}
                        // required={option === "Employee" ? true : false}
                        // className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
                      />
                      <div className="mt-4 w-full flex gap-x-2 items-center justify-center">
                        <button
                          onClick={() => {
                            if (prompt) {
                              const wow = parsePhoneNumber(prompt);

                              let count = wow.countryCallingCode;
                              let num = wow.nationalNumber;
                              setCountryCode(count);
                              setPhoneNoo(num);
                            }
                            if (dob !== null && dob !== "") setSearchDob(dob);
                            setShow(false);
                          }}
                          className={` bg-gradient-to-tr bg-primary text-white mr-3 rounded-[0.5rem] p-2  pr-8 pl-8`}
                        >
                          Apply
                        </button>
                        <button
                          onClick={() => {
                            setSearchDob("");
                            setDob("");
                            setPhoneNoo(null);
                            setPrompt("");
                            setCountryCode(null);
                            setShow(false);
                          }}
                          className={` bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* table */}
          <div className="overflow-x-auto">
            {pensioner.length === 0 ? (
              <h1 className="text-center text-[1.1rem] p-5 h-40">
                No Data Found
              </h1>
            ) : (
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                  <tr>
                    {columnHeaders.map((columnHeader, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 font-semibold tracking-wider"
                      >
                        {columnHeader}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {pensioner.map((pen, index) => (
                    <tr
                      key={index}
                      className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer"
                      onClick={() => {
                        user === "employee"
                          ? router("/EDashboard/profPage")
                          : user === "admin"
                          ? router("/AdminDashboard/profPage")
                          : router("/superAdminDashboard/profPage");
                        dispatch({ type: "universalPenSet", num: pen.id });
                      }}
                    >
                      <th
                        scope="row"
                        className="px-3 py-5 font-medium text-gray-900  whitespace-nowrap"
                      >
                        {search
                          ? index + 1 + (pagewithsearch - 1) * 6
                          : index + 1 + (page - 1) * 6}
                        .
                      </th>
                      <td className="px-6 p-2 py-5">
                        <div className="flex items-center">
                          <img
                            className="w-10 ml-0  h-10 rounded-full mr-2 border-2 border-primary "
                            src={
                              pen?.person !== null
                                ? pen?.person?.image
                                : profile
                            }
                            alt={`pro`}
                          />
                          <p className="mr-6" style={{ whiteSpace: "nowrap" }}>
                            {pen?.person?.firstName} {pen?.person?.lastName}
                          </p>
                        </div>
                      </td>
                      <td className="px-6  py-5">
                        <Popover content={pen.uniqueId}>
                          <div className="inline-block flex items-center justify-center max-w-[120px] whitespace-nowrap overflow-hidden overflow-ellipsis">
                            {pen.uniqueId.substring(0, 5)}..
                          </div>
                        </Popover>
                      </td>
                      <td className="px-6  py-5">{pen.gid}</td>
                      <td className="px-6  py-5">
                        {pen.person === null ? "----" : `${pen.person.gender}`}
                      </td>
                      <td
                        className="px-6  py-5"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {pen?.countryCode !== null
                          ? pen?.countryCode + " " + pen?.phoneNo
                          : "----"}
                      </td>
                      <td
                        className="px-6  py-5"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {pen?.person?.dateOfBirth}
                      </td>
                      <td className="px-6  py-5">
                        <div className="border-none bg-[white] text-[#F7542E] font-semibold p-1 text-center rounded-md  focus:border-none cursor-pointer">
                          145352
                        </div>
                      </td>
                      <td className="px-6  py-5">
                        <Popover
                          content={`${
                            pen.status === "pending" ? "In-Process" : ""
                          }`}
                          placement="bottomLeft"
                        >
                          <div
                            className={`border-none ${
                              pen.status === "pending" && !pen.isDisabled
                                ? "bg-[#2F84F1]"
                                : pen.isDisabled || pen.status === "rejected"
                                ? "bg-[#D64242]"
                                : " bg-primary"
                            }  p-1 pl-5 pr-5 rounded-md flex items-center justify-center text-white focus:border-none cursor-pointer capitalize`}
                          >
                            {pen?.isDisabled
                              ? "Disabled"
                              : pen?.status === "ceased"
                              ? "Deceased"
                              : pen?.status}
                          </div>
                        </Popover>
                      </td>
                      <td className="px-6  py-5">
                        <p>{moment(pen?.createdAt).format("MM/DD/YYYY")}</p>
                        <p> {moment(pen?.createdAt).format("hh:mm A")}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <nav
            className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={search ? pagewithsearch : page}
              />
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default PensionerTable;

import Ellipse from "../../../../imgs/backg.svg";

import vendor1 from "../../../../imgs/vendor1.svg";
import vendor2 from "../../../../imgs/vendor2.svg";
import vendor3 from "../../../../imgs/vendor3.svg";

import img from "../../../../imgs/impLogo.png";

import { useLocation, useNavigate } from "react-router";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { useState } from "react";
let items;
const AdminPanel = () => {
  const location = useLocation();
  const dash = location.pathname;
  const router = useNavigate();
  const dispatch = useDispatch("");
  const dataUser = useSelector((state) => state.dataUser);
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [err, setErr] = useState("");
  const [programsSetting, setProgramsSetting] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showRole, setShowRole] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showRej, setShowRej] = useState(false);
  const [verification, setVerification] = useState(false);
  const [showDef, setShowDef] = useState(false);
  const [beneficiary, setBeneficiary] = useState(false);
  const [programs, setPrograms] = useState([]);
  const show = useSelector((state) => state.isLogged);
  const [messageApi, contextHolder] = message.useMessage();
  const statusCheck = useSelector((state) => state.statusCheck);
  const [showAllContentIndex, setShowAllContentIndex] = useState(-1);
  const handleToggleContent = (index) => {
    setShowAllContentIndex(index === showAllContentIndex ? -1 : index);
  };
  const Success = () => {
    messageApi.open({
      // type: 'success',
      content: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={img} alt="Your Image" width="50" height="50" />
          <h3>
            This Module is currently in development and will be launched soon.
          </h3>
        </div>
      ),
      duration: 3,
    });
  };

  const items = [
    {
      key: "1",
      title: `Categories`,
      content: "Browse, Create, or Delete Your Product Categories",
      img: vendor1,
      route: "/Inventory-Categories",
      count: 150,
    },
    {
      key: "2",
      title: `Products`,
      content: "Organize and mange you product inventory",
      img: vendor2,
      route: "/Inventory",
      count: 200,
    },
    {
      key: "3",
      title: `Orders`,
      content: "Organize and Manage your product inventory",
      img: vendor3,
      route: "/Inventory-Vendor-Orders",
      count: 100,
    },
  ];

  return (
    // <div
    //   className="w-full h-screen bg-center bg-cover"
    //   style={{ backgroundImage: `url(${Ellipse})` }}
    // ></div>

    <div
      //   className="h-full"
      //   style={{
      //     width: "100%",
      //     height: "100%",
      //     backgroundImage: `url(${Ellipse})`,
      //     backgroundSize: "cover",
      //     backgroundPosition: "center 10%",
      //     backgroundPositionY: "-80px",
      //   }}
      className="w-full h-screen bg-center bg-cover"
      style={{
        backgroundImage: `url(${Ellipse})`,
        backgroundPosition: "center 20%",
      }}
    >
      {contextHolder}
      <div className="flex items-center justify-center">
        <div className="md:w-[80%] mt-[2rem] rounded-md w-[95%]  mb-16  bg-[#F8F5F5] shadow-lg">
          <div className="w-full mt-5 grid sm:grid-cols-3 grid-cols-1 sm:px-24 sm:pt-20 gap-8 md:gap-x-8 gap-y-14 sm:min-h-[400px] px-10 pt-4">
            {items?.map((item, index) => (
              <div
                key={index}
                className="relative  rounded-md mb-[30px]  p-2 cursor-pointer custom-shadow"
                style={{
                  background: "linear-gradient(white, #F8F5F5)",
                  height: `170px`,
                }}
                onClick={() => router(item.route)}
              >
                <div className="absolute top-[-40px] left-1/2 flex p-5 border-4 border-[#F8F5F5] p-[1px] items-center justify-center transform -translate-x-1/2 bg-[#F8F5F5] rounded-full h-[80px] w-[80px] shadow-lg">
                  <img src={item?.img} alt="" className="h-14 w-auto p-1" />
                </div>
                <div className="flex items-center justify-center flex-col mt-6 w-full h-full">
                  <div className="flex items-center mt-1 h-full gap-y-3 justify-center w-full flex-col">
                    <div className="text-center h-[50px]  ">
                      <h1
                        className="text-black text-[1rem] font-semibold mb-2"
                        style={{ fontFamily: "poppins", fontWeight: "600" }}
                      >
                        {item?.title}
                      </h1>

                      <span
                        className="text-primary text-[13px] font-medium min-h-[60px] "
                        style={{ fontFamily: "poppins", fontWeight: "600" }}
                      >
                        {item?.count + " " + item?.title}
                      </span>
                    </div>
                    <div className="text-center min-h-[60px] w-[80%]">
                      <p
                        className="text-black text-[13px]"
                        style={{ fontFamily: "poppins", fontWeight: "500" }}
                      >
                        {item?.content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;

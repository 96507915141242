import React, { useState, useEffect } from "react";
import { Button, Checkbox, DatePicker, Input, Select } from "antd";
import back5 from "../../../imgs/back5.png";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import cartAni from "../../../imgs/cartAni.gif";
const EventDetails = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cartData);
  const [localCart, setCart] = useState(cart);
  const [initial, setinitial] = useState(true);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);

  const [selectionMethod, setSelectionMethod] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [department, setDepartment] = useState("");
  const [location, setLocation] = useState("");
  const [scheduledDate, setScheduledDate] = useState(null);

  const handleOptionChange = (e) => {
    setSelectionMethod(e.target.value);
  };

  console.log("Cart", cart);
  useEffect(() => {
    setCart(cart);
  }, [cart]);
  useEffect(() => {
    console.log("OkKK", localCart);
    if (!initial) {
      dispatch({ type: "Cart", num: localCart });
    }
  }, [localCart]);

  const CartItem = ({ item, onIncrement, onDecrement, onDelete }) => {
    return (
      <div className="flex w-full items-center rounded-xl mt-2 bg-white p-4 border-b border-gray-300 relative">
        <img
          src={item.image}
          alt={item.name}
          className="w-24 h-24 rounded-md "
        />
        <div className="ml-4 flex-grow">
          <h2 className="text-lg font-semibold">{item.name}</h2>
          <p className="text-gray-600">
            {item.price} / {item.amount}
          </p>
          <div className="flex items-center mt-2">
            <Button
              onClick={() => onDecrement(item.id)}
              className="bg-gray-300 text-black px-2 rounded-md"
            >
              -
            </Button>
            <span className="mx-2">{item.quantity}</span>
            <Button
              onClick={() => onIncrement(item.id)}
              className="bg-gray-300 text-black px-2 rounded-md"
            >
              +
            </Button>
          </div>
        </div>
        <Button
          onClick={() => onDelete(item.id)}
          className="absolute top-2 right-2 text-red-600"
          icon={<DeleteOutlined />}
        />
      </div>
    );
  };

  const CardItem2 = ({ item, onIncrement, onDecrement, onDelete }) => {
    return (
      <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 md:p-6">
        <div className="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
          <a href="#" className="shrink-0 md:order-1">
            <img className="h-20 w-20 " src={item?.image} alt="imac image" />
          </a>

          <label for="counter-input" className="sr-only">
            Choose quantity:
          </label>
          <div className="flex items-center justify-between md:order-3 md:justify-end">
            <div className="flex items-center">
              <button
                type="button"
                id="decrement-button"
                data-input-counter-decrement="counter-input"
                className="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                onClick={() => onDecrement(item.id)}
              >
                <svg
                  className="h-2.5 w-2.5 text-gray-900 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 2"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 1h16"
                  />
                </svg>
              </button>
              <input
                type="text"
                id="counter-input"
                data-input-counter
                className="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-0 dark:text-white"
                placeholder=""
                value={item.quantity}
                required
              />
              <button
                type="button"
                id="increment-button"
                data-input-counter-increment="counter-input"
                className="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                onClick={() => onIncrement(item.id)}
              >
                <svg
                  className="h-2.5 w-2.5 text-gray-900 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </button>
            </div>
            <div className="text-end md:order-4 md:w-32">
              <p className="text-base font-bold text-gray-900 dark:text-white">
                $1,499
              </p>
            </div>
          </div>

          <div className="w-full min-w-0 flex-1 space-y-1 md:order-2 md:max-w-md">
            <div className="text-xl font-medium text-gray-900 hover:underline dark:text-white">
              {item.name}
            </div>
            <p className="text-gray-600">
              {item.price} / {item.amount}
            </p>

            <div className="flex items-center gap-4">
              <button
                type="button"
                className="inline-flex items-center text-sm font-medium text-red-600 hover:underline dark:text-red-500"
                onClick={() => {
                  onDelete(item.id);
                }}
              >
                <svg
                  className="me-1.5 h-5 w-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18 17.94 6M18 18 6.06 6"
                  />
                </svg>
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleIncrement = (id) => {
    setinitial(false);
    setCart(
      localCart.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrement = (id) => {
    setinitial(false);
    setCart(
      localCart.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const handleDelete = (id) => {
    setinitial(false);
    setCart(localCart.filter((item) => item.id !== id));
  };
  const handleApplyPromoCode = () => {
    if (promoCode === "SAP") {
      setDiscount(15);
    } else {
      setDiscount(0);
    }
  };

  const total = localCart.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  const shipping = 4.6;
  const subtotal = total - discount + shipping;

  return (
    <div className="bg-[#D9EFED] h-screen">
      <div className="flex w-full pb-[5rem] items-center justify-center bg-[#D9EFED]">
        <div className="sm:w-[92%] flex flex-col items-center ">
          <div className="w-full flex items-center justify-between">
            <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
              <div
                onClick={() => {
                  router("/Inventory-Products");
                }}
                className="cursor-pointer flex items-center justify-center"
              >
                <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
                <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                  Shopping Cart
                </h2>
              </div>
            </div>
          </div>
          {/* <div className="px-6 w-full">
            {localCart.length > 0 && (
              <div className="mt-6 sm:mt-8 md:gap-6 lg:flex lg:items-start xl:gap-8 bg-[#F6F4F4] p-5 rounded-md w-full">
                <div className="mx-auto w-full flex-none lg:max-w-2xl xl:max-w-4xl">
                  <div className="space-y-6">
                    {localCart?.map((item) => (
                      <CardItem2
                        key={item.id}
                        item={item}
                        onIncrement={handleIncrement}
                        onDecrement={handleDecrement}
                        onDelete={handleDelete}
                      />
                    ))}
                  </div>
                </div>

                <div className="mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
                  <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">
                    <p className="text-xl font-semibold text-gray-900 dark:text-white">
                      Order summary
                    </p>

                    <div className="space-y-4">
                      <div className="space-y-2">
                        <dl className="flex items-center justify-between gap-4">
                          <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                            Original price
                          </dt>
                          <dd className="text-base font-medium text-gray-900 dark:text-white">
                            $7,592.00
                          </dd>
                        </dl>

                        <dl className="flex items-center justify-between gap-4">
                          <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                            Savings
                          </dt>
                          <dd className="text-base font-medium text-green-600">
                            -$299.00
                          </dd>
                        </dl>

                        <dl className="flex items-center justify-between gap-4">
                          <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                            Store Pickup
                          </dt>
                          <dd className="text-base font-medium text-gray-900 dark:text-white">
                            $99
                          </dd>
                        </dl>

                        <dl className="flex items-center justify-between gap-4">
                          <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                            Tax
                          </dt>
                          <dd className="text-base font-medium text-gray-900 dark:text-white">
                            $799
                          </dd>
                        </dl>
                      </div>

                      <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 dark:border-gray-700">
                        <dt className="text-base font-bold text-gray-900 dark:text-white">
                          Total
                        </dt>
                        <dd className="text-base font-bold text-gray-900 dark:text-white">
                          $8,191.00
                        </dd>
                      </dl>
                    </div>

                    <button
                      onClick={() => {
                        router("/Inventory-PlaceOrder");
                      }}
                      className="flex w-full items-center justify-center rounded-lg bg-primary px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    >
                      Proceed to Checkout
                    </button>

                    <div className="flex items-center justify-center gap-2">
                      <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        {" "}
                        or{" "}
                      </span>
                      <a
                        href="#"
                        title=""
                        className="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline dark:text-primary-500"
                      >
                        Continue Shopping
                        <svg
                          className="h-5 w-5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 12H5m14 0-4 4m4-4-4-4"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>

                  <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">
                    <form className="space-y-4">
                      <div>
                        <label
                          for="voucher"
                          className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                        >
                          {" "}
                          Do you have a voucher or gift card?{" "}
                        </label>
                        <input
                          type="text"
                          id="voucher"
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                          placeholder=""
                          required
                        />
                      </div>
                      <button className="flex w-full items-center justify-center rounded-lg bg-primary px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Apply Code
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div> */}

          {localCart.length > 0 && (
            <div className="p-8 mt-6 rounded-xl bg-white w-[95%] px-12">
              <div className="bg-[#F6F6F6] p-5  rounded-xl">
                {localCart.map((item) => (
                  <CartItem
                    key={item.id}
                    item={item}
                    onIncrement={handleIncrement}
                    onDecrement={handleDecrement}
                    onDelete={handleDelete}
                  />
                ))}
              </div>

              <div className="w-[45%]">
                <div className="mt-4">
                  <h1 className="font-semibold text-[1rem]">
                    Select delivery Address
                  </h1>

                  <div className="my-4  w-full gap-3 rounded-md">
                    <div className="flex gap-4 mb-4 items-center justify-between text-[1rem]">
                      <div className="mb-2 flex items-center">
                        <input
                          type="radio"
                          id="warehouseOption"
                          name="selectionMethod"
                          value="warehouse"
                          className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                          checked={selectionMethod === "warehouse"}
                          onChange={handleOptionChange}
                        />
                        <label
                          htmlFor="warehouseOption"
                          className="cursor-pointer text-md inline-flex items-center ml-2"
                        >
                          Warehouse
                        </label>
                      </div>
                      <div className="mb-2 flex items-center">
                        <input
                          type="radio"
                          id="departmentOption"
                          name="selectionMethod"
                          value="department"
                          className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                          checked={selectionMethod === "department"}
                          onChange={handleOptionChange}
                        />
                        <label
                          htmlFor="departmentOption"
                          className="cursor-pointer text-md inline-flex items-center ml-2"
                        >
                          Department
                        </label>
                      </div>

                      <div className="mb-2 flex items-center">
                        <input
                          type="radio"
                          id="locationOption"
                          name="selectionMethod"
                          value="location"
                          className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                          checked={selectionMethod === "location"}
                          onChange={handleOptionChange}
                        />
                        <label
                          htmlFor="locationOption"
                          className="cursor-pointer text-md inline-flex items-center ml-2"
                        >
                          Location
                        </label>
                      </div>
                    </div>

                    {selectionMethod === "warehouse" && (
                      <div className="w-full mb-3 mt-2">
                        <Select
                          placeholder="Select Warehouse"
                          className={` wow`}
                          // value={type}
                          // onChange={(value) => {
                          //   setType(value);
                          // }}
                          options={[
                            {
                              label: "Master Warehouse",
                              value: "master-warehouse",
                            },
                            {
                              label: "Sub Warehouse",
                              value: "warehouse",
                            },
                          ]}
                        ></Select>
                      </div>
                    )}
                    {selectionMethod === "department" && (
                      <div className="w-full mb-3 mt-2">
                        <Select
                          placeholder="Select Department"
                          className={` wow`}
                          // value={type}
                          // onChange={(value) => {
                          //   setType(value);
                          // }}
                          options={[
                            {
                              label: "Master Warehouse",
                              value: "master-warehouse",
                            },
                            {
                              label: "Sub Warehouse",
                              value: "warehouse",
                            },
                          ]}
                        ></Select>
                      </div>
                    )}
                    {selectionMethod === "location" && (
                      <div className="w-full mb-3 mt-2 flex gap-2 items-center">
                        <Select
                          placeholder="Select Location"
                          className={` wow`}
                          // value={type}
                          // onChange={(value) => {
                          //   setType(value);
                          // }}
                          options={[
                            {
                              label: "Master Warehouse",
                              value: "master-warehouse",
                            },
                            {
                              label: "Sub Warehouse",
                              value: "warehouse",
                            },
                          ]}
                        ></Select>
                        <button className="bg-white border-2 font-semibold border-primary text-primary px-4 py-[0.67rem] rounded-md  w-[16rem]">
                          Add New Location
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-3 mt-2 ">
                  <h1 className="font-semibold text-[1rem] mb-1">
                    Delivery Method
                  </h1>

                  <Select
                    placeholder="Select Delivery Method"
                    className={` wow`}
                    // value={type}
                    // onChange={(value) => {
                    //   setType(value);
                    // }}
                    options={[]}
                  ></Select>
                </div>
              </div>
              <div className="pt-6">
                <Checkbox
                  checked={scheduledDate}
                  onChange={(e) => setScheduledDate(e.target.checked)}
                  className={"checkk4 "}
                >
                  Specify if you need scheduled delivery or same-day delivery
                  for this order.
                </Checkbox>
                <div className={`${scheduledDate ? "w-[45%] mt-3" : "hidden"}`}>
                  <DatePicker
                    style={{ marginBottom: "1.2rem" }}
                    className={`${"date-class1 p-5"} `}
                    placeholder="dd/mm/yyyy"
                  />
                </div>
              </div>
              <div className="mt-6 p-4 w-full bg-[#F6F6F6] rounded-xl text-[0.92rem]">
                <div className="flex w-full justify-between mb-2">
                  <span className="font-semibold">Total Items</span>
                  <span className="font-semibold">{localCart.length}</span>
                </div>
                <div className="flex w-full justify-between mb-2">
                  <span className="font-semibold">Sub Total </span>
                  <span className="font-semibold">${total.toFixed(2)}</span>
                </div>

                <div className="flex justify-between mb-2">
                  <span className="font-semibold">Shipping</span>
                  <span className="font-semibold">${shipping.toFixed(2)}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="font-semibold">Discount</span>
                  <span className="font-semibold">${discount.toFixed(2)}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="font-semibold">Taxes</span>
                  <span className="font-semibold">$300</span>
                </div>
                <div className="bg-[#EDEDED] px-6 py-2 text-gray-600">
                  <div className="flex justify-between mb-2">
                    <span className="font-semibold">VAT (14%)</span>
                    <span className="font-semibold">$300</span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span className="font-semibold">Excise</span>
                    <span className="font-semibold">$300</span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span className="font-semibold">Other Tax</span>
                    <span className="font-semibold">$300</span>
                  </div>
                </div>
                <div className="flex justify-between font-bold text-lg mt-3">
                  <span>Total</span>
                  <span>${subtotal.toFixed(2)}</span>
                </div>
              </div>
            </div>
          )}
          {/* {localCart.length > 0 && (
            <div className="flex gap-4 w-full items-center mt-6">
              <Input
                className="bg-gray-50 border border-gray-300 pl-4 pr-4 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full p-2"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                placeholder="Enter promo code"
              />
              <Button
                onClick={handleApplyPromoCode}
                className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white"
              >
                Apply
              </Button>
            </div>
          )} */}

          {localCart.length > 0 && (
            <div className="mt-8 flex gap-5">
              <Button
                // onClick={() => router("/Inventory-PlaceOrder")}
                className="bg-red-600 rounded-xl cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white w-[12rem]"
              >
                Discard Cart
              </Button>
              <Button
                // onClick={() => router("/Inventory-PlaceOrder")}
                onClick={() => router("/PurchaseOrder")}
                className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white w-[12rem]"
              >
                Create Purchase Order
              </Button>
            </div>
          )}

          {localCart.length === 0 && (
            <div className="mt-8">
              <img src={cartAni} className="w-[30rem] h-64 rounded-md" />
              <h1 className="text-2xl font-bold text-center mt-4 text-gray-600">
                Cart Is Empty ....
              </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventDetails;

import EventDetail from "./eventDetailsInvite";
import EventGallery from "./gallary";
import SubEvents from "./eventListing";
import { useState } from "react";
const EventDetails = () => {
  const [state, setState] = useState("event");
  const [subId, setSubId] = useState("");
  return (
    <div className="flex items-center bg-[#D9EFED] min-h-[100vh] md:pt-[2rem] pt-2 flex-col">
      <div className="sm:w-[80%] w-full p-2">
        {state === "event" || state === "subEventDetails" ? (
          <EventDetail
            setState={setState}
            state={state}
            subId={subId}
            setSubId={setSubId}
          />
        ) : state === "gallary" || state === "subGallery" ? (
          <EventGallery setState={setState} subId={subId} state={state} />
        ) : (
          <SubEvents setState={setState} setSubId={setSubId} />
        )}
      </div>
    </div>
  );
};

export default EventDetails;

import React, { useState, useEffect } from "react";
import { Button, Carousel, Pagination } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import like from "../../../../imgs/heartF.png";
import dislike from "../../../../imgs/heartD.png";
import Footer from "../../cart/footer";
import axios from "axios";
import BranchModal from "./branchModal";
import Filter from "../../superAdmin/vendor/filters/newFilter";
import { image } from "@tensorflow/tfjs";
const EventCard = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cartData);
  const role = useSelector((state) => state.role);
  const fav = useSelector((state) => state.fav);
  const [localCart, setCart] = useState(cart);
  const [favorite, setFavorite] = useState(fav);
  const [initial, setinitial] = useState(true);
  const [initialFav, setinitialFav] = useState(true);
  const user = useSelector((state) => state.user);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const token = useSelector((state) => state.token);
  const [data, setData] = useState([]);
  const [showBranch, setShowBranch] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);

  useEffect(() => {
    dispatch({ type: "EDIT_PRODUCT", num: false });
    dispatch({ type: "PRODUCT_ID", num: null });
    setCart(cart);
  }, [cart]);
  useEffect(() => {
    setFavorite(fav);
  }, [fav]);
  useEffect(() => {
    console.log("OkKK", localCart);
    if (!initial) {
      dispatch({ type: "Cart", num: localCart });
    }
  }, [localCart]);
  useEffect(() => {
    console.log("fav", favorite);
    if (!initialFav) {
      dispatch({ type: "Fav", num: favorite });
    }
  }, [favorite]);

  const addToCart = (event) => {
    setinitial(false);
    const cartItem = {
      id: event.id,
      image: event.invproductgallaries?.[0]?.image,
      name: event.name,
      quantity: event.quantity,
      price: event.basePrice,
      amount: event.amount,
    };

    const updatedCart = [...localCart, cartItem];
    setCart(updatedCart);
  };
  const addToFav = (event) => {
    setinitialFav(false);
    const favItems = {
      id: event.id,
      coverPhotos: event.invproductgallaries,
      name: event.name,
      route: event.route,
      quantity: event.quantity,
      price: event.basePrice,
      amount: event.amount,
    };

    const updatedFav = [...favorite, favItems];
    setFavorite(updatedFav);
  };

  const removeFromCart = (eventId) => {
    setinitial(false);
    const updatedCart = localCart.filter((item) => item.id !== eventId);
    setCart(updatedCart);
  };
  const removeFromFav = (eventId) => {
    setinitialFav(false);
    const updatedFav = favorite.filter((item) => item.id !== eventId);
    setFavorite(updatedFav);
  };

  const isAddedToCart = (eventId) => {
    return localCart.some((item) => item.id === eventId);
  };
  const isAddedToFav = (eventId) => {
    return favorite.some((item) => item.id === eventId);
  };
  const events = [
    {
      id: 1,
      invproductgallaries: [
        {
          image:
            "https://images.unsplash.com/photo-1588165171080-c89acfa5ee83?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3RyYXdiZXJyeXxlbnwwfHwwfHx8MA%3D%3D",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ67Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ67Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0",
        },
      ],
      name: "Strawberry",
      basePrice: 23,
      quantity: 1,
      items: 23,
      routeV: "/Inventory-Vendor-Product",
      pricingUnit: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 2,
      invproductgallaries: [
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZdYAUXvlwNIrC2A_4oIhFUyBtKtLNHm0buQ&s",
        },
        {
          image:
            "https://images.unsplash.com/photo-1571771894821-ce9b6c11b08e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmFuYW5hc3xlbnwwfHwwfHx8MA%3D%3D",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuFJ45D9B5tq0e9ijztRbX_y7YTbGZzn0T1w&s",
        },
      ],
      name: "Banana",
      basePrice: 22,
      items: 12,
      routeV: "/Inventory-Vendor-Product",
      quantity: 1,
      pricingUnit: "dozen",
      route: "/Inventory-Product",
    },
    {
      id: 3,
      invproductgallaries: [
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuIg-NlWsGyHPdsYHRMPHoynmyVvtx9GLNRA&s",
        },
        {
          image:
            "https://cdn.britannica.com/24/174524-050-A851D3F2/Oranges.jpg",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoYq2s3xqHMWVzlAUXGh-LnB20Z3nhC8Y46liCklv90f0ZSaxThNe1EFCAETyg5vnHlK8&usqp=CAU",
        },
      ],
      name: "Orange",
      basePrice: 43,
      items: 97,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      pricingUnit: "dozen",
      route: "/Inventory-Product",
    },
    {
      id: 4,
      invproductgallaries: [
        {
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Mangos_-_single_and_halved.jpg/640px-Mangos_-_single_and_halved.jpg",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmgfExstNMpVm0dVH-xKurYdLlQpZpgJxHaw&s",
        },
        {
          image:
            "https://www.finedininglovers.com/sites/g/files/xknfdk626/files/styles/article_1200_800_fallback/public/2021-06/best-mango-varieties%C2%A9iStock.jpg?itok=KHYB1X__",
        },
      ],
      name: "Mango",
      basePrice: 33,
      items: 56,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      pricingUnit: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 5,
      invproductgallaries: [
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgg1d49uY8Qx_SA9IgB4VC_30POp9wq_Vpig&s",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnfVxPeSWw5EY2KodFM3M8qUIEbTeS5fjt4AucycvBPT9R9JSF3GZGD3zKJV8OaCFlYpQ&usqp=CAU",
        },
        {
          image:
            "https://us.123rf.com/450wm/spyrakot/spyrakot1901/spyrakot190100061/116630376-single-bunch-grapes-on-vine-close-up-of-bunches-of-ripe-wine-grapes-on-vine-blue-grapes-in-a.jpg?ver=6",
        },
      ],
      name: "Grapes",
      basePrice: 89,
      items: 9,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      pricingUnit: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 6,
      invproductgallaries: [
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtywKdongNUiZ8YexgVsgx4hOkSSONFea6eA&s",
        },
        {
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Red_Apple.jpg/640px-Red_Apple.jpg",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjxtWEWP3_ve_0aBf9k1S8-PgZjeu1ZXmWYQ&s",
        },
      ],
      name: "Apple",
      basePrice: 12,
      items: 33,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      pricingUnit: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 7,
      invproductgallaries: [
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSE_i197uwJl2xW0MfAp6oWvcefhLKz85H_HA&s",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4-zuEp1m6DvI71kXWfNTbMsKJIUdWUO2m7A&s",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEQB1rnCE5bdACpu6QxKacY9P5DBE6sScoHg&s",
        },
      ],
      name: "Pear",
      basePrice: 91,
      items: 12,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      pricingUnit: "kg",
      route: "/Inventory-Product",
    },
  ];

  const [afterApi, setAfterApi] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  //Get Listing
  useEffect(() => {
    if (user === "superadmin") {
      setData(events);
      return;
    }
    setAfterApi(false);
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 12,
        skip: 0,
        page: page,
        sortBy: "createdAt:desc",
        ...(selectedBranch && { vendorbranchId: selectedBranch?.id }),
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page, selectedBranch]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-product/vendor-query-own-products`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        setData(res?.data?.rows);
        setTotalPages(res?.data?.totalPages);
        setAfterApi(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <Button
        className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  left-0 transform -translate-y-1/2"
        onClick={onClick}
      >
        <LeftOutlined className="text-white font-semibold" />
      </Button>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <Button
        className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  right-0 transform -translate-y-1/2"
        onClick={onClick}
      >
        <RightOutlined className="text-white font-semibold" />
      </Button>
    );
  };

  const pageHandler = (e) => {
    setPage(e);
  };

  return (
    <div className="flex w-full  pb-[8rem] flex-col justify-center mt-4 ">
      {user === "superadmin" && (
        <div className=" w-full">
          <Filter />
        </div>
      )}
      <div
        className={`${
          user === "superadmin" ? "hidden" : "flex justify-end mt-4 gap-3 mb-4"
        }`}
      >
        <div
          onClick={() => {
            setShowBranch(true);
          }}
          className="bg-white w-[45%] border rounded-lg md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0"
        >
          <div className="w-full  md:w-auto md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div className="flex items-center space-x-3 w-full md:w-auto">
              <div className="relative w-full">
                <button
                  id="filterDropdownButton"
                  data-dropdown-toggle="filterDropdown"
                  className="mr-3 w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900  focus:z-10     "
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="h-4 w-4 mr-2 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Filter By
                  <svg
                    className="-mr-1 ml-1.5 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center bg-[#f9fbfb] border border-gray-300 rounded-md px-4 py-3 w-[300px] shadow-sm">
          <input
            type="text"
            placeholder="Search Product"
            className="bg-transparent focus:outline-none text-gray-500 placeholder-gray-400 flex-grow"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-[#00b0a6]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M11 19a8 8 0 100-16 8 8 0 000 16zm5-1l5 5"
            />
          </svg>
        </div>

        {user === "vendor-owner" && (
          <button
            type="button"
            onClick={() => {
              router("/Inventory-AddProduct");
            }}
            className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
          >
            Add Product
          </button>
        )}
      </div>
      <div
        className={`${
          selectedBranch === null
            ? "hidden"
            : "bg-white w-full p-3 rounded-lg shadow-md mb-4 flex items-center justify-between px-4"
        }`}
      >
        <h1 className="font-semibold text-primary">{selectedBranch?.name} </h1>
        <h1
          className="font-semibold text-gray-800 cursor-pointer"
          onClick={() => {
            setSelectedBranch(null);
          }}
        >
          Clear Filter
        </h1>
      </div>
      {/* 
      <section class="w-fit mx-auto grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5">
        {data?.map((event) => (
          <div class="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">
            <a href="#">
              <Carousel
                arrows
                prevArrow={<CustomPrevArrow />}
                nextArrow={<CustomNextArrow />}
              >
                {event?.invproductgallaries?.map((coverPhoto, index) => (
                  <div key={index}>
                    <img
                      src={coverPhoto?.image}
                      alt="Product"
                      class="h-44 w-72 object-cover rounded-t-xl"
                    />
                  </div>
                ))}
              </Carousel>
             
              <div class="px-4 py-3 w-72">
                <span class="text-gray-400 mr-3 uppercase text-xs">Brand</span>
                <p class="text-lg font-bold text-black truncate block capitalize">
                  Product Name
                </p>
                <div class="flex items-center">
                  <p class="text-lg font-semibold text-black cursor-auto my-3">
                    $149
                  </p>
                  <del>
                    <p class="text-sm text-gray-600 cursor-auto ml-2">$199</p>
                  </del>
                  <div class="ml-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-bag-plus"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"
                      />
                      <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                    </svg>
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </section> */}

      {/* w-full flex flex-wrap md:gap-5 gap-3 gap-y-8 mt-2 justify-center */}
      <div className="w-full grid grid-cols-4 gap-5">
        {data?.map((event) => (
          <div key={event.id} className="w-full">
            <Carousel
              arrows
              prevArrow={<CustomPrevArrow />}
              nextArrow={<CustomNextArrow />}
            >
              {event?.invproductgallaries?.map((coverPhoto, index) => (
                <div key={index}>
                  {/* <img
                    className="w-full h-36 rounded-tr-[1.2rem] rounded-tl-[1.2rem] object-center items-center object-cover"
                    src={coverPhoto?.image}
                    alt={event.name}
                  /> */}
                  {/* <img
                    className="w-full h-36 rounded-tr-[1.2rem] rounded-tl-[1.2rem] object-center object-cover"
                    src={coverPhoto?.image}
                    alt={event.name}
                  /> */}
                  <img
                    className="w-full h-36 rounded-tr-[1.2rem] rounded-tl-[1.2rem] object-fill"
                    src={coverPhoto?.image}
                    alt={event.name}
                  />
                </div>
              ))}
            </Carousel>
            <div className="px-6 py-3 bg-white rounded-br-[1.2rem] rounded-bl-[1.2rem] relative">
              <div
                onClick={() => {
                  if (user === "vendor-owner") {
                    dispatch({ type: "Product", num: event });
                    dispatch({ type: "EDIT_PRODUCT", num: true });
                    dispatch({ type: "PRODUCT_ID", num: event?.id });
                    router("/Inventory/ProductDetail");
                    return;
                  }
                  dispatch({ type: "Product", num: event });
                  router(role === "buyer" ? event?.route : event.routeV);
                }}
                className="font-semibold w-full flex flex-col items-center justify-center text-center text-xl hover:text-[#1BA397] cursor-pointer"
              >
                {event.name}
              </div>
              <p className="text-gray-600 w-full text-center font-semibold capitalize ">
                ${event?.basePrice}/{event?.pricingUnit}
              </p>
              {user !== "vendor-owner" && (
                <div className="w-full flex items-center mt-2 mb-2 justify-center">
                  {isAddedToCart(event.id) ? (
                    <button
                      className="bg-red-600 rounded-xl cursor-pointer pl-5 pr-5 text-semibold text-white"
                      onClick={() => removeFromCart(event.id)}
                    >
                      Remove from Cart
                    </button>
                  ) : (
                    <button
                      className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 text-semibold text-white"
                      onClick={() => addToCart(event)}
                    >
                      Add to Cart
                    </button>
                  )}
                </div>
              )}
              {user !== "vendor-owner" &&
                (isAddedToFav(event.id) ? (
                  <img
                    onClick={() => removeFromFav(event.id)}
                    src={like}
                    alt=""
                    className="w-4 h-4 absolute cursor-pointer right-[5px] top-[5px]"
                  />
                ) : (
                  <img
                    onClick={() => addToFav(event)}
                    src={dislike}
                    alt=""
                    className="w-4 h-4 cursor-pointer absolute right-[5px] top-[5px]"
                  />
                ))}
              {user === "vendor-owner" && (
                <p className="text-red-500 w-full text-center font-semibold ">
                  {event?.stock}{" "}
                  <span className="text-gray-600 w-full text-center font-semibold">
                    {" "}
                    Items Left
                  </span>
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={`flex justify-center mt-7`}>
        <Pagination
          defaultCurrent={1}
          total={totalPages * 10}
          showSizeChanger={false}
          onChange={pageHandler}
          current={page}
        />
      </div>
      {cart.length > 0 && user !== "vendor-owner" && <Footer />}
      {showBranch && (
        <BranchModal
          setShow={setShowBranch}
          setSelectedBranch={setSelectedBranch}
        />
      )}
    </div>
  );
};

export default EventCard;

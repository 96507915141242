import React, { useState, useEffect } from "react";
import back5 from "../../imgs/back5.png";
import { Select, Switch, Pagination } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import ErrorModal from "../Merchant/Modals/errorModal";
import logo from "../../imgs/logo12.png";
import SuccessModal from "../modals/successModal";
import { useNavigate } from "react-router-dom";
const Main = () => {
  const router = useNavigate();
  const [data, setData] = useState([]);
  const [role, setRole] = useState(null);
  const [ip, setIp] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [blacklisted, setBlacklisted] = useState(null);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const [addModal, setAddModal] = useState(false);
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [show1, setShow1] = useState("");
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [message, setMessage] = useState("");
  

  const pageHandler = (e) => {
    setPage(e);
  };
  //Get Listing
  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 9,
        skip: (page - 1) * 9,
        page: page,
        sortBy: "createdAt:desc",
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [refetch, page]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/user-access-restrictions`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        setData(res?.data?.rows);
        setTotalPages(res?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddAccess = () => {
    setAddModal(false);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/user-access-restrictions`,
        {
          roleType: role,
          ip: ip,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((res) => {
        setRefetch(!refetch);
        setMessage("Access Added Successfully");
        setShow(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow1(true);
      });
  };

  const ChangeStatus = () => {
    setChangeStatusModal(false);
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/user-access-restrictions?ip=${ip}&roleType=${role}`,
        {
          blacklisted: !blacklisted,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((res) => {
        setRefetch(!refetch);
        setMessage(
          `Ip Address ${blacklisted ? "Enabled" : "Disabled"} Successfully`
        );
        setShow(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow1(true);
      });
  };

  return (
    <div className="flex items-center justify-center mt-4 flex-col">
      <div className="md:w-[85%] w-full p-4 ">
        {/* header and button */}
        <div
          className="pl-2 pr-2  mt-[1rem] sm:mt-[0rem]"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div className="flex justify-between items-center mt-4">
              <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
                <img
                  className="sm:w-[1.7rem] w-[1.2rem]"
                  src={back5}
                  alt=""
                  onClick={() => {
                    router("/superAdminDashboard");
                  }}
                />
                <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                  Access Setting
                </h2>
              </div>

              <button
                className={` text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md`}
                onClick={() => {
                  setIp("");
                  setRole(null);
                  setAddModal(true);
                }}
              >
                Add Access
              </button>
            </div>
          </div>
        </div>

        <div className="lg:p-12 bg-slate-50 sm:ml-0 w-full  w-full mt-[2rem] mb-8 bg-slate-50 p-[35px] rounded-2xl relative">
          <div className="grid grid-cols-1 w-full sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {data?.map((data, index) => (
              <div className="bg-white shadow-md relative p-4 rounded-md">
                <p className="font-medium text-gray-500 text-[0.9rem]">
                  {data?.ip}
                </p>
                <h1 className="text-[1.1rem] font-semibold text-primary capitalize">
                  {data?.roleType}
                </h1>
                <Switch
                  // checkedChildren="Whitelist"
                  // unCheckedChildren="Blacklist"
                  className="absolute top-4 right-4"
                  size="small"
                  style={{
                    backgroundColor:
                      data?.blacklisted === false ? "#1BA397" : "#c3c6c9",
                  }}
                  checked={data?.blacklisted === false}
                  onClick={() => {
                    setIp(data?.ip);
                    setRole(data?.roleType);
                    setBlacklisted(data?.blacklisted);
                    setChangeStatusModal(true);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={`flex justify-center mt-7`}>
          <Pagination
            defaultCurrent={1}
            total={totalPages * 10}
            showSizeChanger={false}
            onChange={pageHandler}
            current={page}
          />
        </div>
      </div>

      {/* Modal for Payment */}
      <div
        className={`${
          addModal ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[22rem]  pb-6 pt-6 flex flex-col  p-6 bg-white">
          <label className="text-[#596F96] text-[1.2rem] font-semibold mb-2 text-center">
            Add Access
          </label>

          {/* Status Select */}
          <div className="w-full mb-4">
            <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
              Select Role <span className="text-red-500">*</span>
            </label>
            <Select
              placeholder="Select Role"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              className="wow"
              onChange={(e) => setRole(e)}
              value={role}
              options={[
                { label: "Admin", value: "admin" },
                { label: "Employee", value: "employee" },
              ]}
            />
          </div>
          <div className="w-full mb-4">
            <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
              IP Address <span className="text-red-500">*</span>
            </label>
            <form className="flex items-center justify-between w-full bg-white border-[0.3px] border-gray-300 rounded-md text-black text-center py-1 px-2">
              <input
                type="text"
                placeholder="IP Address"
                className="p-2 rounded-l focus:outline-none"
                style={{ border: "none" }}
                value={ip}
                onChange={(e) => setIp(e.target.value)}
              />
            </form>
          </div>

          {/* Buttons */}
          <div className="flex justify-end">
            <button
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              onClick={() => {
                setAddModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
              onClick={() => {
                AddAccess();
                setAddModal(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      {/* Change Status Modal */}
      <div
        className={`${
          changeStatusModal ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[22rem] pb-6 flex items-center p-3 bg-white flex-col">
          <img src={logo} alt="" className="w-20 h-20 mt-4" />
          <p className="text-center text-[0.95rem] mt-3 mb-5 w-[85%]">
            Are you sure you want to{" "}
            {blacklisted === false ? "disable" : "enable"} this ip address?
          </p>
          <div className="flex">
            <button
              onClick={() => setChangeStatusModal(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Cancel
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
              onClick={() => {
                ChangeStatus();
              }}
            >
              {blacklisted === false ? "Disable" : "Enable"}
            </button>
          </div>
        </div>
      </div>
      {/* Error Modal */}

      {show1 && <ErrorModal show={show1} setShow={setShow1} err={err} />}
      {show && <SuccessModal show={show} setShow={setShow} message={message} />}
    </div>
  );
};

export default Main;

import React, { useEffect } from "react";
import AttendanceDashboardWrapper from "../components/AttendanceWrapper";
import { useDispatch } from "react-redux";
import DevicesMain from "./DevicesMain";

const Devices = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "4" });
  }, []);

  return (
    <AttendanceDashboardWrapper>
      <DevicesMain />
    </AttendanceDashboardWrapper>
  );
};

export default Devices;

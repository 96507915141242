import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import across from "../../../imgs/across.png";
import dayjs from "dayjs";
import gallary from "../../../imgs/picture.png";
import device from "../../../imgs/sign.png";
import figA from "../../../imgs/fingerActive.png";
import { DatePicker, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import codeHandler from "../../Register/codeHandler/codehandle";
import axios from "axios";
import { Button } from "antd";
import country from "../../../constants/country";
import { DeleteFilled, UploadOutlined } from "@ant-design/icons";
import ApproveModal from "../modal/approveModal";
import moment from "moment";
let file;
const SelectLocation = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const popData = useSelector((state) => state.popData);
  const profileData = useSelector((state) => state.profileData);
  const namePerson = useSelector((state) => state.namePerson);
  const token = useSelector((state) => state.token);
  const penVerStatus = useSelector((state) => state.penVerStatus);
  const dataUser = useSelector((state) => state.dataUser);
  const veriSelectedPensioner = useSelector(
    (state) => state.veriSelectedPensioner
  );
  const [displaysig, setDisplaysig] = useState("");
  const [display, setDisplay] = useState("");
  const [pop, setPop] = useState(false);
  const [url, setUrl] = useState("");
  // const [pId, setPid] = useState("");
  const [booklet, setBooklet] = useState("");
  const [year, setYear] = useState("2024");
  const [issueDate, setIssueDate] = useState(null);
  const [sigD, setSigD] = useState("");
  const [show, setShow] = useState(false);
  const [showVer, setShowVer] = useState(false);
  const [err, setErr] = useState("");
  const [urlSig, setUrlSig] = useState("");
  const [existingData, setExistingData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pensionBookData, setPensionBookData] = useState([
    {
      year: new Date().getFullYear().toString(),
      bookletNo: "",
      issueDate: "",
      image: "",
      signature: "",
      displayImage: "",
      signatureDisplay: "",
    },
  ]);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL}/v1/pension-books/pensioner/${popData?.id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
  //         },
  //       }
  //     )
  //     .then((data) => {
  //       console.log("pensionBook", data?.data);
  //       setData(data?.data);
  //     })
  //     .catch(() => {});
  // }, []);

  // const Submit = () => {
  //   // Iterate through each item in pensionBookData
  //   pensionBookData.forEach((item) => {
  //     // Validate that all fields are filled
  //     if (
  //       item.bookletNo === "" ||
  //       item.issueDate === "" ||
  //       item.image === "" ||
  //       item.signature === ""
  //     ) {
  //       setErr("Please fill all fields");
  //       setShow(true);
  //       return;
  //     }

  //     // If validation passes, call the Update function for each item
  //     Update(item);
  //   });
  // };

  // const Update = (item) => {
  //   let api = `${process.env.REACT_APP_BASE_URL}/v1/pension-books/add-by-staff?pensionerUserId=${popData?.id}`;
  //   let method = "post";

  //   // Prepare the body using the item data
  //   let body = {
  //     bookInfo: {
  //       bookletNo: item.bookletNo,
  //       frontImg: item.image, // Use item.image instead of url
  //       year: item.year,
  //       issueDate: item.issueDate,
  //     },
  //     consumerInfo: {
  //       ip: "yyy.yy.y.y",
  //       location: "14.37854898945,38.9409095",
  //     },
  //   };

  //   axios({
  //     method: method,
  //     url: api,
  //     data: body,
  //     headers: {
  //       Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
  //     },
  //   })
  //     .then((response) => {
  //       console.log("Datapen", response);

  //       // Call the Consent function with the id from the response and the signature from item
  //       Consent(response.data?.id, item.signature);
  //     })
  //     .catch((err) => {
  //       setErr(err.response.data.message);
  //       setShow(true);
  //     });
  // };

  // const Consent = (id, signature) => {
  //   let body = {
  //     pensionbookId: id,
  //     signature: signature, // Use the signature from the item
  //     consentType: "pensionbook",
  //     userId: profileData.user.id,
  //   };

  //   axios
  //     .post(`${process.env.REACT_APP_BASE_URL}/v1/user-consent`, body, {
  //       headers: {
  //         Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
  //       },
  //     })
  //     .then(() => {
  //       setShowVer(false);
  //       Verification();
  //     })
  //     .catch((err) => {
  //       setShowVer(false);
  //       setErr(err.response.data.message);
  //       setShow(true);
  //     });
  // };

  // const Verification = () => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_BASE_URL}/v1/users/${profileData.user.id}/change-pensioner-verification-status`,
  //       {
  //         verificationStatus: "Pension Book Distributed",
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
  //         },
  //       }
  //     )
  //     .then(() => {
  //       setShowVer(false);
  //       // RemoveQueue();
  //       // router("/pensionerVerification/Profile");
  //       dispatch({
  //         type: "PEN_VER_STATUS",
  //         num: "Pension Book Distributed",
  //       });
  //     })
  //     .catch((err) => {
  //       setShowVer(false);
  //       setErr(err.response.data.message);
  //       setShow(true);
  //     });
  // };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pension-books/pensioner/${popData?.id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setExistingData(data?.data?.rows);
      })
      .catch(() => {});
  }, [refetch]);

  const Submit = () => {
    console.log(pensionBookData, "Data");

    if (
      pensionBookData[0] &&
      pensionBookData[0].bookletNo === "" &&
      pensionBookData[0].issueDate === "" &&
      pensionBookData[0].image === "" &&
      pensionBookData[0].signature === ""
    ) {
      // Remove the first index if all fields are empty
      pensionBookData.splice(0, 1);
    }
    console.log(pensionBookData, "Data After flitering");
    processPensionBook(0);
  };

  const processPensionBook = (index) => {
    if (index >= pensionBookData.length) {
      // All pension books have been processed, call Verification
      Verification();
      return;
    }

    if (
      existingData.find((item) => item.year === pensionBookData[index].year)
    ) {
      // If the pension book already exists, skip to the next one
      processPensionBook(index + 1);
      return;
    }

    const item = pensionBookData[index];

    if (
      item.bookletNo === "" ||
      item.issueDate === "" ||
      item.image === "" ||
      item.signature === ""
    ) {
      setErr(`Please fill all fields for year ${item.year}`);
      setShow(true);
      return;
    }

    Update(item, index);
  };

  const Update = (item, index) => {
    const api = `${process.env.REACT_APP_BASE_URL}/v1/pension-books/add-by-staff?pensionerUserId=${popData?.id}`;
    const method = "post";

    const body = {
      bookInfo: {
        bookletNo: item.bookletNo,
        frontImg: item.image,
        year: item.year,
        issueDate: item.issueDate,
      },
      consumerInfo: {
        ip: "yyy.yy.y.y",
        location: "14.37854898945,38.9409095",
      },
    };

    axios({
      method: method,
      url: api,
      data: body,
      headers: {
        Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
      },
    })
      .then((response) => {
        console.log("Datapen", response);
        Consent(response.data?.id, item.signature, index);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
        setRefetch(!refetch);
      });
  };

  const Consent = (id, signature, index) => {
    const body = {
      pensionbookId: id,
      signature: signature,
      consentType: "pensionbook",
      userId: profileData.user.id,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/user-consent`, body, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then(() => {
        setShowVer(false);
        if (
          pensionBookData[index + 1] &&
          pensionBookData[index + 1].bookletNo === "" &&
          pensionBookData[index + 1].issueDate === "" &&
          pensionBookData[index + 1].image === "" &&
          pensionBookData[index + 1].signature === ""
        ) {
          // Remove the first index if all fields are empty
          pensionBookData.splice(0, 1);
        }
        processPensionBook(index + 1);
      })
      .catch((err) => {
        setShowVer(false);
        setErr(err.response.data.message);
        setShow(true);
      });
  };

  const Verification = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/users/${profileData.user.id}/change-pensioner-verification-status`,
        {
          verificationStatus: "Pension Book Distributed",
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        setShowVer(false);
        dispatch({
          type: "PEN_VER_STATUS",
          num: "Pension Book Distributed",
        });
        setSuccess(true);
        // router("/pensionerVerification/listing");
      })
      .catch((err) => {
        setShowVer(false);
        setErr(err.response.data.message);
        setShow(true);
      });
  };

  const imageHandler = async (e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setDisplaysig(base641);
    console.log("base", base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setPop(false);
        setSigD(false);
        setUrlSig(data.data.url);

        setPensionBookData((prev) => {
          return prev.map((item) => {
            if (item.year === year) {
              return {
                ...item,
                signature: data.data.url,
                signatureDisplay: base641,
              };
            }
            return item;
          });
        });
        e.target.value = null;
      })
      .catch(() => {});
  };
  const imageHandlerSig = (file) => async () => {
    setPop(false);
    setSigD(false);
    const base641 = await convertToBase64(file);
    setDisplaysig(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        console.log("Url", data);
        setUrlSig(data.data.url);
      })
      .catch(() => {});
  };
  const imageHandlerr = async (e, year) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setDisplay(base641);
    console.log("base", base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setPensionBookData((prev) => {
          return prev.map((item) => {
            if (item.year === year) {
              return { ...item, image: data.data.url, displayImage: base641 };
            }
            return item;
          });
        });
        setUrl(data.data.url);
        e.target.value = null;
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  function GetWhitSigPic() {
    var json = { action: "06" };
    var encode = codeHandler?.encode(JSON.stringify(json), "base64");
    console.log("encode", encode);

    // Create a FormData object to send the data
    var formData = new FormData();
    formData.append("data", encode);

    axios
      .post(`http://localhost:28815/`, encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // Handle success response
        console.log("Success:", response.data);
      })
      .catch(function (error) {
        console.log("Error:", error);
      });
  }

  function ConfirmSign() {
    var json = { action: "07" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("Test", response);
        // setDisplay("data:image/png;base64," + response?.data?.SigPicBase64);
        const base64Str = response?.data?.SigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );
        console.log("Tester", file);
        imageHandlerSig(file)();
      })
      .catch(function () {});
  }
  function ConfirmSig() {
    setPop(false);
    setSigD(false);
    var json = { action: "14" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        const base64Str = response?.data?.FigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );

        imageHandlerSig(file)();
      })
      .catch(function () {});
  }

  function ReSign() {
    var json = { action: "08" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function () {
        setDisplaysig("");
      })
      .catch(function () {});
  }

  const dateHandler = (setState, check) => (event) => {
    if (event === null || event === undefined || event === "") {
      // Handle the case when the date picker is cleared
      setState(null);
      return;
    }
    if (check === "tg") {
      setState(event);
    } else if (check === "tgf") {
      let wow;
      if (event.$M <= 9) {
        if (event.$D <= 9) {
          wow = `${event.$y}-0${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-0${Number(event.$M) + 1}-${event.$D}`;
        }
      } else {
        if (event.$D <= 9) {
          wow = `${event.$y}-${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-${Number(event.$M) + 1}-${event.$D}`;
        }
      }
      setState(wow);
    } else {
      setState(event.target.value);
    }
  };
  const RemoveQueue = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/v1/verification-queue/remove?userId=${veriSelectedPensioner?.id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {})
      .catch((err) => {
        setErr(err?.response?.data.message);
        setShow(true);
      });
  };

  // Function to handle booklet number change
  const handleBookletNoChange = (bookletNo, year) => {
    setPensionBookData((prev) => {
      return prev.map((item) => {
        if (item.year === year) {
          return { ...item, bookletNo: bookletNo };
        }
        return item;
      });
    });
  };

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current > disabledBeforeDate1;
  };
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex items-center justify-center flex-col p-4 relative">
        <div
          className={`rounded-sm mx-12 flex  mb-[2rem] items-center flex-col gap-2 bg-white m-5 p-6 w-[80%]`}
        >
          <h2 className="w-full font-semibold text-[0.95rem]">
            Pension book details
          </h2>

          <div className="grid sm:grid-cols-2 sm:gap-y-2 sm:gap-x-5 mt-3 grid-cols-1 w-full">
            <Select
              placeholder="Select Year"
              optionFilterProp="children"
              size="middle"
              className="wow"
              onChange={(value) => {
                setYear(value);
                setPensionBookData((prev) => {
                  const existingYearIndex = prev.findIndex(
                    (item) => item.year === value
                  );

                  if (existingYearIndex !== -1) {
                    // If the year already exists, just return the current array without changes.
                    return prev;
                  } else {
                    // Add a new entry for the selected year.
                    return [
                      ...prev,
                      {
                        year: value,
                        bookletNo: "",
                        issueDate: "",
                        image: "",
                        signature: "",
                      },
                    ];
                  }
                });
              }}
              // options={[
              //   { label: "2024", value: "2024" },
              //   { label: "2025", value: "2025" },
              // ]}
              options={[
                {
                  label: new Date().getFullYear().toString(),
                  value: new Date().getFullYear().toString(),
                },
                {
                  label: (new Date().getFullYear() + 1).toString(),
                  value: (new Date().getFullYear() + 1).toString(),
                },
              ]}
              value={year}
            />

            <input
              type="text"
              //   onChange={(e) => {
              //     // setBooklet(e.target.value);

              //   }}
              onChange={(e) => handleBookletNoChange(e.target.value, year)}
              value={
                pensionBookData.find((item) => item.year === year)?.bookletNo
              }
              //   value={booklet}
              placeholder="Booklet #"
              className=" lg:text-[1.1rem] w-[100%] bg-[#F8F8F8]  rounded-[0.3rem] p-2  py-[9px] mb-2"
            />

            <DatePicker
              placeholder="Issued Date"
              showTime
              // value={
              //   issueDate1 ? dayjs(issueDate1, "YYYY-MM-DD hh:mm A") : undefined
              // }
              format="MM/DD/YYYY hh:mm A"
              disabledDate={disabledDate1}
              className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
              // onChange={(date, dateString) => {
              //   setissueDate1(date ? date.format("MM/DD/YYYY hh:mm A") : null);
              // }}
              value={
                pensionBookData.find((item) => item.year === year)?.issueDate
                  ? dayjs(
                      `${
                        pensionBookData.find((item) => item.year === year)
                          ?.issueDate
                      }`,
                      "MM/DD/YYYY hh:mm A"
                    )
                  : null
              }
              onChange={(date, dateString) => {
                setPensionBookData((prev) => {
                  return prev.map((item) => {
                    if (item.year === year) {
                      return {
                        ...item,
                        issueDate: date.format("MM/DD/YYYY hh:mm A"),
                      };
                    }
                    return item;
                  });
                });
              }}
            />

            {/* <DatePicker
              placeholder="Issued Date"
              style={{
                marginBottom: "1.2rem",
                backgroundColor: "#F8F8F8",
                border: "none",
                paddingBottom: "9px",
                paddingTop: "9px",
              }}
              //   onChange={dateHandler(setIssueDate, "tgf")}
              //   value={issueDate ? dayjs(`${issueDate}`, "YYYY-MM-DD") : null}
              value={
                pensionBookData.find((item) => item.year === year)?.issueDate
                  ? dayjs(
                      `${
                        pensionBookData.find((item) => item.year === year)
                          ?.issueDate
                      }`,
                      "YYYY-MM-DD"
                    )
                  : null
              }
              onChange={(date, dateString) => {
                setPensionBookData((prev) => {
                  return prev.map((item) => {
                    if (item.year === year) {
                      return { ...item, issueDate: dateString };
                    }
                    return item;
                  });
                });
              }}
            /> */}
          </div>
          <div className="w-full">
            <div
              className={`flex items-center flex-col justify-center rounded-lg text-center sm:w-[23rem] h-[13rem] relative`}
            >
              {pensionBookData.find((item) => item.year === year)
                ?.displayImage ? (
                <>
                  <img
                    src={
                      pensionBookData.find((item) => item.year === year)
                        ?.displayImage
                    }
                    alt="Uploaded"
                    className="w-full h-full  rounded-lg"
                  />
                  <button
                    className="absolute bottom-1 right-1"
                    onClick={() => {
                      setUrl("");
                      setDisplay("");
                    }}
                  >
                    <DeleteFilled
                      className="text-2xl text-primary cursor-pointer "
                      onClick={() => {
                        setPensionBookData((prev) => {
                          return prev.map((item) => {
                            if (item.year === year) {
                              return {
                                ...item,
                                image: "",
                                displayImage: "",
                              };
                            }
                            return item;
                          });
                        });
                      }}
                    />
                  </button>
                </>
              ) : (
                <div className="w-full h-full">
                  <div className="w-full h-full flex flex-col gap-1 flex bg-gray-100 p-4 items-center justify-center">
                    <div className="flex items-center w-full justify-center flex-col">
                      <UploadOutlined className="text-4xl text-primary cursor-pointer " />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <label
              htmlFor="file"
              className="text-5xl mt-2 text-primary w-[23rem] cursor-pointer flex flex-col gap-2 items-center justify-center"
            >
              <input
                id="file"
                type="file"
                className="hidden"
                accept="image/*"
                onChange={(e) => imageHandlerr(e, year)}
              />
              <div className="w-full text-sm p-2 cursor-pointer text-center bg-white border-primary text-primary border-2  px-4 rounded-lg">
                Upload
              </div>
            </label>
            <p className="mt-2 ">
              I, <span className="font-semibold">{namePerson}</span>, hereby
              confirm that I have received my pension book for the{" "}
              <span className="font-semibold">year {year}</span>.
            </p>
          </div>
          <h2 className="w-full font-semibold mt-8">Signature/Fingerprint</h2>
          <div className="w-full">
            <div
              className={`border-dashed bg-white border-2 border-primary flex items-center justify-center rounded-lg text-center sm:w-[23rem] sm:h-[16rem] w-[18rem] h-[13rem] relative`}
            >
              {pensionBookData.find((item) => item.year === year)
                ?.signatureDisplay ? (
                <>
                  <img
                    src={
                      pensionBookData.find((item) => item.year === year)
                        ?.signatureDisplay
                    }
                    alt="Uploaded"
                    className="w-full h-full"
                  />
                  <button
                    className="absolute bottom-1 right-1"
                    onClick={() => {
                      setUrlSig("");
                      setDisplaysig("");
                    }}
                  >
                    <DeleteFilled
                      className="text-2xl text-primary cursor-pointer "
                      onClick={() => {
                        setPensionBookData((prev) => {
                          return prev.map((item) => {
                            if (item.year === year) {
                              return {
                                ...item,
                                signature: "",
                                signatureDisplay: "",
                              };
                            }
                            return item;
                          });
                        });
                      }}
                    />
                  </button>
                </>
              ) : (
                <div className="w-[80%] flex flex-col gap-1 flex items-center ">
                  {/* <UploadOutlined className="text-5xl text-primary cursor-pointer " /> */}
                  <div className="flex items-center justify-center">
                    <label
                      // htmlFor="file-upload"
                      className="text-5xl text-primary cursor-pointer"
                    >
                      <UploadOutlined />
                    </label>
                    {/* <input
                      id="file-upload"
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={imageHandler(
                        setDisplay,
                        setSignature,
                        setIsLoading
                      )}
                    /> */}
                  </div>
                  <p>Upload Signature or Thumbprint Here</p>
                  <Button
                    onClick={() => {
                      setPop(true);
                    }}
                    className="mt-4 w-[80%] cursor-pointer text-center bg-white border-primary text-primary border-2  px-4 rounded-lg"
                  >
                    Upload
                  </Button>
                </div>
              )}
            </div>
            {sigD && (
              <div
                className="flex gap-5 sm:w-[23rem] w-[18rem]"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  display: "inline-block",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    GetWhitSigPic();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Open Pad
                </button>
                <button
                  type="button"
                  onClick={() => {
                    ConfirmSign();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Get Sign
                </button>
                <button
                  type="button"
                  onClick={() => {
                    ReSign();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Reset
                </button>
              </div>
            )}
          </div>
        </div>
        {penVerStatus === "Biometric Verified" && (
          <div className={`flex w-[80%] items-center gap-2 mb-5`}>
            <button
              type="button"
              onClick={() => {
                if (issueDate === null) {
                  setErr("Please Enter Issue Date");
                  setShow(true);
                } else if (booklet === "") {
                  setErr("Please Enter Booklet Number");
                  setShow(true);
                } else if (url === "") {
                  setErr("Please Upload pensionbook Image");
                  setShow(true);
                } else if (urlSig === "") {
                  setErr("Please Upload Signature to Verify");
                  setShow(true);
                } else setShowVer(true);
              }}
              className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 `}
            >
              Update and verified
            </button>
            <button
              type="button"
              onClick={() => {
                router("/pensionerVerification/Profile");
              }}
              className={`lg:text-[1rem] bg-gradient-to-tr  bg-red-600 text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Unverified
            </button>
          </div>
        )}
      </div>
      <div
        className={`${
          pop ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 flex items-center p-3 bg-white flex-col">
          <h1 className="text-xl text-primary font-semibold">Choose</h1>
          <div className="w-[80%] flex items-center justify-between mt-10 mb-10">
            <img
              onClick={ConfirmSig}
              src={figA}
              alt=""
              className="w-14 h-14 cursor-pointer"
            />
            <img
              onClick={() => {
                setSigD(true);
                setPop(false);
              }}
              src={device}
              alt=""
              className="w-18 h-14 cursor-pointer"
            />
            <label>
              <img src={gallary} alt="" className="w-14 h-14 cursor-pointer" />
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => imageHandler(e)}
              />
            </label>
          </div>
          <div className="flex items-center justify-end w-[80%]">
            <button
              onClick={() => setPop(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-6 pr-6 text-white bg-primary"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <button
          onClick={() => Submit()}
          className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
        >
          Submit
        </button>
      </div>

      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 z-[9999] flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      {success && (
        <ApproveModal
          show={success}
          setVerify={setSuccess}
          title={"Pensioner Added Successfully"}
          text="Pensioner has been added successfully and is also verified"
          route="/pensionerVerification/listing"
          type="pensionBook"
        />
      )}
    </div>
  );
};

export default SelectLocation;

import React, { useEffect, useState } from "react";
import PageHeader from "../../pensionerVerification/pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
// import axios from "axios";
// import ErrorModal from "../Merchant/Modals/errorModal";
const SelectLocation = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const selectedProgram = useSelector((state) => state.selectedProgram);
  const distributionCenter = useSelector((state) => state.distributionCenter);
  const SelectedMonth = useSelector((state) => state.SelectedMonth);
  const SelectedYear = useSelector((state) => state.SelectedYear);
  const dataUser = useSelector((state) => state.dataUser);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [month, setMonth] = useState(SelectedMonth);
  const [record, setRecord] = useState([]);
  const [afterApi, setAfterApi] = useState(false);

  //Get Listing
  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 30,
        skip: 0,
        sortBy: "createdAt:desc",
        distributioncoverageId: distributionCenter?.id,
        month: month,
        year: SelectedYear,
        ...{ program: `${selectedProgram}` },
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [month]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    setAfterApi(false);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-center-time-manager`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        setRecord(res?.data?.rows);
        setAfterApi(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatProgramNames = (data) => {
    return data
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };

  const Status = (date) => {
    let today = new Date();
    let date1 = new Date(date);

    // Reset both dates' time part to midnight (00:00:00) for date-only comparison
    today.setHours(0, 0, 0, 0);
    date1.setHours(0, 0, 0, 0);

    if (date1 > today) {
      return "Pending";
    } else if (date1 < today) {
      return "Completed";
    } else {
      return "In-Process";
    }
  };

  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4  min-h-screen">
      <PageHeader
        // title={"Scheduled Dates"}
        // route={"/pensionerVerificationScheduling/selectLocation"}
        title={distributionCenter?.coverage}
        route={"/scheduleVerification/selectProgram"}
        header={true}
      />
      <div className="flex justify-center flex-col items-center">
        <div
          className={`bg-[#F6F4F4] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[90%] w-full`}
        >
          <div className="flex justify-center items-center p-4 flex-col">
            {afterApi && record.length === 0 ? (
              <div className="text-center text-gray-500 flex items-center justify-center p-32 text-lg">
                No Scheduled Dates
              </div>
            ) : (
              <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 grid-cols-1  w-full">
                {record?.map((record, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      dispatch({
                        type: "SELECTED_SLOT",
                        num: {
                          ...record, // Spread the existing properties of the record object
                          status: Status(record?.date), // Add the status based on the date
                        },
                      });

                      router("/scheduleVerification/schedulesDetails");
                    }}
                    className="bg-white p-6  cursor-pointer shadow-md rounded-md mb-4 relative"
                  >
                    <span
                      className={`font-semibold absolute top-5 right-5 text-[0.93rem] ${
                        Status(record?.date) === "In-Process"
                          ? "text-[#E5A608]"
                          : Status(record?.date) === "Completed"
                          ? "text-primary"
                          : "text-gray-500"
                      }`}
                    >
                      {Status(record?.date)}
                    </span>

                    <h2 className="font-semibold text-[#646161] mb-1">
                      Scheduled Date
                    </h2>
                    <div className="mb-2 flex gap-1 w-full items-center justify-between">
                      <div className="bg-primary p-2 w-[33%] text-center font-semibold rounded-md text-white mb-1">
                        {moment(record?.date).format("MM/DD/YYYY")}
                      </div>
                    </div>
                    <div className="flex justify-between mb-2 mt-4">
                      <span className="font-semibold text-[#646161]">
                        Program
                      </span>
                      <span className="font-medium">
                        {formatProgramNames(
                          record?.program === "plwd"
                            ? "PLWD"
                            : record?.program === "pa-medical"
                            ? "PA Medical"
                            : record?.program === "pa-economical"
                            ? "PA Economical"
                            : record?.program
                        )}
                      </span>
                    </div>
                    {/* {record?.verifiedPensioners && (
                      <div className="flex justify-between">
                        <span className="font-semibold text-gray-400">
                          Verified Pensioners
                        </span>
                        <span className="font-medium">
                          {record.verifiedPensioners}
                        </span>
                      </div>
                    )} */}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectLocation;

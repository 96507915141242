import React from "react";
import PageHeader from "../pageHeader/pageHeader";
import Table from "./table";

const SelectLocation = () => {
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-6 min-h-screen">
      <PageHeader
        header={true}
        title={"Priority Requests"}
        route={"/pensionerVerification/selectedProgram"}
      />

      <div className="flex justify-center">
        <div className={`w-[95%] mx-5`}>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default SelectLocation;

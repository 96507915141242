import React, { useEffect } from "react";
import logo from "../../../../imgs/logo12.png";
import slipemail from "../../../../imgs/slipemail.png";
import slipcall from "../../../../imgs/slipchat.png";
import slipbg from "../../../../imgs/mhssspaymentslipbg.png";
import ProfileCard from "../ProfileCard";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const Reciept = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const currentDate = new Date();
  const sapmoney = useSelector((state) => state.sapmoney);
  const voucherPayResponse = useSelector((state) => state.voucherPayResponse);
  const dataUser = useSelector((state) => state.dataUser);
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  const voucherAmount = useSelector((state) => state.voucherAmount);

  const selectedVoucherForCash = useSelector(
    (state) => state.selectedVoucherForCash
  );
  const selectedProgramPensionBook = useSelector(
    (state) => state.selectedProgramPensionBook
  );

  const handlePrint = (index) => {
    const data = voucherPayResponse[index];
    const mhsss = logo;
    const callImg = slipcall;
    const emailImg = slipemail;
    const bg = slipbg;

    // Create a temporary iframe
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    const printFunc = (iframe, mhsss, callImg, emailImg) => {
      const iframeDocument = iframe.contentWindow.document;

      // Construct the printable content
      let printableContent = `
        <html>
          <head>
            <title>Print</title>
            <style>
              body {
                font-size: 25pt;
              }
              @media print {
                .printable-container {
                  -webkit-print-color-adjust: exact;
                  color-adjust: exact;
                  background-color: #ffffff;
                  background-image: url('${bg}');
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 100% 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 0.5rem;
                  margin-bottom: 1.5rem;
                  padding: 0.5rem;
                }
                .d1 {
                  border-radius: 0.25rem;
                  padding-left: 1.5rem;
                  padding-right: 1.5rem;
                  padding-top: 1rem;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                }
                .d2 {
                  width: 6rem; 
                  height: 5rem;
                  padding-top: 1rem;
                }
                .d3 {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                }
                .hh1 {
                  font-weight: 600;
                  text-align: center;
                  width: 65%;
                }
                .d4 {
                  display: flex;
                  width: 80%;
                  flex-direction: column;
                  border-bottom: 1px solid #e2e8f0;
                  padding-bottom: 1rem;
                  font-size: 1rem;
                }
                .pp {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                }
                .ss {
                  color: #718096;
                }
                .d5 {
                  display: flex;
                  flex-direction: column;
                  padding-top: 0.5rem;
                  font-size: 1rem;
                  width: 80%;
                }
                .d6 {
                  padding-top: 1rem;
                  padding-bottom: 1rem;
                  display: flex;
                  flex-direction: column;
                  width: 80%;
                }
                .call {
                  width: 1.25rem;
                  height: 1.25rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 0.5rem;
                }
                .mail {
                  width: 1.25rem;
                  height: 1rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 0.5rem;
                }
                .d7 {
                  display: flex;
                  width: 100%;
                  align-items: center;
                }
                .sss {
                  font-weight: 600;
                }
              }
            </style>
          </head>
          <body>
            <div class="printable-container">
              <div class="d1">
                <img id="mhsss" alt="chippz" class="d2" src="${mhsss}" />
                <div class="d3">
                  <h4 class="hh1">Ministry of Human Services and Social Security</h4>
                </div>
                <div class="d4">
                  <p class="pp">
                    <span class="ss">Voucher Transaction Id</span>
                    <span>${data?.transactionId}</span>
                  </p>
                  <p class="pp">
                    <span class="ss">Date & Time</span>
                    <span>${moment(data?.createdAt).format(
                      "MM/DD/YYYY hh:mm A"
                    )}</span>
                  </p>
                  <p class="pp">
                    <span class="ss">Transaction Type</span>
                    <span>${data?.paymentMethod}</span>
                  </p>
                  <p class="pp">
                    <span class="ss">Status</span>
                    <span class="sss">${data?.status}</span>
                  </p>
                  <p class="pp">
                    <span class="ss">Amount</span>
                    <span class="sss">$${(data?.amountPaid).toFixed(2)}</span>
                  </p>
                  <p class="pp">
                    <span class="ss">Pensioner Name</span>
                    <span class="sss">${
                      selectedPensioner?.user?.person?.firstName
                    } ${selectedPensioner?.user?.person?.lastName}</span>
                  </p>
                  <p class="pp">
                    <span class="ss">Program Name</span>
                    <span>${selectedPensioner?.pensionPrograms[0]?.name}</span>
                  </p>
                  <p class="pp">
                    <span class="ss">Merchants Name</span>
                    <span>${
                      dataUser?.data?.merchuser?.merchCompany?.name
                    }</span>
                  </p>
                </div>
                <div class="d5">
                  <div class="d6">
                    <p class="d7">
                      <img id="emailImg" class="mail" alt="chippz" src="${emailImg}" />
                      ${
                        selectedPensioner?.user?.email
                          ? selectedPensioner?.user?.email
                          : "---"
                      }
                    </p>
                    <p class="d7">
                      <img id="callImg" class="call" alt="chippz" src="${callImg}" />
                      ${
                        selectedPensioner?.user?.countryCode
                          ? selectedPensioner?.user?.countryCode
                          : "--"
                      }${
        selectedPensioner?.user?.phoneNo
          ? selectedPensioner?.user?.phoneNo
          : "--"
      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </body>
        </html>
      `;

      // Set the iframe content
      iframeDocument.open();
      iframeDocument.write(printableContent);
      iframeDocument.close();

      // Print the iframe content
      iframe.onload = () => {
        iframe.contentWindow.print();

        // Remove the iframe from the DOM after printing
        document.body.removeChild(iframe);
      };
    };

    const imageSources = [mhsss, callImg, emailImg];

    // Load images and print content
    let loadedImages = 0;
    imageSources.forEach((src) => {
      const img = new Image();
      img.onload = () => {
        loadedImages++;
        if (loadedImages === imageSources.length) {
          printFunc(iframe, mhsss, callImg, emailImg);
        }
      };
      img.src = src;
    });
  };

  useEffect(() => {
    const updatedVoucherPayResponse = voucherPayResponse?.map((responseObj) => {
      const correspondingVoucher = selectedVoucherForCash?.find(
        (voucher) => voucher.id === responseObj.couponId
      );

      // Check if correspondingVoucher is found
      if (correspondingVoucher) {
        // Add the 'month' property to the responseObj
        responseObj.month = correspondingVoucher?.month;
      }

      return responseObj;
    });

    dispatch({ type: "VOUCHER_PAY_RESPONSE", num: updatedVoucherPayResponse });
    console.log(updatedVoucherPayResponse);
  }, []);

  const getMonthName = (month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[month - 1]; // Subtracting 1 since months are zero-based in JavaScript Date
  };

  // const array = [
  //   {
  //     id: 1,
  //     month: 1,
  //     status: "success",
  //     statusReasons: ["Success"],
  //     transactionId: "123456789",
  //   },
  //   {
  //     id: 2,
  //     month: 2,
  //     status: "failed",
  //     statusReasons: ["Failed"],
  //   },
  //   {
  //     id: 3,
  //     month: 3,
  //     status: "success",
  //     statusReasons: ["Success"],
  //     transactionId: "123456789",
  //   },
  //   {
  //     id: 4,
  //     month: 4,
  //     status: "success",
  //     statusReasons: ["Success"],
  //     transactionId: "123456789",
  //   },
  //   {
  //     id: 5,
  //     month: 5,
  //     status: "failed",
  //     statusReasons: ["Failed"],
  //   },
  //   {
  //     id: 6,
  //     month: 6,
  //     status: "success",
  //     statusReasons: ["Success"],
  //     transactionId: "123456789",
  //   },
  // ];
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        console.error("IP address:", response.data.ip);
      } catch (error) {
        console.error("Error fetching the IP address:", error);
      }
    };

    fetchIp();
  }, []);
  return (
    <div className={`${sapmoney ? "mt-3" : "mt-[-2rem] sm:mt-[-4rem]"}`}>
      <div className="sm:mx-8 mb-12">
        <div className="sm:p-8 p-3 bg-white  mt-8 rounded-lg">
          <div className="w-full sm:p-8 p-2  mb-10 rounded-lg border shadow-md">
            <ProfileCard />
            <hr className="h-px my-8 bg-gray-300 border-0 " />
            <section className="text-gray-700 body-font bg-[#F4F5FE] sm:p-6 p-1 rounded-md sm:w-[50%] w-full sm:mx-auto">
              <h1 className="text-[2rem] font-bold text-center">{`$${voucherAmount?.toFixed(
                2
              )}`}</h1>
              <ul className="sm:space-y-4  p-4 pb-1">
                {voucherPayResponse?.map((voc, index) => (
                  <li key={index}>
                    <div
                      className="w-full p-3 
                   text-gray-900 bg-white
                  
                    border border-gray-300 rounded-lg shadow-sm"
                    >
                      {/* <div className="flex gap-2"> */}
                      {/* <img src={"store1"} alt="store" className="w-6 h-6" /> */}
                      <div className="flex items-center space-x-4">
                        <div
                          className={`h-10 w-10 flex items-center justify-center rounded-full ${
                            voc?.status === "failed"
                              ? "bg-red-100"
                              : "bg-green-100"
                          }`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`h-6 w-6 ${
                              voc?.status === "failed"
                                ? "text-red-500"
                                : "text-green-500"
                            }`}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            {voc?.status === "failed" ? (
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            ) : (
                              <path d="M5 13l4 4L19 7" />
                            )}
                          </svg>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-lg font-semibold text-primary">
                            {selectedProgramPensionBook?.bookletNo} :{" "}
                            {getMonthName(voc?.month)}
                          </span>
                        </div>
                      </div>
                      <div className="mt-2">
                        {voc?.statusReasons &&
                          voc?.statusReasons?.map((reason, index) => (
                            <p className="text-[#DE2827] space-y-2 font-semibold">
                              {reason &&
                                reason.charAt(0).toUpperCase() +
                                  reason.slice(1)}
                            </p>
                          ))}
                      </div>

                      <div className={`${voc?.transactionId ? "" : "hidden"}`}>
                        <span className="w-full text-gray-700 text-lg mt-1 font-semibold">
                          Voucher Cashing ID : {voc?.transactionId}
                        </span>
                      </div>

                      {/* </div> */}

                      <div className="w-full text-gray-500 text-sm mt-1">
                        {moment(voc?.createdAt).format("MM/DD/YYYY hh:mm A")}
                      </div>
                    </div>
                    {voc?.status !== "failed" && (
                      <div className="flex gap-2 p-4">
                        <button
                          onClick={() => {
                            handlePrint(index);
                          }}
                          className={`text-white bg-primary inline-flex pr-2 pl-2 w-full justify-center font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                        >
                          Print Receipt
                        </button>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
              <div className="flex gap-2 p-4">
                <button
                  onClick={() => {
                    sapmoney
                      ? router("/SapMoney")
                      : router(`/Merchant/PensionerProfile/ABC123`);
                  }}
                  className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Done
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reciept;

import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const Chart = () => {
  const data = {
    labels: ["July 3", "July 4", "July 5", "July 6", "July 7", "July 8", " July 9"],
    datasets: [
      {
        label: "Present",
        data: [25, 30, 35, 70, 50, 65, 80],
        backgroundColor: "#1ba397",
        borderColor: "#1ba397",
        borderWidth: 1,
      },
      {
        label: "Absent",
        data: [55, 43, 70, 65, 50, 80, 55],
        backgroundColor: "#d2631d",
        borderColor: "#d85300",
        borderWidth: 1,
      },
      {
        label: "Leave",
        data: [70, 56, 78, 56, 76, 89, 49],
        backgroundColor: "#f2da00",
        borderColor: "#f2da00",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Attendance Summary",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default Chart;

import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import across from "../../../../imgs/across.png";
import { useLocation } from "react-router-dom";
import DepartmentList from "../../../../constants/departmentList";

let data;
const EmployerE = () => {
  const location1 = useLocation();
  const dash = location1.pathname;
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();
  const [employer, setEmployer] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [location, setLocation] = useState("");
  const [isLoadingx, setIsLoadingx] = useState("");
  const [show, setShow] = useState("");
  const user = useSelector((state) => state.user);
  const [err, setErr] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [department, setDepartment] = useState(null);

  // useEffect(() => {
  //   if (!test) {
  //     setTest(true);
  //   } else {
  //     setTest(false);
  //   }
  // }, [data]);

  //Get subroles of employee
  useEffect(() => {
    const api =
      user === "admin"
        ? "v1/privileges/list-roles-privileges/by-admin"
        : "v1/privileges/list-roles-privileges/by-superadmin";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then((data) => {
        const rolesWithLabelValue = data?.data?.roles
          ?.map((role) => ({
            label: role.charAt(0).toUpperCase() + role.slice(1),
            value: role,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        // const rolesWithLabelValue = data?.data?.roles?.map(role => ({ label: role, value: role }));
        setRoles(rolesWithLabelValue);
      })
      .catch(() => {});
  }, []);

  //Get deparmtnet locations and warehouse
  useEffect(() => {
    const api = "v1/mhsss-facility";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then((data) => {
        const dep = data?.data?.rows?.map((dep) => ({
          label:
            dep?.name +
            "" +
            (dep?.type === "master-warehouse"
              ? "    (Master Warehouse)"
              : `    (${dep?.type.charAt(0).toUpperCase() + dep?.type.slice(1)}) `),
          value: dep?.id,
        }));
        console.log("dep", dep);
        setDepartment(dep);
      })

      .catch(() => {});
  }, []);
  useEffect(() => {
    if (profileData.employeeInfo) {
      data = profileData.employeeInfo;
      setEmployer(data?.employer);
      setSelectedRole(data?.role);
      setSupervisor(data?.supervisor);
      setLocation(data?.mhsssfacilityId);
    }
  }, [profileData]);

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoadingx(true);
    if (profileData.employeeInfo === null) {
      const api =
        user === "admin"
          ? "v1/employee-info/by-admin?employeeUserId"
          : "v1/employee-info/by-superadmin?employeeUserId";
      const data = {
        employer,
        supervisor,
        // departmentOrLocation: location,
        mhsssfacilityId:location,
        role: selectedRole,
      };
      if (selectedRole === "") {
        delete data.role;
      }
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/${api}=${profileData.user.id}`,
          {
            employeeInfo: data,
            consumerInfo: {
              ip: "111.222.33.4",
              location: "329.3323, 434.343232",
            },
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          dispatch({ type: "RESET" });
          setIsLoadingx(false);
        })
        .catch((err) => {
          setIsLoadingx(false);
          setErr(err.response.data.message);
          setShow(true);
        });
    } else {
      const api =
        user === "admin"
          ? "v1/employee-info/by-admin?employeeUserId"
          : "v1/employee-info/by-superadmin?employeeUserId";
      const data = {
        employer,
        supervisor,
        // departmentOrLocation: location,
        mhsssfacilityId:location,
        role: selectedRole,
      };

      if (
        selectedRole === "" ||
        selectedRole === null ||
        selectedRole === undefined
      ) {
        setErr("Role is required");
        setShow(true);
      } else {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/${api}=${profileData.user.id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            dispatch({ type: "RESET" });
            setIsLoadingx(false);
          })
          .catch((err) => {
            setIsLoadingx(false);
            setErr(err.response.data.message);
            setShow(true);
          });
      }
    }
  };
  return (
    <div>
      <form onSubmit={submitHandler}>
        <h2 className="mb-4 text-[1.1rem] font-semibold">Employer:</h2>
        <input
          type="text"
          name=""
          placeholder="Employer"
          value={employer}
          onChange={(e) => setEmployer(e.target.value)}
          // onChange={handleChange(setEmployer)}
          className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 "
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">Supervisor:</h2>
        <input
          type="text"
          name=""
          placeholder="Supervisor"
          value={supervisor}
          onChange={handleChange(setSupervisor)}
          className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 "
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">Role:</h2>
        <Select
          placeholder="Role"
          optionFilterProp="children"
          style={{ marginBottom: "1.2rem" }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          className="wow"
          onChange={setSelectedRole}
          options={roles}
          value={selectedRole !== "" ? selectedRole : null}
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">
          Department/Location:
        </h2>
        <Select
          placeholder="Department/Location"
          optionFilterProp="children"
          style={{ marginBottom: "1.2rem" }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          className="wow"
          onChange={(value)=>{
            setLocation(value)
          }}
          // options={DepartmentList}
          options={department}
          value={location !== "" ? location : null}
        />

        <button
          onClick={submitHandler}
          className={`${
            (profileData.user.status === "pending" ||
              dataUser?.data?.user?.status === "verified" ||
              dataUser?.data?.user?.status === "re-submitted" ||
              dataUser?.data?.user?.status === "deferred" ||
              dataUser?.data?.user?.status === "in-review") &&
            dash === "/Dashboard/PensionerAccount"
              ? "hidden"
              : ""
          } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          {/* <Spin
            style={{ display: `${isLoadingx ? "" : "none"}` }}
            indicator={antIcon}
          /> */}
          <span>Save</span>
          <div
            className={`w-[100%] h-[100%] ${
              isLoadingx ? "" : "hidden"
            } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
          ></div>
        </button>
      </form>

      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployerE;

import { Select, DatePicker, Space, Checkbox } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import check from "../../../imgs/add.png";
import across from "../../../imgs/across.png";
import deleteicon from "../../../imgs/bs3.png";
import moment from "moment";
import { useEffect } from "react";
import { TimePicker } from "antd";
import { Input, Button } from "antd";
import building from "../../../imgs/building.svg";
import { CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";
let file;
const Mid = () => {
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, []);
  const option = useSelector((state) => state.option);
  const [display, setDisplay] = useState(building);
  const [industry, setIndustry] = useState(null);
  const [business, setBusiness] = useState(null);
  const [test, setTest] = useState(false);
  const [gov, setGov] = useState(false);
  const [utility, setUtility] = useState(false);
  const [compName, setCompName] = useState("");
  const [webSite, setWebSite] = useState("");
  const [year, setYear] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [show1, setShow1] = useState(false);
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [socialLinks, setSocialLinks] = useState([]);
  const [newLink, setNewLink] = useState("");
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const initialShifts = daysOfWeek.map((day) => ({
    dayName: day,
    shifts: [
      { shiftName: "Working hours", start: "", end: "" },
      { shiftName: "Break time", start: "", end: "" },
    ],
  }));
  const [shifts, setShifts] = useState(initialShifts);
  const [selectedDays, setSelectedDays] = useState([]);
  const [addShift, setAddShift] = useState([]);
  const [workingHoursStart, setWorkingHoursStart] = useState(null);
  const [workingHoursEnd, setWorkingHoursEnd] = useState(null);
  const [breakTimeStart, setBreakTimeStart] = useState(null);
  const [breakTimeEnd, setBreakTimeEnd] = useState(null);
  console.log("shifts", shifts);
  console.log("selectedDays", selectedDays);
  console.log("addShift", addShift);

  const toggleDaySelection = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };
  const handleDeleteShift = (dayIndex) => {
    const updatedShifts = [...shifts];
    updatedShifts[dayIndex].shifts.forEach((shift) => {
      shift.start = "";
      shift.end = "";
    });

    setShifts(updatedShifts);

    const addedDays = updatedShifts
      .filter((day) =>
        day.shifts.some((shift) => shift.start !== "" && shift.end !== "")
      )
      .map((day) => day.dayName);

    setAddShift(addedDays);
  };

  const handleValues = () => {
    if (selectedDays.length === 0) {
      setErr("Please specify the days for setting up shift timings.");
      setShow1(true);
    } else if (
      workingHoursStart === null ||
      workingHoursEnd === null ||
      breakTimeStart === null ||
      breakTimeEnd === null
    ) {
      setErr("Please insert all of timings to assign.");
      setShow1(true);
    } else {
      const convertTo24Hour = (time12h) => {
        const [time, modifier] = time12h.split(" ");
        let [hours, minutes] = time.split(":");
        if (hours === "12") {
          hours = "00";
        }
        if (modifier === "PM") {
          hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
      };

      const formattedValueWorkingStart =
        workingHoursStart === null ? "" : convertTo24Hour(workingHoursStart);
      const formattedValueWorkingEnd =
        workingHoursEnd === null ? "" : convertTo24Hour(workingHoursEnd);
      const formattedValueBreakStart =
        breakTimeStart === null ? "" : convertTo24Hour(breakTimeStart);
      const formattedValueBreakend =
        breakTimeEnd === null ? "" : convertTo24Hour(breakTimeEnd);

      setShifts((prevShifts) => {
        const updatedShifts = prevShifts.map((shift) => {
          if (selectedDays.includes(shift.dayName)) {
            return {
              ...shift,
              shifts: shift.shifts.map((s) => {
                if (s.shiftName === "Working hours") {
                  return {
                    ...s,
                    start: formattedValueWorkingStart,
                    end: formattedValueWorkingEnd,
                  };
                } else if (s.shiftName === "Break time") {
                  return {
                    ...s,
                    start: formattedValueBreakStart,
                    end: formattedValueBreakend,
                  };
                }
                return s;
              }),
            };
          }
          return shift;
        });

        setSelectedDays([]);

        const addedDays = updatedShifts
          .map((day) => {
            if (
              day.shifts.some((shift) => shift.start !== "" && shift.end !== "")
            ) {
              return day.dayName;
            }
          })
          .filter((day) => day !== undefined);

        setAddShift(addedDays);

        return updatedShifts;
      });
    }
  };

  const industryOptions = [
    { label: "Healthcare and pharmaceuticals", value: "healthcare" },
    { label: "Food and beverage", value: "foodAndBeverage" },
    { label: "Grocery", value: "grocery" },
    { label: "Banking & Financial Services", value: "finance" },
    { label: "Retail and e-commerce", value: "retail" },
    { label: "Media and entertainment", value: "mediaAndEntertainment" },
    { label: "Sales", value: "sales" },
    { label: "Education & Training", value: "educationAndTraining" },
    { label: "Accounting", value: "accounting" },
    {
      label: "Insurance & Superannuation",
      value: "insuranceAndSuperannuation",
    },
    { label: "Legal", value: "legal" },
    { label: "Other", value: "other" },
  ];
  const companyOptions = [
    { label: "Sole Proprietorship", value: "soleProprietorship" },
    { label: "Partnership", value: "partnership" },
    { label: "Corporation", value: "corporation" },
    { label: "S Corporation", value: "sCorporation" },
    { label: "Other", value: "other" },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const addSocialLink = () => {
    if (newLink.trim() !== "") {
      setSocialLinks([...socialLinks, newLink]);
      setNewLink("");
    }
  };

  const removeSocialLink = (index) => {
    const updatedLinks = [...socialLinks];
    updatedLinks.splice(index, 1);
    setSocialLinks(updatedLinks);
  };

  const handleSocialChange = (e, index) => {
    const updatedLinks = [...socialLinks];
    updatedLinks[index] = e.target.value;
    setSocialLinks(updatedLinks);
  };

  const addEmail = () => {
    const emailFormatRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (newEmail.trim() !== "" && emailFormatRegex.test(newEmail)) {
      setEmails([...emails, newEmail]);
      setNewEmail("");
    } else {
      setErr("Please enter a valid email address");
      setShow1(true);
    }
  };

  const removeEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };

  const handleEmailChange = (e, index) => {
    const updatedEmails = [...emails]; // Create a copy of the emails array
    updatedEmails[index] = e.target.value; // Update the value at the specified index
    setEmails(updatedEmails); // Update the state with the modified array
  };
  const onChange = (date, dateString) => {
    setYear(dateString);
    console.log(date);
  };
  const handleChange = (setState, check) => (event) => {
    if (check === "tg") {
      setState(event);
    } else if (check === "tgf") {
      setState(event.$y);
    } else {
      setState(event.target.value);
    }
  };
  const imageHandler = async (e) => {
    setIsLoading(true);
    file = e.target.files[0];
    const base641 = await convertToBase64(file);
    setDisplay(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-files?fileType=merchantCompanyLogo`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setImageUrl(data.data.url);
        setIsLoading(false);
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const nextHandler = (e) => {
    e?.preventDefault();
    if (imageUrl === "") {
      setErr("Company logo is required");
      setShow1(true);
    } else if (compName === "") {
      setErr("Company name is required");
      setShow1(true);
    } else if (industry === null) {
      setErr("Industry is required");
      setShow1(true);
    } else if (business === null) {
      setErr("Business is required");
      setShow1(true);
    } else if (year === "") {
      setErr("Year founded is required");
      setShow1(true);
    } else if (description === "") {
      setErr("Company Description is required");
      setShow1(true);
    } else if (emails.length === 0) {
      setErr("Company Email is required");
      setShow1(true);
    } else if (selectedDays.length > 0) {
      if (addShift.length === 0) {
        setErr("Please click on set shifts button to set the shifts");
        setShow1(true);
      }
    } else {
      const RemoveShiftDay = shifts?.filter((shift) =>
        shift?.shifts?.some((shift) => shift?.start !== "" && shift?.end !== "")
      );

      if (RemoveShiftDay.length === 0) {
        setErr("Please set the shifts");
        setShow1(true);
        return;
      }
      console.log(RemoveShiftDay);

      let body = {};
      body = {
        name: compName,
        businessType: business,
        logo: imageUrl,
        industry: industry,
        yearFounded: year,
        emails: emails,
        description: description,
        businessHours: RemoveShiftDay,
        // isGovtAgency: gov,
      };

      if (webSite !== "") {
        body.website = webSite;
      }
      if (socialLinks.length !== 0) {
        body.socialLinks = socialLinks;
      }
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/merch-company`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          // if (gov === true) {
          //   dispatch({ type: "GovTrue" });
          //   console.log("doneGov");
          // } else if (gov === false) {
          //   dispatch({ type: "GovFalse" });
          // }
          dispatch({ type: "MERCHANT_COMPANY", num: data?.data });
          dispatch({ type: "IINCREMENT" });
          dispatch({ type: "data1Set", num: data });
          dispatch({
            type: "nameSet",
            num: `${data.data.firstName} ${data.data.lastName}`,
          });
          dispatch({
            type: "imageSet",
            num: `${data.data.image}`,
          });
        })
        .catch((err) => {
          if (
            err.response.data.message === '"image" is not allowed to be empty'
          ) {
            setErr("Please uplaod a profile picture");
          } else if (
            err?.response?.data?.message === `"[0]" must be a valid email`
          ) {
            setErr("Enter valid company email address");
          } else if (
            err?.response?.data?.message ===
            `"start" is not allowed to be empty`
          ) {
            setErr("Please enter all shift timings");
          } else if (
            err?.response?.data?.message === `"end" is not allowed to be empty`
          ) {
            setErr("Please enter all shift timings");
          } else if (
            err?.response?.data?.message === `"[0]" must be a valid uri`
          ) {
            setErr("Enter valid social link");
          } else {
            setErr(err?.response?.data?.message);
          }
          setShow1(true);
        });
    }
  };

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );

  const disabledDate1 = (current) => {
    const disabledBefore1900 = current && current.year() < 1900;
    const disabledAfterDate1 = current && current > disabledBeforeDate1;
    return disabledBefore1900 || disabledAfterDate1;
  };
  const convertTo12Hour = (time24h) => {
    let [hours, minutes] = time24h.split(":");
    let modifier = "AM";

    hours = parseInt(hours, 10);
    if (hours >= 12) {
      modifier = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    }
    if (hours === 0) {
      hours = 12;
    }

    return `${hours} : ${minutes} ${modifier}`;
  };
  return (
    <div className={`${isLoading ? "pointer-events-none" : ""}`}>
      <form action="" className="mt-10 sm:mt-[5rem]" onSubmit={nextHandler}>
        <div className="flex items-center justify-center mb-4 flex-col">
          <label htmlFor="special-input" className="cursor-pointer relative">
            <div className="relative w-[8.5rem] h-[8.5rem] overflow-hidden border-2 border-[#686262] rounded-full mb-4">
              <img
                className="object-cover object-center w-full h-full"
                src={display}
                alt=""
              />
            </div>

            <div
              className={`bg-primary rounded-full absolute bottom-4 right-2`}
            >
              <img src={check} className="lg:w-7 w-5" alt="" />
            </div>
            <input
              type="file"
              className="hidden"
              id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler}
            />
          </label>
          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Upload Company Logo <span className="text-red-600">*</span>
          </h2>
        </div>
        <div className="flex justify-center items-center flex-col">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div className="w-[100%]">
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Company Name <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  name=""
                  
                  onChange={handleChange(setCompName)}
                  value={compName}
                  placeholder="Company"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>

              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Industry <span className="text-red-600">*</span>
                </label>
                <Select
                  placeholder="Industry Type"
                  optionFilterProp="children"
                  className="wow"
                  value={industry}
                  style={{ marginBottom: "1.2rem" }}
                  onChange={handleChange(setIndustry, "tg")}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={industryOptions}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Year Founded <span className="text-red-600">*</span>
                </label>
                <DatePicker
                  style={{ marginBottom: "1.2rem" }}
                  disabledDate={disabledDate1}
                  picker="year"
                  className="date-class"
                  onChange={onChange}
                />
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Website
                </label>
                <input
                  type="text"
                  name=""
                  
                  onChange={handleChange(setWebSite)}
                  value={webSite}
                  placeholder="Website"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Business <span className="text-red-600">*</span>
                </label>
                <Select
                  placeholder="Business Type"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1.2rem" }}
                  onChange={handleChange(setBusiness, "tg")}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={companyOptions}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-1">
                  Description of the Company{" "}
                  <span className="text-red-600">*</span>
                </label>
                <textarea
                  type="text"
                  name=""
                  
                  onChange={handleChange(setDescription)}
                  value={description}
                  placeholder="Enter Company Description"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4 h-[4rem]"
                />
              </div>
            </div>
          </div>
          <div className="sm:w-[70%] w-[80%] mt-8 mb-8 bg-[#F4F5FE] shadow-md p-4 rounded-md">
            <div>
              <>
                <div
                  key="workingHours"
                  className="flex items-center mt-2  flex-col w-full"
                  style={{ marginBottom: "8px" }}
                >
                  <p className="font-bold w-full text-[12px] mb-2 capitalize">
                    Working Hours
                  </p>
                  <div className="w-full grid sm:grid-cols-2 grid-cols-1 gap-4">
                    <label>
                      Start Time:
                      <div className="relative">
                        <TimePicker
                          showNow={false}
                          value={
                            workingHoursStart
                              ? moment(workingHoursStart, "hh:mm A")
                              : null
                          }
                          format="hh:mm A"
                          onOk={(value) => {
                            if (value) {
                              setWorkingHoursStart(value.format("hh:mm A"));
                            }
                          }}
                          allowClear={false}
                        />
                        {workingHoursStart && (
                          <CloseCircleOutlined
                            className="absolute top-1/2 right-3 transform text-gray-500 -translate-y-1/2 cursor-pointer"
                            onClick={() => setWorkingHoursStart(null)}
                          />
                        )}
                      </div>
                    </label>
                    <label>
                      End Time:
                      <div className="relative">
                        <TimePicker
                          needConfirm={false}
                          showNow={false}
                          value={
                            workingHoursEnd
                              ? moment(workingHoursEnd, "hh:mm A")
                              : null
                          }
                          format="hh:mm A"
                          onOk={(value) => {
                            if (value) {
                              setWorkingHoursEnd(value.format("hh:mm A"));
                            }
                          }}
                          allowClear={false}
                        />
                        {workingHoursEnd && (
                          <CloseCircleOutlined
                            className="absolute top-1/2 right-3 transform text-gray-500 -translate-y-1/2 cursor-pointer"
                            onClick={() => setWorkingHoursEnd(null)}
                          />
                        )}
                      </div>
                    </label>
                  </div>
                </div>
                <div
                  key="breakTime"
                  className="flex items-center  flex-col w-full"
                  style={{ marginBottom: "8px" }}
                >
                  <p className="font-bold w-full text-[12px] mb-2 capitalize">
                    Break Time
                  </p>
                  <div className="w-full  grid sm:grid-cols-2 grid-cols-1 gap-4">
                    <label>
                      Start Time:
                      <div className="relative">
                        <TimePicker
                          showNow={false}
                          value={
                            breakTimeStart
                              ? moment(breakTimeStart, "hh:mm A")
                              : null
                          }
                          format="hh:mm A"
                          onOk={(value) => {
                            if (value) {
                              setBreakTimeStart(value.format("hh:mm A"));
                            }
                          }}
                          needConfirm={false}
                          allowClear={false}
                        />
                        {breakTimeStart && (
                          <CloseCircleOutlined
                            className="absolute top-1/2 right-3 transform text-gray-500 -translate-y-1/2 cursor-pointer"
                            onClick={() => setBreakTimeStart(null)}
                          />
                        )}
                      </div>
                    </label>
                    <label>
                      End Time:
                      <div className="relative">
                        <TimePicker
                          needConfirm={false}
                          showNow={false}
                          value={
                            breakTimeEnd
                              ? moment(breakTimeEnd, "hh:mm A")
                              : null
                          }
                          format="hh:mm A"
                          onOk={(value) => {
                            if (value) {
                              setBreakTimeEnd(value.format("hh:mm A"));
                            }
                          }}
                          allowClear={false}
                        />
                        {breakTimeEnd && (
                          <CloseCircleOutlined
                            className="absolute top-1/2 right-3 transform text-gray-500 -translate-y-1/2 cursor-pointer"
                            onClick={() => setBreakTimeEnd(null)}
                          />
                        )}
                      </div>
                    </label>
                  </div>
                </div>
              </>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  overflowX: "auto",
                  WebkitOverflowScrolling: "touch",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  "-ms-overflow-style": "none",
                }}
              >
                {daysOfWeek.map((day) => (
                  <div
                    key={day}
                    onClick={() => toggleDaySelection(day)}
                    className={`cursor-pointer mt-2 mb-2 rounded-full mr-1 font-semibold  pl-4 pr-4 p-1 ${
                      selectedDays.includes(day)
                        ? "bg-primary text-white"
                        : "bg-white"
                    } ${
                      addShift.includes(day)
                        ? "pointer-events-none opacity-40"
                        : ""
                    }`}
                  >
                    {day.charAt(0).toUpperCase() + day.slice(1)}
                  </div>
                ))}
              </div>
              <button
                type="button"
                onClick={() => {
                  handleValues();
                }}
                className={`lg:text-[1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-1  pr-6 pl-6 mt-2`}
              >
                Set Shifts
              </button>
              <div className="flex flex-col mt-4 gap-1 w-full">
                {shifts.map(
                  (day, index) =>
                    day.shifts.some(
                      (shift) => shift.start !== "" && shift.end !== ""
                    ) && (
                      <div
                        key={day.dayName}
                        className="flex flex-wrap w-full bg-primary shadow-md relative text-white pl-4 pr-4 p-1 rounded-lg"
                      >
                        {day.shifts.some(
                          (shift) => shift.start !== "" && shift.end !== ""
                        ) && (
                          <h2 className="mb-1 capitalize font-bold ">
                            {day.dayName}
                          </h2>
                        )}
                        <div className="flex w-full  items-center justify-between">
                          {day.shifts.map(
                            (shift, shiftIndex) =>
                              shift.start !== "" &&
                              shift.end !== "" && (
                                <div
                                  key={shift.shiftName}
                                  className="flex w-full sm:flex-row flex-col pr-4 mb-2"
                                >
                                  <span className="font-semibold mr-2">
                                    {shift.shiftName}:
                                  </span>{" "}
                                  {convertTo12Hour(shift.start)} -{" "}
                                  {convertTo12Hour(shift.end)}
                                </div>
                              )
                          )}
                        </div>
                        <DeleteOutlined
                          className="absolute top-0 text-xl right-2"
                          onClick={() => handleDeleteShift(index)}
                        />
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center flex-col mt-2">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%] gap-3">
            <div className="w-[100%]">
              <div className="flex flex-col">
                <h2 className="mb-4 text-[1rem] font-semibold text-[#596F96]">
                  Company Email Address <span className="text-red-600">*</span>
                </h2>
                <Space.Compact
                  style={{
                    width: "100%",
                  }}
                >
                  <Input
                    placeholder="Enter Email"
                    value={newEmail}
                    className="p-2"
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                  <Button
                    className="bg-slate-300  h-12"
                    onClick={() => {
                      if (newEmail !== "") addEmail();
                    }}
                  >
                    Add
                  </Button>
                </Space.Compact>

                {emails?.map((link, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mt-2 p-2 bg-gray-200 shadow-md rounded-md border border-gray-300"
                  >
                    <Input
                      placeholder="Enter URL"
                      value={link}
                      className="bg-slate-200 mr-2"
                      onChange={(e) => handleEmailChange(e, index)}
                    />

                    <button type="button" onClick={() => removeEmail(index)}>
                      <img src={deleteicon} alt="" className="w-6 h-6" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-[100%]">
              <div className="flex flex-col">
                <h2 className="mb-4 text-[1rem] font-semibold text-[#596F96]">
                  Other Social Media Links
                </h2>
                <Space.Compact
                  style={{
                    width: "100%",
                  }}
                >
                  <Input
                    placeholder="Enter URL"
                    value={newLink}
                    onChange={(e) => setNewLink(e.target.value)}
                    className="p-2"
                  />
                  <Button
                    className="bg-slate-300 h-12"
                    onClick={() => {
                      if (newLink !== "") addSocialLink();
                    }}
                  >
                    Add
                  </Button>
                </Space.Compact>
                {socialLinks?.map((link, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mt-2 p-2 bg-gray-200 shadow-md rounded-md border border-gray-300"
                  >
                    <Input
                      placeholder="Enter URL"
                      value={link}
                      className="bg-slate-200 mr-2"
                      onChange={(e) => handleSocialChange(e, index)}
                    />

                    <button
                      type="button"
                      onClick={() => removeSocialLink(index)}
                    >
                      <img src={deleteicon} alt="" className="w-6 h-6" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div
          className={`lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center sm:w-[85%]`}
        >
          <button
            type="button"
            onClick={() => {
              if (option === "Merchant") {
                nextHandler();
              }
            }}
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Next
          </button>
        </div>
      </form>
      <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-10`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow1(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow1(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Mid;

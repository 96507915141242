import React, { useEffect, useState } from "react";
import PageHeader from "../pageHeader/pageHeader";
import ErrorModal from "../../Merchant/Modals/errorModal";
import axios from "axios";
import moment from "moment";
import logo from "../../../imgs/logo12.png";
import { Select } from "antd";
import { useSelector } from "react-redux";
const SetSlot = () => {
  const [showErr, setShowErr] = useState(false);
  const [err, setErr] = useState("");
  const [reason, setReason] = useState("");
  const [cancelId, setCancelId] = useState("");
  const [showCancel, setShowCancel] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const [type, setType] = useState(null);
  const [slots, setSlots] = useState([]);
  const [fetch, setFetch] = useState(null);
  useEffect(() => {
    setSlots([]);
    let url;
    if (type)
      url = `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/my-booked-slots?program=senior-citizen-Pension&status=${type}`;
    else
      url = `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/my-booked-slots?program=senior-citizen-Pension`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((data) => {
        console.log("Dataaa", data?.data?.rows);
        setSlots(data?.data?.rows);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        console.log(err);
      });
  }, [fetch, type]);
  const isDateTimePassed = (date, time) => {
    const dateTime = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss");
    const now = moment();
    return dateTime.isBefore(now);
  };
  const cancel = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/${cancelId}/cancel/by-self`,
        {
          cancelReason: reason,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then(() => {
        setShowCancel(false);
        setFetch(!fetch);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        console.log(err);
      });
  };
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-3  min-h-screen">
      <PageHeader title={"All Appointments"} route={-1} header={true} />
      <div className="flex justify-center flex-col items-center">
        <div className="flex-col bg-white shadow-lg min-h-[80vh] rounded-sm mx-4 m-2 p-4 sm:w-[90%] w-full">
          <div className="flex justify-end items-center mb-4 w-full">
            <div className="flex gap-2 w-[12rem] items-center justify-center">
              <label
                htmlFor="filter"
                className="font-semibold text-lg text-gray-700"
              >
                Filter:-
              </label>
              <Select
                placeholder="Type"
                id="filter"
                className="wow"
                value={type}
                onChange={(e) => setType(e)}
              >
                <option value="booked">Booked</option>
                <option value="cancelled">Cancelled</option>
              </Select>
            </div>
          </div>
          <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
            {slots.map((record, index) => (
              <div
                key={index}
                className="border p-3 gap-1 bg-gray-100 rounded-md shadow-sm"
              >
                <div className="w-full flex gap-1 mb-4 justify-between">
                  <div>
                    <p className="font-semibold text-gray-600">
                      Slot booked For
                    </p>
                    <div className="px-4 py-1 bg-primary rounded-md text-white">
                      {moment(record?.schedulercentertimemanager?.date).format(
                        "YYYY/MM/DD"
                      )}
                      {", "}
                      {moment(record?.fromTime, "HH:mm:ss").format("hh:mm A")}
                    </div>
                  </div>
                  <p
                    className={`font-semibold ${
                      record.status === "cancelled"
                        ? "text-gray-400"
                        : !isDateTimePassed(
                            record.schedulercentertimemanager.date,
                            record.toTime
                          ) && record?.status === "booked"
                        ? "text-red-600"
                        : "text-primary"
                    }`}
                  >
                    {!isDateTimePassed(
                      record.schedulercentertimemanager.date,
                      record.toTime
                    ) && record?.status === "booked"
                      ? "Upcoming"
                      : record?.status === "cancelled"
                      ? "Cancelled"
                      : "Completed"}
                  </p>
                </div>
                <div className="bg-white p-2 py-3 mb-4 rounded-md flex flex-col gap-2">
                  <div className="flex items-center justify-between">
                    <p className="font-semibold text-gray-600">Program</p>
                    <p className="font-semibold">
                      {record?.schedulercentertimemanager?.program ===
                      "senior-citizen-Pension"
                        ? "Senior Citizen Pension"
                        : record?.schedulercentertimemanager?.program}
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="font-semibold text-gray-600">Centre Name</p>
                    <p className="font-semibold">
                      {
                        record?.schedulercentertimemanager?.distributioncoverage
                          ?.coverage
                      }
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setCancelId(record?.id);
                    setShowCancel(true);
                  }}
                  className={`bg-white flex items-center py-3 rounded-md justify-center gap-2 ${
                    !isDateTimePassed(
                      record.schedulercentertimemanager.date,
                      record.toTime
                    ) && record?.status === "booked"
                      ? ""
                      : "hidden"
                  }`}
                >
                  <p className="font-semibold text-red-600 hover:underline cursor-pointer">
                    Cancel Appointment
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`${
          showCancel ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[26rem]  flex items-center p-5 bg-white flex-col">
          <div className="w-16 h-16" onClick={() => setShowCancel(false)}>
            <img src={logo} alt="" className="w-16 h-16" />
          </div>
          <p className="text-center mt-6 mb-5 font-semibold">
            Are you sure to you want to cancel the appointment?
          </p>
          <textarea
            type="text"
            name=""
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            placeholder="Note"
            className=" lg:text-[1.1rem] w-[85%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <div className="w-full flex gap-2 items-center justify-center">
            <button
              onClick={() => {
                setShowCancel(false);
                setReason("");
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[40%]"
            >
              No
            </button>
            <button
              onClick={() => {
                if (reason === "") {
                  setErr("Please Enter a specific reason.");
                  setShowErr(true);
                } else cancel();
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
    </div>
  );
};

export default SetSlot;

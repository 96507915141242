import React, { useEffect, useState } from "react";
import PageHeader from "./pageHeader/pageHeader";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ErrorModal from "../Merchant/Modals/errorModal";
import { useDispatch, useSelector } from "react-redux";
import calendar from "../../imgs/calendar.svg";
import pdf from "../../imgs/pdf.png";
let searchTimeout;
const SelectLocation = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [allData, setAllData] = useState();
  const [items, setItems] = useState([]);
  // const [selectedData, setSelectedData] = useState([]);
  const [err, setErr] = useState("");
  const user = useSelector((state) => state.user);
  const [showErr, setShowErr] = useState(false);
  const [regionName, setRegionName] = useState("");

  const [locationName, setLocationName] = useState("");

  const [coverage, setCoverage] = useState(null);
  const [allRegions, setAllRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [allLocations, setAllLocations] = useState([]);
  const [selectLocation, setSelectLocation] = useState(null);
  const [locationCode, setLocationCode] = useState("");
  const [allCenters, setAllCenters] = useState([]);
  const [centerCode, setCenterCode] = useState("");
  const [selectedCenter, setSelectedCenter] = useState("");
  const [allCoverages, setAllCoverages] = useState([]);

  //Locations
  useEffect(() => {
    setAllCenters([]);
    setAllLocations([]);
    setAllCoverages([]);

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/distribution-regions`)
      .then((res) => {
        const wow = res?.data.map((item) => {
          return {
            label: `${item.regionNo}`,
            value: `${item.id}`,
          };
        });
        setAllRegions(wow);
      })
      .catch(() => {});
  }, []);

  //Select location
  useEffect(() => {
    if (selectedRegion === null) {
      return;
    }
    setAllLocations([]);

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/distribution-locations?distributionRegionId=${selectedRegion}`
      )
      .then((res) => {
        const wow = res?.data.map((item) => {
          return {
            label: `${item?.locationName}`,
            value: `${item.id}`,
            locationCode: `${item.locationCode}`,
          };
        });
        setAllLocations(wow);
      })
      .catch(() => {});
  }, [selectedRegion]);

  //Select Center
  useEffect(() => {
    if (selectLocation === null) {
      return;
    }
    setAllCenters([]);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/distribution-centers?distributionLocationId=${selectLocation}`
      )
      .then((res) => {
        const wow = res?.data.map((item) => {
          return {
            label: `${item?.centerName}`,
            value: `${item.id}`,
            centerCode: `${item.centerCode}`,
          };
        });
        setAllCenters(wow);
      })
      .catch(() => {});
  }, [selectLocation]);

  //Get Coverages
  useEffect(() => {
    if (selectedCenter === null || selectedCenter === "") {
      return;
    }
    setAllCoverages([]);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/distribution-coverages?distributionCenterId=${selectedCenter}`
      )
      .then((res) => {
        setAllCoverages(res?.data);
      })
      .catch(() => {});
  }, [selectedCenter]);

  const Label = ({ text }) => (
    <label className="text-gray-700 text-[0.88rem] font-semibold mb-2">
      {text}
    </label>
  );
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4  min-h-screen">
      <PageHeader
        title={"Find Coverages"}
        route={
          -1
          //   user === "employee"
          //     ? "/EDashboard"
          //     : user === "admin"
          //     ? "/adminDashboard"
          //     : "/superAdminDashboard"
        }
        header={true}
      />

      <div className="flex justify-center flex-col items-center">
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%] w-full relative`}
        >
          <form action="">
            <div className="flex justify-center items-center p-4 flex-col">
              <div className="w-[100%]">
                <div className="flex flex-col">
                  {/* <Label text={"Coverage"} /> */}
                  <Label text={"Select Region"} />
                  <Select
                    placeholder="Select Region"
                    allowClear
                    onChange={(e) => {
                      setSelectedRegion(e);
                      setAllCenters([]);
                      setAllLocations([]);
                      setAllCoverages([]);
                      setCenterCode("");
                      setLocationCode("");
                      setSelectLocation(null);
                      setSelectedCenter(null);
                    }}
                    onClear={() => {}}
                    value={selectedRegion}
                    showSearch
                    optionFilterProp="children"
                    style={{ marginBottom: "0.5rem" }}
                    className="wow"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size="middle"
                    options={allRegions}
                  />
                </div>
              </div>

              <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1  w-full">
                <div className="w-[100%]">
                  <div className="flex flex-col">
                    <Label text={"Select Location"} />

                    <Select
                      placeholder="Select Location"
                      optionFilterProp="children"
                      className="wow"
                      style={{ marginBottom: "1.2rem" }}
                      onChange={(e, e2) => {
                        setSelectLocation(e);
                        setLocationCode(e2.locationCode);
                        setAllCenters([]);
                        setAllCoverages([]);
                        setSelectedCenter(null);
                        setCenterCode("");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      size="middle"
                      options={allLocations}
                      value={selectLocation}
                    />
                  </div>
                  <div className="flex flex-col">
                    <Label text={"Select Center Name"} />
                    <Select
                      placeholder="Select Center Name"
                      optionFilterProp="children"
                      className="wow"
                      //   style={{ marginBottom: "1.2rem" }}
                      // onChange={handleChange(setTitle, "tg")}
                      onChange={(e, e2) => {
                        setSelectedCenter(e);
                        setCenterCode(e2.centerCode);
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      size="middle"
                      options={allCenters}
                      value={selectedCenter}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex flex-col">
                    <Label text={"Location Code"} />
                    <input
                      type="text"
                      name=""
                      value={locationCode}
                      placeholder="Location Code"
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                    />
                  </div>
                  <div className="flex flex-col">
                    <Label text={"Center Code"} />
                    <input
                      type="text"
                      name=""
                      value={centerCode}
                      placeholder="Center Code"
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                    />
                  </div>
                </div>
              </div>
              <div className="w-[100%]">
                <div className="flex flex-col">
                  {/* <Label text={"Coverage"} /> */}
                  <Label text={"Coverages"} />
                  <div className=" flex flex-col text-[0.9rem] text-gray-700 w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4 min-h-[5rem]">
                    {allCoverages?.map((coverage, index) => {
                      return (
                        <div>
                          <button
                            type="button"
                            className={`${
                              index === allCoverages?.length - 1
                                ? "flex items-center w-full px-4 py-2  font-medium"
                                : "flex items-center w-full px-4 py-2  font-medium border-b border-gray-200 rounded-t-lg"
                            }`}
                          >
                            <span className="w-5">{index + 1}</span> {" - "}
                            {coverage?.coverage}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
    </div>
  );
};

export default SelectLocation;

import React, { useEffect, useState } from "react";
import PageHeader from "../pensionerVerification/pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../Merchant/Modals/errorModal";
import axios from "axios";
import { Checkbox, DatePicker, Pagination, Select } from "antd";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import moment from "moment";
import dayjs from "dayjs";

const SelectProgram = () => {
  const router = useNavigate();
  const SelectedSlot = useSelector((state) => state.SelectedSlot);
  console.log(SelectedSlot);
  const [employees, setEmployees] = useState([]);
  const distributionCenter = useSelector((state) => state.distributionCenter);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const dataUser = useSelector((state) => state.dataUser);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [show, setShow] = useState("");
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [search, setSearch] = useState("");
  const [dob, setDob] = useState("");
  const [prompt, setPrompt] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [searchDob, setSearchDob] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [afterApi, setAfterApi] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const handleSelectAll = () => {
    if (isSelectAll) {
      setSelectedEmployees([]);
    } else {
      setSelectedEmployees(employees.map((user) => user.id));
    }
    setIsSelectAll(!isSelectAll);
  };
  useEffect(() => {
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 6,
        skip:
          search || searchDob || countryCode || phoneNoo ? 0 : (page - 1) * 6,
        page: search ? pagewithsearch : page, // Separate page for search and non-search scenarios
        sortBy: "createdAt:desc",
        ...(search && { search }),
        ...(searchDob && { dateOfBirth: searchDob }),
        ...(countryCode &&
          phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
        status: "approved",
      };

      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page, search, searchDob, countryCode, phoneNoo, pagewithsearch]);

  const fetchEmployees = (params, cancelTokenSource) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/users/employees`, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        setEmployees(data?.data?.rows);
        setTotalPages(data?.data?.totalPages);
        // setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const fetchCoverages = (params, cancelTokenSource) => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL}/v1/scheduler-center-time-manager`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
  //         },
  //         params,
  //         cancelToken: cancelTokenSource.token,
  //       }
  //     )
  //     .then((data) => {})
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const AddEmployees = () => {
    const api = `v1/scheduler-center-time-manager/${SelectedSlot?.id}/add-employees`;

    const method = "post";

    axios[method](
      `${process.env.REACT_APP_BASE_URL}/${api}`,
      {
        employeeUserIDs: selectedEmployees,
      },
      {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      }
    )
      .then((res) => {
        router("/scheduleVerification/selectedEmployees");
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErr(true);
      });
  };

  const showHandler = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
    } else {
      setState(event.target.value);
    }
  };

  const pageHandler = (e) => {
    if (search) {
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4 min-h-screen">
      <PageHeader
        header={true}
        title={"Select Employees"}
        route={"/scheduleVerification/selectedEmployees"}
      />

      <div className="flex items-center flex-col">
        <div className="sm:w-[80%] w-[95%] flex sm:items-center justify-between mx-4 sm:flex-row flex-col ">
          <Checkbox
            className="checkk3 sm:ml-0 ml-4 w-full"
            checked={isSelectAll}
            onChange={handleSelectAll}
          >
            Select All
          </Checkbox>

          <div className="flex flex-col-reverse md:flex-row items-center justify-end space-y-3 md:space-y-0 md:space-x-4 p-2 w-full">
            {/* filter */}
            <div className="sm:mt-0 mt-3 w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="flex items-center space-x-3 w-full md:w-auto">
                <div className="relative">
                  <button
                    id="filterDropdownButton"
                    data-dropdown-toggle="filterDropdown"
                    className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200      "
                    type="button"
                    onClick={showHandler}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="h-4 w-4 mr-2 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Filter By
                    <svg
                      className="-mr-1 ml-1.5 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        clipRule="evenodd"
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      />
                    </svg>
                  </button>

                  {show && (
                    <div
                      id="filterDropdown"
                      className="z-20 absolute top-full md:right-0 mt-2 sm:w-[18rem] w-[15rem] p-3 bg-white rounded-lg shadow "
                    >
                      <div className="mt-5">
                        <DatePicker
                          onChange={(e, f) => setDob(f)}
                          value={dob !== "" ? dayjs(dob) : null}
                          placeholder="Date of birth"
                          // value={wow !== "" ? set : null}
                          // onChange={(e, f) => change1Handler(e, f)}
                        />
                      </div>

                      <PhoneInput
                        placeholder="Enter phone number"
                        defaultCountry="GY"
                        value={prompt}
                        onChange={handleChange(setPrompt, "tt")}
                        international
                        countryCallingCodeEditable={false}
                        // required={option === "Employee" ? true : false}
                        // className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
                      />
                      <div className="mt-4 w-full flex gap-x-2 items-center justify-center">
                        <button
                          onClick={() => {
                            if (prompt) {
                              const wow = parsePhoneNumber(prompt);

                              let count = wow.countryCallingCode;
                              let num = wow.nationalNumber;
                              setCountryCode(count);
                              setPhoneNoo(num);
                            }
                            if (dob !== null && dob !== "") setSearchDob(dob);
                            setShow(false);
                          }}
                          className={` bg-gradient-to-tr bg-primary text-white mr-3 rounded-[0.5rem] p-2  pr-8 pl-8`}
                        >
                          Apply
                        </button>
                        <button
                          onClick={() => {
                            setSearchDob("");
                            setDob("");
                            setPhoneNoo(null);
                            setPrompt("");
                            setCountryCode(null);
                            setShow(false);
                          }}
                          className={` bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* search */}
            <div className="w-full md:w-1/2">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2"
                    placeholder="Search"
                    required=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>

          {/* <div className="flex flex-row items-center justify-end mb-2 gap-2">
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="flex items-center space-x-3 w-full md:w-auto">
                <div className="relative">
                  <button
                    id="filterDropdownButton"
                    data-dropdown-toggle="filterDropdown"
                    className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200      "
                    type="button"
                    onClick={showHandler}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="h-4 w-4 mr-2 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Filter By
                    <svg
                      className="-mr-1 ml-1.5 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        clipRule="evenodd"
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      />
                    </svg>
                  </button>

                  {show && (
                    <div
                      id="filterDropdown"
                      className="z-20 absolute top-full md:right-0 mt-2 sm:w-[18rem] w-[15rem] p-3 bg-white rounded-lg shadow "
                    >
                      <div className="mt-5">
                        <DatePicker
                          onChange={(e, f) => setDob(f)}
                          value={dob !== "" ? moment(dob) : null}
                          placeholder="Date of birth"
                          // value={wow !== "" ? set : null}
                          // onChange={(e, f) => change1Handler(e, f)}
                        />
                      </div>

                      <PhoneInput
                        placeholder="Enter phone number"
                        defaultCountry="GY"
                        value={prompt}
                        onChange={handleChange(setPrompt, "tt")}
                        international
                        countryCallingCodeEditable={false}
                        // required={option === "Employee" ? true : false}
                        // className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
                      />
                      <div className="mt-4 w-full flex gap-x-2 items-center justify-center">
                        <button
                          onClick={() => {
                            if (prompt) {
                              const wow = parsePhoneNumber(prompt);

                              let count = wow.countryCallingCode;
                              let num = wow.nationalNumber;
                              setCountryCode(count);
                              setPhoneNoo(num);
                            }
                            if (dob !== null && dob !== "") setSearchDob(dob);
                            setShow(false);
                          }}
                          className={` bg-gradient-to-tr bg-primary text-white mr-3 rounded-[0.5rem] p-2  pr-8 pl-8`}
                        >
                          Apply
                        </button>
                        <button
                          onClick={() => {
                            setSearchDob("");
                            setDob("");
                            setPhoneNoo(null);
                            setPrompt("");
                            setCountryCode(null);
                            setShow(false);
                          }}
                          className={` bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-2 px-4">
              <div className="w-full md:w-[20rem]">
                <form className="flex items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 "
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                      placeholder="Search"
                      required=""
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div> */}
        </div>

        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%]  w-[95%]`}
        >
          <div className="grid lg:grid-cols-4 md:grid-cols-2  gap-5">
            {employees?.map((user) => (
              <div
                className={`${
                  selectedEmployees.includes(user.id)
                    ? "bg-primary text-white border border-white"
                    : "bg-white text-black"
                }  p-4 cursor-pointer flex items-center   rounded-md shadow-sm bg-white max-w-xs`}
                onClick={() => {
                  if (selectedEmployees.includes(user.id)) {
                    setSelectedEmployees(
                      selectedEmployees.filter((id) => id !== user.id)
                    );
                  } else {
                    setSelectedEmployees([...selectedEmployees, user.id]);
                  }
                }}
              >
                <div
                  className={` ${
                    selectedEmployees.includes(user.id)
                      ? "w-16 h-16 rounded-full border-4  overflow-hidden border-white"
                      : "w-16 h-16 rounded-full border-4  overflow-hidden border-primary"
                  } `}
                >
                  <img
                    src={
                      user?.person?.image
                        ? user?.person?.image
                        : "https://www.w3schools.com/howto/img_avatar.png"
                    } // Replace with the actual image URL
                    alt="Profile"
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="ml-4">
                  <h3 className="text-[0.95rem] font-semibold">
                    {user?.person?.firstName + " " + user?.person?.lastName}
                  </h3>
                  <p
                    className={`${
                      selectedEmployees.includes(user.id)
                        ? "text-white"
                        : "text-primary"
                    } font-medium capitalize`}
                  >
                    {user?.roles?.[0]}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={`flex items-center  justify-center mt-7`}>
            <Pagination
              defaultCurrent={1}
              total={totalPages * 10}
              showSizeChanger={false}
              onChange={pageHandler}
              current={search ? pagewithsearch : page}
            />
          </div>
        </div>
      </div>

      <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            // if (selectedEmployees.length === 0) {
            //   setErr("Please select at least one employee");
            //   setShowErr(true);
            //   return;
            // }
            AddEmployees();
            // router("/scheduleVerification/selectedEmployees");
          }}
          className={`${
            selectedEmployees.length === 0
              ? "hidden"
              : "lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]"
          } 
         `}
        >
          Add Employees
        </button>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
    </div>
  );
};

export default SelectProgram;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  UserOutlined,
  HomeOutlined,
  PhoneOutlined,
  IdcardOutlined,
  TeamOutlined,
  ContactsOutlined,
  DollarOutlined,
  ManOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import profile from "../../../../imgs/profile-img.png";
import arrowIcon from "../../../../imgs/left-arrow.png";

import { Tag } from "antd";
import AttendanceDashboardWrapper from "../../components/AttendanceWrapper";

const EmployeeDetails = () => {
  const dispatch = useDispatch();
  const router = useNavigate();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "2" });
  }, []);

  const deviceData = {
    id: "1",
    profile: profile,
    name: "John Doe",
    position: "Manager",
    department: "IT",
    rate: "$40/hr",
    address: "123 Main St, Springfield, USA",
    contact: "(123) 456-7890",
    gender: "Male",
    maritalStatus: "Single",
  };

  return (
    <AttendanceDashboardWrapper>
      <div className="">
        <div
          onClick={() => router("/AttendanceEmployees")}
          className="flex items-center mt-1 ml-5 gap-2 cursor-pointer hover:opacity-70 text-xl font-medium text-[#1ba397]">
          <img src={arrowIcon} alt="arrow-left" className="h-4 " />
          Back
        </div>
        <div className=" mx-auto flex flex-col items-center gap-6 px-5 py-8">
          <div className="w-20 h-20">
            <img
              className="w-full h-full object-contain"
              src={deviceData.profile}
              alt="Profile-img"
            />
          </div>
          <div className="flex flex-col justify-between h-full gap-1">
            <div>
              <h2 className="text-3xl font-semibold">{deviceData.name}</h2>
              <p className="text-base font-normal mt-3">
                <span className="font-bold text-[#1ba397] tracking-wide">Position:</span>{" "}
                {deviceData.position}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full">
          {/* Profile Section */}
          <div className="px-5 mt-5">
            {[
              { label: "Department", icon: <TeamOutlined />, value: deviceData.department },
              { label: "Rate", icon: <DollarOutlined />, value: deviceData.rate },
              { label: "Address", icon: <HomeOutlined />, value: deviceData.address },
              { label: "Contact", icon: <ContactsOutlined />, value: deviceData.contact },
              { label: "Marital Status", icon: <UserOutlined />, value: deviceData.maritalStatus },
              {
                label: "Gender",
                icon: deviceData.gender === "Male" ? <ManOutlined /> : <WomanOutlined />,
                value: deviceData.gender,
              },
            ].map((item, index) => (
              <div key={index} className="mt-3">
                <div className="flex justify-between items-center border-b-2 border-gray-100 px-2 py-3">
                  <h6 className="text-base flex gap-2 items-center">
                    <span className="text-[#1ba397]">{item.icon}</span>
                    {item.label}
                  </h6>
                  <h4 className="text-base font-semibold">{item.value || "Not Defined"}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AttendanceDashboardWrapper>
  );
};

export default EmployeeDetails;

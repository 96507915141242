import React, { useEffect, useMemo, useState } from "react";
import EmployeeAttendancDashboardeWrapper from "../EmployeeAttendancDashboardeWrapper";
import TopNav from "../components/TopNav";
import totalEmp from "../../../imgs/total-employee.png";
import onLeave from "../../../imgs/user.png";
import workingEmp from "../../../imgs/working-employee.png";
import pendingTasks from "../../../imgs/pending.png";
import device from "../../../imgs/device-green.png";
import { Avatar, Input, Menu, Select, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import AttendanceTable from "../../attendance/attendance/components/AttendanceTable";
import { useDispatch } from "react-redux";
import { generateUniqueId } from "../../../helpers/uniqueId";

const { Option } = Select;

const cardsData = [
  {
    id: 1,
    icon: totalEmp,
    title: "254",
    subTitle: "Total Requests",
  },
  {
    id: 2,
    icon: onLeave,
    title: "20",
    subTitle: "Pending",
  },
  {
    id: 3,
    icon: workingEmp,
    title: "20",
    subTitle: "Approved",
  },
  {
    id: 4,
    icon: pendingTasks,
    title: "4",
    subTitle: "In-Decision",
  },
];

const columns = [
  {
    title: "Request ID",
    dataIndex: "id",
    key: "id",
    render: (id) => {
      return <p className="font-semibold">#{id}</p>;
    },
  },
  {
    title: "Note",
    dataIndex: "note",
    key: "note",
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
  },
  {
    title: "Request To",
    dataIndex: "requestTo",
    key: "requestTo",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (type) => {
      const typeColors = {
        Leave: "green",
        "Short Leave": "gold",
        "IOS Device": "blue",
        Laptop: "purple",
        "Extra Time": "orange",
        "Patrol Card": "red",
      };

      return (
        <Tag color={typeColors[type] || "gray"} className="text-xs font-normal px-1 py-0.5">
          {type}
        </Tag>
      );
    },
  },

  {
    title: "Contact",
    dataIndex: "contact",
    key: "contact",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => (
      <Tag color={status === "Approved" ? "green" : status === "Pending" ? "orange" : "red"}>
        {status}
      </Tag>
    ),
  },
];

const staticData = [
  {
    id: generateUniqueId(),
    profile: device,
    name: "Laptop",
    department: "IT",
    type: "Leave",
    note: "123 Main St, Springfield, USA",
    requestTo: "Manager",
    contact: "9876543210",
    status: "Approved",
  },
  {
    id: generateUniqueId(),
    profile: device,
    name: "Mobile",
    type: "IOS Device",
    department: "IT",
    note: "456 Elm St, Springfield, USA",
    requestTo: "Admin",
    contact: "8765432109",
    status: "Pending",
  },
  {
    id: generateUniqueId(),
    profile: device,
    name: "Tablet",
    type: "Short Leave",
    department: "Medical",
    note: "789 Oak St, Springfield, USA",
    requestTo: "Manager",
    contact: "7654321098",
    status: "Denied",
  },
  {
    id: generateUniqueId(),
    profile: device,
    name: "Computer",
    type: "Laptop",
    department: "Staff",
    note: "101 Maple St, Springfield, USA",
    requestTo: "Admin",
    contact: "6543210987",
    status: "Approved",
  },
  {
    id: generateUniqueId(),
    profile: device,
    name: "Laptop",
    type: "Extra Time",
    department: "Staff",
    note: "101 Maple St, Springfield, USA",
    requestTo: "Admin",
    contact: "6543210987",
    status: "Pending",
  },
  {
    id: generateUniqueId(),
    profile: device,
    name: "Computer",
    type: "Patrol Card",
    department: "Staff",
    note: "101 Maple St, Springfield, USA",
    requestTo: "HR",
    contact: "4321098765",
    status: "Denied",
  },
];

const EmployeeRequest = () => {
  const router = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "4" });
  }, []);

  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedRequestTo, setSelectedRequestTo] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleDepartmentChange = (value) => setSelectedDepartment(value);
  const handleRequestToChange = (value) => setSelectedRequestTo(value);
  const handleTypeChange = (value) => setSelectedType(value);
  const handleStatusChange = (value) => setSelectedStatus(value);

  // Memoize filtered data to optimize performance
  const filteredData = useMemo(() => {
    return staticData.filter((record) => {
      const matchesDepartment = selectedDepartment
        ? record.department === selectedDepartment
        : true;
      const matchesRequestTo = selectedRequestTo ? record.requestTo === selectedRequestTo : true;
      const matchesType = selectedType ? record.type === selectedType : true;
      const matchesStatus = selectedStatus ? record.status === selectedStatus : true;
      return matchesDepartment && matchesRequestTo && matchesType && matchesStatus;
    });
  }, [selectedDepartment, selectedRequestTo, selectedType, selectedStatus]);

  const handleAction = (action, id) => {
    switch (action) {
      case "view":
        router(`/EmployeeDashboard/ERequest/Detail`);
        break;
      case "delete":
        console.log(`Delete request ID: ${id}`);
        break;
      default:
        console.log("Unknown action");
    }
  };

  // Action menu for each row
  const actionMenu = (id) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleAction("view", id)}>
        View
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleAction("delete", id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <EmployeeAttendancDashboardeWrapper>
      <div className="">
        <div className="">
          <TopNav title="Recent Requests" cardData={cardsData} />
        </div>
        <div className="grid gap-4 grid-cols-5 mb-4 mt-5">
          <Select
            placeholder="Select Department"
            onChange={handleDepartmentChange}
            className="w-full h-[32px]"
            allowClear>
            {Array.from(new Set(staticData.map((data) => data.department))).map((department) => (
              <Option key={department} value={department}>
                {department}
              </Option>
            ))}
          </Select>
          <Select 
            placeholder="Select Request To"
            onChange={handleRequestToChange}
            className="w-full h-[32px]"
            allowClear>
            {Array.from(new Set(staticData.map((data) => data.requestTo))).map((requestTo) => (
              <Option key={requestTo} value={requestTo}>
                {requestTo}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Select Type"
            onChange={handleTypeChange}
            className="w-full h-[32px]"
            allowClear>
            {Array.from(new Set(staticData.map((data) => data.type))).map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Select Status"
            onChange={handleStatusChange}
            className="w-full h-[32px]"
            allowClear>
            {Array.from(new Set(staticData.map((data) => data.status))).map((status) => (
              <Option key={status} value={status}>
                {status}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <AttendanceTable
            columns={columns}
            data={filteredData}
            pageSize={10}
            rowKey="id"
            actions={actionMenu}
          />
        </div>
      </div>
    </EmployeeAttendancDashboardeWrapper>
  );
};

export default EmployeeRequest;

import React, { useEffect, useState } from "react";
// import verify from "../../../../imgs/verify.svg";
import vp from "../../../../imgs/vp.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Complete from "../Profile/CompleteProfie";
// import moment from "moment";
// import { CalendarFilled, SlidersFilled } from "@ant-design/icons";
import axios from "axios";
import ErrorModal from "../../Modals/errorModal";
import { message } from "antd";
import SelectVoucher from "./selectVouchers";
// import SelectVoc from "./select";
import bv from "../../../../imgs/bv.svg";
import va from "../../../../imgs/va.svg";
import moment from "moment";
import AmountModal from "../NewPay/AmountModal";

const ProfileCard = () => {
  const router = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const activeVouchers = useSelector((state) => state.activeVouchers);
  const selectedProgramPensionBook = useSelector(
    (state) => state.selectedProgramPensionBook
  );
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  const [amount, setAmount] = useState(0);
  const [showAmountModal, setShowAmountModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  // const activePensionerSession = useSelector(
  //   (state) => state.activePensionerSession
  // );
  // console.log(selectedPensioner);
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  const [selectedVoucherIds, setSelectedVoucherIds] = useState([]);
  const dash = useLocation().pathname;
  const completeProfile = useSelector((state) => state.completeProfile);
  const token = useSelector((state) => state.token);
  const [activeCoupons, setActiveCoupons] = useState(0);
  const [pensionBook, setPensionBook] = useState();
  const [showErrModal, setShowErrModal] = useState(false);
  const [err, setErr] = useState("");
  const sapmoney = useSelector((state) => state.sapmoney);
  const [paymentDate, setPaymentDate] = useState(null);
  const [messageData, setMessageData] = useState("");
  const sampleData = {
    2023: {
      pensionbook: {
        id: "9c5f07ba-1f9e-4d46-bf30-3cb4d9e2c8b9",
        bookletNo: "5512",
        frontImg: "1234567891234-567890123.jpeg",
        year: "2023",
        status: "Active",
        issueDate: "2018-07-10T00:00:00.000Z",
        createdAt: "2023-07-10T18:30:07.000Z",
        updatedAt: "2023-07-10T18:30:07.000Z",
        pensionprogramId: "45e67a27-b45c-478d-a123-d34abc29dc12",
        pensionerinfoId: "7g5ba092-2ea1-4a14-83f0-af5463d4fd22",
      },
      coupons: [
        {
          id: "ab123fb0-012b-12cd-8a7b-e72e9a9a1111",
          month: 1,
          status: "Active",
          createdAt: "2023-07-10T18:30:07.000Z",
          updatedAt: "2023-07-10T18:30:07.000Z",
          pensionbookId: "9c5f07ba-1f9e-4d46-bf30-3cb4d9e2c8b9",
        },
        {
          id: "cd345fb0-034b-23ef-9b7c-f73e9a9a2222",
          month: 2,
          status: "Active",
          createdAt: "2023-07-10T18:30:07.000Z",
          updatedAt: "2023-07-10T18:30:07.000Z",
          pensionbookId: "9c5f07ba-1f9e-4d46-bf30-3cb4d9e2c8b9",
        },
        {
          id: "ef567fb0-056b-34gh-0c8d-h84e9a9a3333",
          month: 3,
          status: "Active",
          createdAt: "2023-07-10T18:30:07.000Z",
          updatedAt: "2023-07-10T18:30:07.000Z",
          pensionbookId: "9c5f07ba-1f9e-4d46-bf30-3cb4d9e2c8b9",
        },
        {
          id: "gh789fb0-078b-45ij-1d9e-i95e9a9a4444",
          month: 4,
          status: "Active",
          createdAt: "2023-07-10T18:30:07.000Z",
          updatedAt: "2023-07-10T18:30:07.000Z",
          pensionbookId: "9c5f07ba-1f9e-4d46-bf30-3cb4d9e2c8b9",
        },
        {
          id: "ij901fb0-091b-56kl-2e0f-j06e9a9a5555",
          month: 5,
          status: "Redeemed",
          createdAt: "2023-07-10T18:30:07.000Z",
          updatedAt: "2023-07-10T18:30:07.000Z",
          pensionbookId: "9c5f07ba-1f9e-4d46-bf30-3cb4d9e2c8b9",
        },
        {
          id: "kl012fb0-012b-67mn-3f1g-k17e9a9a6666",
          month: 6,
          status: "Redeemed",
          createdAt: "2023-07-10T18:30:07.000Z",
          updatedAt: "2023-07-10T18:30:07.000Z",
          pensionbookId: "9c5f07ba-1f9e-4d46-bf30-3cb4d9e2c8b9",
        },
        {
          id: "mn234fb0-034b-78op-4g2h-l18e9a9a7777",
          month: 7,
          status: "Redeemed",
          createdAt: "2023-07-10T18:30:07.000Z",
          updatedAt: "2023-07-10T18:30:07.000Z",
          pensionbookId: "9c5f07ba-1f9e-4d46-bf30-3cb4d9e2c8b9",
        },
        {
          id: "op456fb0-056b-89qr-5h3i-m19e9a9a8888",
          month: 8,
          status: "Redeemed",
          createdAt: "2023-07-10T18:30:07.000Z",
          updatedAt: "2023-07-10T18:30:07.000Z",
          pensionbookId: "9c5f07ba-1f9e-4d46-bf30-3cb4d9e2c8b9",
        },
        {
          id: "qr678fb0-078b-90st-6i4j-n20e9a9a9999",
          month: 9,
          status: "Active",
          createdAt: "2023-07-10T18:30:07.000Z",
          updatedAt: "2023-07-10T18:30:07.000Z",
          pensionbookId: "9c5f07ba-1f9e-4d46-bf30-3cb4d9e2c8b9",
        },
        {
          id: "st890fb0-091b-12uv-7j5k-o21e9a9a0000",
          month: 10,
          status: "Active",
          createdAt: "2023-07-10T18:30:07.000Z",
          updatedAt: "2023-07-10T18:30:07.000Z",
          pensionbookId: "9c5f07ba-1f9e-4d46-bf30-3cb4d9e2c8b9",
        },
        {
          id: "uv012fb0-012b-34wx-8k6l-p22e9a9a1111",
          month: 11,
          status: "Active",
          createdAt: "2023-07-10T18:30:07.000Z",
          updatedAt: "2023-07-10T18:30:07.000Z",
          pensionbookId: "9c5f07ba-1f9e-4d46-bf30-3cb4d9e2c8b9",
        },
        {
          id: "wx234fb0-034b-45yz-9l7m-q23e9a9a2222",
          month: 12,
          status: "Active",
          createdAt: "2023-07-10T18:30:07.000Z",
          updatedAt: "2023-07-10T18:30:07.000Z",
          pensionbookId: "9c5f07ba-1f9e-4d46-bf30-3cb4d9e2c8b9",
        },
      ],
    },
    2024: {
      pensionbook: {
        id: "7be605ba-2e4e-4cb6-a25f-1bd7e8fbae54",
        bookletNo: "6637",
        frontImg: "1723228078672-627017404.jpeg",
        year: "2024",
        status: "Active",
        issueDate: "2019-08-14T00:00:00.000Z",
        createdAt: "2024-08-09T18:30:07.000Z",
        updatedAt: "2024-08-09T18:30:07.000Z",
        pensionprogramId: "61b67a27-b95c-479d-a440-f17dcf29dc00",
        pensionerinfoId: "2f5ca002-2ea2-4a41-83f0-bf3463d4fd44",
      },
      coupons: [
        {
          id: "ab602fb0-022a-42ee-8a7b-e72e9a9a105c",
          month: 1,
          status: "Active",
          createdAt: "2024-08-09T18:30:07.000Z",
          updatedAt: "2024-08-09T18:30:07.000Z",
          pensionbookId: "7be605ba-2e4e-4cb6-a25f-1bd7e8fbae54",
        },
        {
          id: "a70dbb93-77fe-41d0-93b7-6647eccce7bd",
          month: 2,
          status: "Active",
          createdAt: "2024-08-09T18:30:07.000Z",
          updatedAt: "2024-08-09T18:30:07.000Z",
          pensionbookId: "7be605ba-2e4e-4cb6-a25f-1bd7e8fbae54",
        },
        {
          id: "b222720f-d7a9-40cd-9249-c86027d3c238",
          month: 3,
          status: "Active",
          createdAt: "2024-08-09T18:30:07.000Z",
          updatedAt: "2024-08-09T18:30:07.000Z",
          pensionbookId: "7be605ba-2e4e-4cb6-a25f-1bd7e8fbae54",
        },
        {
          id: "47a6ac4b-e717-4e69-a716-c5ebfbbf00b6",
          month: 4,
          status: "Active",
          createdAt: "2024-08-09T18:30:07.000Z",
          updatedAt: "2024-08-09T18:30:07.000Z",
          pensionbookId: "7be605ba-2e4e-4cb6-a25f-1bd7e8fbae54",
        },
        {
          id: "2c021a55-4316-41df-84ca-cbdaf3859717",
          month: 5,
          status: "Active",
          createdAt: "2024-08-09T18:30:07.000Z",
          updatedAt: "2024-08-09T18:30:07.000Z",
          pensionbookId: "7be605ba-2e4e-4cb6-a25f-1bd7e8fbae54",
        },
        {
          id: "13a663a4-9fa6-4be0-9103-72575820f013",
          month: 6,
          status: "Active",
          createdAt: "2024-08-09T18:30:07.000Z",
          updatedAt: "2024-08-09T18:30:07.000Z",
          pensionbookId: "7be605ba-2e4e-4cb6-a25f-1bd7e8fbae54",
        },
        {
          id: "d508f83f-4098-42b8-8e24-3378d1b95039",
          month: 7,
          status: "Active",
          createdAt: "2024-08-09T18:30:07.000Z",
          updatedAt: "2024-08-09T18:30:07.000Z",
          pensionbookId: "7be605ba-2e4e-4cb6-a25f-1bd7e8fbae54",
        },
        {
          id: "3d5db341-b02c-490e-868e-40a45009cc84",
          month: 8,
          status: "Active",
          createdAt: "2024-08-09T18:30:07.000Z",
          updatedAt: "2024-08-13T12:33:38.000Z",
          pensionbookId: "7be605ba-2e4e-4cb6-a25f-1bd7e8fbae54",
        },
        {
          id: "61e42c40-3e7e-4990-9a44-579a2fc676dc",
          month: 9,
          status: "Active",
          createdAt: "2024-08-09T18:30:07.000Z",
          updatedAt: "2024-08-09T18:30:07.000Z",
          pensionbookId: "7be605ba-2e4e-4cb6-a25f-1bd7e8fbae54",
        },
        {
          id: "927396a7-da1e-485c-8d40-1a4a50998b5f",
          month: 10,
          status: "Active",
          createdAt: "2024-08-09T18:30:07.000Z",
          updatedAt: "2024-08-09T18:30:07.000Z",
          pensionbookId: "7be605ba-2e4e-4cb6-a25f-1bd7e8fbae54",
        },
        {
          id: "fb4d0444-965f-4e34-9ecd-a68cb3763b67",
          month: 11,
          status: "Active",
          createdAt: "2024-08-09T18:30:07.000Z",
          updatedAt: "2024-08-09T18:30:07.000Z",
          pensionbookId: "7be605ba-2e4e-4cb6-a25f-1bd7e8fbae54",
        },
        {
          id: "be370a0f-f740-4481-b3c5-02ebf208cff2",
          month: 12,
          status: "Active",
          createdAt: "2024-08-09T18:30:07.000Z",
          updatedAt: "2024-08-09T18:30:07.000Z",
          pensionbookId: "7be605ba-2e4e-4cb6-a25f-1bd7e8fbae54",
        },
      ],
    },
  };
  const getMonthName = (month) => {
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    return monthNames[month - 1]; // Subtracting 1 since months are zero-based in JavaScript Date
  };

  useEffect(() => {
    if (selectedPensioner?.pensionPrograms?.[0]?.id) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/applications/pensioner-recent/for-merch?pensionerUserId=${selectedPensioner?.user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          if (data?.data?.[0]?.applications?.[0]?.finalStatus === "pending") {
            setErr(
              "The voucher cannot be cashed for this pensioner because their application is still pending approval."
            );
            setMessageData(
              "The voucher cannot be cashed for this pensioner because their application is still pending approval."
            );
            setShowErrModal(true);
            return;
          }
          setPaymentDate(data?.data?.[0]?.applications?.[0]?.from);
          dispatch({
            type: "START_PAYMENT_DATE",
            num: data?.data?.[0]?.applications?.[0]?.from,
            // num: "2023-05-01",
          });
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShowErrModal(true);
          console.log(err);
        });
    }
  }, []);

  // useEffect(() => {
  //   if (paymentDate === null) {
  //     return;
  //   }

  //   if (selectedPensioner?.pensionPrograms?.[0]?.id) {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_BASE_URL}/v1/pension-books/merch-get-by-program?pensionProgramId=${selectedPensioner?.pensionPrograms?.[0]?.id}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((data) => {
  //         const apiData = data?.data;
  //         // const apiData = data1;

  //         // const paymentDate = paymentDate; // Updated payment date
  //         const startDate = new Date(paymentDate);
  //         const startMonth = startDate.getMonth() + 1; // +1 because months are 0-based
  //         const startYear = startDate.getFullYear();

  //         const currentDate = new Date();
  //         const currentMonth = currentDate.getMonth() + 1;
  //         const currentYear = currentDate.getFullYear();
  //         const previousYear = currentYear - 1;

  //         const result = {};

  //         // Process vouchers for the previous year
  //         if (apiData.hasOwnProperty(previousYear)) {
  //           const coupons = apiData[previousYear].coupons.filter(
  //             (coupon) =>
  //               coupon.status === "Active" &&
  //               (startYear > previousYear || coupon.month >= startMonth)
  //           );
  //           // console.log("Previous Year Coupons:", coupons);
  //           if (coupons.length > 0) {
  //             result[previousYear] = coupons;
  //           }
  //         }

  //         // Process vouchers for the current year, including the current month
  //         if (apiData[currentYear]) {
  //           const couponsCurrentYear = apiData[currentYear].coupons.filter(
  //             (coupon) =>
  //               coupon.status === "Active" && coupon.month <= currentMonth
  //           );
  //           // console.log("Current Year Coupons:", couponsCurrentYear);
  //           if (couponsCurrentYear.length > 0) {
  //             result[currentYear] = couponsCurrentYear;
  //           }

  //           dispatch({
  //             type: "SELECTED_PENSIONER_VOUCHERS",
  //             num: apiData[currentYear].coupons,
  //           });
  //         }

  //         // Ensure final result includes correct filtering based on month and year
  //         const finalResult = Object.keys(result).reduce((acc, year) => {
  //           const coupons = result[year].filter(
  //             (coupon) =>
  //               parseInt(year) > startYear ||
  //               (parseInt(year) === startYear && coupon.month >= startMonth)
  //           );
  //           console.log(`Filtered Coupons for ${year}:`, coupons);
  //           if (coupons.length > 0) {
  //             acc[year] = coupons;
  //           }
  //           return acc;
  //         }, {});

  //         const totalCount = Object.values(finalResult).reduce(
  //           (count, coupons) => count + coupons.length,
  //           0
  //         );

  //         dispatch({
  //           type: "ACTIVE_VOUCHERS",
  //           num: finalResult,
  //         });

  //         if (apiData[currentYear]) {
  //           const pensionbook = apiData[currentYear].pensionbook;
  //           setPensionBook(pensionbook);
  //           dispatch({
  //             type: "SELECTED_PROGRAM_PENSION_BOOK",
  //             num: pensionbook,
  //           });
  //         }

  //         setActiveCoupons(totalCount);

  //         // const activeCouponCount = data?.data?.coupons?.filter((coupon) => {
  //         //   return coupon?.status === "Active" && coupon?.month <= currentMonth;
  //         // }).length;

  //         // const newActiveCoupons = data?.data?.coupons?.filter((coupon) => {
  //         //   return coupon?.status === "Active" && coupon?.month <= currentMonth;
  //         // });

  //         // dispatch({ type: "ACTIVE_VOUCHERS", num: newActiveCoupons });

  //         // setActiveCoupons(activeCouponCount);
  //       })
  //       .catch((err) => {
  //         setErr(err?.response?.data?.message);
  //         setShowErrModal(true);
  //         console.log(err);
  //       });
  //   }
  // }, [paymentDate]);

  useEffect(() => {
    if (paymentDate === null) {
      return;
    }

    if (selectedPensioner?.pensionPrograms?.[0]?.id) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/pension-books/merch-get-by-program?pensionProgramId=${selectedPensioner?.pensionPrograms?.[0]?.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const apiData = response?.data; // Use sampleData for testing

          const startDate = new Date(paymentDate);
          const startMonth = startDate.getMonth() + 1; // +1 because months are 0-based
          const startYear = startDate.getFullYear();

          const currentDate = new Date();
          const currentMonth = currentDate.getMonth() + 1;
          const currentYear = currentDate.getFullYear();
          const previousYear = currentYear - 1;

          const result = {};

          // Process vouchers for the previous year if available
          if (apiData.hasOwnProperty(previousYear)) {
            const coupons = apiData[previousYear].coupons.filter(
              (coupon) =>
                coupon.status === "Active" &&
                (startYear > previousYear || coupon.month >= startMonth)
            );
            const activeCouponsCount = coupons.length;
            result[previousYear] = {
              coupons,
              allCoupons: apiData[previousYear].coupons,
              activeCoupons: activeCouponsCount,
              pensionBookInfo: apiData[previousYear].pensionbook || {},
            };
          }

          // Process vouchers for the current year if available
          if (apiData[currentYear]) {
            const couponsCurrentYear = apiData[currentYear].coupons.filter(
              (coupon) =>
                coupon.status === "Active" && coupon.month <= currentMonth
            );
            const activeCouponsCount = couponsCurrentYear.length;
            result[currentYear] = {
              coupons: couponsCurrentYear,
              allCoupons: apiData[currentYear].coupons,
              activeCoupons: activeCouponsCount,
              pensionBookInfo: apiData[currentYear].pensionbook || {},
            };

            // dispatch({
            //   type: "SELECTED_PENSIONER_VOUCHERS",
            //   num: apiData[currentYear].coupons,
            // });
          }

          // Debug logs to check the results before filtering
          console.log("Initial Result:", result);

          // Filter final result based on month and year
          const finalResult = Object.keys(result).reduce((acc, year) => {
            const { coupons, pensionBookInfo, activeCoupons, allCoupons } =
              result[year];
            const filteredCoupons = coupons.filter(
              (coupon) =>
                parseInt(year) > startYear ||
                (parseInt(year) === startYear && coupon.month >= startMonth)
            );
            if (filteredCoupons.length > 0 || pensionBookInfo) {
              acc[year] = {
                coupons: filteredCoupons,
                activeCoupons,
                pensionBookInfo,
                allCoupons,
              };
            }
            return acc;
          }, {});

          // Debug logs to check the final result
          console.log("Final Result:", finalResult);

          const totalCount = Object.values(finalResult).reduce(
            (count, { coupons }) => count + coupons.length,
            0
          );

          dispatch({
            type: "ACTIVE_VOUCHERS",
            num: finalResult,
          });

          if (apiData[currentYear]) {
            const pensionbook = apiData[currentYear].pensionbook;
            setPensionBook(pensionbook);
            dispatch({
              type: "SELECTED_PROGRAM_PENSION_BOOK",
              num: pensionbook,
            });
          }

          setActiveCoupons(totalCount);
        })
        .catch((err) => {
          setErr(err?.response?.data?.message);
          setShowErrModal(true);
          console.log(err);
        });
    }
  }, [paymentDate]);

  const handleSelection = (voucher) => {
    setSelectedVouchers((prevSelectedVouchers) => {
      const isSelected = prevSelectedVouchers.some(
        (v) => v?.id === voucher?.id
      );

      const updatedVouchers = isSelected
        ? prevSelectedVouchers.filter((v) => v?.id !== voucher?.id)
        : [...prevSelectedVouchers, voucher];

      dispatch({ type: "SELECTED_VOUCHER_FOR_CASH", num: updatedVouchers });

      return updatedVouchers;
    });

    setSelectedVoucherIds((prevSelectedIds) => {
      const isSelected = prevSelectedIds.includes(voucher?.id);

      return isSelected
        ? prevSelectedIds.filter((id) => id !== voucher?.id)
        : [...prevSelectedIds, voucher?.id];
    });
  };

  const getAmount = () => {
    if (selectedVoucherIds?.length === 0) {
      setErr("Please select a voucher");
      setShowErrorModal(true);
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/get-total-amount-by-program`,
        {
          pensionProgramId: selectedProgramPensionBook?.pensionprogramId,
          couponIds: selectedVoucherIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setAmount(data?.data?.totalAmount);
        dispatch({ type: "VOUCHER_AMOUNT", num: amount });
        // setShowVouchers(false);
        setShowAmountModal(true);
      })
      .catch(() => {});
  };

  console.log(selectedVouchers, "selectedVouchers");

  return (
    <div className={`${sapmoney ? "mt-3" : "sm:mt-[-2rem] mt-3"}`}>
      {contextHolder}
      <div className="sm:mx-4 ">
        <div className="sm:p-8 p-3 bg-white rounded-lg">
          <div className="w-full mb-10 relative">
            <div className="bg-[#F5F5F5] p-6 rounded-md">
              <div
                className={`${
                  selectedPensioner?.user?.status === "rejected"
                    ? "bg-[#C53F3F]"
                    : selectedPensioner?.user?.status === "in-review"
                    ? "bg-blue-600"
                    : selectedPensioner?.user?.status === "pending"
                    ? "bg-yellow-400"
                    : "bg-primary"
                }  absolute top-4 right-5 sm:top-3 sm:right-5 text-white px-4 py-1 rounded text-md sm:text-lg border-none  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
              >
                {selectedPensioner?.user?.status}
              </div>
              <div
                className={`flex justify-between items-center mt-8 md:mt-0 lg:mt-0 `}
              >
                <div className="flex items-center sm:flex-row flex-col ">
                  <div className="sm:w-28 sm:h-28 w-20 h-20 bg-white border  rounded-full shadow-2xl  inset-x-0 top-0 flex items-center justify-center text-indigo-500">
                    <img
                      src={selectedPensioner?.user?.person?.image}
                      alt="profile"
                      className="rounded-full sm:h-24 sm:w-24 h-16 w-16"
                    />
                  </div>
                  <div className="ml-4 space-y-1 ">
                    <h1 className="text-xl font-semibold text-primary capitalize">
                      {selectedPensioner?.user?.person?.firstName}{" "}
                      {selectedPensioner?.user?.person?.middleName &&
                        selectedPensioner?.user?.person?.middleName + " "}
                      {selectedPensioner?.user?.person?.lastName}{" "}
                      {/* <span className="font-light text-gray-600">27</span> */}
                    </h1>
                    <div className="space-y-1">
                      <p className="text-md font-medium text-gray-700">
                        GID :{" "}
                        <span className="text-gray-800 text-md font-bold">
                          {selectedPensioner?.user?.gid}
                        </span>
                      </p>
                      <p className={`text-md font-medium text-gray-600 `}>
                        DOB :{" "}
                        <span className="text-gray-800 text-md font-bold">
                          {selectedPensioner?.user?.person?.dateOfBirth}
                        </span>
                      </p>
                    </div>
                    <button
                      className={`${
                        completeProfile === true ? "hidden" : ""
                      }  text-primary mt-2 font-bold`}
                      onClick={() => {
                        dispatch({ type: "COMPLETE_PROFILE", num: true });
                      }}
                    >
                      View Complete Profile
                    </button>
                    <div className="text-red-600">{messageData}</div>
                  </div>
                </div>
              </div>
              {/* <div className="text-center text-red-600 p-2">{messageData}</div> */}
            </div>
            {/* <hr className="h-px my-8 bg-gray-300 border-0 " /> */}
            <div
              className={`ml-2 mr-2 pt-5 p-4 rounded-md mb-4 cursor-pointer mt-8
              bg-[#F6F4F4] ${completeProfile === true ? "hidden" : ""}
                `}
            >
              <div className="flex items-center">
                <div className="flex items-center justify-center bg-[#F5F5F5] rounded-full w-10 h-10">
                  <img src={vp} alt="vp" className="w-8 h-8" />
                  {/* <SlidersFilled
                    style={{ fontSize: "26px", color: "#1BA397" }}
                  /> */}
                </div>
                <div className="ml-2">
                  <h3
                    className={`text-lg font-semibold mb-1 
                        text-primary
                      `}
                  >
                    {selectedPensioner?.pensionPrograms?.[0]?.name ===
                    "senior-citizen-Pension"
                      ? "Senior Citizen Pension"
                      : selectedPensioner?.pensionPrograms?.[0]?.name}
                  </h3>
                  <p
                    className={`text-xs 
                       text-gray-600
                      `}
                  >
                    {/* {item.text} */}
                  </p>
                </div>
              </div>
              {Object.entries(activeVouchers)?.map(([year, yearData]) => {
                const { coupons, pensionBookInfo, allCoupons } = yearData;
                return (
                  <div
                    key={year}
                    className="year-group mt-3  mb-4 bg-white relative"
                  >
                    <div className=" ml-4 mr-4 mt-3 ">
                      <div
                        className={`ml-2 mr-2 flex items-center justify-between p-3 rounded-lg mb-2 cursor-pointer 
               
                  `}
                      >
                        <div className="flex items-center">
                          <div className="">
                            <p
                              className={`text-[0.92rem]
                         text-gray-700 font-semibold
                         ${pensionBook ? "" : "hidden"}
                        `}
                            >
                              {`Booklet # ${pensionBookInfo?.bookletNo}`} (
                              {year})
                            </p>
                            <p
                              className={`text-[0.9rem]
                         text-gray-400
                        `}
                            >
                              {coupons?.length === 0
                                ? "No active voucher is available"
                                : `${coupons?.length} Vouchers Available`}
                            </p>
                          </div>
                        </div>
                        <p
                          className="absolute top-2 right-2 text-primary cursor-pointer font-semibold"
                          onClick={() => {
                            dispatch({
                              type: "SELECTED_PENSIONER_VOUCHERS",
                              num: {
                                allCoupons,
                                year,
                              },
                            });
                            if (dash === "/Merchant/SapMoney/SelectVouchers") {
                              router("/Merchant/SapMoney/allVouchers");
                            } else {
                              router("/Merchant/PensionerProfile/Vouchers");
                            }
                          }}
                        >
                          View All
                        </p>
                      </div>
                    </div>
                    <div className="w-full rounded-lg relative grid grid-cols-2 gap-3 sm:px-8 px-3 mt-1 pb-5">
                      {coupons.map((voc) => (
                        <div
                          key={voc?.id}
                          onClick={() => handleSelection(voc)}
                          className={`flex items-center justify-between p-3 rounded-md cursor-pointer 
                                ${
                                  selectedVoucherIds?.includes(voc?.id)
                                    ? "bg-primary"
                                    : "bg-[#F6F4F4]"
                                }`}
                        >
                          <div className="flex items-center">
                            <img
                              src={
                                selectedVoucherIds?.includes(voc?.id) ? va : bv
                              }
                              alt="vc"
                              className="w-6 h-6"
                            />
                            <div className="ml-4">
                              <h3
                                className={`text-[1rem] font-semibold mb-1 ${
                                  selectedVoucherIds.includes(voc?.id)
                                    ? "text-white"
                                    : "text-primary"
                                }`}
                              >
                                {getMonthName(voc?.month)}{" "}
                                {moment(year).format("YYYY")}
                              </h3>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className={`${completeProfile === true ? "" : "hidden"}`}>
              <Complete />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex p-4 gap-2 items-center justify-center  px-[30%] mb-6 ${
          Object.keys(activeVouchers)?.length > 0 ? "" : "hidden"
        }`}
      >
        <button
          onClick={() => getAmount()}
          className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-primary  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          Calculate
        </button>
      </div>
      {showAmountModal && (
        <AmountModal setShowAmountModal={setShowAmountModal} amount={amount} />
      )}
      {showErrModal && <ErrorModal err={err} setShow={setShowErrModal} />}
    </div>
  );
};

export default ProfileCard;

import React from "react";
import { Form, Input, Row, Col, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

const EditDevices = ({ initialValues = {}, onSave }) => {
  const [form] = Form.useForm();
  const router = useNavigate();

  const handleFinish = (values) => {
    console.log("Form values:", values);
    router("/Devices");

    onSave && onSave(values);
  };
  const onCancel = (params) => {
    router("/Devices");
  };

  return (
    <div className="p-6 bg-white">
      <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter the device name" }]}>
              <Input placeholder="Enter device name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="IP Address"
              name="ipAddress"
              rules={[{ required: true, message: "Please enter IP address" }]}>
              <Input placeholder="Enter IP address" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Location" name="location">
              <Input placeholder="Enter location" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Department" name="department">
              <Input placeholder="Enter department" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Address" name="address">
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>
          {/* Longitude and Latitude Fields */}
          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Longitude"
                  name="longitude"
                  rules={[{ required: true, message: "Please enter longitude" }]}>
                  <Input placeholder="Enter longitude" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Latitude"
                  name="latitude"
                  rules={[{ required: true, message: "Please enter latitude" }]}>
                  <Input placeholder="Enter latitude" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Form.Item label="Contact" name="contact">
              <Input placeholder="Enter contact" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone" name="phone">
              <Input placeholder="Enter phone number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ type: "email", message: "Please enter a valid email" }]}>
              <Input placeholder="Enter email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Biometric" name="biometric">
              <Select
                mode="multiple"
                placeholder="Select biometric options"
                options={[
                  { label: "FP - Fingerprint", value: "FP" },
                  { label: "FR - Facial Recognition", value: "FR" },
                  { label: "HPV - Hand Palm Vein", value: "HPV" },
                  { label: "NFC - Near Field Card", value: "NFC" },
                  { label: "QRC - QR Code", value: "QRC" },
                  { label: "PIN - Pin Code", value: "PIN" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Device Serial Number"
              name="deviceSerial"
              rules={[{ required: true, message: "Please enter the device serial number" }]}>
              <Input placeholder="Enter device serial number" />
            </Form.Item>
          </Col>
          {/* IMEI Number Field */}
          <Col span={12}>
            <Form.Item
              label="IMEI Number"
              name="imeiNumber"
              rules={[{ required: true, message: "Please enter the IMEI number" }]}>
              <Input placeholder="Enter IMEI number" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Device ID"
              name="deviceId"
              rules={[{ required: true, message: "Please enter device ID" }]}>
              <Input placeholder="Enter device ID" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Telephone Company"
              name="telephoneCompany"
              rules={[{ required: true, message: "Please select a telephone company" }]}>
              <Select
                placeholder="Select telephone company"
                options={[
                  { label: "GTT", value: "GTT" },
                  { label: "Digicel", value: "Digicel" },
                  { label: "eNetwork", value: "eNetwork" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="SIM Number"
              name="simNumber"
              rules={[{ required: true, message: "Please enter SIM number" }]}>
              <Input placeholder="Enter SIM number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[{ required: true, message: "Please enter phone number" }]}>
              <Input placeholder="Enter phone number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Data Plan" name="dataPlan">
              <Input placeholder="Enter data plan details" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Image"
              name="image"
              valuePropName="fileList"
              getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}>
              <Upload
                listType="picture"
                beforeUpload={() => false}
                onClick={(e) => e.preventDefault()} // Prevents form submission
              >
                <Button transparent className="border-dotted" icon={<UploadOutlined />}>
                  Upload Image
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-end gap-3 mt-4">
          <Button transparent onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditDevices;

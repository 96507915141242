import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";

const EmployeeAttendancDashboardeWrapper = ({ children }) => {
  const openSideBar = useSelector((state) => state.openSideBar);
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "1" });
  }, [dispatch]);

  return (
    <div
      className={`${
        windowSize?.width <= 768
          ? "h-screen flex flex-col"
          : "flex flex-row p-7 gap-5 h-screen bg-[#D9EFED] w-full"
      }`}>
      {windowSize?.width <= 768 ? (
        <>
          <header className="flex items-center bg-primary px-4 py-2 gap-4 text-white">
            <MenuUnfoldOutlined
              className="text-2xl cursor-pointer"
              onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: true })}
            />
            <h1 className="text-xl font-bold">Dashboard</h1>
          </header>
          {openSideBar && (
            <div className="fixed left-0 top-0 bottom-0 z-20 bg-white w-4/5">
              <Sidebar />
            </div>
          )}
        </>
      ) : (
        <aside
          className="w-[20%] rounded-lg"
          style={{
            backgroundColor: "white",
            boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          }}>
          <Sidebar />
        </aside>
      )}
      <div
        className={`${
          windowSize?.width <= 768
            ? "flex-grow overflow-y-auto p-4"
            : "flex flex-col w-[78%] gap-3 p-3 "
        }`}>
        <header className="h-18 shrink-0 rounded-lg">
          <Header type={"Payment"} header={false} route={"/MHSSS/payment"} />
        </header>
        <main
          className={`${windowSize?.width <= 768 ? "" : "flex-grow rounded-lg py-3 px-2"}`}
          style={
            windowSize?.width <= 768
              ? {}
              : {
                  backgroundColor: "white",
                }
          }>
          {children}
        </main>
      </div>
    </div>
  );
};

export default EmployeeAttendancDashboardeWrapper;

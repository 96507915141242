import {
  isLoggedReducer,
  veriReducer,
  veri2Reducer,
  wowReducer,
  wowxReducer,
  tokenReducer,
  tokenRefReducer,
  myPrivileges,
  refreshTokenReducer,
  benIdReducer,
  loginTokenReducer,
  nameReducer,
  universalCheckReducer,
  appQuestionsReducer,
  editReducer,
  placeReducer,
  userDataReducer,
  dataReducer,
  popDataReducer,
  data1Reducer,
  data2Reducer,
  data3Reducer,
  profileDataReducer,
  allBenReducer,
  userDataReducer1,
  benDoneReducer,
  camReducer,
  idDoneReducer,
  pensionerNameReducer,
  pensionerIdReducer,
  addPensionerReducer,
  imgReducer,
  applicationAnswersReducer,
  whichViewReducer,
  fromAwaitedReducer,
  penIdReducer,
  sidebar,
  sidebarE,
  pensionerInfoReducer,
  faceApi,
  user,
  tokenExpiry,
  employeeId,
  remEmployee,
  remPensioner,
  remMerchat,
  remVendor,
  PesnionerLinkedCenter,
  PesnionerLinkedCenterCheck,
} from "./wow";
import {
  authStateReducer,
  placePopReducer,
  pol1Reducer,
  polNameReducer,
  polReducer,
  polStatusReducer,
  polUserReducer,
  popupReducer,
  resetReducer,
} from "./popup";
import {
  rcurrentReducer,
  icurrentReducer,
  bicurrentReducer,
  brcurrentReducer,
  acurrentReducer,
  merchantEmployeeeStepReducer,
  subEvent,
  verify,
} from "./currents";
import {
  optionReducer,
  option2Reducer,
  statusReducer,
  gidFilterReducer,
  phoneFilterReducer,
  emailFilterReducer,
  universalPenReducer,
  universalBenReducer,
  universalEmpReducer,
  benCreate,
} from "./option";
import { combineReducers } from "redux";
import {
  appUrlReducer,
  question1Reducer,
  question2Reducer,
  question3Reducer,
  question4Reducer,
  question5Reducer,
  question6Reducer,
  question9Reducer,
  questionaReducer,
  questionbReducer,
  questioncReducer,
  questiondReducer,
  questioneReducer,
  questionxReducer,
  questionyReducer,
  questionzReducer,
  appId,
} from "./application";

import {
  selectedChatUser,
  selectedChatPhoneNo,
  chatMessages,
  selectedChatNewUser,
} from "./chat";
import {
  selectedTransections,
  totalPayment,
  aiCurrent,
  vcd,
  sap,
} from "./mhsssAccounting";

import {
  voucherAmount,
  isGovernmentAgency,
  selectedVoucherForCash,
  voucherPayResponse,
  merchantCompanyReducer,
  selectedPensioner,
  completeProfile,
  selectedPensionerVouchers,
  activePensionerSession,
  selectedPensionerPrograms,
  activeVouchers,
  selectedProgramPensionBook,
  allTransactions,
  allReports,
  selectedReportPensioner,
  openSideBar,
  merchantEmployee,
  payment,
  merchantStores,
  merchantEmployeeInfo,
  merchantEmployeePass,
  merchantEmployeeId,
  definedPrivilegesMerchant,
  selectedMerchantEmployeeInfo,
  employeeLinkedStores,
  employeeHeadQuarterPrivileges,
  employeeStorePrivileges,
  selectedMerchantForApproval,
  selectedMerchantForApprovalBankInfo,
  selectedMerchantForApprovalChequeInfo,
  selectedMerchantForApprovalEwalletInfo,
  selectedMerchantForApprovalId,
  merchantStatus,
  empType,
  selectedStore,
  merchType,
  startPaymentDate,
  govAgencyReqData,
  merchEmpType,
} from "./merchant";

import {
  mhhsMerchId,
  selectedOutstandingMerchant,
  selectedTransactions,
  selectedTransactionsVouchers,
  selectedPaymentMethod,
  afterPaymentData,
  selectedOutstandingStatus,
  selectedPaymentDetail,
  mhhsMerchUser,
  mhhsMerchEmpId,
  selectedGovAgency,
  requestedAmount,
  depositMoneyData,
} from "./mhhs";

import {
  selectedPensionerSap,
  merchantSapTypeData,
  merchantSapType,
  sapMoneyEnable,
  childData,
  // sapMemberType,
  programTransactionType,
  transactionType,
  sapPaymentSelectedPerson,
  pensionerSenderTypeVerification,
  totalBalance,
  sapMoneyStores,
  requestPageEnable,
  mhsssPayStatus,
  merPenReqDetail,
  merPenReqCheck,
  uuid,
} from "./sapMoney";

import {
  priorityPensioner,
  distributionCenter,
  selectedProgram,
  veriSelectedPensioner,
  veriSelectedDoc,
  searchModalCount,
  searchResult,
  veriPriority,
  penVerStatus,
  penVerQueue,
  verificationStart,
  penRejNote,
  scheduleVerificationSelectedSlot,
  SelectedSlot,
  SelectedMonth,
  SelectedYear,
  EditSlot,
  progId,
  PensionerBookSlot,
  appDetail,
  appSearch,
} from "./verification";
import {
  eventId,
  eventCreator,
  subEventId,
  eventPage,
  subId,
  batchDetails,
} from "./events";

import {
  cartData,
  category,
  productData,
  favData,
  role,
  PlaceOrderCheck,
  vendorStatus,
  StorageType,
  ProductId,
  VendorBranches,
  editProduct,
  Edit,
  Data
} from "./inventory";

const allReducers = combineReducers({
  appSearch: appSearch,
  appDetail: appDetail,
  eventPage: eventPage,
  progId: progId,
  subId: subId,
  wowt: wowReducer,
  wowx: wowxReducer,
  rcurrent: rcurrentReducer,
  icurrent: icurrentReducer,
  brcurrent: brcurrentReducer,
  bicurrent: bicurrentReducer,
  acurrent: acurrentReducer,
  popup: popupReducer,
  option: optionReducer,
  option2: option2Reducer,
  statusCheck: statusReducer,
  isLogged: isLoggedReducer,
  veri: veriReducer,
  veri2: veri2Reducer,
  token: tokenReducer,
  tokenRef: tokenRefReducer,
  myPrivileges: myPrivileges,
  benId: benIdReducer,
  refreshToken: refreshTokenReducer,
  dataBen: dataReducer,
  dataUser: userDataReducer,
  loginToken: loginTokenReducer,
  gidFilter: gidFilterReducer,
  phoneFilter: phoneFilterReducer,
  emailFilter: emailFilterReducer,
  profileData: profileDataReducer,
  universalPenId: universalPenReducer,
  universalBenId: universalBenReducer,
  universalEmpId: universalEmpReducer,
  namePerson: nameReducer,
  imagePerson: imgReducer,
  universalCheck: universalCheckReducer,
  popData: popDataReducer,
  appQuestions: appQuestionsReducer,
  data1: data1Reducer,
  data2: data2Reducer,
  data3: data3Reducer,
  applicationAnswers: applicationAnswersReducer,
  editFlag: editReducer,
  reset: resetReducer,
  polName: polNameReducer,
  pol: polReducer,
  polId: polUserReducer,
  polStatus: polStatusReducer,
  Question1: question1Reducer,
  Question2: question2Reducer,
  Question3: question3Reducer,
  Question4: question4Reducer,
  Question5: question5Reducer,
  Question6: question6Reducer,
  Questionx: questionxReducer,
  Questiony: questionyReducer,
  Question9: question9Reducer,
  Questiona: questionaReducer,
  Questionb: questionbReducer,
  Questionc: questioncReducer,
  Questiond: questiondReducer,
  Questione: questioneReducer,
  Questionz: questionzReducer,
  appUrl: appUrlReducer,
  place: placeReducer,
  placePop: placePopReducer,
  authState: authStateReducer,
  addPensionerData: addPensionerReducer,
  allBenId: allBenReducer,
  whichView: whichViewReducer,
  idDone: idDoneReducer,
  userData: userDataReducer1,
  benDone: benDoneReducer,
  fromAwaited: fromAwaitedReducer,
  pol1: pol1Reducer,
  penId: penIdReducer,
  pensionerName: pensionerNameReducer,
  pensionerId: pensionerIdReducer,
  cam: camReducer,
  benCreate: benCreate,
  sidebar: sidebar,
  sidebarE: sidebarE,
  selectedChatUser: selectedChatUser,
  selectedChatPhoneNo: selectedChatPhoneNo,
  chatMessages: chatMessages,
  selectedChatNewUser: selectedChatNewUser,
  pensionerInfo: pensionerInfoReducer,
  merchantCompany: merchantCompanyReducer,
  selectedPensioner: selectedPensioner,
  completeProfile: completeProfile,
  selectedPensionerVouchers: selectedPensionerVouchers,
  activePensionerSession: activePensionerSession,
  selectedPensionerPrograms: selectedPensionerPrograms,
  activeVouchers: activeVouchers,
  selectedProgramPensionBook: selectedProgramPensionBook,
  voucherPayResponse: voucherPayResponse,
  selectedVoucherForCash: selectedVoucherForCash,
  voucherAmount: voucherAmount,
  faceApi: faceApi,
  allTransactions: allTransactions,
  allReports: allReports,
  selectedReportPensioner: selectedReportPensioner,
  isGovernmentAgency: isGovernmentAgency,
  openSideBar: openSideBar,
  selectedTransections: selectedTransections,
  totalPayment: totalPayment,
  aiCurrent: aiCurrent,
  ecurrent: merchantEmployeeeStepReducer,
  merchantEmployee: merchantEmployee,
  merchantEmployeePass: merchantEmployeePass,
  payment: payment,
  merchantStores: merchantStores,
  user: user,
  tokenExpiry: tokenExpiry,
  mhhsMerchId: mhhsMerchId,
  merchantEmployeeId: merchantEmployeeId,
  selectedOutstandingMerchant: selectedOutstandingMerchant,
  selectedTransactions: selectedTransactions,
  merchantEmployeeInfo: merchantEmployeeInfo,
  definedPrivilegesMerchant: definedPrivilegesMerchant,
  selectedMerchantEmployeeInfo: selectedMerchantEmployeeInfo,
  employeeLinkedStores: employeeLinkedStores,
  selectedTransactionsVouchers: selectedTransactionsVouchers,
  selectedPaymentMethod: selectedPaymentMethod,
  afterPaymentData: afterPaymentData,
  employeeHeadQuarterPrivileges: employeeHeadQuarterPrivileges,
  selectedMerchantForApproval: selectedMerchantForApproval,
  selectedMerchantForApprovalBankInfo: selectedMerchantForApprovalBankInfo,
  selectedMerchantForApprovalChequeInfo: selectedMerchantForApprovalChequeInfo,
  selectedMerchantForApprovalEwalletInfo:
    selectedMerchantForApprovalEwalletInfo,
  selectedMerchantForApprovalId: selectedMerchantForApprovalId,
  merchantStatus: merchantStatus,
  selectedPaymentDetail: selectedPaymentDetail,
  mhhsMerchEmpId: mhhsMerchEmpId,
  subEvent: subEvent,
  category: category,
  cartData: cartData,
  selectedOutstandingStatus: selectedOutstandingStatus,
  mhhsMerchUser: mhhsMerchUser,
  selectedPensionerSap: selectedPensionerSap,
  merchantSapType: merchantSapType,
  merchantSapTypeData: merchantSapTypeData,
  product: productData,
  fav: favData,
  childData: childData,
  role: role,
  sapmoney: sapMoneyEnable,
  mhsssPayStatus: mhsssPayStatus,
  // sapMemberType:
  programTransactionType: programTransactionType,
  transactionType: transactionType,
  sapPaymentSelectedPerson: sapPaymentSelectedPerson,
  empType: empType,
  pensionerSenderTypeVerification: pensionerSenderTypeVerification,
  employeeStorePrivileges: employeeStorePrivileges,
  selectedStore: selectedStore,
  totalBalance: totalBalance,
  sapMoneyStores: sapMoneyStores,
  requestPageEnable: requestPageEnable,
  merchType: merchType,
  vcd: vcd,
  sap: sap,
  appId: appId,
  verify: verify,
  merPenReqDetail: merPenReqDetail,
  merPenReqCheck: merPenReqCheck,
  uuid: uuid,
  penRejNote: penRejNote,
  remEmployee: remEmployee,
  remVendor: remVendor,
  batchDetails: batchDetails,
  remPensioner: remPensioner,
  merchEmpType: merchEmpType,
  remMerchat: remMerchat,
  startPaymentDate: startPaymentDate,
  priorityPensioner: priorityPensioner,
  selectedGovAgency: selectedGovAgency,
  requestedAmount: requestedAmount,
  govAgencyReqData: govAgencyReqData,
  depositMoneyData: depositMoneyData,
  distributionCenter: distributionCenter,
  selectedProgram: selectedProgram,
  veriSelectedPensioner: veriSelectedPensioner,
  veriSelectedDoc: veriSelectedDoc,
  searchModalCount: searchModalCount,
  searchResult: searchResult,
  veriPriority: veriPriority,
  penVerStatus: penVerStatus,
  penVerQueue: penVerQueue,
  verificationStart: verificationStart,
  employeeId: employeeId,
  eventId: eventId,
  subEventId: subEventId,
  eventCreator: eventCreator,
  scheduleVerificationSelectedSlot: scheduleVerificationSelectedSlot,
  SelectedSlot: SelectedSlot,
  SelectedMonth: SelectedMonth,
  SelectedYear: SelectedYear,
  EditSlot: EditSlot,
  PesnionerLinkedCenter: PesnionerLinkedCenter,
  PensionerBookSlot: PensionerBookSlot,
  PesnionerLinkedCenterCheck: PesnionerLinkedCenterCheck,
  PlaceOrderCheck: PlaceOrderCheck,
  vendorStatus: vendorStatus,
  StorageType: StorageType,
  ProductId: ProductId,
  VendorBranches: VendorBranches,
  editProduct: editProduct,
  Edit:Edit,
  Data:Data
});

export default allReducers;

import { Select } from "antd";
import React, { useState } from "react";
import Table from "./table";

const Main = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [secondDropdownOptions, setSecondDropdownOptions] = useState([]);
  const statusesOptions = [
    { value: "Warehouse", label: "Warehouse" },
    { value: "Location", label: "Location" },
    { value: "Department ", label: "Department " },
    { value: "Product", label: "Products" },
  ];

  // Real-time product options (e.g., company products)
  const productsOptions = [
    { value: "Laptop", label: "Laptop" },
    { value: "Smartphone", label: "Smartphone" },
    { value: "Headphones", label: "Headphones" },
    { value: "Monitor", label: "Monitor" },
    { value: "Keyboard", label: "Keyboard" },
  ];

  // Real-time location options (e.g., store locations or warehouses)
  const locationsOptions = [
    { value: "New York Warehouse", label: "New York Warehouse" },
    { value: "San Francisco Store", label: "San Francisco Store" },
    { value: "Los Angeles Store", label: "Los Angeles Store" },
    {
      value: "Chicago Distribution Center",
      label: "Chicago Distribution Center",
    },
    { value: "Miami Warehouse", label: "Miami Warehouse" },
  ];

  // Real-time warehouse options (e.g., specific warehouses)
  const warehouseOptions = [
    { value: "Master Warehouse", label: "Master Warehouse" },
    { value: "Secondary Warehouse", label: "Secondary Warehouse" },
    { value: "Regional Warehouse", label: "Regional Warehouse" },
  ];
  const handleFirstSelectChange = (value) => {
    setSelectedOption(value);

    // Dynamically set options for second dropdown based on selection
    if (value === "Product") {
      setSecondDropdownOptions(productsOptions);
    } else if (value === "Location") {
      setSecondDropdownOptions(locationsOptions);
    } else if (value === "Department ") {
      setSecondDropdownOptions(locationsOptions);
    } else if (value === "Warehouse") {
      setSecondDropdownOptions(warehouseOptions);
    } else {
      setSecondDropdownOptions([]);
    }
  };
  return (
    <div>
      <div className="bg-white flex p-6 rounded-md  w-full gap-4 mb-2">
        <div className="flex-1 flex flex-col justify-between">
          <div className="flex items-center justify-between w-full">
            <p className="mr-4 text-[0.95rem] font-semibold text-gray-600 w-3/5">
              Calculate inventory cost by
            </p>
            <Select
              placeholder="Filter"
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              onChange={handleFirstSelectChange}
              size="middle"
              className="w-2/5 wow"
              options={statusesOptions}
              value={selectedOption}
            />
          </div>
          <div
            className={`${
              selectedOption === null ||
              selectedOption === "" ||
              selectedOption === undefined
                ? "hidden"
                : "flex items-center justify-between w-full mt-3"
            }`}
          >
            <p className="mr-4 text-[0.95rem] font-semibold text-gray-600 w-3/5">
              Select {selectedOption}
            </p>
            <Select
              placeholder={`Select ${selectedOption}`}
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              size="middle"
              className="w-2/5 wow"
              options={secondDropdownOptions}
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-between">
          <div className="bg-[#F6F4F4] flex-1 flex flex-col p-3">
            <h1 className="font-medium ">Total Inventory Cost</h1>
            <p className="text-center font-semibold text-[1.2rem] mb-3">
              $2324,42424
            </p>
          </div>
        </div>
      </div>
      <h1 className="text-[1.1rem] font-semibold text-gray-600 py-4">
        Inventory Detail
      </h1>
      <Table />
    </div>
  );
};

export default Main;

import Nav from "../navbar";
import MainDash from "./main";
import Whatsapp from "../../../../Whatsapp/whatsapp";
const Dashboard = () => {
  return (
    <div>
      <Nav />
      <MainDash />
      <Whatsapp />
    </div>
  );
};

export default Dashboard;

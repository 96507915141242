import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import cartW from "../../../imgs/cartW.png";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const router= useNavigate();
  const [totalPrice, setTotalPrice] = useState(0);
  const cart = useSelector((state) => state.cartData);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    const newTotalPrice = cart.reduce((acc, item) => acc + item.price, 0);
    setTotalPrice(newTotalPrice);
    const newTotalItems = cart.length;
    setTotalItems(newTotalItems);
  }, [cart]);
  const handleBuyNow = () => {
    router("/Inventory-Cart");
  };
  return (
    <div className="bg-primary shadow-3xl z-20 flex items-center justify-center fixed bottom-0 left-0 right-0">
      <div className="text-gray-700 pt-4 pb-4 rounded-md pl-2 pr-2 bg-[#1BA397] sm:w-[80%] flex justify-between items-center">
        <div className="flex gap-2">
          <div className=" bg-gray-50 flex items-center justify-between pl-4 p-2 rounded-md pr-4">
            <span className="text-lg font-semibold">{totalItems}</span>
            <span className="text-lg font-semibold ml-2">Items</span>
          </div>
          <div className=" bg-gray-50 flex items-center justify-between pl-4 p-2 rounded-md pr-4">
            <span className="text-lg font-semibold">Total Price:</span>
            <span className="text-lg font-semibold ml-2">${totalPrice}</span>
          </div>
        </div>
        <button
          onClick={handleBuyNow}
          className="bg-primary text-white flex gap-2 font-bold py-2 px-4 rounded"
        >
          <h1>Show</h1>
          <img src={cartW} alt="" className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Personal from "../../../Merchant/Profile/Tabs/Personal";
import ContactPerson from "../../../Merchant/Profile/Tabs/ContactPerson";
import Headquarter from "../../../Merchant/Profile/Tabs/headquater";
import Stores from "../../../Merchant/Profile/Tabs/Stores";
import Documentation from "../../../Merchant/Profile/Tabs/Documentation";
import EmployeeDocumentation from "../../../Merchant/Profile/Tabs/EmployeeDocs/Documentation";
import TaxInformation from "../../../Merchant/Profile/Tabs/EmployeeDocs/TaxInformation";
import Company from "../../../Merchant/Profile/Tabs/Company";
import CompanyAddress from "../../../Merchant/Profile/Tabs/addresses";
import Banking from "../../../Merchant/Profile/Tabs/Banking";
import Owner from "../../../Merchant/Profile/Tabs/Owner";
import EmployeeProfile from "../../../Merchant/Profile/Tabs/EmployeePersonal";
import EmployeeAddress from "../../../Merchant/Profile/Tabs/EmployeeAddress";
import EmployeeInfo from "../../../Merchant/Profile/Tabs/EmployeeInfo";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
const Profile = () => {
  const isGovernmentAgency = useSelector((state) => state?.isGovernmentAgency);
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const state = location.state;
  const dispatch = useDispatch();
  // Access the state object properties
  const employeeProfile = state && state.employeeProfile;
  const [refetch, setRefetch] = useState(false);
  const [active, setActive] = useState("1");
  const token = useSelector((state) => state.token);
  const merchantEmployeeId = useSelector((state) => state.merchantEmployeeId);
  const [personal, setPersonal] = useState();
  const [headquarter, setHeadquarter] = useState();
  const [mailAddress, setMailAddress] = useState();
  const [stores, setStores] = useState([]);
  const [company, setCompany] = useState();
  const [documentation, setDocumentation] = useState([]);
  const [identification, setIdentification] = useState([]);
  const [banking, setBanking] = useState();
  const [cheque, setCheque] = useState();
  const [ewallet, setEwallet] = useState();
  const [contactPerson, setContactPerson] = useState([]);
  const user = useSelector((state) => state.user);
  const [employeeDocs, setEmployeeDocs] = useState([]);
  const merchantEmployeeInfo = useSelector(
    (state) => state.merchantEmployeeInfo
  );
  console.log(identification);
  //   useEffect(() => {
  //     if (user === "merchant-employee") {
  //       setEmployeeDocs(merchantEmployeeInfo?.data?.merchDocuments);
  //       merchantEmployeeInfo?.data?.merchDocuments?.map((doc, docindex) => {
  //         doc?.merchdocimages?.map((img, index) => {
  //           imageConverter(img?.imageUrl, docindex, index, "employeeDocs");
  //         });
  //       });
  //     }
  //   }, []);

  //   useEffect(() => {
  //     if (
  //       user === "merchant-owner" ||
  //       (user === "merchant-admin" && employeeProfile === true)
  //     ) {
  //       setEmployeeDocs(merchantEmployeeInfo?.data?.merchDocuments);
  //       merchantEmployeeInfo?.data?.merchDocuments?.map((doc, docindex) => {
  //         doc?.merchdocimages?.map((img, index) => {
  //           imageConverter(img?.imageUrl, docindex, index, "employeeDocs");
  //         });
  //       });
  //     }
  //   }, [merchantEmployeeInfo]);

  //   // get Company
  useEffect(() => {
    if (user === "vendor-owner") {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/vendor-company/own`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setCompany(data.data);
        })
        .catch(() => {});
    }
  }, []);

  //   //get Personal
  useEffect(() => {
    if (user === "vendor-owner") {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/vendor-user/my-profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setPersonal(data.data?.person);
        })
        .catch(() => {});
    }
  }, []);
  //   useEffect(() => {
  //     if (user === "merchant-admin") {
  //       axios
  //         .get(
  //           `${process.env.REACT_APP_BASE_URL}/v1/merch-user/my-owner/for-merch-admin-or-employee`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         )
  //         .then((data) => {
  //           setPersonal(data.data?.merchperson);
  //         })
  //         .catch(() => {});
  //     }
  //   }, []);

  useEffect(() => {
    if (user === "vendor-owner") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/vendor-documents/own?setName=personal`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          setIdentification(data.data);
          // console.log(data.data);
          data?.data?.map((doc, docindex) => {
            doc?.vendordocimages?.map((img, index) => {
              imageConverter(img?.imageUrl, docindex, index, "identification");
            });
          });
        })
        .catch(() => {});
    }
  }, []);

  //   //get contact Person
  //   useEffect(() => {
  //     if (user === "merchant-owner") {
  //       axios
  //         .get(
  //           `${process.env.REACT_APP_BASE_URL}/v1/merch-user/list-my-merch-admins?sortBy=createdAt:desc`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         )
  //         .then((data) => {
  //           setContactPerson(data.data.rows);
  //           // data?.data?.map((contact, contactIndex) => {
  //           //   contact?.merchcontactpersondocs?.map((doc, docIndex) => {
  //           //     doc.images?.map((img, imageIndex) => {
  //           //       imageConverterContact(
  //           //         img,
  //           //         contactIndex,
  //           //         docIndex,
  //           //         imageIndex,
  //           //         "contactPerson"
  //           //       );
  //           //     });
  //           //   });
  //           // });
  //         })
  //         .catch(() => {});
  //     }
  //   }, []);

  //   // get Head Quarters
  //   useEffect(() => {
  //     if (user === "merchant-owner" || user === "merchant-admin") {
  //       axios
  //         .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-headquarter/own`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         })
  //         .then((data) => {
  //           setHeadquarter(data.data);
  //         })
  //         .catch(() => {});
  //     }
  //   }, []);

  //   // get Head Quarter mail addresses
  //   useEffect(() => {
  //     if (user === "merchant-owner" || user === "merchant-admin") {
  //       axios
  //         .get(
  //           `${process.env.REACT_APP_BASE_URL}/v1/merch-headquarter/my-hq-mail-address`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         )
  //         .then((data) => {
  //           setMailAddress(data.data);
  //         })
  //         .catch(() => {});
  //     }
  //   }, []);

  //  get Branches
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/vendor-branch/own`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        setStores(data?.data?.rows);
      })
      .catch(() => {});
  }, []);

   // get Documentation
    useEffect(() => {
      if (user === "vendor-owner") {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/v1/vendor-documents/own?setName=business`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((data) => {
            setDocumentation(data.data);
            data?.data?.map((doc, docindex) => {
              doc?.vendordocimages?.map((img, index) => {
                imageConverter(img?.imageUrl, docindex, index, "documentation");
              });
            });
          })
          .catch(() => {});
      }
    }, []);
  //   useEffect(() => {
  //     if (
  //       user === "merchant-owner" ||
  //       (user === "merchant-admin" && employeeProfile === true)
  //     ) {
  //       axios
  //         .get(
  //           `${process.env.REACT_APP_BASE_URL}/v1/merch-user/${merchantEmployeeId}/merch-employee/profile`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         )
  //         .then((data) => {
  //           dispatch({ type: "MERCHANT_EMPLOYEE_INFO", num: data });
  //         })
  //         .catch(() => {});
  //     }
  //   }, [refetch]);

  //   // Image Urls
  const imageConverter = (url, docIndex, imageIndex, type) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/vendor/get-image-url?fileType=document`,
        {
          fileNames: [url],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        type === "documentation"
          ? setDocumentation((prev) => {
              let newArr = [...prev];
              newArr[docIndex].vendordocimages[imageIndex].imageUrl =
                data?.data?.[0];
              return newArr;
            })
          : type === "identification"
          ? setIdentification((prev) => {
              let newArr = [...prev];
              newArr[docIndex].vendordocimages[imageIndex].imageUrl =
                data?.data?.[0];
              return newArr;
            })
          : type === "employeeDocs"
          ? setEmployeeDocs((prev) => {
              let newArr = [...prev];
              newArr[docIndex].merchdocimages[imageIndex].url =
                data?.data?.[0]?.url;
              return newArr;
            })
          : setContactPerson((prev) => {
              let newArr = [...prev];
              newArr[docIndex].merchcontactpersondocs[docIndex].images[
                imageIndex
              ] = data?.data?.[0]?.url;
              return newArr;
            });
      })
      .catch(() => {
        // Handle error
      });
  };

  //   // get Identification
  //   useEffect(() => {
  //     if (user === "merchant-owner") {
  //       axios
  //         .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-document`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         })
  //         .then((data) => {
  //           setIdentification(data.data);
  //           data?.data?.map((doc, docindex) => {
  //             doc?.merchdocimages?.map((img, index) => {
  //               imageConverter(img?.imageUrl, docindex, index, "identification");
  //             });
  //           });
  //         })
  //         .catch(() => {});
  //     }
  //   }, []);

  //   //getBanking
  //   useEffect(() => {
  //     if (user === "merchant-owner") {
  //       axios
  //         .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-bank-info/own`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         })
  //         .then((data) => {
  //           setBanking(data.data);
  //         })
  //         .catch(() => {});
  //     }
  //   }, []);
  //   useEffect(() => {
  //     if (user === "merchant-admin") {
  //       axios
  //         .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-bank-info/my-owner-bank-info/for-merch-admin`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         })
  //         .then((data) => {
  //           setBanking(data.data);
  //         })
  //         .catch(() => {});
  //     }
  //   }, []);

  //   //getCheque
  //   useEffect(() => {
  //     if (user === "merchant-owner") {
  //       axios
  //         .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-cheque-info/own`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         })
  //         .then((data) => {
  //           setCheque(data.data);
  //         })
  //         .catch(() => {});
  //     }
  //   }, []);
  //   useEffect(() => {
  //     if (user === "merchant-admin") {
  //       axios
  //         .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-cheque-info/my-owner-cheque-info/for-merch-admin`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         })
  //         .then((data) => {
  //           setCheque(data.data);
  //         })
  //         .catch(() => {});
  //     }
  //   }, []);
  // useEffect(() => {
  //   if (user === "merchant-owner" || user === "merchant-admin") {
  //     axios
  //       .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-cheque-info/own`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then((data) => {
  //         setCheque(data.data);
  //       })
  //       .catch(() => {});
  //   }
  // }, []);

  //getEwallet
  // useEffect(() => {
  //   if (user === "merchant-owner") {
  //     axios
  //       .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-ewallet-info/own`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then((data) => {
  //         setEwallet(data.data);
  //       })
  //       .catch(() => {});
  //   }
  // }, []);

  // const imageConverterContact = (
  //   url,
  //   contactIndex,
  //   docIndex,
  //   imageIndex,
  //   type
  // ) => {
  //   console.log(type);
  //   const api = "merchantIdentificationDocument";
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-file-presigned-url?fileType=${api}`,
  //       {
  //         fileNames: [url],
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((data) => {
  //       setContactPerson((prev) => {
  //         let newArr = [...prev];
  //         newArr[contactIndex].merchcontactpersondocs[docIndex].images[
  //           imageIndex
  //         ] = data?.data[0]?.url;
  //         return newArr;
  //       });
  //     })
  //     .catch(() => {
  //       // Handle error
  //     });
  // };

  const tabs = [
    {
      key: "1",
      label: "Email, Phone & Password",
      component: <Personal data={personal} check={"owner"} />,
    },
    {
      key: "2",
      label: "Owner Details",
      component: (
        <Owner
          data={personal}
          contactPerson={contactPerson}
          identification={identification}
          gov={isGovernmentAgency ? true : false}
        />
      ),
      user: "merchant-owner",
    },
    {
      key: "3",
      label: "Company Details",
      component: <Company company={company} />,
      user: "merchant-owner",
    },
    {
      key: "4",
      label: "Company Address",
      component: <CompanyAddress address={company?.vendoraddresses} />,
      user: "merchant-owner",
    },

    {
      key: "6",
      label: "Branches",
      component: <Stores stores={stores} />,
    },

    {
      key: "7",
      label: "Documents",
      component: <Documentation documentation={documentation} />,
    },
  ];

  const handleTabClick = (key) => {
    setActive(key);
  };

  //   if (!isGovernmentAgency) {
  //     tabs.splice(5, 0, {
  //       key: "6",
  //       label: "Stores",
  //       component: <Stores stores={stores} />,
  //       user: "merchant-owner",
  //     });
  //   }

  return (
    <div>
      {/* Tabs */}
      <div className="sm:px-8 mt-6">
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200  ">
          <ul className="flex flex-wrap -mb-px">
            {tabs
              //     user === "merchant-employee" || employeeProfile === true
              //   ? employeeTabs
              //   : user === "merchant-admin"
              //   ? adminTabs
              //   :
              .map((tab) => (
                <li
                  key={tab.key}
                  onClick={() => handleTabClick(tab.key)}
                  className={`${
                    active === tab.key
                      ? "border-primary text-primary font-bold"
                      : "border-transparent"
                  } text-[1rem] cursor-pointer inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-primary hover:border-primary`}
                >
                  {tab.label}
                </li>
              ))}
          </ul>
        </div>

        <div className="p-2">
          {
            tabs
              //     user === "merchant-employee" || employeeProfile === true
              //   ? employeeTabs
              //   : user === "merchant-admin"
              //   ? adminTabs
              //   :
              .find((tab) => tab.key === active)?.component
          }
        </div>
      </div>
    </div>
  );
};

export default Profile;

import Nav from "../components/Nav/nav";
import back5 from "../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";

const PowerBiPage = () => {
  const router = useNavigate();
  const user = localStorage.getItem("role");
  const ref = useRef(null);

  useEffect(() => {
    const vizElement = ref.current.getElementsByTagName("object")[0];
    vizElement.style.width = "100%";
    if (ref.current.offsetWidth > 800) {
      vizElement.style.height = "997px";
    } else if (ref.current.offsetWidth > 500) {
      vizElement.style.height = "997px";
    } else {
      vizElement.style.height = "1527px";
    }

    const scriptElement = document.createElement("script");
    scriptElement.src = "https://public.tableau.com/javascripts/api/viz_v1.js";
    vizElement.parentNode.insertBefore(scriptElement, vizElement);
  }, []);

  return (
    <div className="">
      <Nav />
      <div className="relative flex justify-start pl-[4rem] pr-[4rem] items-center text-[1.8rem] font-bold mb-[1rem]">
        <div
          onClick={() => {
            user === "admin"
              ? router("/AdminDashboard")
              : user === "superadmin"
              ? router("/superAdminDashboard")
              : router("/EDashboard");
          }}
          className="cursor-pointer pt-[2rem] mr-5"
        >
          <img className="w-[1.7rem]" src={back5} alt="" />
        </div>
        <div className="flex pt-[2rem]">
          <h2>Reports</h2>
        </div>
      </div>
      <div
        ref={ref}
        className="tableauPlaceholder w-full h-[70vh] relative"
        id="viz1730737330451"
        style={{ position: "relative" }}
      >
        <noscript>
          <a href="#">
            <img
              alt="Priority Pensioner"
              src="https://public.tableau.com/static/images/KP/KPIReport_v31/PriorityPensioner/1_rss.png"
              style={{ border: "none" }}
            />
          </a>
        </noscript>
        <object className="tableauViz" style={{ display: "none" }}>
          <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
          <param name="embed_code_version" value="3" />
          <param name="site_root" value="" />
          <param name="name" value="KPIReport_v31/PriorityPensioner" />
          <param name="tabs" value="no" />
          <param name="toolbar" value="yes" />
          <param
            name="static_image"
            value="https://public.tableau.com/static/images/KP/KPIReport_v31/PriorityPensioner/1.png"
          />
          <param name="animate_transition" value="yes" />
          <param name="display_static_image" value="yes" />
          <param name="display_spinner" value="yes" />
          <param name="display_overlay" value="yes" />
          <param name="display_count" value="yes" />
          <param name="language" value="en-US" />
          <param name="filter" value="publish=yes" />
          <param
            name="url"
            value="https://public.tableau.com/views/KPIReport_v31/PriorityPensioner?:language=en-US&publish=yes&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link"
          />
        </object>
      </div>
    </div>
  );
};

export default PowerBiPage;

import React, { useState, useEffect } from "react";
import back5 from "../../../../../imgs/back5.png";
import pic from "../../../../../imgs/company.svg";
import { Pagination } from "antd";
import axios from "axios";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { FaTimes } from "react-icons/fa";
import ErrorModal from "../../../../Merchant/Modals/errorModal";
import SuccessModal from "../../../../modals/successModal";
import { useNavigate } from "react-router-dom";
let file;
const Main = () => {
  const router = useNavigate();
  const [data, setData] = useState([]);
  const [dataS, setDataS] = useState([]);
  const [role, setRole] = useState(null);
  const [ip, setIp] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPagesS, setTotalPagesS] = useState(0);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [page, setPage] = useState(1);
  const [pageS, setPageS] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const batchDetails = useSelector((state) => state.batchDetails);
  const [addModal, setAddModal] = useState(false);
  const [err, setErr] = useState("");
  const [emailValue, setEmailValue] = useState(false);
  const [gid, setGid] = useState("");
  const [phone, setPhone] = useState("");
  const [emailValueS, setEmailValueS] = useState("");
  const [gidS, setGidS] = useState("");
  const [phoneS, setPhoneS] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNoo, setPhoneNoo] = useState("");
  const [show, setShow] = useState("");
  const [show1, setShow1] = useState("");
  const [contactMethod, setContactMethod] = useState("gid");
  const [message, setMessage] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedMembersR, setSelectedMembersR] = useState([]);

  const handleSelectMember = (id) => {
    setSelectedMembers((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };
  const handleSelectMemberR = (id) => {
    setSelectedMembersR((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };
  const handleOptionChange = (event) => {
    setContactMethod(event.target.value);
    if (event.target.value === "phone") {
      setEmailValue("");
      setGid("");
    }
    if (event.target.value === "email") {
      setGid("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
    if (event.target.value === "gid") {
      setEmailValue("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
    if (event.target.value === "import") {
      setEmailValue("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
    if (event.target.value === "batch") {
      setEmailValue("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
  };
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPhone(event);
      if (event) {
        const wow = parsePhoneNumber(event);
        if (wow) {
          let count = wow.countryCallingCode;
          let num = wow.nationalNumber;
          setCountryCode(count);
          setPhoneNoo(num);
        }
      }
    }
    if (check === "ts") {
      setPhoneS(event);
    } else {
      setState(event.target.value);
    }
  };
  console.log("Test", batchDetails);
  const pageHandler = (e) => {
    setPage(e);
  };
  const pageHandlerS = (e) => {
    setPageS(e);
  };

  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 6,
        skip: (page - 1) * 6,
        page: page,
        sortBy: "createdAt:desc",
        ...(emailValueS && { search: emailValueS }),
        ...(phoneS && { search: `${phoneS}` }),
        ...(gidS && { search: gidS }),
      };

      Search(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [refetch, pageS, emailValueS, phoneS, gidS]);
  const Search = (params, cancelTokenSource) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/users/search`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        setDataS(res?.data?.rows);
        setTotalPagesS(res?.data?.totalPages);
      })
      .catch((err) => {
        setDataS([]);
        console.log(err);
      });
  };
  useEffect(() => {
    console.log("Data", dataS);
    console.log("Data", phoneS);

  }, [dataS, phoneS]);

  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 9,
        skip: (page - 1) * 9,
        page: page,
        sortBy: "createdAt:desc",
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [refetch, page]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/event-batch/joinee-members/${batchDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        setData(res?.data?.rows);
        setTotalPages(res?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddMember = () => {
    setAddModal(false);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/event-batch/joinee`,
        {
          eventBatchId: batchDetails?.id,
          eventBatchJoinees: selectedMembers,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then(() => {
        setRefetch(!refetch);
        setMessage("Member Added Successfully");
        setShow(true);
        setSelectedMembers([]);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow1(true);
      });
  };
  const remove = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/event-batch/remove/joinee`,
        {
          eventBatchId: batchDetails?.id,
          eventBatchJoinees: selectedMembersR,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then(() => {
        setRefetch(!refetch);
        setMessage("Member Removed Successfully");
        setSelectedMembersR([]);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow1(true);
      });
  };

  return (
    <div className="flex items-center justify-center mt-4 flex-col">
      <div className="md:w-[85%] w-full p-4 ">
        <div
          className="pl-2 pr-2  mt-[1rem] sm:mt-[0rem]"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div className="flex justify-between items-center mt-4">
              <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
                <img
                  className="sm:w-[1.7rem] w-[1.2rem]"
                  src={back5}
                  alt=""
                  onClick={() => {
                    router("/EventDetails/Batch");
                  }}
                />
                <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                  Batch
                </h2>
              </div>
              <button
                className={` text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md`}
                onClick={() => {
                  setIp("");
                  setRole(null);
                  setAddModal(true);
                  setShowPopup(true);
                }}
              >
                Add Member
              </button>
            </div>
          </div>
        </div>

        <div className="lg:p-12 bg-slate-50 sm:ml-0 w-full  w-full mt-[2rem] mb-8 bg-slate-50 p-[35px] rounded-2xl relative">
          <div className="flex items-center gap-4 w-full rounded-md bg-white shadow-md p-4">
            <img
              src={batchDetails.image}
              alt={batchDetails.name}
              className="w-20 h-20 rounded-full cursor-pointer"
            />
            <div className="flex flex-col gap-1">
              <p className="cursor-pointer text-lg font-semibold">
                {batchDetails.name}
              </p>
              <p className="cursor-pointer">{batchDetails.detail}</p>
            </div>
          </div>

          <div className="w-full flex mt-4 items-center justify-between">
            <h1 className="flex text-lg font-semibold">Members:</h1>
            {data?.length > 0 && (
              <h1
                onClick={() => {
                  if (selectedMembersR.length === 0) {
                    setErr("Please Select members to remove.");
                    setShow1(true);
                  } else {
                    remove();
                  }
                }}
                className="flex text-red-600 hover:underline cursor-pointer font-semibold"
              >
                Remove Members
              </h1>
            )}
          </div>
          {data?.length === 0 && (
            <p className="mt-4 text-center cursor-pointer font-semibold">
              No members added yet.
            </p>
          )}
          {data?.length > 0 && (
            <div className="grid grid-cols-1 mt-3 w-full sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {data.map((item) => (
                <div
                  key={item.id}
                  className="bg-white flex items-center hover:bg-gray-200 cursor-pointer shadow-md relative p-4 gap-3 rounded-md"
                >
                  <div>
                    <img
                      src={item?.user?.person?.image}
                      alt={item?.user?.person?.firstName}
                      className="w-16 h-16 rounded-full cursor-pointer"
                    />
                  </div>
                  <div className="flex-grow">
                    <p className="cursor-pointer font-semibold">
                      {item?.user?.person?.firstName}{" "}
                      {item?.user?.person?.lastName}
                    </p>
                    {item?.user?.gid && (
                      <h1 className="text-[0.9rem] font-semibold text-primary capitalize">
                        {"GID:"} {item?.user?.gid ? item?.user?.gid : ""}
                      </h1>
                    )}
                    <h1 className="text-[0.9rem] font-semibold text-primary capitalize">
                      {item?.user?.email ? item?.user?.email : ""}
                    </h1>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={selectedMembersR.includes(item.userId)}
                      onChange={() => handleSelectMemberR(item.userId)}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={`flex justify-center mt-7`}>
          <Pagination
            defaultCurrent={1}
            total={totalPages * 10}
            showSizeChanger={false}
            onChange={pageHandler}
            current={page}
          />
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 z-100 flex items-center justify-center bg-black bg-opacity-50">
          <div className="my-4 relative sm:w-[50%] w-full w-full py-8 gap-3 px-6 bg-[#F7F7F7] max-h-[90%] overflow-auto rounded-md">
            <FaTimes
              onClick={() => {
                setShowPopup(false);
                setGidS("");
                setPhoneS("");
                setEmailValue("");
              }}
              className="absolute top-2 right-2 text-red-600 text-lg cursor-pointer"
            />
            <h1 className="font-semibold text-[1.8rem] mb-4 w-full text-center">
              Add Member
            </h1>
            <div className="w-full">
              <div className="flex gap-4">
                <div className="mb-2 flex items-center">
                  <input
                    type="radio"
                    id="gidOption"
                    name="contactMethod"
                    value="gid"
                    className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                    checked={contactMethod === "gid"}
                    onChange={handleOptionChange}
                  />
                  <label
                    htmlFor="gidOption"
                    className="cursor-pointer text-sm inline-flex items-center ml-2"
                  >
                    GID
                  </label>
                </div>
                <div className="mb-2 flex items-center">
                  <input
                    type="radio"
                    id="phoneOption"
                    name="contactMethod"
                    value="phone"
                    className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                    checked={contactMethod === "phone"}
                    onChange={handleOptionChange}
                  />
                  <label
                    htmlFor="phoneOption"
                    className="cursor-pointer text-sm inline-flex items-center ml-2"
                  >
                    Phone Number
                  </label>
                </div>

                <div className="mb-2 flex items-center">
                  <input
                    type="radio"
                    id="emailOption"
                    name="contactMethod"
                    value="email"
                    className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                    checked={contactMethod === "email"}
                    onChange={handleOptionChange}
                  />
                  <label
                    htmlFor="emailOption"
                    className="cursor-pointer text-sm inline-flex items-center ml-2"
                  >
                    Email
                  </label>
                </div>
              </div>
              <div className="flex gap-1 items-center">
                {contactMethod === "gid" && (
                  <div className="w-full mb-3 mt-2">
                    <input
                      type="text"
                      name=""
                      onChange={(e) => setGidS(e.target.value)}
                      placeholder="Enter GID"
                      className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
                    />
                  </div>
                )}
                {contactMethod === "phone" && (
                  <PhoneInput
                    placeholder="Enter phone number"
                    defaultCountry="GY"
                    international
                    value={phoneS}
                    onChange={handleChange(setPhoneS, "ts")}
                    countryCallingCodeEditable={false}
                    className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow w-full"
                  />
                )}

                {contactMethod === "email" && (
                  <div className="w-full mb-3 mt-2">
                    <input
                      type="text"
                      name=""
                      value={emailValueS}
                      onChange={(e) => setEmailValueS(e.target.value)}
                      placeholder="Enter Email"
                      className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
                    />
                  </div>
                )}
              </div>
              <h1 className="flex text-lg font-semibold">Searched Members:</h1>
              <div>
                {dataS?.length === 0 && (
                  <p className="mt-4 text-center cursor-pointer font-semibold">
                    No searched members.
                  </p>
                )}
                {dataS?.length > 0 && (
                  <div className="grid grid-cols-1 mt-3 w-full sm:grid-cols-1 md:grid-cols-2 gap-2">
                    {dataS.map((item) => (
                      <div
                        key={item.id}
                        className="bg-white flex items-center hover:bg-gray-200 cursor-pointer shadow-md relative p-4 gap-3 rounded-md"
                      >
                        <div>
                          <img
                            src={item?.person?.image}
                            alt={item?.person?.firstName}
                            className="w-16 h-16 rounded-full cursor-pointer"
                          />
                        </div>
                        <div className="flex-grow">
                          <p className="cursor-pointer font-semibold">
                            {item?.person?.firstName} {item?.person?.lastName}
                          </p>
                          <h1 className="text-[0.9rem] font-semibold text-primary capitalize">
                            {"GID:"} {item?.gid ? item?.gid : ""}
                          </h1>
                          <h1 className="text-[0.9rem] font-semibold text-primary capitalize">
                            {item?.email ? item?.email : ""}
                          </h1>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            checked={selectedMembers.includes(item.id)}
                            onChange={() => handleSelectMember(item.id)}
                            className="cursor-pointer"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {dataS?.length > 0 && (
                  <div className={`flex justify-center mt-4`}>
                    <Pagination
                      defaultCurrent={1}
                      total={totalPagesS * 10}
                      showSizeChanger={false}
                      onChange={pageHandlerS}
                      current={pageS}
                    />
                  </div>
                )}
              </div>
              <div className="w-full flex items-center mt-8 gap-4 justify-center">
                <button
                  onClick={() => {
                    setShowPopup(false);
                    setGidS("");
                    setEmailValueS("");
                    setPhoneS("");
                  }}
                  className={`text-white w-[40%] bg-red-600 pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    if (selectedMembers?.length === 0) {
                      setErr("Please Search and select members to add.");
                      setShow1(true);
                    } else AddMember();
                  }}
                  className={`text-white w-[40%] bg-primary pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                >
                  ADD
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {show1 && <ErrorModal show={show1} setShow={setShow1} err={err} />}
      {show && <SuccessModal show={show} setShow={setShow} message={message} />}
    </div>
  );
};

export default Main;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../Merchant/Modals/errorModal";
import { Dropdown, Button, Menu } from "antd";
import vector from "../../../imgs/Vector.svg";
import vector1 from "../../../imgs/Vector-1.svg";
import vector2 from "../../../imgs/Vector-2.svg";
import vector3 from "../../../imgs/Vector-3.svg";
import vector4 from "../../../imgs/Vector-4.svg";
import arrow from "../../../imgs/Vector.png";
import axios from "axios";
import {
  LogoutOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  AuditOutlined,
  CompassOutlined,
  SnippetsOutlined,
  LaptopOutlined,
  RedEnvelopeOutlined,
  HomeOutlined,
  InteractionOutlined,
  BellFilled,
  MessageOutlined,
  SettingFilled,
  ClockCircleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";

import { useLocation, useNavigate } from "react-router-dom";
let items = [];

const Header = () => {
  const items4 = [
    {
      label: "Program Payment",
      key: "1",
      icon: (
        <img
          src={vector4}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
    {
      label: "Sap Money Payment",
      key: "2",
      icon: (
        <img
          src={vector}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
    {
      label: "Access Setting",
      key: "3",
      icon: (
        <img
          src={vector1}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
    {
      label: "Holidays Setting",
      key: "4",
      icon: (
        <img
          src={vector2}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
    {
      label: "Scheduler Time Management",
      key: "5",
      icon: (
        <img
          src={vector3}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
  ];
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const dataUser = useSelector((state) => state.dataUser);
  const user = dataUser?.data?.user?.roles[0];

  const resetStates = () => {
    dispatch({ type: "USER", num: null });
    dispatch({ type: "authStateSet", num: "false" });
    dispatch({ type: "tokenSet", num: "0" });
    dispatch({ type: "tokenRef", num: "0" });
    dispatch({ type: "myPrivileges", num: "0" });
    dispatch({ type: "pensionerName", num: "" });
    dispatch({ type: "pensionerId", num: "" });
    dispatch({ type: "IdDoneSet", num: "" });
    dispatch({ type: "universalPenSet", num: "" });
    dispatch({ type: "RSET", num: 0 });
    dispatch({ type: "ISET", num: 1 });
    dispatch({ type: "BRSET", num: 0 });
    dispatch({ type: "BISET", num: 0 });
    dispatch({ type: "ASET", num: 0 });
    dispatch({ type: "setUserData", num: [] });
    dispatch({ type: "userDataSet", num: "" });
    dispatch({ type: "penId", num: "0" });
    dispatch({ type: "benId", num: "0" });
    dispatch({ type: "setProfileData", num: [] });
    dispatch({ type: "polIdSet", num: "" });
    dispatch({ type: "polNameSet", num: "" });
    dispatch({ type: "polStatusSet", num: "" });
    dispatch({ type: "popDataSet", num: "" });
    dispatch({ type: "appQuestionsSet", num: [] });
    dispatch({ type: "data1Set", num: "" });
    dispatch({ type: "data2Set", num: "" });
    dispatch({ type: "data3Set", num: "" });
    dispatch({ type: "nameSet", num: "" });
    dispatch({ type: "imageSet", num: "" });
    dispatch({ type: "setData", num: [] });
    dispatch({ type: "CHAT_SELECTED_PHONE_NO", num: null });
    dispatch({ type: "CHAT_SELECTED_USER", num: null });
    dispatch({ type: "CHAT_SELECTED_NEW_USER", data: null });
    dispatch({ type: "CHAT_MESSAGES", messages: [] });
    dispatch({ type: "PENSIONER_INFO", num: "" });
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const popupRef = useRef(null);
  const privilege = useSelector((state) => state.myPrivileges);
  const [test, setTest] = useState(false);

  const profile = dataUser?.data?.user?.person?.image;
  const Name =
    dataUser?.data?.user?.person?.firstName + " " + dataUser?.data?.user?.person?.lastName;
  const [showModal, setShowModal] = useState(false);
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);
  const [showR, setShowR] = useState(false);

  const PesnionerLinkedCenterCheck = useSelector((state) => state.PesnionerLinkedCenterCheck);
  const PesnionerLinkedCenter = useSelector((state) => state.PesnionerLinkedCenter);

  useEffect(() => {
    if (PesnionerLinkedCenterCheck === false) {
      dispatch({
        type: "PENSIONER_LINKED_CENTER",
        num: null,
      });
    }
    if (dataUser?.data?.user?.roles[0] === "employee") {
      items = [
        {
          label: "Profile ",
          key: "1",
          icon: <UserOutlined className="some" />,
        },
        {
          label: "Account Status ",
          key: "3",
          icon: <CompassOutlined className="some" />,
        },
        {
          label: "Live Verification ",
          key: "4",
          icon: <LaptopOutlined className="some" />,
        },
        {
          label: "Message Panel ",
          key: "6",
          icon: <MessageOutlined className="some" />,
        },

        {
          label: "Logout",
          key: "2",
          icon: <LogoutOutlined className="some" />,
        },
      ];
    } else if (
      dataUser?.data?.user?.roles[0] === "admin" ||
      dataUser?.data?.user?.roles[0] === "superadmin"
    ) {
      items = [
        {
          label: "Profile ",
          key: "1",
          icon: <UserOutlined className="some" />,
        },
        {
          label: "Schedule Verification ",
          key: "20",
          icon: <LaptopOutlined className="some" />,
        },
        {
          label: "Message Panel ",
          key: "6",
          icon: <MessageOutlined className="some" />,
        },
        {
          label: "Logout",
          key: "2",
          icon: <LogoutOutlined className="some" />,
        },
      ];
    } else if (
      dataUser?.data?.user?.roles[0] === "gro-employee" ||
      dataUser?.data?.user?.roles[0] === "gro-admin"
    ) {
      items = [
        {
          label: "Profile ",
          key: "1",
          icon: <UserOutlined className="some" />,
        },
        {
          label: "Message Panel ",
          key: "6",
          icon: <MessageOutlined className="some" />,
        },
        {
          label: "Logout",
          key: "2",
          icon: <LogoutOutlined className="some" />,
        },
      ];
    } else {
      items = [
        {
          label: "Profile ",
          key: "1",
          icon: <UserOutlined className="some" />,
        },
        {
          label: "Account Status ",
          key: "3",
          icon: <CompassOutlined className="some" />,
        },
        {
          label: "Book a Slot",
          key: "21",
          icon: <CalendarOutlined className="some" />,
        },
        {
          label: "Documents",
          key: "10",
          icon: <SnippetsOutlined className="some" />,
        },
        {
          label: "Logout",
          key: "2",
          icon: <LogoutOutlined className="some" />,
        },
      ];
    }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, []);

  const onClick = ({ key }) => {
    if (key === "2") {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/logout`, {
          refreshToken: `${dataUser?.data.tokens.refresh.token}`,
        })
        .then(() => {
          localStorage.removeItem("EPhone");
          localStorage.removeItem("role");
          localStorage.removeItem("addPenEmp");
          localStorage.removeItem("status");
          localStorage.removeItem("app");
          localStorage.removeItem("pol");
          localStorage.removeItem("authen");
          localStorage.removeItem("penNum");
          resetStates();
          router("/");
        })
        .catch((err) => {
          setErr(err?.response?.data?.message);
          setShow(true);
        });
    } else if (key === "1") {
      // alert("Hello")
      // if(user === "admin"){
      //   router("/EDashboard/Account");
      // }
      // return
      if (location.pathname[1] === "E") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        dispatch({ type: "Employee" });
        router("/EDashboard/Account");
        dispatch({ type: "PENSIONER" });
        dispatch({ type: "editSet", num: "editEmployee" });
      } else if (location.pathname[1] === "D") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/Dashboard/PensionerAccount");
        dispatch({ type: "editSet", num: "editPensioner" });
      } else if (dataUser.data.user.roles[0] === "admin") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/EDashboard/Account");
        dispatch({ type: "editSet", num: "editPensioner" });
      } else if (dataUser.data.user.roles[0] === "superadmin") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/EDashboard/Account");
        dispatch({ type: "editSet", num: "editPensioner" });
      } else if (dataUser.data.user.roles[0] === "gro-employee") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/EDashboard/Account");
        dispatch({ type: "editSet", num: "editPensioner" });
      } else if (dataUser.data.user.roles[0] === "gro-admin") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/EDashboard/Account");
        dispatch({ type: "editSet", num: "editPensioner" });
      }
    } else if (key === "4") {
      if (dataUser?.data?.user?.status[0] !== "pending") {
        router("/EDashboard/polList");
      }
    } else if (key === "3") {
      if (location.pathname[1] === "D") {
        router("/Dashboard/statusPage");
      } else if (location.pathname[1] === "E") {
        router("/EDashboard/statusPage");
      }
    } else if (key === "5") {
      if (user === "employee" && privilege && privilege?.includes("View history")) {
        router("/EDashboard/PowerBi");
      } else if (user === "admin" || user === "superadmin") {
        // Handle navigation for admin users
        user === "admin"
          ? router("/AdminDashboard/PowerBi")
          : router("/superAdminDashboard/PowerBi");
      } else {
        // Handle navigation for other roles or non-privileged users
        setShowR(true);
      }
    } else if (key === "10") {
      router("/Dashboard/additionalDocs");
    } else if (key === "6") {
      user === "employee"
        ? router("/EDashboard/chatPanel")
        : user === "admin"
        ? router("/AdminDashboard/chatPanel")
        : router("/superAdminDashboard/chatPanel");
    } else if (key === "7") {
      router("/meeting");
    } else if (key === "20") {
      router("/pensionerVerificationScheduling/status");
    } else if (key === "21") {
      if (PesnionerLinkedCenter !== null) {
        router("/pensionerVerification/BookSlot");
        return;
      }
      dispatch({ type: "PENSIONER_BOOK_SLOT", num: true });
      router("/pensionerVerification/BookSlot/SelectLocation");
    }
  };

  const onClickMenu4 = ({ key }) => {
    if (key === "1") {
      router("/superAdminDashboard/Programs");
    } else if (key === "2") {
      router("/superadmin/setTransactionLimits");
    } else if (key === "3") {
      router("/superAdminDashboard/AccessSetting");
    } else if (key === "4") {
      router("/superAdminDashboard/holidaySetting");
    } else if (key === "5") {
      router("/superAdminDashboard/SchedulerTimeSetting");
    }
  };

  const togglePopup = () => {
    setPopupOpen((prevPopupOpen) => !prevPopupOpen);
  };

  const notifications = [
    { type: "Confirmation", message: "Transaction successful!" },
    { type: "Informational", message: "New message received." },
    { type: "Alert", message: "Critical update required!" },
    { type: "Error", message: "Error: Unable to connect to server." },
  ];

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };
  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  return (
    <div className="">
      <div className="flex items-center h-[3rem] w-[99%] justify-between text-lg">
        <h2 className="mb-3 font-bold">
          Welcome <span className="mr-2 font-semibold text-gray-500">{Name}</span>
        </h2>
        <div className="flex items-center gap-6">
          <div
            onClick={togglePopup}
            onMouseDown={(e) => e.preventDefault()}
            className=" relative text-[1.1rem]  font-bold cursor-pointer">
            <BellFilled className="text-[22px] flex items-center justify-center" />
          </div>
          <div
            // className="flex items-center gap-4"
            className={`${
              user === "superadmin"
                ? "text-[1.6rem] font-bold cursor-pointer hover:text-[#F35929] "
                : "hidden"
            }
          flex items-center gap-4
          `}>
            <Dropdown
              menu={{
                items: items4,
                onClick: onClickMenu4,
              }}
              placement="bottomRight"
              arrow
              className="custom-dropdown">
              <SettingFilled className="text-[22px] flex items-center justify-center" />
            </Dropdown>
          </div>
          {isPopupOpen && (
            <div
              className="absolute border-t border-grey-50 right-[195px] top-[85px] bg-white p-0 m-0 rounded-lg shadow-xl flex flex-col text-left z-50"
              ref={popupRef}>
              <div className="w-full h-[50px] border-b border-black-200 flex items-center justify-center rounded-t-lg">
                <h1 className="w-full text-center text-xl font-bold">Notifications</h1>
              </div>
              {notifications &&
                notifications.map((item, index) => (
                  <div key={index}>
                    <div className="p-2 w-full h-[20%] bg-[#fff]">
                      <h2 className=" font-bold m-1 text-gray-700">{item.type}</h2>
                      <p className=" mb-1 text-gray-500">{item.message}</p>
                    </div>
                    <hr />
                  </div>
                ))}
              <Button
                type="default"
                block
                onClick={() => {
                  router("/notifications");
                }}
                className="w-full text-white bg-primary h-[40px] rounded-b-lg rounded-t-none m-0 border-none">
                View All
              </Button>
            </div>
          )}
          <div className="relative mt-2 flex items-center justify-center">
            <div className="flex flex-col flex items-center justify-center">
              <img
                src={dataUser?.data?.user?.person?.image}
                className="w-[3.3rem] h-[3.3rem] border-2 border-primary p-[1px] rounded-full"
                alt=""
              />
            </div>
            <div className="mt-1 ml-2">
              <Dropdown
                menu={{
                  items,
                  onClick,
                }}
                isOpen={dropdownOpen}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <a onClick={(e) => e.preventDefault()}>
                  <div className="min-w-[100px] flex items-center capitalize justify-center">
                    {dataUser?.data?.user?.person && (
                      <h2 className="font-bold">
                        {dataUser?.data?.user?.person.firstName}&nbsp;
                        {/* {dataUser?.data?.user?.person.lastName} */}
                      </h2>
                    )}
                    <h2 className={`font-bold ${dataUser?.data?.user?.person ? "hidden" : ""}`}>
                      John Doe
                    </h2>
                    <img
                      src={arrow}
                      alt=""
                      className="ml-1 mt-1 flex items-center justify-center"
                    />
                  </div>
                </a>
              </Dropdown>
              <p className="text-[0.8rem] ml-1.5 font-bold text-[#F25A29]">
                {dataUser?.data?.user?.roles[0] === "employee"
                  ? "Employee"
                  : dataUser?.data?.user?.roles[0] === "pensioner"
                  ? "Pensioner"
                  : dataUser?.data?.user?.roles[0] === "admin"
                  ? "Admin"
                  : dataUser?.data?.user?.roles[0] === "superadmin"
                  ? "Super Admin"
                  : dataUser?.data?.user?.roles[0] === "gro-admin"
                  ? "GRO Admin"
                  : dataUser?.data?.user?.roles[0] === "gro-employee"
                  ? "GRO Employee"
                  : ""}
              </p>
            </div>
          </div>
        </div>
      </div>
      {showModal && <ErrorModal setShow={setShowModal} message={err} />}
    </div>
  );
};

export default Header;

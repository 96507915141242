import { DatePicker } from "antd";
import React, { useState } from "react";

const SearchModal = ({
  setShow,
  gid,
  setGid,
  bookletNo,
  setBookletNo,
  setDob,
  lastName,
  setLastName,
  searchFunction,

}) => {
  const [contactMethod, setContactMethod] = useState("gid");
  const handleOptionChange = (event) => {
    setContactMethod(event.target.value);
    if (event.target.value === "lastName") {
      setGid("");
      setBookletNo("");
    }
    if (event.target.value === "booklet") {
      setGid("");
      setLastName("");
      setDob("");
    }
    if (event.target.value === "gid") {
      setBookletNo("");
      setLastName("");
      setDob("");
    }
  };
  return (
    <div
      className={`w-[100%] h-[100%] z-[50] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
    >
      <div
        className={`bg-[#F6F4F4] rounded-lg relative sm:w-[30rem] w-[23rem] h-auto px-6 py-6`}
      >
        <div className="w-full gap-3  rounded-md">
          <h1 className="font-semibold text-gray-800 text-[0.9rem] mb-2">
            Search Pensioner
          </h1>
          <div className="flex gap-4 mb-4 items-center justify-between bg-white p-5 rounded-md">
            <div className="mb-2 flex items-center">
              <input
                type="radio"
                id="gidOption"
                name="contactMethod"
                value="gid"
                className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                checked={contactMethod === "gid"}
                onChange={handleOptionChange}
              />
              <label
                htmlFor="gidOption"
                className="cursor-pointer text-sm inline-flex items-center ml-2"
              >
                GID
              </label>
            </div>
            <div className="mb-2 flex items-center">
              <input
                type="radio"
                id="phoneOption"
                name="contactMethod"
                value="lastName"
                className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                checked={contactMethod === "lastName"}
                onChange={handleOptionChange}
              />
              <label
                htmlFor="phoneOption"
                className="cursor-pointer text-sm inline-flex items-center ml-2"
              >
                Last Name & DOB
              </label>
            </div>

            <div className="mb-2 flex items-center">
              <input
                type="radio"
                id="emailOption"
                name="contactMethod"
                value="booklet"
                className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                checked={contactMethod === "booklet"}
                onChange={handleOptionChange}
              />
              <label
                htmlFor="emailOption"
                className="cursor-pointer text-sm inline-flex items-center ml-2"
              >
                Booklet #
              </label>
            </div>
          </div>

          <h1 className="font-semibold text-gray-800 text-[0.9rem]">
            {contactMethod === "gid"
              ? "Enter GID"
              : contactMethod === "lastName"
              ? "Enter Last Name and DOB"
              : "Enter Booklet #"}
          </h1>

          {contactMethod === "gid" && (
            <div className="w-full mb-3 mt-2">
              <input
                type="text"
                name=""
                
                onChange={(e) => setGid(e.target.value)}
                value={gid}
                placeholder="Enter GID"
                className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
              />
            </div>
          )}
          {contactMethod === "lastName" && (
            <div className="flex flex-col gap-3 mt-2">
              <input
                type="text"
                name=""
                
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter Last Name"
                className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
              />
              <DatePicker
                placeholder="Enter DOB"
                className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
                onChange={(date, dateString) => setDob(dateString)}
              />
            </div>
          )}

          {contactMethod === "booklet" && (
            <div className="w-full mb-3 mt-2">
              <input
                type="text"
                name=""
                
                value={bookletNo}
                onChange={(e) => setBookletNo(e.target.value)}
                placeholder="Enter Booklet #"
                className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
              />
            </div>
          )}

          <div className="flex justify-center mt-10">
            <button
              onClick={() => setShow(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[40%]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                searchFunction();
              }}
              type="submit"
              className="ml-5 cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;

import React from "react";
import { Form, Input, Row, Col, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

const EditProfile = ({ initialValues = {}, onSave }) => {
  const [form] = Form.useForm();
  const router = useNavigate();

  const handleFinish = (values) => {
    console.log("Form values:", values);
    // router("/AttendanceEmployees");

    onSave && onSave(values);
  };
  const onCancel = (params) => {
    // router("/AttendanceEmployees");
  };

  return (
    <div className="p-6 bg-white">
      <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleFinish}>
        <Row gutter={16}>
          {/* Employee Name */}
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter the employee name" }]}>
              <Input placeholder="Enter employee name" />
            </Form.Item>
          </Col>

          {/* Image Upload */}
          <Col span={12}>
            <Form.Item
              label="Image"
              name="image"
              valuePropName="fileList"
              getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}>
              <Upload
                listType="picture"
                beforeUpload={() => false}
                onClick={(e) => e.preventDefault()} // Prevents form submission
              >
                <Button transparent className="border-dotted" icon={<UploadOutlined />}>
                  Upload Image
                </Button>
              </Upload>
            </Form.Item>
          </Col>

          {/* Gender */}
          <Col span={12}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Please select gender" }]}>
              <Select placeholder="Select gender">
                <Select.Option value="Male">Male</Select.Option>
                <Select.Option value="Female">Female</Select.Option>
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {/* Marital Status */}
          <Col span={12}>
            <Form.Item
              label="Marital Status"
              name="maritalStatus"
              rules={[{ required: true, message: "Please select marital status" }]}>
              <Select placeholder="Select marital status">
                <Select.Option value="Single">Single</Select.Option>
                <Select.Option value="Married">Married</Select.Option>
                <Select.Option value="Divorced">Divorced</Select.Option>
                <Select.Option value="Widowed">Widowed</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Position */}
          <Col span={12}>
            <Form.Item
              label="Position"
              name="position"
              rules={[{ required: true, message: "Please enter the position" }]}>
              <Input placeholder="Enter position" />
            </Form.Item>
          </Col>

          {/* Department */}
          <Col span={12}>
            <Form.Item
              label="Department"
              name="department"
              rules={[{ required: true, message: "Please enter department" }]}>
              <Input placeholder="Enter department" />
            </Form.Item>
          </Col>

          {/* Address */}
          <Col span={12}>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: "Please enter address" }]}>
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>

          {/* Contact */}
          <Col span={12}>
            <Form.Item
              label="Contact"
              name="contact"
              rules={[{ required: true, message: "Please enter contact" }]}>
              <Input placeholder="Enter contact" />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-end gap-3 mt-4">
          <Button transparent onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" htmlType="submit">
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditProfile;

import React, { useState, useEffect } from "react";
import { Button, Checkbox } from "antd";
import back5 from "../../../imgs/back5.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const EventDetails = () => {
  const router = useNavigate();
  const product = useSelector((state) => state.product);
  const cart = useSelector((state) => state.cartData);
  const [localCart, setCart] = useState(cart);
  const [show, setShow] = useState(false);
  const [lot, setLot] = useState("");
  const [address, setAddress] = useState("");
  const [initial, setinitial] = useState(true);
  const [regionNo, setRegionNo] = useState(null);
  const [regionName, setRegionName] = useState("");
  const [city, setCity] = useState(null);
  const [ward, setWard] = useState(null);
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  // Dummy data for options
  const allRegions = [
    { label: "Region 1", value: "1" },
    { label: "Region 2", value: "2" },
    { label: "Region 3", value: "3" },
  ];

  const allCities = [
    { label: "City A", value: "cityA" },
    { label: "City B", value: "cityB" },
    { label: "City C", value: "cityC" },
  ];

  const allWards = [
    { label: "Ward 1", value: "ward1" },
    { label: "Ward 2", value: "ward2" },
    { label: "Ward 3", value: "ward3" },
  ];
  const CartItem = ({ item, onIncrement, onDecrement }) => {
    console.log("Itemssss", item);
    return (
      <div className="flex w-full mt-2 bg-white pb-8 border-b">
        <img
          src={item.image}
          alt={item.name}
          className="w-[7.5rem] h-[7.5rem] rounded-md "
        />
        <div className="ml-4 flex flex-col">
          <h2 className="text-lg font-semibold">{item.name}</h2>
          <p className="text-gray-600 font-semibold">${item.price}</p>
          <div className="flex items-center mt-2">
            <Button
              onClick={() => onDecrement(item.id)}
              className="bg-gray-300 text-black px-[10px] rounded-md"
            >
              -
            </Button>
            <span className="mx-2">{item.quantity}</span>
            <Button
              onClick={() => onIncrement(item.id)}
              className="bg-gray-300 text-black px-2 rounded-md"
            >
              +
            </Button>
          </div>
        </div>
      </div>
    );
  };

  // Example functions to fetch region name or wards based on selections
  const getRegionName = (value) => {
    const region = allRegions.find((region) => region.value === value);
    setRegionName(region ? region.label : "");
  };

  const getWards = (regionNo, regionName, city) => {
    if (regionNo && city) {
      setWard(null); // Reset ward selection
    }
  };

  useEffect(() => {
    setCart(cart);
  }, [cart]);

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false);
        router("/Inventory-Product");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [show]);

  const handleIncrement = (id) => {
    setinitial(false);
    setCart(
      localCart.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrement = (id) => {
    setinitial(false);
    setCart(
      localCart.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  return (
    <div className="flex w-full bg-[#D9EFED] pb-[5rem] pt-[5rem] items-center justify-center">
      <div className="sm:w-[80%] flex flex-col items-center">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => {
                router("/Inventory-PlaceOrder");
              }}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Delivery Details
              </h2>
            </div>
          </div>
        </div>
        <div className="w-full p-8 mt-6 rounded-xl flex flex-col gap-4 bg-gray-100">
          <div className="grid grid-cols-[60%_40%] w-full gap-2">
            <div className="bg-white p-6 px-12">
              <div className="flex flex-col gap-4">
                <div className="flex gap-1 items-center justify-between">
                  <label
                    className="block whitespace-nowrap flex items-center justify-center font-semibold"
                    htmlFor="delivery-method"
                  >
                    Delivery Method
                  </label>
                  <div className="flex gap-2 w-[70%] flex items-center justify-center">
                    <Select
                      id="delivery-method"
                      placeholder="Select a delivery method"
                      options={[
                        { value: "standard", label: "Standard Delivery" },
                        { value: "express", label: "Express Delivery" },
                        { value: "pickup", label: "Pickup" },
                      ]}
                      className="w-full"
                      styles={{
                        control: (base) => ({
                          ...base,
                          backgroundColor: "#f0f0f0",
                          border: "none",
                        }),
                      }}
                    />
                    <div className="border border-primary border-2 cursor-pointer hover:bg-[#1BA397] hover:text-white rounded-full text-primary font-semibold w-6 h-6 flex items-center justify-center">
                      i
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 items-center justify-between">
                  <label
                    className="block whitespace-nowrap flex items-center justify-center font-semibold"
                    htmlFor="saved-address"
                  >
                    Use Saved Address
                  </label>
                  <Select
                    id="saved-address"
                    placeholder="Select a saved address"
                    options={[
                      { value: "home", label: "Home Address" },
                      { value: "work", label: "Work Address" },
                      { value: "other", label: "Other Address" },
                    ]}
                    className="w-[70%]"
                    styles={{
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#f0f0f0",
                        border: "none",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="flex items-center mt-8 mb-6">
                <Checkbox
                  id="deliver-another-address"
                  className="font-semibold"
                >
                  Deliver to another address
                </Checkbox>
              </div>
              <div className="flex items-center justify-center w-full flex-col mt-4">
                <div className="w-full grid sm:grid-cols-2 gap-4 grid-cols-1">
                  <div className="w-full">
                    <div className="w-full flex flex-col">
                      <h2 className="mb-2 whitespace-nowrap text-[#718096] font-semibold">
                        Lot #
                      </h2>
                      <input
                        type="text"
                        value={lot}
                        placeholder="Lot #"
                        onChange={(e) => setLot(e.target.value)}
                        className="lg:text-[1.1rem] w-[100%] bg-gray-200 rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <h2 className="mb-2 whitespace-nowrap text-[#718096] font-semibold">
                        Address
                      </h2>
                      <input
                        type="text"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                        placeholder="Address"
                        className="lg:text-[1.1rem] w-[100%] bg-gray-200 rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <h2 className="mb-2 whitespace-nowrap text-[#718096] font-semibold">
                        Region No
                      </h2>
                      <Select
                        placeholder="Region No"
                        options={allRegions}
                        className="mb-4"
                        onChange={(option) => {
                          setRegionNo(option.value);
                          getRegionName(option.value);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            backgroundColor: "#f0f0f0",
                            border: "none",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                          }),
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <h2 className="mb-2 whitespace-nowrap text-[#718096] font-semibold">
                        Region Name
                      </h2>
                      <input
                        type="text"
                        value={regionName}
                        placeholder="Region Name"
                        className="lg:text-[1.1rem] w-[100%] bg-gray-200 rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="w-full flex flex-col">
                      <h2 className="mb-2 whitespace-nowrap text-[#718096] font-semibold">
                        Town/City
                      </h2>
                      <Select
                        placeholder="Town/City"
                        options={allCities}
                        className="mb-4"
                        onChange={(option) => {
                          setCity(option.value);
                          getWards(regionNo, regionName, option.value);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            backgroundColor: "#f0f0f0",
                            border: "none",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                          }),
                        }}
                      />
                    </div>
                    <div className="w-full flex flex-col">
                      <h2 className="mb-2 whitespace-nowrap text-[#718096] font-semibold">
                        Ward
                      </h2>
                      <Select
                        placeholder="Ward"
                        options={allWards}
                        className="mb-4"
                        onChange={(option) => setWard(option.value)}
                        styles={{
                          control: (base) => ({
                            ...base,
                            backgroundColor: "#f0f0f0",
                            border: "none",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                          }),
                        }}
                      />
                    </div>
                    {/* <div className="w-full flex flex-col">
                      <h2 className="mb-2 whitespace-nowrap text-[#718096] font-semibold">
                        State
                      </h2>
                      <input
                        type="text"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        placeholder="State"
                        className="lg:text-[1.1rem] w-[100%] bg-gray-200 rounded-[0.3rem] p-2 mb-4"
                      />
                    </div> */}
                    <div className="w-full flex flex-col">
                      <h2 className="mb-2 whitespace-nowrap text-[#718096] font-semibold">
                        Zip Code
                      </h2>
                      <input
                        type="text"
                        value={zip}
                        onChange={(e) => setZip(e.target.value)}
                        placeholder="Zip Code"
                        className="lg:text-[1.1rem] w-[100%] bg-gray-200 rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                  </div>

                  <div className="flex items-center mt-2">
                    <Checkbox
                      id="deliver-another-address"
                      className="font-semibold"
                    >
                      Save this Address
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full bg-white py-6 px-4">
              <h2 className="font-semibold">Order Summary</h2>
              {localCart.length > 0 && (
                <div className="mx-auto w-full flex flex-col gap-2  mt-3 lg:max-w-2xl xl:max-w-4xl">
                  <div className="space-y-6">
                    {localCart?.map((item) => (
                      <CartItem
                        item={item}
                        onIncrement={handleIncrement}
                        onDecrement={handleDecrement}
                      />
                    ))}
                  </div>

                  <div className="mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
                    <div className="space-y-4">
                      <div className="space-y-2">
                        <dl className="flex items-center justify-between gap-4">
                          <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                            Sub total
                          </dt>
                          <dd className="text-base font-medium text-gray-900 dark:text-white">
                            $75.00
                          </dd>
                        </dl>

                        <dl className="flex items-center justify-between gap-4">
                          <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                            Tax
                          </dt>
                          <dd className="text-base font-medium text-gray-900 dark:text-white">
                            $9.00
                          </dd>
                        </dl>

                        <dl className="flex items-center justify-between gap-4">
                          <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                            Shipping
                          </dt>
                          <dd className="text-base font-medium text-primary">$5</dd>
                        </dl>

                        <dl className="flex items-center justify-between gap-4">
                          <dt className="text-base font-normal font-semibold dark:text-gray-400">
                            Total
                          </dt>
                          <dd className="text-base font-medium text-gray-900 dark:text-white">
                            $799
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-8 flex gap-6">
          <div className="bg-none border-none font-semibold flex items-center hover:underline justify-center cursor-pointer  text-semibold ">
            Cancel order
          </div>
          <Button
            onClick={() => {
              router("/Inventory-Payment");
            }}
            className="bg-primary rounded-xl font-semibold cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white"
          >
            Proceed with Payment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;

import React, { useEffect } from "react";
import PageHeader from "./pageHeader/pageHeader";
import vp1 from "../../imgs/vp1.svg";
import vp2 from "../../imgs/vp2.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const SelectedProgram = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const selectedProgram = useSelector((state) => state.selectedProgram);
  const privilege = useSelector((state) => state.myPrivileges);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch({
      type: "PRIORITY_PENSIONER",
      num: false,
    });
  }, []);
  const Card = ({ icon, title, description, bgColor, circleColor, route }) => {
    return (
      <div
        className={`flex flex-col items-center p-6 rounded-lg shadow-md ${bgColor} w-auto cursor-pointer`}
        onClick={() => {
          router(route);
        }}
      >
        <div
          className={`w-14 h-14 mb-4 ${circleColor} rounded-full flex items-center justify-center p-3`}
        >
          <img src={icon} alt={title} className="w-8 h-8" />
        </div>
        <h3 className="text-lg font-semibold mb-2 text-center">{title}</h3>
        <p className="text-center text-gray-600">{description}</p>
      </div>
    );
  };
  const formatProgramNames = (data) => {
    return data
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4 min-h-screen">
      <PageHeader
        header={true}
        title={formatProgramNames(selectedProgram)}
        route={"/pensionerVerification/selectProgram"}
      />

      <div className="flex justify-center">
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%] w-full`}
        >
          <div
            className={`grid  p-4 py-20  ${
              user === "employee" && !privilege.includes("View priority")
                ? "md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-10"
                : `md:grid-cols-4 sm:grid-cols-2 grid-cols-1   gap-5`
            }`}
          >
            <Card
              icon={vp1}
              title="Verify Pensioner"
              description="View and verify the pensioner"
              bgColor="bg-[#F1FFFE]"
              circleColor="bg-[#1BA397]"
              route="/pensionerVerification/listing"
            />
            {/* {user === "employee" &&
            !privilege.includes("View priority") ? null : ( */}
            <Card
              icon={vp2}
              title="Verify Priority Pensioners"
              description="Verify the pensioners who are approved for priority status"
              bgColor="bg-[#F5ECCD]"
              circleColor="bg-[#F3C729]"
              route={"/pensionerVerification/Verify/PriorityPensioners"}
            />
            {/* )} */}

            {user === "employee" &&
            !privilege.includes("View priority") ? null : (
              <Card
                icon={vp2}
                title="See Priority Requests"
                description="Respond to priority requests from pensioners"
                bgColor="bg-[#FCEFEB]"
                circleColor="bg-[#F35929]"
                route={"/pensionerVerification/PriorityRequests"}
              />
            )}
            <Card
              icon={vp2}
              title="See Appointments"
              description="View and manage appointments booked by pensioners"
              bgColor="bg-[#EAF0FA]"
              circleColor="bg-[#2758A7]"
              route={"/pensionerVerification/AllAppointments"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedProgram;

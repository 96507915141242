import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Coupon from "./Coupon";
import ErrorModal from "../../Modals/errorModal";
import AmountModal from "../NewPay/AmountModal";
import axios from "axios";

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1;
const currentYear = currentDate.getFullYear();

const ProfileCard = () => {
  const dispatch = useDispatch();
  const selectedProgramPensionBook = useSelector(
    (state) => state.selectedProgramPensionBook
  );
  const token = useSelector((state) => state.token);
  const paymentDate = useSelector((state) => state.startPaymentDate);
  const [startDate, setStartDate] = useState(new Date(paymentDate));
  const [startMonth, setStartMonth] = useState(startDate.getMonth() + 1);
  const [startYear, setStartYear] = useState(startDate.getFullYear());
  const [active, setActive] = useState("1");
  const selectedPensioner = useSelector((state) => state.selectedPensioner);
  const selectedPensionerVouchers = useSelector(
    (state) => state.selectedPensionerVouchers
  );
  const selectedVoucherForCash = useSelector(
    (state) => state.selectedVoucherForCash
  );
  console.log(selectedVoucherForCash);

  // console.log(selectedPensionerVouchers);

  const activeVouchers = useSelector((state) => state.activeVouchers);
  const [items, setItems] = useState([]);
  const [amount, setAmount] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [err, setErr] = useState("");
  const [showAmountModal, setShowAmountModal] = useState(false);
  const [selectedVoucherIds, setSelectedVoucherIds] = useState([]);
  const [selectedVouchers, setSelectedVouchers] = useState([]);

  useEffect(() => {
    const voucherCounts = {
      all: 0,
      active: 0,
      redeemed: 0,
      pending: 0,
    };

    selectedPensionerVouchers?.allCoupons?.forEach((voucher) => {
      // console.log(voucher?.month < currentMonth)

      if (voucher?.status === "Active" && voucher.month <= currentMonth) {
        if (
          parseInt(currentYear) > startYear ||
          (parseInt(currentYear) === startYear && voucher.month >= startMonth)
        ) {
          voucherCounts.active++;
          // voucherCounts.pending++;
        }
      }
      if (
        voucher?.status === "Active" &&
        voucher?.month > currentMonth &&
        currentYear === startYear
      ) {
        voucherCounts.pending++;
      }
      if (voucher.status === "Redeemed") {
        voucherCounts.redeemed++;
      }
      // if (voucher.status === "Active" && voucher?.month <= currentMonth) {
      //   voucherCounts.pending++;
      // }
    });

    setItems([
      {
        key: "1",
        label: "All",
        count: selectedPensionerVouchers?.allCoupons?.length,
      },
      {
        key: "2",
        label: `Available`,
        count: voucherCounts?.active,
      },
      {
        key: "3",
        label: `Upcoming`,
        count: voucherCounts.pending,
      },
      {
        key: "4",
        label: `Cashed`,
        count: voucherCounts.redeemed,
      },
    ]);

    // Perform other actions based on voucher counts if needed
  }, [selectedPensionerVouchers]);

  const getAmount = () => {
    if (selectedVoucherIds?.length === 0) {
      setErr("Please select a voucher");
      setShowErrorModal(true);
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-redeem-coupon/get-total-amount-by-program`,
        {
          pensionProgramId: selectedProgramPensionBook?.pensionprogramId,
          couponIds: selectedVoucherIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setAmount(data?.data?.totalAmount);
        dispatch({ type: "VOUCHER_AMOUNT", num: amount });
        // setShowVouchers(false);
        setShowAmountModal(true);
      })
      .catch(() => {});
  };

  return (
    <div className="mt-[-2rem] sm:mt-[-4rem]">
      <div className="sm:mx-8 mb-12">
        <div className="sm:p-8 p-3 bg-white  mt-8 rounded-lg">
          <div className="w-full  p-8 rounded-lg mb-10 relative border  shadow-md ">
            <div
              className={`${
                selectedPensioner?.user?.status === "rejected"
                  ? "bg-[#C53F3F]"
                  : selectedPensioner?.user?.status === "in-review"
                  ? "bg-blue-600"
                  : selectedPensioner?.user?.status === "pending"
                  ? "bg-yellow-500"
                  : "bg-primary"
              }  absolute top-4 right-5 sm:top-8 sm:right-5 text-white px-4 py-1 rounded text-md sm:text-lg border-none  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
            >
              {selectedPensioner?.user?.status}
            </div>
            <div className="flex justify-between items-center mt-8 md:mt-0 lg:mt-0">
              <div className="flex items-center sm:flex-row flex-col">
                <div className="sm:w-36 sm:h-36 w-20 h-20 bg-indigo-100  rounded-full shadow-2xl  inset-x-0 top-0 flex items-center justify-center text-indigo-500">
                  <img
                    src={selectedPensioner?.user?.person?.image}
                    alt="profile"
                    className="rounded-full sm:h-24 sm:w-24 h-16 w-16"
                  />
                </div>
                <div className="ml-4">
                  <h1 className="text-2xl sm:text-3xl font-semibold text-gray-800 capitalize">
                    {selectedPensioner?.user?.person?.firstName}{" "}
                    {selectedPensioner?.user?.person?.middleName &&
                      selectedPensioner?.user?.person?.middleName + " "}
                    {selectedPensioner?.user?.person?.lastName}{" "}
                    {/* <span className="font-light text-gray-600">27</span> */}
                  </h1>
                  <div className="mt-4 space-y-2">
                    <p className="text-md font-medium text-gray-700">
                      GID:{" "}
                      <span className="text-md text-gray-500">
                        {selectedPensioner?.user?.gid}
                      </span>
                    </p>
                    <p className={`text-md font-medium text-gray-700 `}>
                      DOB:{" "}
                      <span className="text-gray-500 text-md">
                        {selectedPensioner?.user?.person?.dateOfBirth}
                      </span>
                    </p>
                    <p className={`text-md font-medium text-gray-700 `}>
                      Gender:{" "}
                      <span className="text-gray-500 text-md">
                        {selectedPensioner?.user?.person?.gender}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="h-px my-8 bg-gray-300 border-0 " />
            <section className="text-gray-700 body-font mt-[2rem] ">
              <div className="container">
                <div className="grid grid-cols-4 gap-4 text-center mb-3">
                  {items.map((item, index) => (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setActive(item.key);
                      }}
                    >
                      <div
                        className={`${
                          item.key === active
                            ? "bg-primary text-white"
                            : "bg-[#F4F5FE]"
                        } shadow-md px-2 py-4 rounded-lg transform transition duration-500 hover:scale-100`}
                      >
                        <p className="leading-relaxed ">{item?.label}</p>
                        <h2
                          className={`${
                            item.key === active ? "text-white" : "text-gray-900"
                          }title-font font-medium text-3xl `}
                        >
                          {item?.count}
                        </h2>
                        {/* <p className="leading-relaxed">{item?.label}</p> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            {/* Table */}
            <div className="w-full py-2">
              <div className=" bg-white rounded-lg sm:border  sm:p-8 sm:border-gray-100">
                {/* Search */}
                {/* <div>
                  <div className="flex flex-col p-2  m-h-screen">
                    <div className="bg-[#F4F5FE] items-center justify-between w-full flex rounded-full shadow-lg p-2 mb-5 sticky">
                      <input
                        className="rounded-full w-full py-2 pl-4 text-gray-700 bg-white leading-tight focus:outline-none focus:shadow-outline lg:text-sm text-xs"
                        type="text"
                        placeholder="Search by Date, Code"
                        style={{
                          "::placeholder": { color: "#1BA397 !important" },
                        }}
                      />

                      <div className="bg-primary p-2 hover:bg-blue-400 cursor-pointer mx-2 rounded-full">
                        <svg
                          className="w-6 h-6 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div> */}

                {selectedPensionerVouchers?.allCoupons?.length === 0 ? (
                  <p className="text-center p-4 text-gray-500 text-lg">
                    No Voucher Found
                  </p>
                ) : (
                  <div>
                    {/* Coupon list */}
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-10 lg:ml-2 lg:mr-2">
                      {selectedPensionerVouchers?.allCoupons
                        ?.filter((data) => {
                          if (active === "1") {
                            return data;
                          }
                          if (active === "2") {
                            return (
                              data.status === "Active" &&
                              data?.month <= currentMonth &&
                              (parseInt(currentYear) > startYear ||
                                (parseInt(currentYear) === startYear &&
                                  data.month >= startMonth))
                            );
                          } else if (active === "3") {
                            return (
                              data?.month > currentMonth &&
                              currentYear === startYear
                            );
                          } else if (active === "4") {
                            return (
                              data.status === "Redeemed" &&
                              data?.month <= currentMonth &&
                              (parseInt(currentYear) > startYear ||
                                (parseInt(currentYear) === startYear &&
                                  data.month >= startMonth))
                            );
                          }
                          return true;
                        })
                        .map((data, index) => {
                          // Add the newStatus based on the active value
                          let newStatus = "";
                          if (active === "2") {
                            newStatus = "available";
                          } else if (active === "3") {
                            newStatus = "upcoming";
                          } else if (active === "4") {
                            newStatus = "redeemed";
                          }

                          return (
                            <div key={index} className="md:m-2">
                              <Coupon
                                data={{ ...data, newStatus }} // Add newStatus to the coupon data
                                year={selectedPensionerVouchers?.year}
                                selectedVoucherIds={selectedVoucherIds}
                                setSelectedVoucherIds={setSelectedVoucherIds}
                                selectedVouchers={selectedVouchers}
                                setSelectedVouchers={setSelectedVouchers}
                              />
                            </div>
                          );
                        })}
                    </div>

                    {/* Conditionally render the Calculate button when active === "2" */}
                    {active === "2" && selectedVoucherIds.length > 0 && (
                      <div
                        className={`flex p-4 gap-2 items-center justify-center  px-[30%] mb-6 `}
                      >
                        <button
                          onClick={() => getAmount()}
                          className="text-white inline-flex pr-2 pl-2 w-full justify-center bg-primary  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Calculate
                        </button>
                      </div>
                    )}
                  </div>
                )}

                {/* Previous code without new status */}

                {/* <div className="grid md:grid-cols-2 grid-cols-1 gap-10 lg:ml-2 lg:mr-2">
                    {selectedPensionerVouchers?.allCoupons
                      ?.filter((data) => {
                        if (active === "1") {
                          return data;
                        }
                        if (active === "2") {
                          return (
                            data.status === "Active" &&
                            data?.month <= currentMonth &&
                            (parseInt(currentYear) > startYear ||
                              (parseInt(currentYear) === startYear &&
                                data.month >= startMonth))
                          );
                        } else if (active === "3") {
                          return (
                            // data.status === "Active" &&
                            // data?.month <= currentMonth &&
                            // (parseInt(currentYear) > startYear ||
                            //   (parseInt(currentYear) === startYear &&
                            //     data.month >= startMonth))
                            data?.month > currentMonth &&
                            currentYear === startYear
                          );
                        } else if (active === "4") {
                          // return data.status === "Redeemed";
                          return (
                            data.status === "Redeemed" &&
                            data?.month <= currentMonth &&
                            (parseInt(currentYear) > startYear ||
                              (parseInt(currentYear) === startYear &&
                                data.month >= startMonth))
                          );
                        }
                        return true; // Show all if active is neither "1", "2", nor "3"
                      })
                      .map((data, index) => (
                        <div key={index} className="md:m-2">
                          <Coupon
                            data={data}
                            year={selectedPensionerVouchers?.year}
                          />
                        </div>
                      ))}
                  </div> */}

                {/* <div className="flow-root">
                  <ul className="divide-y divide-gray-200 ">
                    {employeesData.map((employee) => (
                      <li key={employee.id} className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                          <div className="flex-shrink-0">
                            <img
                              className="w-10 h-10 "
                              src={vc}
                              alt={`${employee.name}`}
                            />
                          </div>
                          <div className="flex-1 min-w-0">
                            <div className="flex items-center space-x-2">
                              <p className="text-md font-semibold text-primary truncate ">
                                Voucher
                              </p>
                            </div>
                            <p className="text-md font-semibold text-primary truncate ">
                              {employee.no}
                            </p>
                            {active === "2" && (
                              <p className="text-sm text-gray-400 truncate">
                                Valid till : {employee?.valid}
                              </p>
                            )}
                            {active === "3" && (
                              <p className="text-sm text-gray-400 truncate">
                                Cashed on : {employee?.valid}
                              </p>
                            )}
                          </div>
                          <div className="inline-flex items-center text-base text-primary text-[0.9rem] font-semibold cursor-pointer">
                            <div
                              className={`flex-shrink-0 ${
                                active === "3" ? "hidden" : ""
                              }`}
                            >
                              <img
                                className={` ${
                                  active === "2" ? "w-10 h-10" : "w-6 h-6"
                                }`}
                                src={qr}
                                alt={`${employee.name}`}
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAmountModal && (
        <AmountModal setShowAmountModal={setShowAmountModal} amount={amount} />
      )}
      {showErrorModal && <ErrorModal err={err} setShow={setShowErrorModal} />}
    </div>
  );
};

export default ProfileCard;

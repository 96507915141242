import { useLocation, useNavigate } from "react-router-dom";
import Ellipse from "../../imgs/bgabout.png";
import Ellipse1 from "../../imgs/bgContact.png";
import page from "../../imgs/page.png";
import page1 from "../../imgs/contactfg.png";
import back5 from "../../imgs/back5.png";
import head from "../../imgs/headings.png";
import poly from "../../imgs/poly.png";
import FinAb from "../../imgs/FinAb.png";
import medAb from "../../imgs/medAb.png";
import disAb from "../../imgs/disAb.png";
import proAb from "../../imgs/proAb.png";
import woAb from "../../imgs/woAb.png";
import abuseAb from "../../imgs/abuseAb.png";
import parentAb from "../../imgs/parentAb.png";
import UEAb from "../../imgs/UEAb.png";
import contactA from "../../imgs/contactA.png";
import contactB from "../../imgs/contactB.png";
import contactC from "../../imgs/contactC.png";
const About = () => {
  const router = useNavigate();
  const location = useLocation();
  const items = [
    {
      key: "1",
      title: `Financial`,
      content: "",
      img: FinAb,
    },
    {
      key: "2",
      title: `Medical`,
      content: "",
      img: medAb,
    },
    {
      key: "3",
      title: `Disability`,
      content: "",
      img: disAb,
    },
    {
      key: "4",
      title: `Child Protection`,
      content: "",
      img: proAb,
    },
    {
      key: "5",
      title: `Battered Women`,
      content: "",
      img: woAb,
    },
    {
      key: "6",
      title: `Domestic Abuses`,
      content: "",
      img: abuseAb,
    },
    {
      key: "7",
      title: `Single Parent`,
      content: "",
      img: parentAb,
    },
    {
      key: "8",
      title: `Unemployed`,
      content: "",
      img: UEAb,
    },
  ];

  function handleAction(action) {
    switch (action) {
      case "email":
        window.location.href = "mailto:info@mhsss.gov.gy";
        break;
      case "location":
        window.open(
          "https://www.google.com/maps/place/Human+services+and+social+security/@6.8172241,-58.1552799,19.24z/data=!4m9!1m2!2m1!1sLamaha+%26+East+Streets,+South+Cummingsburg,+Georgetown!3m5!1s0x8dafef0068661717:0x482d0a39ce345028!8m2!3d6.8174641!4d-58.1550383!16s%2Fg%2F11vqnz67l6?entry=ttu"
        );
        break;
      case "call":
        window.location.href = "tel:+5922257112";
        break;
      default:
        console.error("Unknown action:", action);
    }
  }

  const itemsC = [
    {
      key: "1",
      title: `Tel: +592-225-7112`,
      content: "(Monday to Friday 9am to 4pm CST)",
      img: contactA,
      action: "call",
    },
    {
      key: "2",
      title: `info@mhsss.gov.gy`,
      content: "Just send us your questions and concerns at mentioned email.",
      img: contactB,
      action: "email",
    },
    {
      key: "3",
      title: `Location`,
      content: "Lamaha & East Streets, South Cummingsburg, Georgetown",
      img: contactC,
      action: "location",
    },
  ];

  return (
    <div>
      <div
        className="flex items-center flex-col justify-center mb-[20px]"
        style={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${Ellipse})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="w-[80%]">
          <div
            onClick={() => {
              if (location.pathname === "/EDashboard/About") {
                router("/EDashboard");
              } else if (location.pathname === "/Dashboard/About") {
                router("/Dashboard");
              } else if (location.pathname === "/AdminDashboard/About") {
                router("/AdminDashboard");
              } else if (location.pathname === "/superAdminDashboard/About") {
                router("/superAdminDashboard");
              } else if (location.pathname === "/GroAdminDash/About") {
                router("/GroAdminDash");
              } else if (location.pathname === "/GRO/About") {
                router("/GRO");
              } else {
                router("/Dashboard");
              }
            }}
            className=" cursor-pointer flex items-center"
          >
            <div>
              <img className="w-[1.7rem]" src={back5} alt="" />
            </div>
            <h2 className="font-bold sm:text-[2rem] text-[1.6rem] ml-3">
              About Us
            </h2>
          </div>
        </div>
        <div
          className="min-h-[100vh] w-[80%] shadow-xl  mt-[2rem]"
          style={{
            width: "80%",
            height: "100%",
            backgroundImage: `url(${page})`,
            backgroundSize: "cover",
            backgroundPosition: "center 10%",
          }}
        >
          <div className="w-full h-auto pb-[3rem] pl-[3rem] pr-[3rem] pt-[2rem]">
            <p style={{ fontFamily: "poppins", fontWeight: "400" }}>
              Any person <b>65 years</b> of age and a citizen of The Country is
              eligible for MHSSS-Senior Citizen Pension (SCP) benefits upon
              completing the application, submit their documents and is approved
              by program Director.
            </p>
            <ul
              className="mt-6 list-disc pl-6 mb-8"
              style={{ fontFamily: "poppins", fontWeight: "400" }}
            >
              <li>You must be 65 years or older.</li>
              <li>You must be a Citizen of The Country.</li>
              <li>You must have lived in The Country for at least 5 years.</li>
            </ul>
            <p style={{ fontFamily: "poppins", fontWeight: "400" }}>
              <b>Public Assistance</b> is a temporary financial assistance
              designed to assist the infirm, aged, handicapped, orphans, persons
              affected by HIV and all other persons who cannot adequately
              maintain their households.
            </p>
          </div>
          <div>
            <div
              className="right-[11px] relative"
              style={{
                width: "10px",
                height: "10px",
                backgroundImage: `url(${poly})`,
                backgroundSize: "cover",
                backgroundPosition: "center 10%",
              }}
            ></div>
            <div
              className="right-[10px] relative"
              style={{
                width: "100%",
                height: "auto",
                backgroundImage: `url(${head})`,
                backgroundSize: "cover",
                backgroundPosition: "center 10%",
              }}
            >
              <h1 className="text-white text-[22px] sm:pl-[3rem] pl-[1rem]">
                Other Social assistance programs includes:
              </h1>
            </div>
          </div>
          <div className="w-full min-h-[400px] flex-col flex items-center justify-center">
            <div className="sm:w-[85%] mt-[2rem] rounded-md w-full p-4 mb-16  h-full">
              <div className="w-full flex flex-wrap items-center justify-center gap-4 min-h-[400px]">
                {items.map((item, index) => (
                  <div
                    key={index}
                    className="relative w-[180px] rounded-md sm:mt-[30px] mb-[30px] min-h-[130px] p-2 cursor-pointer custom-shadows"
                    style={{
                      background:
                        "linear-gradient(rgba(255, 255, 255, 0.9), rgba(248, 245, 245, 0.1))",
                    }}
                  >
                    <div className="absolute top-[-35px] left-1/2 flex p-5 border-[3px] border-[#F8F5F5] p-[1px] items-center justify-center transform -translate-x-1/2 bg-[#8BD6B5] rounded-full h-[70px] w-[70px]">
                      <img
                        src={item?.img}
                        alt=""
                        className="h-[35px] w-[35px]"
                      />
                    </div>
                    <div className="flex items-center justify-center flex-col mt-6 w-full h-full">
                      <div className="flex items-center justify-center w-full flex-col">
                        <div className="text-center mt-4 h-auto ">
                          <h1
                            className="text-black text-[16px] font-bold"
                            style={{ fontFamily: "poppins", fontWeight: "600" }}
                          >
                            {item?.title}
                          </h1>
                          <p
                            className="text-black text-[13px] "
                            style={{ fontFamily: "poppins", fontWeight: "300" }}
                          >
                            {item?.content}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full h-auto pb-[3rem] pl-[3rem] pr-[3rem]">
              <p style={{ fontFamily: "poppins", fontWeight: "400" }}>
                If you are eligible for MHSSS-Social Assistance
                Programs (SAP), the amount of money you get will depend on your
                specific criteria. You could receive up to{" "}
                <b className="text-red-500">50,000$ a month</b> for basic needs
                and shelter upon approved.
              </p>
              <p
                style={{ fontFamily: "poppins", fontWeight: "400" }}
                className="mt-6 mb-6"
              >
                <b>You may receive additional benefits too:</b>
              </p>
              <ul
                className="mt-6 list-disc pl-6"
                style={{ fontFamily: "poppins", fontWeight: "400" }}
              >
                <li>Money for transportation to medical appointments</li>
                <li>Other immediate family members</li>
                <li>Free travel passes for Government owned ferries.</li>
              </ul>
            </div>
          </div>
          <div>
            <div
              className="right-[11px] relative"
              style={{
                width: "10px",
                height: "10px",
                backgroundImage: `url(${poly})`,
                backgroundSize: "cover",
                backgroundPosition: "center 10%",
              }}
            ></div>
            <div
              className="right-[10px] relative"
              style={{
                width: "100%",
                height: "auto",
                backgroundImage: `url(${head})`,
                backgroundSize: "cover",
                backgroundPosition: "center 10%",
              }}
            >
              <h1 className="text-white text-[22px] sm:pl-[3rem] pl-[1rem]">
                Application:
              </h1>
            </div>
          </div>
          <div className="w-full h-auto mt-6 pb-[3rem] pl-[3rem] pr-[3rem]">
            <p style={{ fontFamily: "poppins", fontWeight: "400" }}>
              Applications maybe completed online on website or Android
              application downloaded from the app stores or local offices:
            </p>
            <ul
              className="mt-6 list-disc pl-6"
              style={{ fontFamily: "poppins", fontWeight: "400" }}
            >
              <li> MHSSS Head Office</li>
              <li> MHSSS Regional Office</li>
            </ul>
            <div className="w-full flex items-center justify-center mt-8">
              <div className="w-full bg-white min-h-[150px] p-8">
                <p style={{ fontFamily: "poppins", fontWeight: "400" }}>
                  Make sure all family members included in your application that
                  are living with you, have the following documentations
                  available and uploaded into the platform.
                </p>
                <ul
                  className="mt-4 list-disc pl-6 grid gap-x-[6rem] text-[16px] sm:grid-cols-2 p-6 grid-cols-1"
                  style={{ fontFamily: "poppins", fontWeight: "600" }}
                >
                  <li className="text-red-500 sm:mt-4 mt-2">
                    Tax Identification Numbers (TIN)
                  </li>
                  <li className="text-red-500 sm:mt-4 mt-2">
                    Birth certificates
                  </li>
                  <li className="text-red-500 sm:mt-4 mt-2">
                    National Insurance Scheme (NIS)
                  </li>
                  <li className="text-red-500 sm:mt-4 mt-2"> Passport</li>
                  <li className="text-red-500 sm:mt-4 mt-2"> GID card</li>
                  <li className="text-red-500 sm:mt-4 mt-2">
                    {" "}
                    Utilities bills
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-10">
              <p style={{ fontFamily: "poppins", fontWeight: "400" }}>
                A caseworker from your local MHSSS office will review your
                application and contact you by a voice or video call to schedule
                an appointment for reviewing the application and benefits.
              </p>
              <p
                className="mt-8"
                style={{ fontFamily: "poppins", fontWeight: "400" }}
              >
                <b>You will be notified</b> through the platform on your{" "}
                <span className="text-red-500 font-semibold">
                  approval, denied or deferred
                </span>{" "}
                benefits, amount and first payment date. If deferred or denied,
                you will have the opportunity to present your case Infront of
                the Social Services board for review.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full min-h-[40vh]">
        <div
          className="flex w-full min-h-[40vh] pb-6 items-center flex-col justify-center"
          style={{
            backgroundImage: `url(${Ellipse1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            className="min-h-[20vh] w-[80%] rounded-md shadow-xl"
            style={{
              backgroundImage: `url(${page1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              className="right-[11px] top-[20px] relative"
              style={{
                width: "10px",
                height: "10px",
                backgroundImage: `url(${poly})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="relative w-[101%] right-[10px] h-auto bg-primary mt-5">
              <h1 className="text-white text-[22px] sm:pl-[3rem] pl-[1rem]">
                Contact Us
              </h1>
            </div>
            <div className="rounded-md sm:pl-[2rem] sm:pr-[2rem] pt-[3rem] pb-[3rem] pl-[1rem] pr-[1rem] w-full min:h-[200px]">
            <div className="w-full flex flex-wrap items-center justify-center gap-4">
                {itemsC.map((item, index) => (
                  <div
                    key={index}
                    className="relative w-[230px] rounded-md bg-white gap-8 min-h-[220px] p-2 cursor-pointer shadow-md"
                  >
                    <div className="flex p-4 items-center justify-center rounded-md w-full">
                      <img
                        src={item?.img}
                        alt=""
                        className="h-[50px] w-[50px]"
                      />
                    </div>
                    <div className="flex items-center justify-center flex-col w-full h-full">
                      <div className="flex items-center justify-center w-full flex-col">
                        <div className="text-center mt-4 h-auto ">
                          <h1
                            onClick={() => {
                              if (item.action !== null) {
                                handleAction(item.action);
                              }
                            }}
                            className={`text-black min-w-[30px] font-bold text-16 hover:text-[#F35929] cursor-pointer`}
                            style={{ fontFamily: "poppins", fontWeight: "600" }}
                          >
                            {item?.title}
                          </h1>
                          <p
                            className="text-black text-[13px] mt-2 "
                            style={{ fontFamily: "poppins", fontWeight: "300" }}
                          >
                            {item?.content}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

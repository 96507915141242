import React from "react";
import Card from "./employees";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import AttendanceDashboardWrapper from "../components/AttendanceWrapper";
const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "5" });
  }, []);

  return (
    <AttendanceDashboardWrapper>
      <Card />
    </AttendanceDashboardWrapper>
  );
};

export default Index;

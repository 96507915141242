import React from "react";
import { useNavigate } from "react-router-dom";
const SuccessModal = ({ setShow, message, route }) => {
  const router = useNavigate();
  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-10`}
    >
      <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
        <div className="absolute top-[-11%] left-[42%]">
          <div className="rounded-full bg-primary p-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-8 h-8"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </div>
        </div>
        <p className="text-center mt-[3rem] mb-5 w-[90%]">{message}</p>
        <button
          onClick={() => {
            router(route);
            setShow(false);
          }}
          className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
        >
          Ok
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;

import { Avatar, Dropdown, Menu, Input, Pagination, Select, Tag } from "antd";
import { useState, useMemo } from "react";
import totalEmp from "../../../imgs/totalEmp.png";
import empLeft from "../../../imgs/empOff.png";
import empAct from "../../../imgs/currEmp.png";
import { useNavigate } from "react-router-dom";
import DevicesCards from "./components/DevicesCards";
import AttendanceTable from "../attendance/components/AttendanceTable";
import Button from "../components/Button";
import D1 from "../../../imgs/D1.jpg";
import D2 from "../../../imgs/D2.jpg";
import D3 from "../../../imgs/D3.jpg";
import D4 from "../../../imgs/D4.jpg";
import D5 from "../../../imgs/D5.jpg";

const { Option } = Select;

const columnHeaders = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name, row) => (
      <div className="flex items-center">
        {/* <Avatar src={row?.profile} className="mr-2 w-10 h-10" /> */}
        <img src={row?.profile} alt="profile" className="mr-2 w-10 h-10" />
        <p className="text-primary capitalize">{name}</p>
      </div>
    ),
  },
  {
    title: "IP Address",
    dataIndex: "ipAddress",
    key: "ipAddress",
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    key: "contact",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Biometric",
    dataIndex: "biometric",
    key: "biometric",
    render: (biometric, record) => {
      const biometricLabels = {
        FP: "Fingerprint",
        FR: "Facial Recognition",
        HPV: "Hand Palm Vein",
        NFC: "Near Field Card",
        QRC: "QR Code",
        PIN: "Pin Code",
      };

      const biometricColors = {
        FP: "green",
        FR: "yellow",
        HPV: "orange",
        NFC: "pink",
        QRC: "red",
        PIN: "cyan",
      };

      // Render both biometric and biometric2 tags if both are available
      return (
        <div className="flex gap-1">
          <Tag
            color={biometricColors[biometric] || "gray"}
            className="text-xs font-normal px-1 py-0.5">
            {biometric}
          </Tag>
          {record.biometric2 && (
            <Tag
              color={biometricColors[record.biometric2] || "gray"}
              className="text-xs font-normal px-1 py-0.5 ml-1">
              {record.biometric2}
            </Tag>
          )}
        </div>
      );
    },
  },
  // {
  //   title: "Biometric",
  //   dataIndex: "biometric",
  //   key: "biometric",
  //   render: (biometric) => {
  //     const biometricLabels = {
  //       FP: "Fingerprint",
  //       FR: "Facial Recognition",
  //       HPV: "Hand Palm Vein",
  //       NFC: "Near Field Card",
  //       QRC: "QR Code",
  //       PIN: "Pin Code",
  //     };

  //     const biometricColors = {
  //       FP: "green",
  //       FR: "yellow",
  //       HPV: "orange",
  //       NFC: "pink",
  //       QRC: "red",
  //       PIN: "cyan",
  //     };

  //     return (
  //       <Tag
  //         color={biometricColors[biometric] || "gray"}
  //         className="text-xs font-normal px-1 py-0.5">
  //         {biometric}
  //       </Tag>
  //     );
  //   },
  // },
];

const dummyData = [
  {
    id: "1",
    profile: D1,
    name: "Fingerprint",
    ipAddress: "192.168.1.1",
    location: "New York",
    department: "IT",
    address: "123 Main St, New York, NY",
    contact: "9876543210",
    phone: "9876543210",
    email: "john.doe@example.com",
    biometric: "FP",
    biometric2: "FR",
  },
  {
    id: "2",
    profile: D2,
    name: "Facial Recognition",
    ipAddress: "192.168.1.2",
    location: "Los Angeles",
    department: "Mechanical",
    address: "456 Elm St, Los Angeles, CA",
    contact: "8765432109",
    phone: "8765432109",
    email: "jane.smith@example.com",
    biometric: "FR",
  },
  {
    id: "3",
    profile: D3,
    name: "Hand Palm Vein",
    ipAddress: "192.168.1.3",
    location: "Chicago",
    department: "Medical",
    address: "789 Maple St, Chicago, IL",
    contact: "7654321098",
    phone: "7654321098",
    email: "michael.johnson@example.com",
    biometric: "HPV",
    biometric2: "FR",
  },
  {
    id: "4",
    profile: D4,
    name: "QR Code",
    ipAddress: "192.168.1.4",
    location: "Houston",
    department: "Sales",
    address: "123 Oak St, Houston, TX",
    contact: "6543210987",
    phone: "6543210987",
    email: "emily.davis@example.com",
    biometric: "NFC",
    biometric2: "PIN",
  },
  {
    id: "5",
    profile: D5,
    name: "Near Field Card",
    ipAddress: "192.168.1.5",
    location: "Phoenix",
    department: "Marketing",
    address: "456 Pine St, Phoenix, AZ",
    contact: "5432109876",
    phone: "5432109876",
    email: "chris.brown@example.com",
    biometric: "QRC",
  },
  {
    id: "6",
    profile: D3,
    name: "Pin Code",
    ipAddress: "192.168.1.6",
    location: "Philadelphia",
    department: "HR",
    address: "789 Cedar St, Philadelphia, PA",
    contact: "4321098765",
    phone: "4321098765",
    email: "sarah.wilson@example.com",
    biometric: "PIN",
  },
  {
    id: "7",
    profile: D1,
    name: "QR Code",
    ipAddress: "192.168.1.7",
    location: "San Antonio",
    department: "Finance",
    address: "123 Birch St, San Antonio, TX",
    contact: "3210987654",
    phone: "3210987654",
    email: "david.lee@example.com",
    biometric: "FP",
  },
  {
    id: "8",
    profile: D2,
    name: "Pin Code",
    ipAddress: "192.168.1.8",
    location: "San Diego",
    department: "Engineering",
    address: "456 Spruce St, San Diego, CA",
    contact: "2109876543",
    phone: "2109876543",
    email: "laura.martinez@example.com",
    biometric: "FR",
  },
  {
    id: "9",
    profile: D4,
    name: "Facial Recognition",
    ipAddress: "192.168.1.9",
    location: "Dallas",
    department: "Legal",
    address: "789 Walnut St, Dallas, TX",
    contact: "1098765432",
    phone: "1098765432",
    email: "james.anderson@example.com",
    biometric: "NFC",
  },
  {
    id: "10",
    profile: D3,
    name: "Fingerprint",
    ipAddress: "192.168.1.10",
    location: "San Jose",
    department: "Support",
    address: "123 Maple St, San Jose, CA",
    contact: "0987654321",
    phone: "0987654321",
    email: "olivia.harris@example.com",
    biometric: "HPV",
  },
];

const DevicesMain = () => {
  const router = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [locationSelected, setLocationSelected] = useState("");
  const [departmentSelected, setDepartmentSelected] = useState("");

  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handleLocationChange = (value) => setLocationSelected(value);
  const handleDepartmentChange = (value) => setDepartmentSelected(value);

  const filteredData = useMemo(() => {
    return dummyData.filter((record) => {
      const matchesLocation = locationSelected ? record.location === locationSelected : true;
      const matchesDepartment = departmentSelected
        ? record.department === departmentSelected
        : true;
      const matchesSearch = record.name?.toLowerCase().includes(searchTerm.toLowerCase());
      return record && matchesLocation && matchesDepartment && matchesSearch;
    });
  }, [searchTerm, locationSelected, departmentSelected]);

  const handleAction = (action, id) => {
    switch (action) {
      case "view":
        router(`/Devices/DeviceDetails`);
        break;
      case "edit":
        console.log(`Edit details for employee ID: ${id}`);
        router(`/Devices/1`);

        break;
      case "remove":
        console.log(`Remove employee ID: ${id}`);
        break;
      default:
        console.log("Unknown action");
    }
  };

  // Action menu for each row
  const actionMenu = (id) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleAction("view", id)}>
        View
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleAction("edit", id)}>
        Edit
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleAction("remove", id)}>
        Remove
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="h-full px-2">
      <div className="flex w-full justify-between">
        <h2 className="text-xl font-semibold">Devices</h2>
        <Button className="rounded-md" onClick={() => router("/Devices/AddDevice")}>
          Add Device
        </Button>
      </div>
      <div style={{ margin: "20px 0px 20px 0px" }}>
        <DevicesCards />
      </div>

      <div className="grid gap-4 grid-cols-5 mb-4 mt-5">
        <Input
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearch}
          className="w-full h-[32px]"
        />
        <Select
          placeholder="Location"
          onChange={handleLocationChange}
          className="w-full h-[32px]"
          allowClear>
          {Array.from(new Set(dummyData.map((data) => data.location))).map((location) => (
            <Option key={location} value={location}>
              {location}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Department"
          onChange={handleDepartmentChange}
          className="w-full h-[32px]"
          allowClear>
          {Array.from(new Set(dummyData.map((data) => data.department))).map((department) => (
            <Option key={department} value={department}>
              {department}
            </Option>
          ))}
        </Select>
      </div>

      <div className="">
        <AttendanceTable
          columns={columnHeaders}
          data={filteredData}
          pageSize={10}
          actions={actionMenu}
        />
      </div>
    </div>
  );
};

export default DevicesMain;

import React, { useState, useEffect } from "react";
import { Button, Rate, Tabs, Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import like from "../../../imgs/heartF.png";
import dislike from "../../../imgs/heartD.png";
import share from "../../../imgs/share.png";
import cartW from "../../../imgs/cartW.png";
import exchange from "../../../imgs/exchange.svg";
import returnImg from "../../../imgs/return.svg";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;
const EventDetails = ({ data }) => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const cart = useSelector((state) => state.cartData);
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);
  const [localCart, setCart] = useState(cart);
  const [mainImage, setMainImage] = useState(
    data.invproductgallaries?.[0]?.image
  );
  const fav = useSelector((state) => state.fav);
  const [favorite, setFavorite] = useState(fav);
  const [flag, setFlag] = useState("n");
  const [initial, setinitial] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [initialFav, setinitialFav] = useState(true);
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const thumbnailsToShow = 3;

  const handlePrevR = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : events.length - 1
    );
  };

  const handleNextR = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < events.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrev = () => {
    setThumbnailIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : data.coverPhotos.length - thumbnailsToShow
    );
  };

  const handleNext = () => {
    setThumbnailIndex((prevIndex) =>
      prevIndex < data.coverPhotos.length - thumbnailsToShow ? prevIndex + 1 : 0
    );
  };

  const ratingsData = {
    0: [{ name: "John Doe", comment: "No stars given." }],
    1: [{ name: "Jane Smith", comment: "Poor quality." }],
    2: [{ name: "Alice Johnson", comment: "Not great, but okay." }],
    3: [{ name: "Chris Lee", comment: "Average experience." }],
    4: [{ name: "Emily Davis", comment: "Good, but not perfect." }],
    5: [{ name: "Michael Brown", comment: "Excellent! Highly recommended." }],
  };

  const productDetails = [
    { label: "Item Code:", value: "5689" },
    { label: "SKU", value: "565" },
    { label: "Category:", value: "Fruit" },
    { label: "Warranty", value: "No Warranty" },
    { label: "Vendor", value: "Smart Bazar" },
  ];

  // const displayedThumbnails = data?.invproductgallaries?.images?.slice(
  //   thumbnailIndex,
  //   thumbnailIndex + thumbnailsToShow
  // );
  const displayedThumbnails = data?.invproductgallaries
    ?.slice(thumbnailIndex, thumbnailIndex + thumbnailsToShow)
    .map((gallery) => gallery.image);

  console.log("display", displayedThumbnails);

  useEffect(() => {
    setCart(cart);
  }, [cart]);
  useEffect(() => {
    setFavorite(fav);
  }, [fav]);
  useEffect(() => {
    if (flag === "buy") {
      router("/Inventory-Cart");
      setFlag("sd");
    }
  }, [flag]);
  useEffect(() => {
    console.log("OkKK", localCart);
    if (!initial) {
      dispatch({ type: "Cart", num: localCart });
    }
  }, [localCart]);
  useEffect(() => {
    console.log("fav", favorite);
    if (!initialFav) {
      dispatch({ type: "Fav", num: favorite });
    }
  }, [favorite]);

  const onIncrement = (id) => {
    setinitial(false);
    setCart(
      localCart.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const onDecrement = (id) => {
    setinitial(false);
    setCart(
      localCart.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const addToCart = (event) => {
    setinitial(false);
    const cartItem = {
      id: event.id,
      image: event.invproductgallaries?.[0]?.image,
      name: event.name,
      price: event.basePrice,
      amount: event.amount,
    };
    const updatedCart = [...localCart, cartItem];
    setCart(updatedCart);
  };
  const addToFav = (event) => {
    setinitialFav(false);
    const favItems = {
      id: event.id,
      coverPhotos: event.coverPhotos,
      name: event.name,
      quantity: event.quantity,
      price: event.price,
      amount: event.amount,
    };

    const updatedFav = [...favorite, favItems];
    setFavorite(updatedFav);
  };

  const removeFromFav = (eventId) => {
    setinitialFav(false);
    const updatedFav = favorite.filter((item) => item.id !== eventId);
    setFavorite(updatedFav);
  };

  const isAddedToFav = (eventId) => {
    return favorite.some((item) => item.id === eventId);
  };

  const removeFromCart = (eventId) => {
    setinitial(false);
    const updatedCart = localCart.filter((item) => item.id !== eventId);
    setCart(updatedCart);
  };

  const isAddedToCart = (eventId) => {
    return localCart.some((item) => item.id === eventId);
  };

  function ProductInfo() {
    return (
      <div className="flex flex-col space-y-2">
        {productDetails.map((detail, index) => (
          <div key={index} className="flex justify-between">
            <span className="font-semibold text-[0.93rem]">{detail.label}</span>
            <span className="font-medium text-[0.93rem] text-gray-600">
              {detail.value}
            </span>
          </div>
        ))}
      </div>
    );
  }
  const events = [
    {
      id: 1,
      invproductgallaries: [
        {
          image:
            "https://images.unsplash.com/photo-1588165171080-c89acfa5ee83?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3RyYXdiZXJyeXxlbnwwfHwwfHx8MA%3D%3D",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ67Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ67Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0G12j7Y0",
        },
      ],
      name: "Strawberry",
      basePrice: 23,
      quantity: 1,
      items: 23,
      routeV: "/Inventory-Vendor-Product",
      pricingUnit: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 2,
      invproductgallaries: [
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZdYAUXvlwNIrC2A_4oIhFUyBtKtLNHm0buQ&s",
        },
        {
          image:
            "https://images.unsplash.com/photo-1571771894821-ce9b6c11b08e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmFuYW5hc3xlbnwwfHwwfHx8MA%3D%3D",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuFJ45D9B5tq0e9ijztRbX_y7YTbGZzn0T1w&s",
        },
      ],
      name: "Banana",
      basePrice: 22,
      items: 12,
      routeV: "/Inventory-Vendor-Product",
      quantity: 1,
      pricingUnit: "dozen",
      route: "/Inventory-Product",
    },
    {
      id: 3,
      invproductgallaries: [
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuIg-NlWsGyHPdsYHRMPHoynmyVvtx9GLNRA&s",
        },
        {
          image:
            "https://cdn.britannica.com/24/174524-050-A851D3F2/Oranges.jpg",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoYq2s3xqHMWVzlAUXGh-LnB20Z3nhC8Y46liCklv90f0ZSaxThNe1EFCAETyg5vnHlK8&usqp=CAU",
        },
      ],
      name: "Orange",
      basePrice: 43,
      items: 97,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      pricingUnit: "dozen",
      route: "/Inventory-Product",
    },
    {
      id: 4,
      invproductgallaries: [
        {
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Mangos_-_single_and_halved.jpg/640px-Mangos_-_single_and_halved.jpg",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmgfExstNMpVm0dVH-xKurYdLlQpZpgJxHaw&s",
        },
        {
          image:
            "https://www.finedininglovers.com/sites/g/files/xknfdk626/files/styles/article_1200_800_fallback/public/2021-06/best-mango-varieties%C2%A9iStock.jpg?itok=KHYB1X__",
        },
      ],
      name: "Mango",
      basePrice: 33,
      items: 56,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      pricingUnit: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 5,
      invproductgallaries: [
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgg1d49uY8Qx_SA9IgB4VC_30POp9wq_Vpig&s",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnfVxPeSWw5EY2KodFM3M8qUIEbTeS5fjt4AucycvBPT9R9JSF3GZGD3zKJV8OaCFlYpQ&usqp=CAU",
        },
        {
          image:
            "https://us.123rf.com/450wm/spyrakot/spyrakot1901/spyrakot190100061/116630376-single-bunch-grapes-on-vine-close-up-of-bunches-of-ripe-wine-grapes-on-vine-blue-grapes-in-a.jpg?ver=6",
        },
      ],
      name: "Grapes",
      basePrice: 89,
      items: 9,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      pricingUnit: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 6,
      invproductgallaries: [
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtywKdongNUiZ8YexgVsgx4hOkSSONFea6eA&s",
        },
        {
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Red_Apple.jpg/640px-Red_Apple.jpg",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjxtWEWP3_ve_0aBf9k1S8-PgZjeu1ZXmWYQ&s",
        },
      ],
      name: "Apple",
      basePrice: 12,
      items: 33,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      pricingUnit: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 7,
      invproductgallaries: [
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSE_i197uwJl2xW0MfAp6oWvcefhLKz85H_HA&s",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4-zuEp1m6DvI71kXWfNTbMsKJIUdWUO2m7A&s",
        },
        {
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEQB1rnCE5bdACpu6QxKacY9P5DBE6sScoHg&s",
        },
      ],
      name: "Pear",
      basePrice: 91,
      items: 12,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      pricingUnit: "kg",
      route: "/Inventory-Product",
    },
  ];
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <Button
        className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  left-0 transform -translate-y-1/2"
        onClick={onClick}
      >
        <LeftOutlined className="text-white font-semibold" />
      </Button>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <Button
        className="absolute border-none hover:bg-[#1BA397] z-10 h-7 w-7 top-1/2 flex items-center justify-center rounded-full  right-0 transform -translate-y-1/2"
        onClick={onClick}
      >
        <RightOutlined className="text-white font-semibold" />
      </Button>
    );
  };

  const getDisplayedEvents = (currentIndex, count) => {
    const end = currentIndex + count;
    return events
      .slice(currentIndex, end)
      .concat(events.slice(0, Math.max(0, end - events.length)));
  };

  const displayedR = getDisplayedEvents(currentIndex, 4);
  return (
    <div className="flex w-full pb-[5rem] pt-[1rem] items-center justify-center">
      <div className="w-full  flex flex-col">
        <div className="w-full h-full h-auto grid grid-cols-[45%_55%]">
          <div key={data.id} className="w-full flex flex-col gap-2 mt-2">
            <div className="w-full h-[300px] mb-2">
              <img
                className="w-full h-[300px] rounded-[1.2rem]"
                src={mainImage}
                alt={data.name}
              />
            </div>
            <div className="relative w-full flex items-center">
              <button
                // onClick={handlePrev}
                className="absolute left-0 z-10 p-2 rounded-full"
              >
                <LeftOutlined />
              </button>
              {/* w-full flex gap-2 justify-between items-center overflow-hidden mx-8 */}
              <div className="w-full grid grid-cols-3 gap-2 mx-8">
                {displayedThumbnails?.map((coverPhoto, index) => (
                  <div
                    key={index}
                    className="w-full h-[100px] border  rounded-[0.8rem] cursor-pointer"
                    onMouseEnter={() => setMainImage(coverPhoto)}
                  >
                    <img
                      className="w-full h-full rounded-[0.8rem]"
                      src={coverPhoto}
                      alt={`${data.name}-${index}`}
                    />
                  </div>
                ))}
              </div>
              <button
                // onClick={handleNext}
                className="absolute right-0 z-10 p-2 rounded-full"
              >
                <RightOutlined />
              </button>
            </div>

            {/* Exchange Return */}
            <div class="flex items-center justify-center  bg-gray-100 mt-7">
              <div class="flex gap-10">
                <div class="flex flex-col items-center">
                  <div class="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center">
                    <img
                      src={exchange}
                      alt="No Exchange Icon"
                      class="w-8 h-8"
                    />
                  </div>
                  <p class="mt-2 text-sm font-semibold text-gray-800">
                    No Exchange
                  </p>
                </div>

                <div class="flex flex-col items-center">
                  <div class="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center">
                    <img
                      src={returnImg}
                      alt="No Return Icon"
                      class="w-8 h-8"
                    />
                  </div>
                  <p class="mt-2 text-sm font-semibold text-gray-800">
                    No Return
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col border-b  px-4">
            <div className="w-full flex flex-col pb-4 border-b">
              <div className="flex gap-4 items-center">
                <h1 className="text-3xl font-semibold">{data.name}</h1>
                <button
                  className="text-white bg-[#256091] pl-2 pr-2 py-2  rounded-lg sm:text-md text-md w-[11rem] flex items-center justify-center space-x-2"
                  onClick={() => addToCart(data)}
                >
                  <span>Add to Cart</span>
                  <img src={cartW} alt="Cart Icon" className="w-5 h-5" />
                </button>

                {/* <p className="font-semibold mt-3 text-gray-700">
                ${data.price}/{data.amount}
              </p> */}
              </div>
              <div className="flex items-center w-full justify-between">
                <div className="mt-2 items-center flex gap-4">
                  <Rate allowHalf defaultValue={4.5} />
                  <h1 className="font-semibold text-primary mt-2">
                    14 Ratings
                  </h1>
                </div>
                <div className="mt-2 items-center flex gap-4">
                  <img
                    onClick={() => removeFromFav(data.id)}
                    src={share}
                    alt=""
                    className="w-5 h-5 cursor-pointer"
                  />
                  {isAddedToFav(data.id) ? (
                    <img
                      onClick={() => removeFromFav(data.id)}
                      src={like}
                      alt=""
                      className="w-5 h-5 cursor-pointer"
                    />
                  ) : (
                    <img
                      onClick={() => addToFav(data)}
                      src={dislike}
                      alt=""
                      className="w-5 h-5 cursor-pointer"
                    />
                  )}
                </div>
              </div>
              <div>
                <button
                  onClick={() => {
                    router("/Inventory-Vendor-Products");
                  }}
                  className="mt-2 pl-3 pr-3 p-1 bg-gray-200 rounded-full font-semibold hover:bg-gray-300"
                >
                  Semule's Grocery Store
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-2 pb-4 ">
              <div className="bg-white  rounded-sm flex items-center  justify-between relative px-3">
                <div className="">
                  <div className="flex gap-10">
                    <h1 className="font-semibold text-xl text-red-600">
                      Price :
                    </h1>
                    <p className="font-semibold text-xl text-red-600">
                      ${data.basePrice}
                    </p>
                  </div>
                  <div className="flex gap-10">
                    <h1 className="text-md text-gray-600 line-through">
                      Price : 100
                    </h1>
                    <p className="">-20%</p>
                  </div>
                </div>

                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAC3CAMAAAAGjUrGAAAAhFBMVEX///8AAADj4+Pg4OD19fX4+PiXl5f8/Pzo6Oi2trbR0dGvr6+9vb1iYmK5ubk2NjZ1dXXKysqpqana2tpAQECOjo6Dg4NFRUVSUlLNzc0nJyePj4/CwsJ0dHQcHBx9fX0RERGfn59cXFw5OTktLS1OTk5fX19ra2sWFhYgICAMDAwoKCiIWm+IAAAEtklEQVR4nO2df4+qOBSGT2lLK6AICKKiiL9Q5/t/v0WhpTr3zu4mu8nMzfuMcRTacnh6oon/vEQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPxBKFKPh3q8fP57PPUH6fmOqH+QPW5H0DDRjB7+lDOUzKh++HNyv6Zzwg7sT/enzOlhlqmrHyLJqVQN88zkoVhzV3YEjeuo8fhwYTI37NykWVeq8RbV+DyYcC7sKhimSDvDXNPezlj3eHM0rkhjQeQIHP0rszlv5Zr15TDfOlR2s18H2n16rcfBNoHZG6c/hh4gu6lk3pP69M4YppcV3Uazw52llF1AOltlqzEFqNfxit6mq/H1uO9k2uGlpGHJrtHssnLsqIGKNRQVovQWpyMFO1FUuhHxLdGxR6IUTXJJ9lXp1cWHPvI03Otap4Wc17LkizrMUi9ug7luaFlQqza61WudsEjm8kCNqGI50TTNontyEOnsGvqzimcsCHwvSCfhhdqTrmZRrhpqW3UJrsGCF81dt6tmdQ9yztc3L1tmvncmP8zThTgE4XVCYaMPPD6dqbqKNuGpd7plOtZTUapNNa/WSRn426O3yGbhTbZdvTTdiHN2yhvd8FPbLRyqdSNblfI534nJPdKFLvUkY17C84gi5tNkK0/elV0pYXI707lgrBKsc/Ih/GhZsVnstXPmMe6nTF+0v1XTKR35ftp05bIy6A7RfktLmncrM91NoLmKaSMrJlOPllnEklj4E5YW4YQHnZPCS5qwWdKB6Sic1GpDZUnLFVvdebthukz8hK3mnDPGg1t3Dzcq0rphYpGkLKQm7/aHsRNNmCoj3mjGsu66R/lB9Wz6uFhQXO7ePQtTJs/C39KikLuAFbnnZ129dZYSy1VJPp9yJkM2E1tx0mnAeJTVE+Mk9nZs5zhJnk5OnZPz08m2Zvr+dDLV/rx3smsHJ90h2s07J/WLE184To5PJ7l1smrC1HVyODycBFfHSd07WXdO1g8n6V0seycbvehOxW9OFvJEddQ+neTTvbc3TrrSCnl7c7KBEzj5vk4knKBP4ARO4ASfsegTOIETOIETOIETOIETOIETOIETOIETOMHvJ9/GCfoETuAETuAE3zvoEziBEziBEziBEziBEziBEziBEziBEzjB7yffxwn6BE7gBE7wGYs+gRM4gRM4+YlO8L2DPoETOIETOIETOIETOIETOIETOIETOIET/H6CPoETOIETfMZ+HyfoEziBEziBEziBEziBEziBEziBEzj5IU7+/FyVdydf5Kr0+Tvy7B3d/J21zd+pps/8ncLm78z/7/wd/yV/hzv5O0eTv+Ob/J3d5/ydulqvPuXvXDZi+cjf8Z75O0X2df4OjVFGf5PTJF+SmV7fKTWOc0KRTLDWv8xpMif/y5wmOS6phpymYdbnnCaTYWVe/jrPy0aR2YgtG8n1VZ6XO+Uf5nk5MWV2Cefob/K8eqf28YuS7UV+k+c1RnrZBZx7cSPMbEKa2a0XKTTWR67K4ZW013diy1wFY+aYU6izaaN9W6PZOXIqHX07QpXZfXOVcZg9OxZiN+GtoQEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA+PH8Bdb5bD1874lhAAAAAElFTkSuQmCC"
                  alt=""
                  className="w-[10rem] h-[5rem]  p-3"
                />
              </div>

              <div className="w-[60%]">
                <ProductInfo />
              </div>

              <div className="mt-4 flex gap-4 items-center">
                <h2 className="text-lg font-semibold">Quantity</h2>
                <div className="flex items-center mt-2 gap-4">
                  <Button
                    onClick={() => onDecrement(data.id)}
                    className="bg-gray-300 text-black px-2 w-8 rounded-md"
                  >
                    -
                  </Button>
                  <span className="mx-2">{data.quantity}</span>
                  <Button
                    onClick={() => onIncrement(data.id)}
                    className="bg-gray-300 text-black px-2 w-8 rounded-md"
                  >
                    +
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex items-center h-full justify-center">
              <div className="w-[80%] p-6 flex flex-col items-center justify-center rounded-xl mt-6">
                <div className="flex w-full  gap-3">
                  {/* {isAddedToCart(data.id) ? (
                    <button
                      className="text-red-600 w-full bg-gray-400 pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md "
                      onClick={() => removeFromCart(data.id)}
                    >
                      Remove from Cart
                    </button>
                  ) : ( */}
                  <button
                    className="text-white w-full bg-[#FF9F40] pl-5 pr-5 py-2 rounded-lg sm:text-[1rem] text-md "
                    // onClick={() => addToCart(data)}
                  >
                    Request Quotations
                  </button>
                  {/* )} */}
                  <button
                    onClick={async () => {
                      await addToCart(data);
                      router("/Inventory-Cart")
                      // router("/Inventory-Buy");
                      // addToCart(data);
                      // setFlag("buy")
                    }}
                    className={`text-white w-full bg-primary pl-5 pr-5 py-2 rounded-lg sm:text-[1rem] text-md `}
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex gap-4">
          <div className="mt-6 w-full">
            <h1 className="font-semibold text-gray-800 text-xl">Description</h1>
            <p className="mb-10 mt-2">
              Indulge in the succulent sweetness of our premium pears, nature's
              gift wrapped in a delicate green skin. Each bite unveils a juicy,
              tender flesh bursting with refreshing flavor, a testament to the
              orchard's care and expertise. Whether enjoyed fresh or
              incorporated into culinary delights, our pears promise a
              delightful sensory experience, elevating any occasion with their
              unparalleled taste and texture. Savor the crisp, aromatic essence
              of our meticulously selected pears, a symbol of purity and
              wholesomeness
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2 mt-2">
          <h1 className="font-semibold text-gray-800 text-xl">
            Product Ratings
          </h1>
          <div className="mt-2 items-center flex gap-4">
            <Rate allowHalf defaultValue={4.5} />
            <h1 className="font-semibold text-primary mt-2">14 Ratings</h1>
          </div>
          <Tabs defaultActiveKey="0" tabBarStyle={{ marginBottom: 0 }}>
            {Object.keys(ratingsData).map((key) => (
              <TabPane
                tab={
                  <span className="border p-2 ml-2  rounded-md bg-gray-200 hover:bg-gray-300">
                    <span className="text-semibold p-2">{key} Stars</span>
                  </span>
                }
                key={key}
              >
                {ratingsData[key].map((rating, index) => (
                  <div key={index} className="p-2">
                    <p className="font-bold">{rating.name}</p>
                    <p>{rating.comment}</p>
                  </div>
                ))}
              </TabPane>
            ))}
          </Tabs>
        </div>
        {/* <div className="mt-6">
          <h1 className="font-semibold text-gray-800 mb-4 text-xl">
            Recently Bought :
          </h1>
          <div className="relative w-full flex items-center">
            <button
              onClick={handlePrevR}
              className="absolute left-0 z-10 p-2 rounded-full"
            >
              <LeftOutlined />
            </button>
            <div className="w-full flex gap-1 justify-between items-center overflow-hidden mx-8">
              {displayedR?.map((event) => (
                <div key={event.id} className="w-[22%]">
                  <Carousel
                    arrows
                    prevArrow={<CustomPrevArrow />}
                    nextArrow={<CustomNextArrow />}
                  >
                    {data?.invproductgallaries?.map((coverPhoto, index) => (
                      <div key={index}>
                        <img
                          className="w-full h-36 rounded-tr-[1.2rem] rounded-tl-[1.2rem] object-center items-center object-cover"
                          src={coverPhoto?.image}
                          alt={event.name}
                        />
                      </div>
                    ))}
                  </Carousel>
                  <div className="px-6 py-3 bg-gray-100 rounded-br-[1.2rem] rounded-bl-[1.2rem] relative">
                    <div
                      onClick={() => {
                        // dispatch({ type: "Product", num: event });
                        // router(role === "buyer" ? event?.route : event.routeV);
                      }}
                      className="font-semibold w-full flex flex-col items-center justify-center text-center text-xl hover:text-[#1BA397] cursor-pointer"
                    >
                      {data.name}
                    </div>
                    <div className="flex justify-center items-center">
                      <span className="mr-2 text-gray-500  text-center whitespace-nowrap font-semibold">
                        Old Price:
                      </span>
                      <p className="text-red-500 line-through text-center font-semibold">
                        ${event.basePrice + 4}/{event.pricingUnit}
                      </p>
                    </div>
                    <div className="flex justify-center items-center">
                      <span className="mr-2 text-gray-500 text-center font-semibold">
                        New Price:
                      </span>
                      <p className="text-green-500 text-center font-semibold">
                        ${event.basePrice}/{event.pricingUnit}
                      </p>
                    </div>

                    {user === "superadmin" && (
                      <div className="w-full flex items-center mt-2 justify-center">
                        {isAddedToCart(event.id) ? (
                          <button
                            className="bg-red-600 rounded-xl cursor-pointer pl-5 pr-5 text-semibold text-white"
                            // onClick={() => removeFromCart(event.id)}
                          >
                            Remove from Cart
                          </button>
                        ) : (
                          <button
                            className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 text-semibold text-white"
                            // onClick={() => addToCart(event)}
                          >
                            Add to Cart
                          </button>
                        )}
                      </div>
                    )}
                    {user === "superadmin" &&
                      (isAddedToFav(event.id) ? (
                        <img
                          // onClick={() => removeFromFav(event.id)}
                          src={like}
                          alt=""
                          className="w-4 h-4 absolute cursor-pointer right-[5px] top-[5px]"
                        />
                      ) : (
                        <img
                          // onClick={() => addToFav(event)}
                          src={dislike}
                          alt=""
                          className="w-4 h-4 cursor-pointer absolute right-[5px] top-[5px]"
                        />
                      ))}
                    <div className="w-full flex items-center justify-center">
                      <button
                        onClick={() => {
                          "";
                        }}
                        className="mt-2 pl-3 pr-3 p-1 bg-gray-200 rounded-full font-semibold hover:bg-gray-300"
                      >
                        {event?.vendor}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button
              onClick={handleNextR}
              className="absolute right-0 z-10 p-2 rounded-full"
            >
              <RightOutlined />
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default EventDetails;

import React, { useEffect, useState } from "react";
import PageHeader from "../pensionerVerification/pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../Merchant/Modals/errorModal";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import SuccessModal from "../modals/successModal";
import axios from "axios";
import { Select } from "antd";
import { Calendar } from "react-multi-date-picker";
import moment, { months } from "moment/moment";
// import { TimePicker } from "antd";

import calendar from "../../imgs/calendar.svg";

const SelectProgram = () => {
  const router = useNavigate();
  const edit = useSelector((state) => state.Edit);
  const data = useSelector((state) => state.Data);
  console.log(data);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [show, setShow] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [message, setMessage] = useState("");
  const StorageType = useSelector((state) => state.StorageType);

  const token = useSelector((state) => state.token);
  const [initial, setInitial] = useState(true);
  const [initial1, setInitial1] = useState(true);
  const [initial2, setInitial2] = useState(true);
  const [name, setName] = useState("");
  const [type, setType] = useState(null);
  const [address, setAddress] = useState("");
  const [regionNo, setRegionNo] = useState(null);
  const [regionName, setRegionName] = useState(null);
  const [lot, setLot] = useState("");
  const [city, setCity] = useState(null);
  const [ward, setWard] = useState(null);
  const [allRegions, setAllRegions] = useState("");
  const [allCities, setAllCities] = useState("");
  const [allWards, setAllWards] = useState("");

  useEffect(() => {
    if (edit) {
      setType(data?.type);
      setName(data?.name);
      setAddress(data?.address);
      setRegionNo(data?.regionNo);
      setRegionName(data?.regionName);
      setLot(data?.lot);
      setCity(data?.city);
      setWard(data?.ward);
    }
  }, []);
  //   useEffect(() => {
  //     setSelectedDateandTime(SelectedSlots);
  //     if (SelectedSlots.length > 0) {
  //       setSelectedDates(
  //         SelectedSlots.map((item) => moment(item.date.split("T")[0]))
  //       );
  //     }
  //     dispatch({ type: "SELECTED_MONTH", num: moment().format("MM") });
  //     dispatch({ type: "SELECTED_YEAR", num: moment().format("YYYY") });
  //     dispatch({ type: "SELECTED_PROGRAM", num: "senior-citizen-Pension" });
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_BASE_URL}/v1/pension-programs/names-and-icons`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
  //           },
  //         }
  //       )
  //       .then((data) => {
  //         setPrograms(data?.data);
  //       })
  //       .catch(() => {});
  //   }, []);

  const AddStorage = () => {
    if (name === "") {
      setErr("Please enter name");
      setShowErr(true);
      return;
    }

    const body = {
      name: name, // e.g., "Test3"
      //   description: description, // optional, e.g., "Test"
      type: StorageType === "warehouse" ? type : StorageType,
      regionNo: regionNo, // e.g., "Test"
      regionName: regionName, // e.g., "Test"
      lot: lot, // optional, e.g., "Test"
      address: address, // e.g., "Test"
      city: city, // optional, e.g., "Test"
      ward: ward, // optional, e.g., "Test"
      //   location: location, // optional, e.g., "349.4343, 594.349334"
    };

    const editBody = {
      name,
    };

    const api = edit ? `v1/mhsss-facility/${data?.id}` : "v1/mhsss-facility";

    const method = edit ? "put" : "post";
    axios[method](
      `${process.env.REACT_APP_BASE_URL}/${api}`,
      edit ? editBody : body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        edit
          ? setMessage("Storage Updated Successfully")
          : setMessage("Storage Added Successfully");
        setShow(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErr(true);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/gy-addresses`)
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setAllRegions(wow);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}`
        )
        .then((data) => {
          if (!edit) {
            setCity(null);
            setAllCities("");
            setAllWards("");
            setRegionName("");
            setWard(null);
            setRegionName(data.data.data[0]);
          }
        })
        .catch((err) => {});
    }
  }, [regionNo]);
  useEffect(() => {
    if (initial1) {
      setInitial1(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}`
        )
        .then((data) => {
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllCities(wow);
        })
        .catch((err) => {});
    }
  }, [regionName]);
  useEffect(() => {
    if (initial2) {
      setInitial2(false);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNo}&regionName=${regionName}&city=${city}`
        )
        .then((data) => {
          const wow = data.data.data.map((region) => {
            return {
              label: `${region}`,
              value: `${region}`,
            };
          });
          setAllWards(wow);
        })
        .catch((err) => {});
    }
  }, [city]);

  const handleChange1 = (setChange, check) => (event) => {
    if (check === "tt" || check === "tn") {
      setChange(event);
    } else {
      setChange(event.target.value);
    }
  };

  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4 min-h-screen">
      <PageHeader
        header={true}
        title={`${edit ? `${data?.type} Details` : "Add Storage"}`}
        route={"/InventoryStorage"}
      />

      <div className="flex justify-center">
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%] w-full`}
        >
          <div className="bg-white p-3 rounded-md mt-1 mb-4">
            <div className="w-[65%] p-4">
              <div
                className={`${
                  StorageType !== "warehouse" ? "hidden" : "block"
                } ${edit ? "pointer-events-none" : ""} `}
              >
                <h3 className="font-medium mb-1 text-[0.93rem]">
                  Storage Type
                </h3>

                <Select
                  placeholder="Storage Type"
                  className={`${edit ? "disabled-shadow" : ""} wow`}
                  value={type}
                  onChange={(value) => {
                    setType(value);
                  }}
                  options={[
                    {
                      label: "Master Warehouse",
                      value: "master-warehouse",
                    },
                    {
                      label: "Sub Warehouse",
                      value: "warehouse",
                    },
                  ]}
                ></Select>
              </div>

              <h3 className="font-medium mb-1 text-[0.93rem] capitalize mt-2">
                {StorageType} Name
              </h3>

              <input
                type="text"
                value={name}
                placeholder={`${StorageType} Name`}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              />
              <h3 className="font-medium mb-1 text-[0.93rem]">Address</h3>
              <div
                className={`${
                  edit
                    ? "pointer-events-none"
                    : "grid sm:grid-cols-1 sm:gap-4 grid-cols-1"
                }`}
              >
                <div className="flex flex-col">
                  <input
                    type="text"
                    name=""
                    onChange={(e) => {
                      setLot(e.target.value);
                    }}
                    value={lot}
                    placeholder="Lot #"
                    className={`${
                      edit
                        ? " disabled-shadow border-[1px] border-[#C6CFE5] "
                        : " bg-white border-[1px] border-[#C6CFE5] "
                    } lg:text-[1.1rem] w-[100%]  rounded-[0.3rem] p-2 mb-4`}
                  />
                  <input
                    type="text"
                    name=""
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    value={address}
                    placeholder="Address"
                    className={`${
                      edit
                        ? " disabled-shadow border-[1px] border-[#C6CFE5] "
                        : " bg-white border-[1px] border-[#C6CFE5] "
                    } lg:text-[1.1rem] w-[100%]  rounded-[0.3rem] p-2 mb-4`}
                  />
                  <Select
                    placeholder="Region No"
                    optionFilterProp="children"
                    className={`${edit ? "disabled-shadow" : ""} wow`}
                    style={{ marginBottom: "1.2rem" }}
                    onChange={handleChange1(setRegionNo, "tn")}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size="middle"
                    options={allRegions}
                    value={regionNo}
                  />
                  <input
                    type="text"
                    name=""
                    onChange={handleChange1(setRegionName, "tt")}
                    value={regionName}
                    placeholder="Region Name"
                    className={`${
                      edit
                        ? " disabled-shadow border-[1px] border-[#C6CFE5] "
                        : " bg-white border-[1px] border-[#C6CFE5] "
                    } lg:text-[1.1rem] w-[100%]  rounded-[0.3rem] p-2 mb-4`}
                  />
                  <Select
                    placeholder="Town/City"
                    optionFilterProp="children"
                    className={`${edit ? "disabled-shadow" : ""} wow`}
                    style={{
                      marginBottom: "1.2rem",
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={handleChange1(setCity, "tt")}
                    value={city}
                    size="middle"
                    options={allCities}
                  />
                  <Select
                    placeholder="Ward/Village"
                    optionFilterProp="children"
                    className={`${edit ? "disabled-shadow" : ""} wow`}
                    style={{ marginBottom: "1.2rem" }}
                    value={ward}
                    onChange={handleChange1(setWard, "tt")}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size="middle"
                    options={allWards}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            AddStorage();
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
        >
          Save
        </button>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
      {show && (
        <SuccessModal
          show={show}
          setShow={setShow}
          message={message}
          route="/InventoryStorage"
        />
      )}
    </div>
  );
};

export default SelectProgram;

import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import across from "../../../../imgs/across.png";
import { LoadingOutlined } from "@ant-design/icons";

let data;
const EmployerE = () => {
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();
  const [test, setTest] = useState(true);
  const [employer, setEmployer] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [position, setPosition] = useState("");
  const [location, setLocation] = useState("");
  const [show, setShow] = useState("");
  const [err, setErr] = useState("");
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [data]);
  useEffect(() => {
    if (profileData.employeeInfo) {
      data = profileData.employeeInfo;
      setEmployer(data.employer);
      setSupervisor(data.supervisor);
      setPosition(data.role);
      setLocation(data.departmentOrLocation);
    }
  }, [profileData]);

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/v1/employee-info`,
        {
          employer,
          supervisor,
          position,
          // departmentOrLocation: location,
          mhsssfacilityId: location,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        dispatch({ type: "RESET" });
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  };
  return (
    <div>
      <form onSubmit={submitHandler}>
        <h2 className="mb-4 text-[1.1rem] font-semibold">Employer:</h2>
        <input
        disabled
          type="text"
          name=""
          
          placeholder="Employer"
          value={employer}
          onChange={handleChange(setEmployer)}
          className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 "
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">Supervisor:</h2>
        <input
        disabled
          type="text"
          name=""
          
          placeholder="Supervisor"
          value={supervisor}
          onChange={handleChange(setSupervisor)}
          className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 "
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">Role:</h2>
        <input
        disabled
          type="text"
          name=""
          
          placeholder="Position"
          value={position}
          onChange={handleChange(setPosition)}
          className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 capitalize "
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">
          Department/Location:
        </h2>
        <input
        disabled
          type="text"
          name=""
          
          placeholder="Department/Location"
          value={location}
          onChange={handleChange(setLocation)}
          className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 "
        />
        {/* <button
          onClick={submitHandler}
          className={`${
            profileData.user.status === "pending" &&
            dash === "/Dashboard/PensionerAccount"
              ? "hidden"
              : ""
          } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          <Spin
            style={{ display: `${isLoadingx ? "" : "none"}` }}
            indicator={antIcon}
          /><span>Save</span>
          <span>Save</span>
          <div
            className={`w-[100%] h-[100%] ${
              isLoadingx ? "" : "hidden"
            } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
          ></div>
        </button> */}
      </form>

      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployerE;

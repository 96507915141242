import axios from "axios";
import { useState } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import across from "../../imgs/across.png";
import ReactInputVerificationCode from "react-input-verification-code";
import cross from "../../imgs/cross.png";
import eye from "../../imgs/eyee.png";
import PhoneInput from "react-phone-number-input";
let countryCode;
let number;
const ForgotPassword = () => {
  const [prompt, setPrompt] = useState("");
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [tok, setTok] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [showPop, setShowPop] = useState(false);
  const [passShow, setPassShow] = useState("password");
  const [showPopReset, setShowPopReset] = useState(false);
  const [code1, setCode1] = useState("");
  const [countryCodee, setCountryCodee] = useState("");
  const [numberr, setNumberr] = useState("");
  const option = useSelector((state) => state.option);
  const submitHandler = (e) => {
    e.preventDefault();

    const api =
      option === "Merchant"
        ? "v1/merch-auth/forgot-password"
        : option === "Vendor"
        ? "v1/vendor-auth/forgot-password"
        : "v1/auth/forgot-password";

    e.preventDefault();
    if (email) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/${api}`, {
          email: email,
        })
        .then(() => {
          setShowPop(true);
        })
        .catch((err) => {
          if (err?.response?.data?.message === "incorrect email or password") {
            setErr("Incorrect Email or Password");
          } else {
            setErr(err?.response?.data?.message);
          }

          setShow(true);
        });
    } else if (prompt) {
      const wow = parsePhoneNumber(prompt);
      countryCode = wow.countryCallingCode;
      number = wow.nationalNumber;
      setCountryCodee(countryCode);
      setNumberr(number);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/${api}`, {
          countryCode: `+${countryCode}`,
          phoneNo: number,
        })
        .then(() => {
          setShowPop(true);
        })
        .catch((err) => {
          if (err?.response?.data?.message === "incorrect email or password") {
            setErr("Incorrect Email or Password");
          } else {
            setErr(err?.response?.data?.message);
          }
          setShow(true);
        });
    }
  };
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
    } else {
      setState(event.target.value);
    }
  };
  const checkHandler = () => {
    if (code1 === "") {
      setShow(true);
      setErr("Please enter the verification code");
    }
    // else if (option === "Merchant")
    else {
      if (email) {
        const api =
          option === "Merchant"
            ? "v1/merch-auth/verify-reset-pwd-otp"
            : option === "Vendor"
            ? "v1/vendor-auth/verify-reset-pwd-otp"
            : "v1/auth/verify-otp";
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/${api}`, {
            email: email,
            otp: code1,
          })
          .then((data) => {
            setTok(data?.data?.tokens);
            setShowPop(false);
            setCode1("");
            setShowPopReset(true);
          })
          .catch((err) => {
            setErr(err.response.data.message);
            setShow(true);
          });
      } else if (prompt) {
        const api =
          option === "Merchant"
            ? "v1/merch-auth/verify-reset-pwd-otp"
            : option === "Vendor"
            ? "v1/vendor-auth/verify-reset-pwd-otp"
            : "v1/auth/verify-otp";
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/${api}`, {
            countryCode: `+${countryCodee}`,
            phoneNo: numberr,
            otp: code1,
          })
          .then((data) => {
            setTok(data?.data?.tokens);
            setShowPop(false);
            setCode1("");
            setShowPopReset(true);
          })
          .catch((err) => {
            setErr(err.response.data.message);
            setShow(true);
          });
      }
    }
    // else {
    //   if (email) {
    //     axios
    //       .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/verify-otp`, {
    //         email: email,
    //         otp: code1,
    //       })
    //       .then((data) => {
    //         setTok(data?.data?.tokens);
    //         setShowPop(false);
    //         setCode1("");
    //         setShowPopReset(true);
    //       })
    //       .catch((err) => {
    //         setErr(err.response.data.message);
    //         setShow(true);
    //       });
    //   } else if (prompt) {
    //     axios
    //       .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/verify-otp`, {
    //         countryCode: `+${countryCodee}`,
    //         phoneNo: numberr,
    //         otp: code1,
    //       })
    //       .then((data) => {
    //         setTok(data?.data?.tokens);
    //         setShowPop(false);
    //         setCode1("");
    //         setShowPopReset(true);
    //       })
    //       .catch((err) => {
    //         setErr(err.response.data.message);
    //         setShow(true);
    //       });
    //   }
    // }
  };
  const completeHandler = (e) => {
    setCode1(e);
  };

  const resetHandler = () => {
    if (newPass !== confirmPass) {
      setShow(true);
      setErr("Please confirm password again");
      return;
    }

    const api =
      option === "Merchant"
        ? "v1/merch-auth/reset-password"
        : option === "Vendor"
        ? "v1/vendor-auth/reset-password"
        : "v1/auth/reset-password";
    // else if (option === "Merchant")

    if (email) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/${api}`, {
          email: email,
          password: newPass,
          resetToken: tok,
        })
        .then(() => {
          setShowPopReset(false);
          setNewPass("");
          setConfirmPass("");
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow(true);
        });
    } else if (prompt) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/${api}`, {
          countryCode: `+${countryCodee}`,
          phoneNo: numberr,
          password: newPass,
          resetToken: tok,
        })
        .then(() => {
          setShowPopReset(false);
          setNewPass("");
          setConfirmPass("");
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow(true);
        });
    }

    //  else {
    //   if (email) {
    //     axios
    //       .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/reset-password`, {
    //         email: email,
    //         password: newPass,
    //         resetToken: tok,
    //       })
    //       .then(() => {
    //         setShowPopReset(false);
    //         setNewPass("");
    //         setConfirmPass("");
    //       })
    //       .catch((err) => {
    //         setErr(err.response.data.message);
    //         setShow(true);
    //       });
    //   } else if (prompt) {
    //     axios
    //       .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/reset-password`, {
    //         countryCode: `+${countryCodee}`,
    //         phoneNo: numberr,
    //         password: newPass,
    //         resetToken: tok,
    //       })
    //       .then(() => {
    //         setShowPopReset(false);
    //         setNewPass("");
    //         setConfirmPass("");
    //       })
    //       .catch((err) => {
    //         setErr(err.response.data.message);
    //         setShow(true);
    //       });
    //   }
    // }
  };
  const passShowHandler = () => {
    if (passShow === "password") {
      setPassShow("text");
    } else {
      setPassShow("password");
    }
  };
  return (
    <div className="flex justify-center">
      <form className="sm:w-[50%] w-[80%]">
        <div className="text-center mt-[3rem] flex flex-col items-center mb-[3rem]">
          <h2 className="font-medium text-[1.6rem] text-[#2c5281] mb-1">
            Password Reset
          </h2>
          <p className="w-[60%] text-[#596F96] text-[0.9rem]">
            To reset your password, enter the email address you use to sign in
            to SCP.
          </p>
        </div>
        <div className={`mb-6`}>
          <label
            htmlFor="input1"
            className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
          >
            Email *
          </label>
          <input
            className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2"
            type="text"
            placeholder="Email"
            id="input1"
            value={email}
            onChange={handleChange(setEmail)}
          />
        </div>
        <p className="text-gray-400 text-[1rem] font-semibold mb-2 text-center">
          or
        </p>
        <div>
          <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Phone *
          </label>
          <PhoneInput
            placeholder="Enter phone number"
            defaultCountry="GY"
            // value={prompt}
            onChange={handleChange(setPrompt, "tt")}
            international
            countryCallingCodeEditable={false}
            className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow .PhoneInputCountrySelect"
          />
        </div>
        {/* <div className="flex flex-col">
              <label
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Password *
              </label>
              <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                <input
                  type="password"
                  placeholder="Password"
                  id="Pass11"
                  onChange={handleChange(setPassword)}
                  value={password}
                  required
                  className="w-[100%]"
                ></input>
                <img
                  className="w-[1.6rem] cursor-pointer"
                  onClick={passShowHandler}
                  src={eye}
                  alt=""
                />
              </div>
            </div> */}
        <button
          type="button"
          onClick={submitHandler}
          className="justify-center mt-8 flex w-[100%] cursor-pointer bg-primary pt-2 pb-2 pl-[4rem] pr-[4rem] text-white rounded-xl"
        >
          Submit
        </button>
        {/* <button
          className="lg:text-[1.1rem] bg-[#d64242] mt-4 w-full text-white rounded-[0.5rem] p-2  pr-8 pl-8"
          onClick={() => dispatch({ type: "XSET", num: 0 })}
        >
          Back
        </button> */}
      </form>
      <div
        className={`fixed top-0 w-full h-full  bg-slate-500/50  justify-center items-center ${
          showPop ? "flex" : "hidden"
        }`}
      >
        <div className="lg:pt-[2rem] lg:pl-[2.2rem] lg:pr-[2.2rem] lg:w-[30%] lg:h-[21rem] w-[80%] h-[18rem] bg-white rounded-2xl flex items-center flex-col p-6 pl-5 pr-5 relative">
          <h2 className="mb-[1rem] text-[1.5rem] font-bold">Verification</h2>
          <div
            className="absolute top-3 right-4 cursor-pointer"
            onClick={() => {
              setShowPop(false);
              setCode1("");
            }}
          >
            <img className="scale-[0.8]" src={cross} />
          </div>
          <p className="text-center text-[1rem] mb-[2rem]">
            Please enter the six digit verification code that was sent...
          </p>
          <ReactInputVerificationCode
            className="--ReactInputVerificationCode-itemWidth"
            length={6}
            onCompleted={completeHandler}
            value={code1}
          />
          <button
            onClick={checkHandler}
            className="w-full bg-primary text-white p-3 mb-4 text-medium text-[1.1rem] rounded-lg mt-8"
          >
            Verify
          </button>
        </div>
      </div>
      <div
        className={`fixed top-0 w-full h-full  bg-slate-500/50  justify-center items-center ${
          showPopReset ? "flex" : "hidden"
        }`}
      >
        <div className="lg:pt-[2rem] lg:pl-[2.2rem] lg:pr-[2.2rem] lg:w-[30%] lg:h-[22rem] w-[80%] h-[21rem] bg-white rounded-2xl flex items-center flex-col p-6 pl-5 pr-5 relative">
          <h2 className="mb-[1rem] text-[1.5rem] font-bold">Reset Password</h2>
          <div
            className="absolute top-3 right-4 cursor-pointer"
            onClick={() => {
              setShowPopReset(false);
            }}
          >
            <img className="scale-[0.8]" src={cross} />
          </div>
          <div className="flex flex-col">
            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              New Password *
            </label>
            <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
              <input
                type={passShow}
                placeholder="Password"
                onChange={handleChange(setNewPass)}
                value={newPass}
                required
                className="w-[100%]"
              ></input>
              <img
                className="w-[1.6rem] cursor-pointer"
                onClick={passShowHandler}
                src={eye}
                alt=""
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Confirm Password *
            </label>
            <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
              <input
                type={passShow}
                placeholder="Password"
                onChange={handleChange(setConfirmPass)}
                value={confirmPass}
                required
                className="w-[100%]"
              ></input>
              <img
                className="w-[1.6rem] cursor-pointer"
                onClick={passShowHandler}
                src={eye}
                alt=""
              />
            </div>
          </div>
          <button
            onClick={resetHandler}
            className="w-full bg-primary text-white p-3 mb-4 text-medium text-[1.1rem] rounded-lg mt-5"
          >
            Reset
          </button>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] z-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import React, { useEffect, useState } from "react";
import PageHeader from "../pageHeader/pageHeader";
import ErrorModal from "../../Merchant/Modals/errorModal";
import axios from "axios";
import moment from "moment";
import logo from "../../../imgs/logo12.png";
import { Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
const SetSlot = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [showErr, setShowErr] = useState(false);
  const [err, setErr] = useState("");
  const [reason, setReason] = useState("");
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [showCancel, setShowCancel] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const appSearch = useSelector((state) => state.appSearch);
  const [fetch, setFetch] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const handleCheckboxChange = (id) => {
    setSelectedRecords((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((recordId) => recordId !== id)
        : [...prevSelected, id]
    );
  };
  const isDateTimePassed = (date, time) => {
    const dateTime = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss");
    const now = moment();
    return dateTime.isBefore(now);
  };
  const cancel = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/cancel-bulk/by-mhsss`,
        {
          ids: selectedRecords,
          cancelReason: reason,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then(() => {
        setShowCancel(false);
        setFetch(!fetch);
        router("/pensionerVerification/AllAppointments");
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErr(true);
      });
  };
  const handleSelectAllChange = () => {
    setSelectAll((prev) => !prev);
    if (!selectAll) {
      const upcomingIds = appSearch
        .filter((record) => {
          return (
            record.status !== "cancelled" &&
            !isDateTimePassed(
              record.schedulercentertimemanager.date,
              record.toTime
            )
          );
        })
        .map((record) => record.id);
      setSelectedRecords(upcomingIds);
    } else {
      setSelectedRecords([]);
    }
  };
  useEffect(() => {
    console.log("SELECTED", selectedRecords);
    console.log("SELECTEDAll", appSearch);
  }, [selectedRecords]);
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-3  min-h-screen">
      <PageHeader title={"All Appointments"} route={-1} header={true} />
      <div className="flex justify-center flex-col items-center">
        <div className="flex-col pb-12 bg-white shadow-lg min-h-[80vh] rounded-sm mx-4 m-2 p-4 sm:w-[90%] w-full">
          {/* <div className="flex justify-between items-center mb-4 w-full">
            <p className="font-semibold text-red-500 hover:underline cursor-pointer">
              Cancel Appointments
            </p>
            <div className="flex gap-2 w-[12rem] items-center justify-center">
              <label
                htmlFor="filter"
                className="font-semibold text-lg text-gray-700"
              >
                Filter:-
              </label>
              <Select
                placeholder="Type"
                id="filter"
                className="wow"
                value={type}
                onChange={(e) => setType(e)}
              >
                <option value="booked">Booked</option>
                <option value="cancelled">Cancelled</option>
              </Select>
            </div>
          </div> */}
          <div className="w-full flex mb-6 items-center justify-between">
            <div className="mb font-semibold">
              <Checkbox checked={selectAll} onChange={handleSelectAllChange}>
                Select All
              </Checkbox>
            </div>
            <p
              onClick={() => {
                if (selectedRecords?.length === 0) {
                  setErr("Please Select Appointment to cancel.");
                  setShowErr(true);
                } else setShowCancel(true);
              }}
              className="font-semibold text-red-500 hover:underline cursor-pointer"
            >
              Cancel Appointments
            </p>
          </div>
          <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
            {appSearch.map((record, index) => {
              const isUpcoming = !isDateTimePassed(
                record.schedulercentertimemanager.date,
                record.fromTime
              );
              console.log("Timing", record.date, record.fromTime);
              const type =
                record.status === "cancelled"
                  ? "Cancelled"
                  : isUpcoming
                  ? "Upcoming"
                  : "Completed";

              return (
                <div
                  key={index}
                  className="border p-3 gap-1 bg-gray-100 rounded-md shadow-sm"
                >
                  <div className="w-full flex gap-1 sm:flex-row flex-col mb-4 justify-between">
                    <div>
                      <p className="font-semibold text-gray-500">
                        Slot booked For
                      </p>
                      <div className="px-4 py-1 bg-primary rounded-md text-white">
                        {moment(
                          record?.schedulercentertimemanager?.date
                        ).format("YYYY/MM/DD")}
                        {", "}
                        {moment(record?.fromTime, "HH:mm:ss").format("hh:mm A")}
                      </div>
                    </div>
                    <div>
                      {type === "Upcoming" && (
                        <Checkbox
                          className="checkk"
                          checked={selectedRecords.includes(record.id)}
                          onChange={() => handleCheckboxChange(record.id)}
                        />
                      )}
                      <p
                        className={`font-semibold text-right ${
                          type === "Cancelled"
                            ? "text-red-500"
                            : type === "Completed"
                            ? "text-gray-500"
                            : "hidden"
                        }`}
                      >
                        {type}
                      </p>
                      <p className={`font-semibold w-full text-right`}>
                        {type === "Cancelled" || type === "Completed"
                          ? moment(record.updatedAt).format(
                              "YYYY-MM-DD hh:mm A"
                            )
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="bg-white p-2 py-3 mb-4 rounded-md flex flex-col gap-2">
                    <div className="flex items-center gap-3">
                      <img
                        src={
                          record.schedulercentertimemanager?.assignedByUserId
                            ?.profileImage || record.bookForUser?.person?.image
                        }
                        alt="Profile"
                        className="w-14 h-14 rounded-full"
                      />
                      <div className="ml-3 w-full">
                        <div className="w-full flex items-center justify-between">
                          <p className="font-semibold text-gray-600">
                            {record.bookForUser?.person?.firstName}{" "}
                            {record.bookForUser?.person?.lastName}
                          </p>
                          <p className="text-gray-600">
                            {record.bookForUser?.person?.gender}
                          </p>
                        </div>
                        <p className="text-gray-600">
                          <span className="font-semibold">GID:</span>{" "}
                          {record.bookForUser?.gid}
                        </p>
                        <div className="w-full flex items-center justify-between">
                          <p className="text-gray-600">
                            <span className="font-semibold">DOB:</span>{" "}
                            {moment(
                              record.bookForUser?.person?.dateOfBirth
                            ).format("YYYY-MM-DD")}
                          </p>
                          {/* <p
                            onClick={() => {
                              dispatch({
                                type: "APP_DETAIL",
                                num: record,
                              });
                              router(
                                "/pensionerVerification/AppointmentDetails"
                              );
                            }}
                            className="text-primary font-semibold cursor-pointer hover:underline"
                          >
                            View Details
                          </p> */}
                        </div>
                        <div className="w-full flex items-center justify-end">
                          <p
                            onClick={() => {
                              dispatch({
                                type: "APP_DETAIL",
                                num: record,
                              });
                              router(
                                "/pensionerVerification/AppointmentDetails"
                              );
                            }}
                            className="text-primary font-semibold cursor-pointer hover:underline"
                          >
                            View Details
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {type === "Cancelled" && (
                    <div className="bg-white p-2 py-2 mb-2 rounded-md flex flex-col">
                      <p className="font-semibold text-red-500">Reason:</p>
                      <p>{record.cancelReason}</p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className={`${
          showCancel ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[26rem]  flex items-center p-5 bg-white flex-col">
          <div className="w-16 h-16" onClick={() => setShowCancel(false)}>
            <img src={logo} alt="" className="w-16 h-16" />
          </div>
          <p className="text-center mt-6 mb-5 font-semibold">
            Are you sure to you want to cancel the appointment?
          </p>
          <textarea
            type="text"
            name=""
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            placeholder="Note"
            className=" lg:text-[1.1rem] w-[85%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <div className="w-full flex gap-2 items-center justify-center">
            <button
              onClick={() => {
                setShowCancel(false);
                setReason("");
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[40%]"
            >
              No
            </button>
            <button
              onClick={() => {
                if (reason === "") {
                  setErr("Please Enter a specific reason.");
                  setShowErr(true);
                } else cancel();
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showCancel ? "" : "hidden"
        } w-[100%] h-[100%] z-[50] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[26rem]  flex items-center p-5 bg-white flex-col">
          <div className="w-16 h-16" onClick={() => setShowCancel(false)}>
            <img src={logo} alt="" className="w-16 h-16" />
          </div>
          <p className="text-center mt-6 mb-5 font-semibold">
            Are you sure to you want to cancel the appointment?
          </p>
          <textarea
            type="text"
            name=""
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            placeholder="Note"
            className=" lg:text-[1.1rem] w-[85%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <div className="w-full flex gap-2 items-center justify-center">
            <button
              onClick={() => {
                setShowCancel(false);
                setReason("");
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[40%]"
            >
              No
            </button>
            <button
              onClick={() => {
                if (reason === "") {
                  setErr("Please Enter a specific reason.");
                  setShowErr(true);
                } else cancel();
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
    </div>
  );
};

export default SetSlot;

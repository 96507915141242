import { useLocation, useNavigate } from "react-router-dom";
import head from "../../../imgs/headings.png";
import poly from "../../../imgs/poly.png";
import circle from "../../../imgs/circle.png";
// import Stores from "./Tabs/stores";
// import Documentation from "./Tabs/documents";
import Financial from "./Tabs/financial";
import { Checkbox, DatePicker, Pagination, Select } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../Merchant/Modals/errorModal";
import SuccessModal from "../../Merchant/Modals/successModal";
// import Stores from "../../Merchant/Profile/Tabs/stores";
import Stores from "./Tabs/merchantStores";
import Company from "../../Merchant/Profile/Tabs/Company";
import Owner from "../../Merchant/Profile/Tabs/Owner";
import Headquarter from "../../Merchant/Profile/Tabs/headquater";
import ContactPerson from "../../Merchant/Profile/Tabs/ContactPerson";
import Documentation from "../../Merchant/Profile/Tabs/Documentation";
import days from "dayjs";
import moment from "moment";
const About = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const privilege = useSelector((state) => state.myPrivileges);
  const [active, setActive] = useState("1");
  const dataUser = useSelector((state) => state.dataUser);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [err, setErr] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [status, setStatus] = useState("Pending");
  const [reason, setReason] = useState(null);
  const [publicNote, setPublicNote] = useState("");
  const [privateNote, setPrivateNote] = useState("");
  const [feeType, setFeeType] = useState("");
  const [feeAmount, setFeeAmount] = useState(100);
  const [contractFrom, setContractFrom] = useState(null);
  const [contractTo, setContractTo] = useState(null);
  const [paymentTerms, setPaymentTerms] = useState(null);
  const [defaultId, setDefaulId] = useState("");
  // const [banking, setBanking] = useState([]);
  // const [cheque, setCheque] = useState([]);
  // const [ewallet, setEwallet] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [merchantInfo, setMerchantInfo] = useState();
  const [contactPerson, setContactPerson] = useState([]);
  const [identification, setIdentification] = useState([]);
  const merchantId = useSelector(
    (state) => state.selectedMerchantForApprovalId
  );
  const [verify, setVerify] = useState(false);
  const [merchStores, setMerchStores] = useState([]);
  const [documentation, setDocumentation] = useState([{}]);
  const [company, setCompany] = useState("");
  const [storesTotalPages, setStoresTotalPages] = useState(0);
  const [storesCurrentPage, setStoresCurrentPage] = useState(1);
  const [contactPersonTotalPages, setContactPersonTotalPages] = useState(0);
  const [contactPersonCurrentPage, setContactPersonCurrentPage] = useState(1);
  const merchType = useSelector((state) => state.merchType);
  const merch = useSelector((state) => state.selectedMerchantForApproval);
  const [limit, setLimit] = useState(null);
  const ChangeStatus = () => {
    if (!verify) {
      setErr("Please verify the information first and mark the checkbox");
      setShowErrModal(true);
      return;
    }
    if (status === "Pending") {
      setErr("Please select status");
      setShowErrModal(true);
      return;
    }
    if (reason === "") {
      setErr("Please select reason");
      setShowErrModal(true);
      return;
    }

    if (status === "Approved" && feeType === "") {
      setErr("Please select fee type");
      setShowErrModal(true);
      return;
    }
    if (
      merchType === "merch" &&
      status === "Approved" &&
      feeAmount === "" &&
      feeType !== "no fee"
    ) {
      setErr("Please add fee amount");
      setShowErrModal(true);
      return;
    }

    if (status === "Approved" && contractFrom === null) {
      setErr("Please select contract from date");
      setShowErrModal(true);
      return;
    }
    if (status === "Approved" && contractTo === null) {
      setErr("Please select contract to date");
      setShowErrModal(true);
      return;
    }
    if (
      merchType === "merch" &&
      status === "Approved" &&
      paymentTerms === null
    ) {
      setErr("Please select payment terms");
      setShowErrModal(true);
      return;
    }
    if (status === "Approved" && defaultId === "") {
      setErr("Please select default payment method");
      setShowErrModal(true);
      return;
    }
    const amount = feeAmount.toString();
    const lowerLimit = parseInt(limit, 10);
    const body = {
      status: status,
      reason: reason,
      // paymentTerms: paymentTerms,
      lowLimit: lowerLimit,
      ip: "139.433.23.2",
      location: "394.43422323, 434.43423232",
    };

    if (merchType === "merch") {
      body.lowLimit = 0;
    }

    if (paymentTerms !== null) {
      body.paymentTerms = parseInt(paymentTerms);
    }
    if (feeType !== "" || feeType !== null) {
      body.feeType = feeType;
    }
    if (feeAmount !== "" || feeAmount !== null) {
      body.feeAmount = parseInt(amount);
    }

    if (feeType === "no fee") {
      body.feeAmount = 0;
    }
    if (contractFrom !== null) {
      body.contractFrom = contractFrom;
    }
    if (contractTo !== null) {
      body.contractTo = contractTo;
    }
    if (publicNote !== "") {
      body.publicNote = publicNote;
    }
    if (privateNote !== "") {
      body.privateNote = privateNote;
    }
    if (selectedPayment === "Bank") {
      body.defaultBankInfoId = defaultId;
    }
    if (selectedPayment === "Cheque") {
      body.defaultChequeInfo = defaultId;
    }
    if (selectedPayment === "SAP-Wallet") {
      body.defaultSapWalletInfo = defaultId;
    }
    body.status = status.toLowerCase();
    if (publicNote === null) {
      delete body.publicNote;
    }
    if (privateNote === null) {
      delete body.privateNote;
    }

    if (merchType === "gov") {
      body.paymentTerms = 0;
      // body.defaultBankInfoId = defaultId;
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/${merchantId}/status`,
        body,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then(() => {
        setShowSuccessModal(true);
        setErr("Status updated successfully");
        if (user === "superadmin") {
          router("/superAdminDashboard/Merchant/Listing");
          return;
        }
        if (user === "admin") {
          router("/adminDashboard/Merchant/Listing");
          return;
        }
        if (user === "employee") {
          router("/EDashboard/Merchant/Listing");
          return;
        }
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErrModal(true);
      });
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/list-merch-admins/for-mhsss?sortBy=createdAt:desc&merchheadquarterId=${merch?.merchHeadquarter?.id}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setContactPerson(data?.data?.rows);
        // data?.data?.map((contact, contactIndex) => {
        //   contact?.merchcontactpersondocs?.map((doc, docIndex) => {
        //     doc.images?.map((img, imageIndex) => {
        //       imageConverterContact(
        //         img,
        //         contactIndex,
        //         docIndex,
        //         imageIndex,
        //         "contactPerson"
        //       );
        //     });
        //   });
        // });
      })
      .catch(() => {});
  }, [merch]);

  const generateItems = (user, privilege) => {
    const baseItems = [
      {
        key: "1",
        label: "Company Details",
        component: <Company company={company} />,
        user: "merchant-owner",
      },
      {
        key: "3",
        label: "Owner Details",
        component: (
          <Owner
            data={merchantInfo?.merchPerson}
            identification={identification}
            mhhs={true}
          />
        ),
        user: "merchant-owner",
      },
      {
        key: "4",
        label: "Admin Details",
        component: (
          <ContactPerson
            data={merchantInfo?.merchPerson}
            contactPerson={contactPerson}
          />
        ),
        user: "merchant-owner",
      },
      {
        key: "5",
        label: "Headquarter",
        component: (
          <Headquarter
            headquarter={merchantInfo?.merchHeadquarter}
            mailAddress={
              merchantInfo?.merchHeadquarter?.merchheadquarterMailaddrs?.[0]
            }
            company={merchantInfo?.merchCompany}
          />
        ),
        user: "merchant-owner",
      },
      {
        key: "6",
        label: "Stores",
        component: <Stores stores={merchStores} />,
        user: "merchant-owner",
      },
      {
        key: "7",
        label: "Documents",
        component: <Documentation documentation={documentation} />,
      },
    ];

    // Check user privilege and include Financial Information tab if applicable
    if (
      (user === "employee" &&
        privilege?.includes("View merchant financial info")) ||
      user === "admin" ||
      user === "superadmin"
    ) {
      baseItems.push({
        key: "8",
        label: "Financial Info",
        component: <Financial />,
        user: "employee", // Set the user type for consistency
      });
    }

    return baseItems;
  };
  const generateItemsGov = (user, privilege) => {
    const baseItems = [
      {
        key: "1",
        label: "Company Details",
        component: <Company company={company} />,
        user: "merchant-owner",
      },
      {
        key: "3",
        label: "Director Details",
        component: (
          <Owner
            data={merchantInfo?.merchPerson}
            identification={identification}
            mhhs={true}
            gov={true}
          />
        ),
        user: "merchant-owner",
      },
      {
        key: "6",
        label: "Admin Details",
        component: (
          <ContactPerson
            data={merchantInfo?.merchPerson}
            contactPerson={contactPerson}
          />
        ),
        user: "merchant-owner",
      },

      {
        key: "5",
        label: "Headquarter",
        component: (
          <Headquarter
            headquarter={merchantInfo?.merchHeadquarter}
            mailAddress={
              merchantInfo?.merchHeadquarter?.merchheadquarterMailaddrs?.[0]
            }
            company={merchantInfo?.merchCompany}
          />
        ),
        user: "merchant-owner",
      },
      // {
      //   key: "6",
      //   label: "Stores",
      //   component: <Stores stores={merchStores} />,
      //   user: "merchant-owner",
      // },
      {
        key: "7",
        label: "Documents",
        component: <Documentation documentation={documentation} />,
      },
    ];

    // Check user privilege and include Financial Information tab if applicable
    if (
      (user === "employee" &&
        privilege?.includes("View merchant financial info")) ||
      user === "admin" ||
      user === "superadmin"
    ) {
      baseItems.push({
        key: "8",
        label: "Financial Info",
        component: <Financial />,
        user: "employee", // Set the user type for consistency
      });
    }

    return baseItems;
  };

  // Usage:
  const items =
    merchType === "merch"
      ? generateItems(user, privilege)
      : generateItemsGov(user, privilege);

  const DisplayDiv = ({ title, content }) => {
    return (
      <div className="py-1 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-10 sm:px-6">
        <dt className="text-sm font-medium text-gray-500 font-semibold mb-2 sm:mb-0">
          {title}
        </dt>
        <dd
          className={`mt-0 text-sm text-gray-800 sm:mt-0 sm:col-span-2 font-bold ${
            title === "Name" ? "capitalize" : ""
          }`}
        >
          {content}
        </dd>
      </div>
    );
  };

  const handlePerTransactionChange = (e) => {
    setSelectedOption(e.target.checked ? "Per Transaction" : "");
    setFeeType(e.target.checked ? "per transaction" : "");
  };

  const handlePerVoucherChange = (e) => {
    setSelectedOption(e.target.checked ? "Per Voucher" : "");
    setFeeType(e.target.checked ? "per voucher" : "");
  };

  const handleNoFeeChange = (e) => {
    setSelectedOption(e.target.checked ? "No Fee" : "");
    setFeeType(e.target.checked ? "no fee" : "");
  };
  const handlePaymentClick = (paymentType) => {
    setSelectedPayment(paymentType);
  };

  //Get Compnay
  useEffect(() => {
    // if (merchType === "merch") {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-company/for-mhsss?merchUserId=${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setCompany(data.data);
      })
      .catch((err) => {});
    // }
  }, []);

  //Get Stores
  useEffect(() => {
    // if (merchType === "merch") {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-store/for-mhsss`, {
        params: {
          limit: 3,
          skip: 0,
          page: storesCurrentPage,
          sortBy: "createdAt:desc",
          merchUserId: merchantId,
        },
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((data) => {
        setMerchStores(data?.data?.rows);
        setStoresTotalPages(data?.data?.totalPages);
      })
      .catch((err) => {});
    // }
  }, [storesCurrentPage]);

  //Get Contact Persons
  // useEffect(() => {
  //   if (merchType === "merch") {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_BASE_URL}/v1/merch-contact-person/for-mhsss`,
  //         {
  //           params: {
  //             limit: 3,
  //             skip: 0,
  //             page: contactPersonCurrentPage,
  //             sortBy: "createdAt:desc",
  //             merchUserId: merchantId,
  //           },
  //           headers: {
  //             Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
  //           },
  //         }
  //       )
  //       .then((data) => {
  //         setContactPersonTotalPages(data?.data?.totalPages);
  //         setContactPerson(data?.data?.rows);
  //         data?.data?.rows?.map((contact, contactIndex) => {
  //           contact?.merchcontactpersondocs?.map((doc, docIndex) => {
  //             doc.images?.map((img, imageIndex) => {
  //               imageConverterContactPerson(
  //                 img,
  //                 contactIndex,
  //                 docIndex,
  //                 imageIndex,
  //                 "contactPerson"
  //               );
  //             });
  //           });
  //         });
  //       })
  //       .catch((err) => {});
  //   }
  // }, [contactPersonCurrentPage]);

  //getBanking
  useEffect(() => {
    // if (merchType === "merch") {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-bank-info/for-mhsss?merchUserId=${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({
          type: "SELECTED_MERCHANT_FOR_APPROVAL_BANK_INFO",
          num: data.data,
        });
        // if (merchType === "gov") {
        //   setDefaulId(data?.data?.id);
        // }
        if (
          data?.data &&
          !paymentMethods.some((method) => method.paymentMethod === "Bank")
        ) {
          setPaymentMethods((prevPaymentMethods) => [
            ...prevPaymentMethods,
            {
              id: data?.data?.id,
              paymentMethod: "Bank",
            },
          ]);
        }
        // setBanking(data.data);
      })
      .catch((err) => {});
    // }
  }, []);

  //getCheque
  useEffect(() => {
    // if (merchType === "merch") {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-cheque-info/for-mhsss?merchUserId=${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({
          type: "SELECTED_MERCHANT_FOR_APPROVAL_CHEQUE_INFO",
          num: data.data,
        });
        if (
          data?.data &&
          !paymentMethods.some((method) => method.paymentMethod === "Cheque")
        ) {
          setPaymentMethods((prevPaymentMethods) => [
            ...prevPaymentMethods,
            {
              id: data?.data?.id,
              paymentMethod: "Cheque",
            },
          ]);
        }
        // setCheque(data.data);
      })
      .catch((err) => {});
    // }
  }, []);

  //getEwallet
  useEffect(() => {
    if (merchType === "merch") {
    if (merchantInfo?.merchHeadquarter) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/sap-wallet-info/by-merch-headquarter?merchheadquarterId=${merchantInfo?.merchHeadquarter?.id}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
            },
          }
        )
        .then((data) => {
          dispatch({
            type: "SELECTED_MERCHANT_FOR_APPROVAL_EWALLET_INFO",
            num: data.data,
          });

          if (
            data?.data &&
            !paymentMethods.some(
              (method) => method.paymentMethod === "SAP-Wallet"
            )
          ) {
            setPaymentMethods((prevPaymentMethods) => [
              ...prevPaymentMethods,
              {
                id: data?.data?.id,
                paymentMethod: "SAP-Wallet",
              },
            ]);
            // setEwallet(data.data);
          }
        })
        .catch((err) => {});
    }
    }
  }, [merchantInfo]);

  //Merch Profile
  useEffect(() => {
    // if (merchType === "merch") {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/${merchantId}/merch-profile/for-mhsss`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        if (data?.data?.merchUser?.status !== "pending") {
          merchantStatusInfo();
        }
        dispatch({ type: "SELECTED_MERCHANT_FOR_APPROVAL", num: data.data });
        setMerchantInfo(data.data);
      })
      .catch((err) => {});
    // }
  }, []);

  //get Status API
  const merchantStatusInfo = () => {
    // if (merchType === "merch") {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/${merchantId}/status`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        // console.log("status", data.data);
        const merStatus = data?.data?.toggle;

        const capitalizedStatus =
          merStatus.charAt(0).toUpperCase() + merStatus.slice(1);
        setStatus(capitalizedStatus);
        setPrivateNote(data?.data?.privateNote);
        setPublicNote(data?.data?.publicNote);
        setReason(data?.data?.reason);
        setFeeAmount(data?.data?.feeAmount);
        setFeeType(data?.data?.feeType);
        setPaymentTerms(data?.data?.paymentTerms);
        setContractFrom(data?.data?.contractFrom);
        setContractTo(data?.data?.contractTo);
        setLimit(data?.data?.lowLimit);
        setSelectedOption(
          data?.data?.feeType === "per transaction"
            ? "Per Transaction"
            : data?.data?.feeType === "per voucher"
            ? "Per Voucher"
            : "No Fee"
        );
        if (data?.data?.defaultBankInfoId) {
          setSelectedPayment("Bank");
          setDefaulId(data?.data?.defaultBankInfoId);
        }
        if (data?.data?.defaultChequeInfo) {
          setSelectedPayment("Cheque");
          setDefaulId(data?.data?.defaultChequeInfo);
        }
        if (data?.data?.defaultSapWalletInfo) {
          setSelectedPayment("SAP-Wallet");
          setDefaulId(data?.data?.defaultEwalletInfo);
        }
      })
      .catch((err) => {});
    // }
  };

  //Owner Identification Docs
  useEffect(() => {
    // if (merchType === "merch") {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-document/for-mhsss?merchUserId=${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        console.log("Step1");
        setIdentification(data.data);
        console.log("Step2");
        data?.data?.map((doc, docIndex) => {
          doc?.merchdocimages?.map((img, imageIndex) => {
            imageConverterContact(
              img?.imageUrl,
              docIndex,
              imageIndex,
              "merchantDocument"
            );
          });
        });
        console.log("Step3");
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
    // }
  }, []);

  //Compnay Docs
  useEffect(() => {
    // if (merchType === "merch") {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-company-doc/for-mhsss?merchUserId=${merchantId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setDocumentation(data.data);
        data?.data?.map((doc, docindex) => {
          doc?.merchcompanydocimgs?.map((img, index) => {
            imageConverter(img?.imageUrl, docindex, index, "documentation");
          });
        });
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
    // }
  }, []);

  const imageConverterContactPerson = (
    url,
    contactIndex,
    docIndex,
    imageIndex,
    type
  ) => {
    const api = "merchantIdentificationDocument";
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=merchantDocument`,
        {
          fileNames: [url],
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setContactPerson((prev) => {
          let newArr = [...prev];
          newArr[contactIndex].merchcontactpersondocs[docIndex].images[
            imageIndex
          ] = data?.data[0]?.url;
          return newArr;
        });
      })
      .catch((err) => {
        // Handle error
      });
  };

  const imageConverterContact = (url, docIndex, imageIndex, type) => {
    const api = "merchantIdentificationDocument";
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=merchantDocument `,
        {
          fileNames: [url],
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setIdentification((prev) => {
          let newArr = [...prev];
          newArr[docIndex].merchdocimages[imageIndex].url =
            data?.data?.[0]?.url;
          return newArr;
        });
      })
      .catch((err) => {
        console.error("Error fetching image URL:", err);
      });
  };

  // Image Urls
  const imageConverter = (url, docIndex, imageIndex, type) => {
    const api =
      type === "documentation"
        ? "merchantDocument"
        : "merchantIdentificationDocument";
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=${api}`,
        {
          fileNames: [url],
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setDocumentation((prev) => {
          let newArr = [...prev];
          newArr[docIndex].merchcompanydocimgs[imageIndex].imageUrl =
            data?.data?.[0];
          return newArr;
        });
      })
      .catch((err) => {
        // Handle error
      });
  };

  const pageHandler = (e, type) => {
    type === "stores"
      ? setStoresCurrentPage(e)
      : setContactPersonCurrentPage(e);
  };

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }

  const date = getCurrentDate();
  const disabledBeforeDate = moment(
    `${date.props.children[0]}-${date.props.children[2]}-${date.props.children[4]}`
  );
  const disabledDate = (current) => {
    return current && current < disabledBeforeDate;
  };

  return (
    <div className="w-full sm:px-8 px-4">
      <div className="py-3 w-full mt-[2rem] mb-20">
        {/* Profile */}
        <div className="relative w-full h-auto pb-[2rem] pl-[3rem] pr-[3rem] pt-[2rem] bg-[#D9EFED]">
          <div>
            <img
              src={circle}
              alt=""
              className="absolute sm:top-[-10%] sm:left-[-3%] sm:w-[10rem] sm:h-[10rem] sm:block hidden"
            />
            <img
              src={circle}
              alt=""
              className="absolute sm:hidden top-[-5%]  left-1/2 transform -translate-x-1/2 w-[8rem] h-[8rem]"
            />

            <img
              src={
                company?.logo
                  ? company.logo
                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw7Q28ZJ-jjpZ480zkqfXp_rtc2_gUsJ0LKA&s"
              }
              alt="profile"
              className="hidden sm:block absolute sm:top-[-10%] sm:left-[-3%] sm:w-[10rem] sm:h-[10rem] rounded-full p-2"
            />

            <img
              src={company?.logo}
              alt="profile"
              className="absolute sm:hidden rounded-full p-2 top-[-5%]  left-1/2 transform -translate-x-1/2 w-[8rem] h-[8rem]"
            />
            <div className="hidden sm:block absolute text-center sm:top-[9rem] top-[6rem]  sm:left-5 ">
              <h2 className="text-md font-semibold">
                {company !== null ? company?.name : "------"}
              </h2>
              {merchType === "gov" && (
                <h2 className="text-md font-semibold text-primary">
                  {"Government Agency"}
                </h2>
              )}

              <p
                className={`${
                  merchantInfo?.merchUser?.status === "rejected"
                    ? "text-red-600"
                    : merchantInfo?.merchUser?.status === "approved"
                    ? "text-primary"
                    : "text-blue-600"
                }  font-semibold text-md capitalize`}
              >
                {merchantInfo?.merchUser?.status}
              </p>
            </div>
            <div className="absolute sm:hidden rounded-full p-2 left-1/2 transform -translate-x-1/2 absolute text-center sm:top-[9rem] top-[6rem]  sm:left-5 ">
              <h2 className="text-md font-semibold">
                {company !== null ? company?.name : "------"}
              </h2>

              <p
                className={`${
                  merchantInfo?.merchUser?.status === "rejected"
                    ? "text-red-600"
                    : merchantInfo?.merchUser?.status === "approved"
                    ? "text-primary"
                    : "text-blue-600"
                }  font-semibold text-md capitalize`}
              >
                {merchantInfo?.merchUser?.status}
              </p>
            </div>
          </div>
          <div className="flex items-center sm:ml-32 sm:mt-0 mt-32">
            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-2">
              <div className="flex flex-col gap-2">
                <h3 className="text-lg font-semibold mb-2 text-primary">
                  Personal Info
                </h3>

                <Div
                  title="Name"
                  content={`${
                    merchantInfo?.merchPerson?.firstName !== null
                      ? merchantInfo?.merchPerson?.firstName
                      : "----"
                  } 
      ${
        merchantInfo?.merchPerson?.middleName !== null
          ? merchantInfo?.merchPerson?.middleName
          : ""
      } 
      ${
        merchantInfo?.merchPerson?.lastName !== null
          ? merchantInfo?.merchPerson?.lastName
          : ""
      }`}
                />
                <Div
                  title="Date of Birth"
                  content={merchantInfo?.merchPerson?.dateOfBirth}
                />
                <Div
                  title="Gender"
                  content={merchantInfo?.merchPerson?.gender}
                />
                <Div
                  title="Nationality"
                  content={merchantInfo?.merchPerson?.nationality}
                />
              </div>
              <div className="flex flex-col gap-2">
                <h3 className="text-lg font-semibold mb-2 text-primary">
                  Contact Info
                </h3>
                <Div title="Email" content={merchantInfo?.merchUser?.email} />
                <Div
                  title="Phone"
                  content={
                    merchantInfo?.merchUser?.countryCode !== null
                      ? merchantInfo?.merchUser?.countryCode +
                        " " +
                        merchantInfo?.merchUser?.phoneNo
                      : "-----"
                  }
                />
               
              </div>
            </div> */}
            {merchType === "merch" && (
              <div className="flex sm:gap-1 flex-col sm:flex-row">
                <div>
                  <h3 className="text-lg sm:font-semibold font-bold sm:mb-2 mb-1 text-primary sm:px-6">
                    Owner Info
                  </h3>
                  <DisplayDiv
                    title="Name"
                    content={
                      merchantInfo?.merchPerson
                        ? `${merchantInfo?.merchPerson?.firstName || "----"} 
      ${merchantInfo?.merchPerson?.middleName || ""} 
      ${merchantInfo?.merchPerson?.lastName || ""}`
                        : ""
                    }
                  />
                  <DisplayDiv
                    title="Date of Birth"
                    content={moment(
                      merchantInfo?.merchPerson?.dateOfBirth
                    ).format("MM/DD/YYYY")}
                  />
                  <DisplayDiv
                    title="Gender"
                    content={merchantInfo?.merchPerson?.gender}
                  />
                  <DisplayDiv
                    title="Nationality"
                    content={merchantInfo?.merchPerson?.nationality}
                  />
                </div>
                <div className="mt-3 sm:mt-0">
                  <h3 className="text-lg sm:font-semibold font-bold  sm:mb-2 mb-1 text-primary sm:px-6">
                    Contact Info
                  </h3>

                  <DisplayDiv
                    title="Email Address"
                    content={merchantInfo?.merchUser?.email}
                  />
                  <DisplayDiv
                    title="Phone No"
                    content={
                      merchantInfo?.merchUser?.countryCode &&
                      merchantInfo?.merchUser?.phoneNo
                        ? `${merchantInfo.merchUser.countryCode} ${merchantInfo.merchUser.phoneNo}`
                        : "-----"
                    }
                  />
                </div>
              </div>
            )}
            {merchType === "gov" && (
              <div className="flex sm:gap-1 flex-col sm:flex-row">
                <div>
                  <h3 className="text-lg sm:font-semibold font-bold sm:mb-2 mb-1 text-primary sm:px-6">
                    Director Info
                  </h3>
                  <DisplayDiv
                    title="Name"
                    content={
                      merchantInfo?.merchPerson
                        ? `${merchantInfo?.merchPerson?.firstName || "----"} 
      ${merchantInfo?.merchPerson?.middleName || ""} 
      ${merchantInfo?.merchPerson?.lastName || ""}`
                        : ""
                    }
                  />
                  <DisplayDiv
                    title="Date of Birth"
                    content={moment(
                      merchantInfo?.merchPerson?.dateOfBirth
                    ).format("MM/DD/YYYY")}
                  />
                  <DisplayDiv
                    title="Gender"
                    content={merchantInfo?.merchPerson?.gender}
                  />
                  <DisplayDiv
                    title="Nationality"
                    content={merchantInfo?.merchPerson?.nationality}
                  />
                  {/* <DisplayDiv title="GID" content={"007"} />
                  <DisplayDiv title="Location" content={"Ward 2, Region 6"} /> */}
                </div>
                <div
                  className={`${
                    contactPerson?.length === 0 ? "hidden" : "mt-3 sm:mt-0"
                  }`}
                >
                  <h3 className="text-lg sm:font-semibold font-bold  sm:mb-2 mb-1 text-primary sm:px-6">
                    Admin info
                  </h3>

                  <DisplayDiv
                    title="Name"
                    content={
                      contactPerson?.[0]?.merchperson?.firstName !== null ||
                      contactPerson?.[0]?.merchperson?.middleName !== null ||
                      contactPerson?.[0]?.merchperson?.lastName !== null
                        ? [
                            contactPerson?.[0]?.merchperson?.firstName,
                            contactPerson?.[0]?.merchperson?.middleName,
                            contactPerson?.[0]?.merchperson?.lastName,
                          ]
                            .filter(
                              (name) => name !== null && name !== undefined
                            )
                            .join(" ")
                        : ""
                    }
                  />

                  <DisplayDiv
                    title="Email"
                    content={
                      contactPerson?.[0]?.email !== null
                        ? contactPerson?.[0]?.email
                        : ""
                    }
                  />
                  <DisplayDiv
                    title="Phone Number"
                    content={
                      contactPerson?.[0]?.countryCode !== null
                        ? contactPerson?.[0]?.countryCode +
                          " " +
                          contactPerson?.[0]?.phoneNo
                        : ""
                    }
                  />
                  {/* <h1 className="font-semibold text-primary pl-4">View More</h1> */}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="sm:bg-[#D9EFED]">
          <div
            className="right-[11px] relative"
            style={{
              width: "10px",
              height: "10px",
              backgroundImage: `url(${poly})`,
              backgroundSize: "cover",
              backgroundPosition: "center 10%",
            }}
          ></div>
          <div
            className="right-[10px] relative bg-primary"
            style={{
              width: "100%",
              height: "2.9rem",
              backgroundImage: `url(${head})`,
              backgroundSize: "cover",
              backgroundPosition: "center 10%",
            }}
          >
            <h1 className="text-white text-[0.85rem]  sm:pl-[1rem]">
              <ul className="grid md:grid-cols-4 lg:grid-cols-7 grid-cols-2 text-center h-[2.9rem] ipad-pro:grid-cols-5">
                {items.map((item) => (
                  <li
                    onClick={() => {
                      setActive(item.key);
                    }}
                  >
                    <p
                      className={`${
                        active === item?.key
                          ? "bg-[#F6F4F4] text-primary font-semibold sm:mt-[0.2rem] rounded-tl-lg rounded-tr-lg border-l border-t border-r border-gray-100"
                          : "bg-primary text-white"
                      }flex justify-center cursor-pointer p-3 whitespace-nowrap`}
                    >
                      {item.label}
                    </p>
                  </li>
                ))}
              </ul>
            </h1>
          </div>
          <div className="bg-[#F6F4F4] shadow border border-gray-100 sm:p-6 p-1 text-gray-700 sm:mt-6 mt-36">
            {items.map((item) => (
              <div
                key={item.key}
                className={`${
                  active === item.key ? "block" : "hidden"
                } bg-[#F6F4F4]`}
              >
                {item.component}

                {item?.label === "Stores" && (
                  <div>
                    {merchStores?.length === 0 ? (
                      <p className="text-center text-[1.1rem]">
                        No Store Record Found
                      </p>
                    ) : (
                      <div>
                        <div className="w-full flex justify-center items-center mt-6">
                          <Pagination
                            defaultCurrent={1}
                            total={storesTotalPages * 10}
                            showSizeChanger={false}
                            onChange={(e) => {
                              pageHandler(e, "stores");
                            }}
                            current={storesCurrentPage}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {item?.label === "Contact Person" && (
                  <div>
                    {contactPerson?.length === 0 ? (
                      <p className="text-center text-[1.1rem]">
                        No Contact Person Record Found
                      </p>
                    ) : (
                      <div>
                        <div className="w-full flex justify-center items-center mt-6">
                          <Pagination
                            defaultCurrent={1}
                            total={contactPersonTotalPages * 10}
                            showSizeChanger={false}
                            onChange={(e) => {
                              pageHandler(e, "contactPerson");
                            }}
                            current={contactPersonCurrentPage}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {user === "employee" &&
        privilege &&
        !privilege?.includes("Change merchant status") ? null : (
          <div>
            <div
              className={`${
                merchantInfo?.merchUser?.status === "approved"
                  ? "pointer-events-none "
                  : ""
              }w-full md:w-[55%] flex flex-col gap-2`}
            >
              <Checkbox
                className={`checkk4 mt-6 ${
                  merchantInfo?.merchUser?.status === "approved"
                    ? "hidden "
                    : ""
                }`}
                checked={verify}
                onChange={(e) => {
                  setVerify(e.target.checked);
                }}
              >
                All the information related to Documents, Identification, and
                all other information has been verified.
              </Checkbox>
              <label className="font-semibold mt-4">
                Status <span className="text-red-600">*</span>
              </label>
              <div className="w-full sm:w-[40%]  md:w-[40%] ">
                <Select
                  placeholder="Pending"
                  optionFilterProp="children"
                  className="wow text-primary"
                  style={{ marginBottom: "1.2rem" }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  value={status}
                  onChange={(e) => setStatus(e)}
                  size="middle"
                  options={[
                    // {
                    //   labe: "Pending",
                    //   value: "Pending",
                    // },
                    {
                      labe: "Approved",
                      value: "Approved",
                    },
                    {
                      labe: "Rejected",
                      value: "Rejected",
                    },
                    {
                      label: "Deferred",
                      value: "Deferred",
                    },
                  ]}
                />
              </div>
              <label className="font-semibold">
                Reason <span className="text-red-600">*</span>
              </label>
              <textarea
                type="text"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="Type Note Here"
                rows={3}
                className="lg:text-[1.1rem] mb-3 w-[100%] bg-[#F6F4F4] border-none rounded-[0.3rem] mt-1 p-3"
              />
              <label className="font-semibold">
                Public Note <span className="text-gray-400">(if any)</span>
              </label>
              <textarea
                type="text"
                value={publicNote}
                onChange={(e) => setPublicNote(e.target.value)}
                placeholder="Type Note Here"
                rows={4}
                className="lg:text-[1.1rem] mb-3 w-[100%] bg-[#F6F4F4] border-none rounded-[0.3rem] mt-1 p-3"
              />
              {/* <Select
                placeholder="Select Reason"
                showSearch
                // onChange={changeHandler}
                // value={status1}
                value={reason}
                onChange={(e) => setReason(e)}
                optionFilterProp="children"
                className="wow10 mb-3"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                options={[
                  {
                    label: "Pending",
                    value: "Pending",
                  },
                  {
                    label: "Approved",
                    value: "approved",
                  },
                  {
                    label: "Rejected",
                    value: "rejected",
                  },
                ]}
              /> */}
              <label className="font-semibold">
                Private Note <span className="text-gray-400">(if any)</span>
              </label>
              <textarea
                type="text"
                value={privateNote}
                onChange={(e) => setPrivateNote(e.target.value)}
                placeholder="Type Note Here"
                rows={4}
                className="lg:text-[1.1rem] mb-3 w-[100%] bg-[#F6F4F4] border-none rounded-[0.3rem] mt-1 p-3"
              />
            </div>
            <div
              className={`${
                merchantInfo?.merchUser?.status === "approved"
                  ? "pointer-events-none "
                  : ""
              }w-full flex flex-col gap-4`}
            >
              <div className="w-full rounded-lg flex flex-col gap-6 p-4 bg-[#D9EFED]">
                <div className="flex flex-col gap-1">
                  <div>
                    <h1 className="font-semibold mb-2">Contract Period</h1>
                    <div className="w-full md:w-[40%] pl-6 pr-6 pb-4 pt-4 bg-white rounded-lg">
                      <div className="w-full flex gap-6 mt-3 ">
                        <div>
                          <h1 className="font-semibold">Contract From :</h1>
                          <DatePicker
                            className="w-full"
                            placeholder="Contract From"
                            value={
                              contractFrom
                                ? days(contractFrom, "YYYY-MM-DD")
                                : null
                            }
                            onChange={(e, f) => setContractFrom(f)}
                            disabledDate={disabledDate}
                          />
                        </div>
                        <div>
                          <h1 className="font-semibold">Contract To :</h1>

                          <DatePicker
                            className="w-full"
                            placeholder="Contract To"
                            value={
                              contractTo ? days(contractTo, "YYYY-MM-DD") : null
                            }
                            onChange={(e, f) => setContractTo(f)}
                            disabledDate={disabledDate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h1 className="font-semibold mb-2 mt-2">
                      Voucher Cashing Charges
                    </h1>
                  </div>
                  <div className="w-full md:w-[40%] pl-6 pr-6 pb-4 pt-4 bg-white rounded-lg">
                    <div className="w-full flex gap-6 mt-5">
                      <Checkbox
                        className="checkk4 "
                        checked={selectedOption === "Per Transaction"}
                        onChange={handlePerTransactionChange}
                      >
                        Per Transaction
                      </Checkbox>
                      <Checkbox
                        className="checkk4 "
                        checked={selectedOption === "Per Voucher"}
                        onChange={handlePerVoucherChange}
                      >
                        Per Voucher
                      </Checkbox>
                      <Checkbox
                        className={`${
                          merchType === "merch" ? "hidden" : "checkk4"
                        }  `}
                        checked={selectedOption === "No Fee"}
                        onChange={handleNoFeeChange}
                      >
                        No Fee
                      </Checkbox>
                    </div>

                    <div
                      className={`${
                        feeType === "no fee"
                          ? "hidden"
                          : "flex items-center gap-x-4 mt-5"
                      }`}
                    >
                      <h1 className="font-semibold whitespace-nowrap">
                        Amount :
                      </h1>
                      <input
                        type="text"
                        value={`${feeAmount}$`}
                        className="w-[7rem] h-[2rem] pl-4 pr-4 rounded-lg bg-[#F6F4F4]"
                      />
                      <div className="flex gap-2">
                        <button
                          onClick={() => {
                            if (feeAmount > 100) setFeeAmount(feeAmount - 25);
                          }}
                          className="bg-[#F6F4F4] text-primary border font-bold h-6 w-6 flex items-center justify-center rounded-full focus:outline-none"
                        >
                          -
                        </button>
                        <button
                          onClick={() => {
                            if (feeAmount < 300) {
                              setFeeAmount(feeAmount + 25);
                            }
                          }}
                          className="bg-primary text-white font-bold h-6 w-6 flex items-center justify-center rounded-full focus:outline-none"
                        >
                          +
                        </button>
                      </div>

                      {/* <select
                        value={feeAmount}
                        onChange={(e) => setFeeAmount(e.target.value)}
                        className="w-[7rem] h-[2rem] pl-4 pr-4 rounded-lg bg-gray-200 "
                      >
                        <option value="" selected hidden>
                          Amount
                        </option>
                        <option value="50">$50</option>
                        <option value="100">$100</option>
                        <option value="500">$500</option>
                        <option value="1000">$1000</option>
                        <option value="5000">$5000</option>
                      </select> */}
                    </div>
                  </div>
                </div>
                {/* {merchType === "merch" && ( */}
                  <div className="flex flex-col gap-1">
                    <div>
                      <h1 className="font-semibold">Payment Method</h1>
                    </div>
                    <div className=" w-full md:w-[40%] h-[5rem] bg-white rounded-lg gap-6 flex items-center p-4">
                      {paymentMethods &&
                        paymentMethods.map((method) => (
                          <button
                            className={`bg-${
                              selectedPayment === method?.paymentMethod
                                ? "[#1BA397] text-white"
                                : "[#F6F4F4]"
                            } rounded-full sm:px-6 px-3  py-1 font-semibold shadow-sm `}
                            onClick={() => {
                              handlePaymentClick(method.paymentMethod);
                              setDefaulId(method.id);
                            }}
                          >
                            {method.paymentMethod}
                          </button>
                        ))}
                    </div>
                  </div>
                {/* )} */}
                {merchType === "merch" && (
                  <div className="flex items-center gap-x-4">
                    <h1 className="font-semibold">Payment Terms :</h1>
                    <select
                      value={paymentTerms}
                      onChange={(e) => setPaymentTerms(e.target.value)}
                      className="w-[7rem] h-[2rem] pl-4 pr-4 rounded-lg bg-white "
                    >
                      <option value="" selected hidden>
                        Payment
                      </option>
                      <option value={10}>Net 10</option>
                      <option value={15}>Net 15</option>
                      <option value={30}>Net 30</option>
                      <option value={45}>Net 45</option>
                      <option value={60}>Net 60</option>
                      <option value={90}>Net 90</option>
                    </select>
                  </div>
                )}
                {merchType === "gov" && (
                  <div className="flex items-center gap-x-4">
                    <h1 className="font-semibold">Low Limit :</h1>
                    <input
                      type="text"
                      placeholder="Low Limit"
                      value={limit}
                      onChange={(e) => setLimit(e.target.value)}
                      className="w-[7rem] h-[2rem] pl-4 pr-4 rounded-lg bg-white "
                    />
                  </div>
                )}
              </div>

              <div
                className={`${
                  dash === "/MHSSS/Merchant/Profile" ||
                  merchantInfo?.merchUser?.status === "approved"
                    ? "hidden"
                    : "flex justify-start"
                }`}
              >
                <button
                  onClick={ChangeStatus}
                  className={` relative lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white ml-2 rounded-[0.5rem] p-2  pr-8 pl-8`}
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {showErrModal && <ErrorModal setShow={setShowErrModal} err={err} />}
      {showSuccessModal && (
        <SuccessModal setShow={setShowSuccessModal} err={err} />
      )}
    </div>
  );
};

export default About;

import Nav from "../components/Nav/nav";
import back5 from "../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Select } from "antd";
import axios from "axios";
import across from "../imgs/across.png";
import pdf from "../imgs/pdf.png";
import Whatsapp from "../Whatsapp/whatsapp";
const DistriutionsPage = () => {
  const user = useSelector((state) => state.user);
  const privilege = useSelector((state) => state.myPrivileges);
  const router = useNavigate();
  const universalPenId = useSelector((state) => state.universalPenId);
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const [test, setTest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [allData, setAllData] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [err, setErr] = useState("");
  const [show1, setShow1] = useState(false);
  const [regionName, setRegionName] = useState("");
  const [regionNo, setRegionNo] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationCode, setLocationCode] = useState("");
  const [centerId, setCenterId] = useState("");
  const [centerName, setCenterName] = useState("");
  const [coverage, setCoverage] = useState(null);
  const [already, setAlready] = useState(false);
  const [distributionlocationaddressId, setDistributionlocationId] =
    useState("");
  useEffect(() => {
    return () => {
      // setErr2("");
    };
  }, []);
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [items]);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/distribution-info?pensionerUserId=${universalPenId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setAlready(true);
        setCoverage(data.data.distributioncoverage.coverage);
        setRegionName(
          data.data.distributioncoverage.distributioncenter.distributionlocation
            .distributionregion.regionName
        );
        setRegionNo(
          data.data.distributioncoverage.distributioncenter.distributionlocation
            .distributionregion.regionNo
        );
        setLocationName(
          data.data.distributioncoverage.distributioncenter.distributionlocation
            .locationName
        );
        setLocationCode(
          data.data.distributioncoverage.distributioncenter.distributionlocation
            .locationCode
        );
        setCenterName(
          data.data.distributioncoverage.distributioncenter.centerName
        );
        // setCoverageIdR(data.data.distributioncoverage.coverage);
        setCenterId(
          data.data.distributioncoverage.distributioncenter.centerCode
        );
        // setErr2("done");
      })
      .catch((err) => {
        if (err.response.data.message === "pensionerInfo not found") {
          setErr(err.response.data.message);
          setShow1(true);
        }
        // setErr2(err.response.data.message);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/distribution-regions`)
      .then(() => {
        // setAllRData(data.data);
        // const wow = data.data.map((item) => {
        //   return {
        //     label: `${item.regionNo}`,
        //     value: `${item.regionNo}`,
        //   };
        // });
        // setAllRegions(wow);
      })
      .catch(() => {});
  }, []);

  const updateHandler = (e) => {
    e.preventDefault();
    if (selectedData?.length === 0) {
      setErr("Please select a coverage");
      setShow1(true);
    } else {
      setIsLoading(true);

      if (already) {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/v1/distribution-info?pensionerUserId=${universalPenId}`,
            {
              distributioncoverageId: `${distributionlocationaddressId}`,
              consumerInfo: {
                ip: "d",
                location: "d",
              },
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            setIsLoading(false);
            user === "employee"
              ? router("/EDashboard/profPage")
              : user === "admin"
              ? router("/AdminDashboard/profPage")
              : user === "superadmin"
              ? router("/superAdminDashboard/profPage")
              : router("/EDashboard/profPage");
          })
          .catch((err) => {
            setErr(err.response.data.message);
            setShow1(true);
            setIsLoading(false);
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/distribution-info?pensionerUserId=${universalPenId}`,
            {
              distributioncoverageId: `${distributionlocationaddressId}`,
              consumerInfo: {
                ip: "d",
                location: "d",
              },
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            setIsLoading(false);
            user === "employee"
              ? router("/EDashboard/profPage")
              : user === "admin"
              ? router("/AdminDashboard/profPage")
              : user === "superadmin"
              ? router("/superAdminDashboard/profPage")
              : router("/EDashboard/profPage");

            // router("/EDashboard/Pensioner_HistoryP");
          })
          .catch((err) => {
            setErr(err.response.data.message);
            setShow1(true);
            setIsLoading(false);
          });
      }
    }
  };

  let searchTimeout;

  const handleSearch = (query) => {
    // Clear previous timeouts to ensure only one request is sent
    clearTimeout(searchTimeout);

    // Check if the query length is greater than or equal to 3
    if (query.length >= 3) {
      // Set a timeout to delay the API call
      searchTimeout = setTimeout(() => {
        // Make the API call
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/v1/distribution-coverages/search?search=${query}`
          )
          .then((data) => {
            let wow = [];

            // Inserting a default option at the beginning
            if (data?.data?.rows?.length !== 0) {
              wow.push({
                value: 0,
                label: "Select coverage", // Adjust the label as needed
              });
            }

            data.data.rows.map((ww, index) => {
              wow.push({
                value: ww?.id, // Adjust the index to start from 1
                label: `${ww.coverage}`,
              });
            });

            setItems(wow);
            setAllData(data.data.rows);
          })
          .catch(() => {
            // Handle error
          });
      }, 500); // 500 milliseconds delay (adjust as needed)
    }
  };

  // Call handleSearch on input change

  return (
    <div>
      <Nav />
      <div className="flex items-center justify-center flex-col">
        <div className="relative flex justify-between md:w-[75%] p-4 w-full sm:mt-[5rem] mt-[2rem]">
          <div className="flex items-center">
            <div
              onClick={() => {
                user === "admin"
                  ? router("/AdminDashboard/profPage")
                  : user === "superadmin"
                  ? router("/superAdminDashboard/profPage")
                  : router("/EDashboard/profPage");
                // router("/EDashboard/profPage");
              }}
              // className="absolute top-[2.8] cursor-pointer sm:left-[20%] left-[3%]"
            >
              <img className="sm:w-[1.7rem] w-[1.2rem]" src={back5} alt="" />
            </div>
            <h2 className="font-bold ml-[1rem] sm:text-[1.8rem] text-[1.4rem]">
              Distribution Details
            </h2>
          </div>
          {/* <a
            href="https://sap-api-staging.jabcomp.gy/public/office-distribution-location-list-v18.pdf"
            target="_blank"
            className="cursor-pointer"
            rel="noreferrer"
          >
            <img className="sm:w-[3rem] w-[2rem]" src={pdf} alt="" />
          </a> */}
          <div
            className="cursor-pointer absolute top-4 right-3 flex items-center gap-2"
            onClick={() => {
              router("/pensionerVerification/searchDistribution");
            }}
          >
            <p className="text-primary font-semibold text-[0.95rem]">Find Coverages</p>
            <p className="text-white font-semibold bg-primary w-4 h-4 flex items-center justify-center rounded-full p-2 ">
              i
            </p>
          </div>
        </div>
        <div
          className={`flex justify-center items-center mt-[3rem] md:w-[75%] w-full mb-[5rem] ${
            profileData.user.isDisabled ? "pointer-events-none" : ""
          }`}
        >
          <form
            onSubmit={updateHandler}
            className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 p-4 w-full"
          >
            <div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Coverage{" "}
                  <span className="text-red-600">
                    ( Insert atleast 3 letters to search )
                  </span>
                </label>
                <Select
                  placeholder="Select Coverage"
                  onChange={(e, w) => {
                    if (e) {
                      const wow = allData.filter((item) => {
                        return item?.id === e;
                      });

                      setSelectedData(wow);
                      setCoverage(wow[0].coverage);
                      setRegionNo(
                        wow[0].distributioncenter.distributionlocation
                          .distributionregion.regionNo
                      );
                      setRegionName(
                        wow[0].distributioncenter.distributionlocation
                          .distributionregion.regionName
                      );
                      setLocationCode(
                        wow[0].distributioncenter.distributionlocation
                          .locationCode
                      );
                      setLocationName(
                        wow[0].distributioncenter.distributionlocation
                          .locationName
                      );
                      setCenterId(wow[0].distributioncenter.centerCode);
                      setCenterName(wow[0].distributioncenter.centerName);
                      setDistributionlocationId(wow[0].id);
                    }
                  }}
                  allowClear
                  onClear={() => {
                    setItems([]);
                    setCoverage(null);
                    setSelectedData([]);
                    setRegionNo("");
                    setRegionName("");
                    setLocationCode("");
                    setLocationName("");
                    setCenterId("");
                    setCenterName("");
                  }}
                  onSearch={(e) => handleSearch(e)}
                  value={coverage}
                  showSearch
                  optionFilterProp="children"
                  style={{ marginBottom: "0.5rem" }}
                  className="wow"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={items}
                />
                {/* <span className="text-red-600">Note : Insert atleast 3 letters to search coverage</span>  */}

                <label className="text-[#596F96] mt-[1rem] text-[0.88rem] font-semibold mb-2">
                  Region No
                </label>
                <input
                  type="text"
                  name=""
                  value={regionNo}
                  placeholder="Region No"
                  className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Region Name
                </label>
                <input
                  type="text"
                  name=""
                  value={regionName}
                  placeholder="Region Name"
                  className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  District Code
                </label>
                <input
                  type="text"
                  name=""
                  value={locationCode}
                  placeholder="District Code"
                  className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  District Name
                </label>
                <input
                  type="text"
                  name=""
                  value={locationName}
                  placeholder="District Name"
                  className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                />
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Center Code
                </label>
                <input
                  type="text"
                  name=""
                  value={centerId}
                  placeholder=" Center ID"
                  className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Center Name
                </label>
                <input
                  type="text"
                  name=""
                  placeholder=" Center Name"
                  value={centerName}
                  className={`  lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                />
              </div>
            </div>
            {user === "employee" &&
            privilege &&
            privilege?.includes("View distribution") &&
            (privilege?.includes("Edit distribution") ||
              (privilege && privilege?.includes("Add distribution"))) ? (
              <div
              // className={`${
              //   err2 === "distributionInfo not found" ? "" : "hidden"
              // }`}
              >
                <button
                  type="submit"
                  className={`relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
                >
                  {/* <Spin
                    style={{ display: `${isLoading ? "" : "none"}` }}
                    indicator={antIcon}
                  /> */}
                  <span className={`${isLoading ? "ml-3" : ""}`}>Save</span>
                  <div
                    className={`w-[100%] h-[100%] ${
                      isLoading ? "" : "hidden"
                    } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
                  ></div>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // setRegionId("");
                    setRegionName("");
                    // setAllLData("");
                    // setAllLocationCodes("");
                    setLocationName("");
                    // setLocationId("");
                    setCenterId("");
                    // setCoverageIdR("");
                    setCoverage(null);
                    setCenterName("");
                    setRegionNo("");
                    setLocationCode("");
                  }}
                  className={`lg:text-[1.1rem] bg-[#F2F4F8] text-black rounded-[0.5rem] p-2  pr-8 pl-8 ml-4`}
                >
                  Cancel
                </button>
              </div>
            ) : user !== "employee" ? (
              <div
              // className={`${
              //   err2 === "distributionInfo not found" ? "" : "hidden"
              // }`}
              >
                <button
                  type="submit"
                  className={`relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
                >
                  {/* <Spin
                    style={{ display: `${isLoading ? "" : "none"}` }}
                    indicator={antIcon}
                  /> */}
                  <span className={`${isLoading ? "ml-3" : ""}`}>Save</span>
                  <div
                    className={`w-[100%] h-[100%] ${
                      isLoading ? "" : "hidden"
                    } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
                  ></div>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // setRegionId("");
                    setRegionName("");
                    // setAllLData("");
                    // setAllLocationCodes("");
                    setLocationName("");
                    // setLocationId("");
                    setCenterId("");
                    // setCoverageIdR("");
                    setCoverage(null);
                    setCenterName("");
                    setRegionNo("");
                    setLocationCode("");
                  }}
                  className={`lg:text-[1.1rem] bg-[#F2F4F8] text-black rounded-[0.5rem] p-2  pr-8 pl-8 ml-4`}
                >
                  Cancel
                </button>
              </div>
            ) : null}
          </form>
          <div
            className={`${
              show1 ? "" : "hidden"
            } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
          >
            <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
              <div
                className="absolute top-[-11%] left-[42%]"
                onClick={() => setShow1(false)}
              >
                <img src={across} alt="" />
              </div>
              <p className="text-center mt-[3rem] mb-5">{err}</p>
              <button
                onClick={() => setShow1(false)}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
        <Whatsapp />
      </div>
    </div>
  );
};

export default DistriutionsPage;

import { useEffect, useState } from "react";
import Third from "./stepsR/ThirdR";
import Fifth from "./stepsR/FifthR";
import EmployeeInfo from "../Employee/employeeInfo";
import { useSelector } from "react-redux";
import FirstR from "./stepsR/FirstR";
import SecondR from "./stepsR/SecondR";
import ThirdR from "./stepsR/ThirdR";
import Mid from "./stepsR/mid";
import Identification from "../Identification/stepsI/identification";
import ThirdI from "../Identification/stepsI/ThirdI";
import FourthI from "../Identification/stepsI/FourthI";
import AddBenificiary from "../Beneficiary/AddBenificiary";
import FirstB from "../Beneficiary/stepsBR/FirstBR";
import FifthB from "../Beneficiary/stepsBR/FifthBR";
import Application from "../Application/application";
import Awaited from "../Awaited/Awaited";
import FourthR from "./stepsR/FourthR";
import Banking1 from "../Identification/stepsI/Banking";
import FirstStep from "../Merchant/Register/FirstStep";
import ThirdStep from "../Merchant/Register/ThirdStep";
import AdminStep from "../Merchant/Register/addAdmin";
import FourthStep from "../Merchant/Register/FourthStep";
import SecondStep from "../Merchant/Register/SecondStep";
import FifthStep from "../Merchant/Register/FifthStep";
import SixthStep from "../Merchant/Register/SixthStep";
import SeventhStep from "../Merchant/Register/SeventhStep";
import EigthStep from "../Merchant/Register/EigthStep";
import NinthStep from "../Merchant/Register/NinthStep";
import TenthStep from "../Merchant/Register/TenthStep";

//Vendor Steps
import Vendorfirst from "../inventory/vendor/register/steps/firstStepV";
import Vendorsecond from "../inventory/vendor/register/steps/secondStep";
import Vendorthird from "../inventory/vendor/register/steps/thirdStep";
import Vendorfourth from "../inventory/vendor/register/steps/fourthStep";
import Vendorfifth from "../inventory/vendor/register/steps/fifthStep";
import Vendorsixth from "../inventory/vendor/register/steps/sixthStep";
import BranchStep from "../inventory/vendor/register/steps/branchStep";
let Show;
Show = [
  FirstR,
  SecondR,
  Mid,
  Third,
  Fifth,
  Identification,
  ThirdI,
  FourthI,
  Banking1,
  AddBenificiary,
  FirstB,
  Mid,
  ThirdR,
  Fifth,
  Identification,
  ThirdI,
  FifthB,
  Application,
  Awaited,
];
const Register = () => {
  const option = useSelector((state) => state.option);
  console.log(option);
  const isGovernmentAgency = useSelector((state) => state?.isGovernmentAgency);
  useEffect(() => {
    if (option === "Pensioner") {
      Show = [
        FirstR,
        SecondR,
        Mid,
        Third,
        Fifth,
        Identification,
        ThirdI,
        FourthI,
        Banking1,
        AddBenificiary,
        FirstB,
        Mid,
        ThirdR,
        Fifth,
        Identification,
        ThirdI,
        FifthB,
        Application,
        Awaited,
      ];
    } else if (option === "Employee") {
      Show = [
        FirstR,
        SecondR,
        Mid,
        FourthR,
        EmployeeInfo,
        Identification,
        ThirdI,
        Awaited,
      ];
    } else if (option === "Merchant") {
      Show = [
        FirstR,
        FirstStep,
        SecondStep,
        ThirdStep,
        FourthStep,
        FifthStep,
        AdminStep,
        SixthStep,
        SeventhStep,
        EigthStep,
        NinthStep,
        TenthStep,
      ];
    } else if (option === "Vendor") {
      Show = [
        Vendorfirst,
        Vendorsecond,
        Vendorthird,
        Vendorfourth,
        BranchStep,
        Vendorfifth,
        Vendorsixth,
      ];
    }
  }, []);
  const [word, setWord] = useState("Your Registration");
  const [mainWord, setMainWord] = useState(
    "The Ministry of Human Services and Social Security offers this free online service to Guyanese nationwide that are sixty-five (65) years plus and living in The Country."
  );
  const [test, setTest] = useState(false);
  const icurrent = useSelector((state) => state.icurrent);
  const acurrent = useSelector((state) => state.acurrent);
  const Dis = Show[icurrent];
  useEffect(() => {
    if (option === "Vendor") {
      // setMainWord(
      //   "The Ministry of Human Services and Social Security offers Merchant registration on cashing vouchers for the pensioners upon approval. Please complete all details below and submit."
      // );
      setMainWord("");
      if (icurrent === 1) {
        setWord("Owner Details");
      } else if (icurrent === 2) {
        setWord("Company Details");
      } else if (icurrent === 3) {
        setWord("Company Address");
      } else if (icurrent === 4) {
        setWord("Branch Detail");
      } else if (icurrent === 5) {
        setWord("Business Documents");
      } else if (icurrent === 6) {
        setWord("Owner Identification");
        // setMainWord(
        //   "Please enter your administrative information, if applicable, So that MHSSS can contact you. Otherwise, click the skip option, and we will forward your contact information to MHSSS for any questions."
        // );
      } else if (icurrent === 7) {
        setWord("Request Sent");
        setMainWord(
          "Your account is sent to MHSSS for approval. It might take some time for the management to go through all the steps of reviewing your account. Thank you for your patience."
        );
      }
    } else if (option === "Merchant") {
      setMainWord(
        "The Ministry of Human Services and Social Security offers Merchant registration on cashing vouchers for the pensioners upon approval. Please complete all details below and submit."
      );
      if (icurrent === 1) {
        setWord("Your Registration");
      } else if (icurrent === 2) {
        setWord("Company Details");
      } else if (icurrent === 3) {
        isGovernmentAgency
          ? setWord("Director Details")
          : setWord("Owner Details");
      } else if (icurrent === 4) {
        setWord("Head Quarter");
      } else if (icurrent === 5) {
        setWord("Stores");
      } else if (icurrent === 6) {
        setWord("Admin Details");
        setMainWord(
          "Please enter your administrative information, if applicable, So that MHSSS can contact you. Otherwise, click the skip option, and we will forward your contact information to MHSSS for any questions."
        );
      } else if (icurrent === 7) {
        setWord("Documents");
      } else if (icurrent === 8) {
        setWord("Identification Documents");
      } else if (icurrent === 9) {
        setMainWord(
          "The Ministry of Human Services and Social Security offers this free online service to the merchants within The Country."
        );
        setWord("Financial Information for HQ");
      } else if (icurrent === 10) {
        setMainWord(
          "The Ministry of Human Services and Social Security will inform you upon approval, addtional information request or rejected within 30 days."
        );
        setWord("Confirmation");
      } else if (icurrent === 11) {
        setWord("Request Sent");
        setMainWord(
          "Your account is sent to MHSSS for approval. It might take some time for the management to go through all the steps of reviewing your account. Thank you for your patience."
        );
      }
    } else {
      if (icurrent > 4 && icurrent <= 6) {
        setWord("Your Identification");
      } else if (icurrent === 7) {
        setWord("SCP Details");
        if (option !== "Pensioner") {
          setWord("Request Sent");
          setMainWord(
            " Your Employee account is setup and details forwarded to MHSSS administrator for review and approval. It might take some time for management to go through all the steps of your profile. Thanks for your patience and cooperation."
          );
        }
      } else if (icurrent === 8) {
        setWord("SCP Financial");
      } else if (icurrent === 9) {
        setWord("Add Beneficiary");
      } else if (icurrent > 9 && icurrent < 14) {
        setWord("Beneficiary Registration");
      } else if (icurrent >= 14 && icurrent < 16) {
        setWord("Beneficiary Identification");
      } else if (icurrent === 18) {
        setWord("Request Sent");
        if (option === "Pensioner") {
          setMainWord(
            "Your account is sent to MHSSS for approval. It might take some time for the management to go through all the steps of reviewing your account. Thank you for your patience."
          );
        }
      } else if (icurrent === 18) {
        setWord("Witnesses");
      } else if (icurrent === 17 && acurrent !== 6) {
        setWord("Your Application");
      } else if (icurrent === 17 && acurrent === 6) {
        setWord("SCP Financial");
      } else {
        setWord("Your Registration");
      }
    }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [icurrent, acurrent]);
  return (
    <div>
      <div
        className={`${
          option === "Pensioner" ? "" : "hidden"
        } w-[100%] sm:h-[3.8rem] h-[3rem] flex bg-[#F2F4F8] text-[0.95rem] rounded-tl-2xl rounded-tr-2xl`}
      >
        <div
          className={`flex ${
            icurrent < 9 ? "bg-gradient-to-tr bg-primary" : ""
          } rounded-tl-[2.8rem] w-[33%] items-center justify-center rounded-tr-[5rem] rounded-br-[5rem]`}
        >
          <div
            className={`w-5 sm:flex hidden h-5 ${
              icurrent < 9 ? "bg-white" : "bg-[#898989]"
            }   rounded-full flex justify-center items-center`}
          >
            <p
              className={` ${
                icurrent < 9 ? "text-[lightblue]" : "text-white"
              } text-[0.7rem]`}
            >
              1
            </p>
          </div>
          <p
            className={`ml-2 sm:text-[1rem] text-[0.85rem] ${
              icurrent < 9 ? "text-white" : "text-[#898989]"
            }  `}
          >
            {icurrent > 4 ? "Identification" : "Registration"}
          </p>
        </div>
        <div
          className={`${
            icurrent >= 9 && icurrent < 17 ? "bg-gradient-to-tr bg-primary" : ""
          } flex w-[33%] rounded-[5rem] items-center justify-center`}
        >
          <div
            className={`w-5 sm:flex hidden h-5 ${
              icurrent >= 9 && icurrent < 17 ? "bg-white" : "  bg-[#898989]"
            }  rounded-full flex justify-center items-center`}
          >
            <p
              className={`text-[0.7rem] ${
                icurrent >= 9 && icurrent < 17
                  ? "text-[lightblue] "
                  : " text-white"
              } `}
            >
              2
            </p>
          </div>
          <p
            className={`ml-2 sm:text-[1rem] text-[0.85rem] ${
              icurrent >= 9 && icurrent < 17 ? "text-white" : "text-[#898989]"
            } `}
          >
            Beneficiary
          </p>
        </div>
        <div
          className={`flex ${
            icurrent === 17 ? "bg-gradient-to-tr bg-primary" : ""
          } w-[34%] rounded-tl-[5rem] rounded-bl-[5rem] items-center justify-center rounded-tr-[2.9rem]`}
        >
          <div
            className={`w-5 sm:flex hidden h-5  ${
              icurrent === 17 ? "bg-white" : "bg-[#898989]"
            } rounded-full flex justify-center items-center`}
          >
            <p
              className={` ${
                icurrent === 17 ? "text-[lightblue]" : "text-white"
              } text-[0.7rem]`}
            >
              3
            </p>
          </div>
          <p
            className={`ml-2 sm:text-[1rem] text-[0.85rem] ${
              icurrent === 17 ? "text-white" : "text-[#898989]"
            } `}
          >
            Application
          </p>
        </div>
      </div>
      <div
        className={`${
          option === "Employee" ? "" : "hidden"
        } w-[100%] h-[3.8rem] flex bg-[#F2F4F8] text-[0.95rem] rounded-tl-2xl rounded-tr-2xl`}
      >
        <div
          className={`flex ${
            icurrent <= 4 ? "bg-gradient-to-tr bg-primary " : ""
          } rounded-tl-[2.8rem] w-[50%] items-center justify-center rounded-tr-[5rem] rounded-br-[5rem]`}
        >
          <div
            className={`w-5 h-5 ${
              icurrent <= 4 ? "bg-white" : "bg-[#898989]"
            }   rounded-full flex justify-center items-center`}
          >
            <p
              className={` ${
                icurrent <= 4 ? "text-[lightblue]" : "text-white"
              } text-[0.7rem]`}
            >
              1
            </p>
          </div>
          <p
            className={`ml-2 ${
              icurrent <= 4 ? " text-white" : " text-[#898989]"
            }  `}
          >
            Registration
          </p>
        </div>
        <div
          className={`${
            icurrent > 4 && icurrent <= 17 ? "bg-gradient-to-tr bg-primary" : ""
          } flex w-[50%] rounded-tl-[5rem] rounded-bl-[5rem] items-center justify-center`}
        >
          <div
            className={`w-5 h-5 ${
              icurrent > 4 && icurrent <= 17 ? "bg-white" : "  bg-[#898989]"
            }  rounded-full flex justify-center items-center`}
          >
            <p
              className={`text-[0.7rem] ${
                icurrent > 4 && icurrent <= 17
                  ? "text-[lightblue] "
                  : " text-white"
              } `}
            >
              2
            </p>
          </div>
          <p
            className={`ml-2 ${
              icurrent > 4 && icurrent <= 17 ? "text-white" : "text-[#898989]"
            } `}
          >
            Identification
          </p>
        </div>
      </div>
      <div
        className={`${
          option === "Merchant" ? "" : "hidden"
        } w-[100%] h-[3.8rem] flex bg-[#F2F4F8] text-[0.95rem] rounded-tl-2xl rounded-tr-2xl`}
      >
        <div
          className={`flex ${
            icurrent <= 6 ? "bg-gradient-to-tr bg-primary " : ""
          } rounded-tl-[2.8rem] w-[50%] items-center justify-center rounded-tr-[5rem] rounded-br-[5rem]`}
        >
          <div
            className={`w-5 h-5 ${
              icurrent <= 6 ? "bg-white" : "bg-[#898989]"
            }   rounded-full flex justify-center items-center`}
          >
            <p
              className={` ${
                icurrent <= 6 ? "text-[lightblue]" : "text-white"
              } text-[0.7rem]`}
            >
              1
            </p>
          </div>
          <p
            className={`ml-2 ${
              icurrent <= 6 ? " text-white" : " text-[#898989]"
            }  `}
          >
            Registration
          </p>
        </div>
        <div
          className={`${
            icurrent > 6 && icurrent <= 17 ? "bg-gradient-to-tr bg-primary" : ""
          } flex w-[50%] rounded-tl-[5rem] rounded-bl-[5rem] items-center justify-center`}
        >
          <div
            className={`w-5 h-5 ${
              icurrent > 6 && icurrent <= 17 ? "bg-white" : "  bg-[#898989]"
            }  rounded-full flex justify-center items-center`}
          >
            <p
              className={`text-[0.7rem] ${
                icurrent > 6 && icurrent <= 17
                  ? "text-[lightblue] "
                  : " text-white"
              } `}
            >
              2
            </p>
          </div>
          <p
            className={`ml-2 ${
              icurrent > 6 && icurrent < 17 ? "text-white" : "text-[#898989]"
            } `}
          >
            Identification
          </p>
        </div>
      </div>
      <div className="w-full">
        <div className="text-center sm:mt-[3rem] mt-[2rem] flex flex-col items-center">
          <p className="text-[#898989] mb-1">Step {icurrent}</p>
          <h2 className="font-medium text-[1.6rem] text-[#2c5281] mb-1">
            {word}
          </h2>
          <p className="sm:w-[60%] w-[80%] text-[#596F96] text-[0.9rem]">
            {mainWord}
          </p>
          <p className="font-normal text-[1rem]"></p>
        </div>
        <div className="w-full mt-5">
          <Dis />
        </div>
      </div>
    </div>
  );
};

export default Register;

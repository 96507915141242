import axios from "axios";
import { Select } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const EmployeeInfo = ({
  setShow,
  setShowErrorModal,
  setErr,
  setRefetch,
  refetch,
}) => {
  const token = useSelector((state) => state.token);
  const router = useNavigate();
  const [selectedOption, setSelectedOption] = useState("Distance");
  const [method, setMethod] = useState("");
  const [typeUnit, setTypeUnit] = useState(null);
  const [typeValue, setTypeValue] = useState("");
  const [fee, setFee] = useState("");

  const nextHandler = () => {
    setShow(false);
    var body;
    body = {
      method: method,
      type: selectedOption,
      typeValue: typeValue,
      fee: fee,
    };

    if (selectedOption === "Distance") {
      body.typeUnit = typeUnit;
    }

    if (selectedOption === "Other") {
      delete body.typeValue 
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/inv-delivery-fee`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setShow(false);
        setRefetch(!refetch);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  return (
    <>
      <div
        className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-30 `}
      >
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow ">
            <div className="flex items-center justify-between p-4 md:p-4 border-b rounded-t ">
              <h3 className="text-lg font-semibold text-gray-900 ">
                Add Delivery Charges
              </h3>
              <button
                onClick={() => {
                  setShow(false);
                }}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
                data-modal-toggle="select-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <div className="flex justify-center  overflow-y-auto custom-scrollbar min-h-fit">
              <div className="w-[90%] mt-3  ">
                <div>
                  {/* Delivery Type */}
                  <div className={`${selectedOption === "tier" ? "mt-2" : ""}`}>
                    <h2 className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                      Delivery Method :
                    </h2>

                    <input
                      type="text"
                      placeholder="Delivery Method"
                      value={method}
                      onChange={(e) => {
                        setMethod(e.target.value);
                      }}
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 "
                    />
                  </div>

                  {/* Delivery Charges Bases on */}
                  <div className="w-full  mt-2">
                    <h2 className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                      Delivery Charges Based on :
                    </h2>
                    <div className="flex  items-center justify-between bg-[#F7F7F7] rounded-md py-2 px-3">
                      <div className=" flex items-center">
                        <input
                          type="radio"
                          id="gidOption"
                          name="contactMethod"
                          value="Distance"
                          className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                          checked={selectedOption === "Distance"}
                          onChange={(e) => {
                            setSelectedOption(e.target.value);
                          }}
                        />
                        <label
                          htmlFor="gidOption"
                          className="cursor-pointer text-sm inline-flex items-center ml-2"
                        >
                          Distance
                        </label>
                      </div>
                      <div className=" flex items-center">
                        <input
                          type="radio"
                          id="phoneOption"
                          name="contactMethod"
                          value="Days"
                          className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                          checked={selectedOption === "Days"}
                          onChange={(e) => {
                            setSelectedOption(e.target.value);
                          }}
                        />
                        <label
                          htmlFor="phoneOption"
                          className="cursor-pointer text-sm inline-flex items-center ml-2"
                        >
                          Days
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="phoneOption"
                          name="contactMethod"
                          value="Other"
                          className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                          checked={selectedOption === "Other"}
                          onChange={(e) => {
                            setSelectedOption(e.target.value);
                          }}
                        />
                        <label
                          htmlFor="phoneOption"
                          className="cursor-pointer text-sm inline-flex items-center ml-2"
                        >
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-2">
                    <h2 className="text-gray-600 text-[0.9rem] font-semibold">
                      {selectedOption === "tier"
                        ? "Tier Name"
                        : "Specific Pricing Name"}
                    </h2>

                    <input
                      type="text"
                      placeholder={
                        selectedOption === "tier"
                          ? "Tier Name"
                          : "Specific Pricing Name"
                      }
                      value={
                        selectedOption === "tier" ? levelName : specificGroup
                      }
                      onChange={(e) => {
                        if (selectedOption === "tier") {
                          setLevelName(e.target.value);
                        } else {
                          setSpecificGroup(e.target.value);
                        }
                      }}
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2"
                    />
                  </div> */}

                  <div
                    className={`${
                      selectedOption === "Distance" || selectedOption === "Days"
                        ? "mt-2"
                        : "hidden"
                    }`}
                  >
                    <h2 className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                      {selectedOption === "Distance"
                        ? "Distance :"
                        : "No Of Days :"}
                    </h2>
                    <div className="flex items-center gap-1 w-full">
                      <div
                        className={`${
                          selectedOption === "Distance" ? "w-2/5" : "hidden"
                        }`}
                      >
                        <Select
                          placeholder="Select Type"
                          optionFilterProp="children"
                          className="wow "
                          onChange={(val) => setTypeUnit(val)}
                          size="middle"
                          options={[
                            { value: "Kilometre", label: "Kilometre" },
                            { value: "Mile", label: "Mile" },
                          ]}
                          value={typeUnit}
                        />
                      </div>
                      <div
                        className={`${
                          selectedOption === "Distance" ? "w-3/5" : "w-full"
                        }`}
                      >
                        <input
                          type="text"
                          placeholder={
                            selectedOption === "Distance"
                              ? "Enter Distance"
                              : "No Of Days"
                          }
                          value={typeValue}
                          onChange={(e) => {
                            setTypeValue(e.target.value);
                          }}
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <h2 className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                      Charges :
                    </h2>

                    <input
                      type="text"
                      placeholder="Charges"
                      value={fee}
                      onChange={(e) => {
                        setFee(e.target.value);
                      }}
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 "
                    />
                  </div>
                </div>
                <div className="flex justify-center pb-6 mt-4">
                  <button
                    onClick={() => {
                      setShow(false);
                    }}
                    className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[45%]"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      nextHandler();
                    }}
                    className="ml-5 cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[45%]"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeInfo;

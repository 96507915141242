import React, { useState, useEffect } from "react";
import { Button, Input } from "antd";
import back5 from "../../../imgs/back5.png";
import Wallet from "../../../imgs/wpay.png";
import cod from "../../../imgs/cod.png";
import bank from "../../../imgs/bpay.png";
import thumb from "../../../imgs/Thumb.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const EventDetails = () => {
  const router = useNavigate();
  const product = useSelector((state) => state.product);
  const [localCart, setCart] = useState(product);
  const [show, setShow] = useState(false);
  const [discount, setDiscount] = useState(0);
  setDiscount(0);
  useEffect(() => {
    setCart(product);
  }, [product]);

  const total = localCart.price * localCart.quantity;
  const shipping = 4.6;
  const subtotal = total - discount + shipping;
  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false);
        router("/Inventory-Product");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [show]);
  return (
    <div className="flex w-full pb-[5rem] pt-[5rem] items-center justify-center">
      <div className="sm:w-[80%] flex flex-col items-center">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => {
                router("/Inventory-Buy");
              }}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Checkout
              </h2>
            </div>
          </div>
        </div>
        <div className="w-full p-8 mt-6 rounded-xl flex flex-col gap-4 bg-gray-100">
          <div className="flex flex-col w-full">
            <h2
              htmlFor="input2"
              className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
            >
              Name
            </h2>
            <Input
              className="bg-gray-50 border border-gray-300 pl-4 pr-4 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full p-2"
              placeholder="Name..."
            />
          </div>
          <div className="flex flex-col w-full">
            <h2
              htmlFor="input2"
              className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
            >
              Mobile Number
            </h2>
            <Input
              className="bg-gray-50 border border-gray-300 pl-4 pr-4 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full p-2"
              placeholder="Mobile Number..."
            />
          </div>
          <div className="flex flex-col w-full">
            <h2
              htmlFor="input2"
              className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
            >
              Shipping Address
            </h2>
            <Input
              className="bg-gray-50 border border-gray-300 pl-4 pr-4 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full p-2"
              placeholder="Shipping Address..."
            />
          </div>
        </div>
        <div className="mt-6 p-4 w-full bg-gray-100 rounded-xl">
          <h3 className="font-semibold text-lg mb-4">Payment Methods:</h3>
          <div className="flex items-center space-x-10">
            <div className="flex flex-col items-center ">
              <img
                src={Wallet}
                alt="e-Wallet"
                className="mb-2 cursor-pointer w-8 h-8"
              />
              <span className="font-semibold cursor-pointer">e-Wallet</span>
            </div>
            <div className="flex flex-col items-center">
              <img
                src={bank}
                alt="Banking"
                className="mb-2 w-8 h-8 cursor-pointer"
              />
              <span className="font-semibold cursor-pointer">Banking</span>
            </div>
            <div className="flex flex-col items-center">
              <img
                src={cod}
                alt="COD"
                className="mb-2 w-8 h-8 cursor-pointer"
              />
              <span className="font-semibold cursor-pointer">COD</span>
            </div>
          </div>
        </div>
        <div className="mt-6 p-4 w-full bg-gray-100 rounded-xl">
          <div className="flex w-full justify-between mb-2">
            <span className="font-semibold">Total:</span>
            <span className="font-semibold">${total.toFixed(2)}</span>
          </div>
          <div className="flex justify-between mb-2">
            <span className="font-semibold">Shipping:</span>
            <span className="font-semibold">${shipping.toFixed(2)}</span>
          </div>
          <div className="flex justify-between mb-2">
            <span className="font-semibold">Discount:</span>
            <span className="font-semibold">${discount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between font-bold text-lg">
            <span>Subtotal:</span>
            <span>${subtotal.toFixed(2)}</span>
          </div>
        </div>
        <div className="mt-8">
          <Button
            onClick={() => {
              // setShow(true);
              router("/Inventory-CheckOut");
            }}
            className="bg-primary rounded-xl cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white"
          >
            Place Orderrr
          </Button>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-black bg-opacity-50`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div className="w-[4rem] h-[4rem] p-3 flex items-center justify-center bg-primary rounded-full">
            <img src={thumb} alt="" className="w-full h-full" />
          </div>
          <h1 className="font-bold mt-2">Order Placed</h1>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;

import React, { useEffect, useState } from "react";
import PageHeader from "../pensionerVerification/pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../Merchant/Modals/errorModal";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import SuccessModal from "../modals/successModal";
import axios from "axios";
import { Pagination, Select } from "antd";

const SelectProgram = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [data, setData] = useState([]);
  const [type, setType] = useState(null);
  const [afterApi, setAfterApi] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const pageHandler = (e) => {
    setPage(e);
  };

  //Get Listing
  useEffect(() => {
    dispatch({ type: "EDIT", num: false });
    dispatch({ type: "DATA", num: null });
    if (type === null) {
      return;
    }

    setAfterApi(false);
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 6,
        skip: 0,
        page: page,
        sortBy: "createdAt:desc",
        ...(type && { type }),
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [type, page]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/mhsss-facility`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        setData(res?.data?.rows);
        setTotalPages(res?.data?.totalPages);
        setAfterApi(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4 min-h-screen">
      <PageHeader
        header={true}
        title={"Inventory Storage"}
        route={"/superAdminDashboard"}
      />
      <div
        onClick={() => {
          if (type === null) {
            setShowErr(true);
            setErr("Please select storage type");
            return;
          }
          router("/AddInventoryStorage");
        }}
        className={`flex justify-end items-center gap-2 cursor-pointer sm:w-[90%] w-full
        }`}
      >
        <button className="bg-primary text-white rounded-md px-8 py-2">
          Add Storage
        </button>
      </div>
      <div className="flex justify-center">
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%] w-full`}
        >
          <div className="bg-white p-3 rounded-md mt-1 mb-4">
            <h3 className="font-medium mb-1 text-[0.93rem]">
              Select Storage Type
            </h3>

            <Select
              placeholder="Select Storage Type"
              // className="wow w-[100%] mb-4"
              className="w-[100%] woww mb-4"
              value={type}
              onChange={
                (value) => {
                  setType(value);
                  dispatch({ type: "STORAGE_TYPE", num: value });
                }
                // console.log(value)
              }
              options={[
                {
                  label: "Warehouse",
                  value: "warehouse",
                },
                {
                  label: "Department",
                  value: "department",
                },
                {
                  label: "Location",
                  value: "location",
                },
              ]}
            ></Select>
          </div>
          {type === null ? (
            <div className="flex justify-center items-center p-16">
              <h1 className="text-lg text-gray-600">
                Select type to view listing
              </h1>
            </div>
          ) : data?.length === 0 && afterApi ? (
            <div className="flex justify-center items-center p-16">
              <h1 className="text-lg text-gray-600">No Data Found</h1>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-2 gap-3">
                {data?.map((item, index) => (
                  <div key={index}>
                    <div className="bg-white rounded-lg p-4 flex justify-between items-center shadow-sm  mt-4">
                      <div>
                        <h2 className="text-lg font-semibold text-gray-900 capitalize">
                          {item?.name}{" "}
                          <span className="text-teal-500 text-sm font-medium ml-16">
                            {item?.type === "master-warehouse" ? "Master" : ""}
                          </span>
                        </h2>
                        <p className="text-gray-600 text-sm mt-[2px]">
                          {item?.regionNo + " " + item?.regionName}
                        </p>
                      </div>
                      <div
                        className="text-primary flex items-center gap-2 cursor-pointer"
                        onClick={() => {
                        
                          dispatch({ type: "EDIT", num: true });
                          dispatch({ type: "DATA", num: item });
                          router("/AddInventoryStorage")
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex justify-center mt-7">
                <Pagination
                  defaultCurrent={1}
                  total={totalPages * 10}
                  showSizeChanger={false}
                  onChange={pageHandler}
                  current={page}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
      {show && (
        <SuccessModal
          show={show}
          setShow={setShow}
          message={message}
          //   route="/scheduleVerification/selectedEmployees"
        />
      )}
    </div>
  );
};

export default SelectProgram;

import axios from "axios";
import { Checkbox, Select } from "antd";
import { useEffect, useState } from "react";
import across from "../../../../imgs/across.png";
import { useSelector } from "react-redux";
import SuccessModal from "../../Modals/successModal";
const Headqaurters = ({
  // selectedPrivileges,
  // setSelectedPrivileges,
  // url,
  refetch,
  setRefetch,
}) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [role, setRole] = useState("");
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [url, setUrl] = useState("");
  // const [check, setCheck] = useState(true);
  const token = useSelector((state) => state.token);
  // const [imageUrl, setImageUrl] = useState("");
  // const dispatch = useDispatch();
  const merchantEmployeeInfo = useSelector(
    (state) => state.merchantEmployeeInfo
  );
  const [selectedPrivileges, setSelectedPrivileges] = useState([]);
  const definedPrivilegesMerchant = useSelector(
    (state) => state.definedPrivilegesMerchant
  );
  const selectedMerchantEmployeeInfo = useSelector(
    (state) => state.selectedMerchantEmployeeInfo
  );

  console.log(selectedMerchantEmployeeInfo);
  // const imageHandler = async (e) => {
  //   file = e.target.files[0];
  //   const base641 = await convertToBase64(file);
  //   setImage(base641);
  //   var bodyFormData = new FormData();
  //   bodyFormData.append("file", file);

  //   axios({
  //     method: "post",
  //     url: `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-files?fileType=merchantIdentificationDocument`,
  //     data: bodyFormData,
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((data) => {
  //       setImageUrl(data.data.url);
  //     })
  //     .catch((err) => {});
  // };
  // function convertToBase64(file) {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // }

  useEffect(() => {
    setRole(selectedMerchantEmployeeInfo?.data?.merchEmployeeInfo?.role);
  }, []);

  // const nextHandler = (e) => {
  //   e.preventDefault();
  //   if (role === "") {
  //     setErr("Please Select a role.");
  //     setShow(true);
  //   } else if (imageUrl === "") {
  //     setErr("Please Upload Signature.");
  //     setShow(true);
  //   } else {
  //     var body;
  //     if (check) {
  //       // if (role === "") {
  //       //   body = {
  //       //     employer,
  //       //     supervisor,
  //       //     departmentOrLocation: `${location}`,
  //       //   };
  //       // } else {
  //       body = {
  //         role,
  //         signature: imageUrl,
  //       };
  //       // }
  //       axios
  //         .post(
  //           `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-info?merchUserId=${merchantEmployee?.data?.merchUser?.id}`,
  //           body,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         )
  //         .then(() => {
  //           assignedPrivileges();
  //           // if (merchantStores?.length > 0) {
  //           //   return;
  //           // }
  //           dispatch({ type: "EINCREMENT" });
  //         })
  //         .catch((err) => {
  //           setErr(err.response.data.message);
  //           setShow(true);
  //         });
  //     }
  //   }
  // };

  const assignedPrivileges = () => {
    setSelectedPrivileges(selectedPrivileges.filter((item) => item !== ""));
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-privileges`,
        {
          merchEmployeeUserId:
            selectedMerchantEmployeeInfo?.data?.merchUser?.id,
          privileges: selectedPrivileges,
          merchHeadquarterId:
            selectedMerchantEmployeeInfo?.data?.merchUser?.merchheadquarterId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setShowSuccessModal(true);
        setErr("Updated Sucessfully");
        setRefetch(!refetch);
      })
      .catch((err) => {
        setErr(err?.message);
        setShow(true);
      });
    // }
    // }
  };

  //Select Multiple Priviliges
  const togglePrivilege = (privilege) => {
    setSelectedPrivileges((prevSelectedPrivileges) => {
      if (prevSelectedPrivileges.includes(privilege)) {
        return prevSelectedPrivileges.filter((item) => item !== privilege);
      } else {
        return [...prevSelectedPrivileges, privilege];
      }
    });
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-privileges?merchEmployeeUserId=${merchantEmployeeInfo?.id}&merchHeadquarterId=${merchantEmployeeInfo?.merchheadquarterId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        const privileges = data?.data?.map((privilege) => privilege.privilege);
        setSelectedPrivileges(privileges);
      })

      .catch(() => {});
  }, [refetch]);
  const imageConverterContact = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-file-presigned-url?fileType=merchantIdentificationDocument`,
        {
          fileNames: [
            selectedMerchantEmployeeInfo?.data?.merchEmployeeInfo?.signature,
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setUrl(data?.data?.[0]?.url);
      })
      .catch(() => {
        // Handle error
      });
  };
  useEffect(() => {
    imageConverterContact();
  }, []);

  return (
    <div className="flex justify-center">
      <div
        action=""
        className="sm:w-[85%] w-[85%] mt-6"
        // onSubmit={nextHandler}
      >
        <h2 className="text-[#596F96] text-[1rem] font-semibold mb-2">
          Roles/Position:
        </h2>
        <div>
          <Select
            placeholder="Select Role/Position"
            optionFilterProp="children"
            className="wow"
            style={{
              marginBottom: "1.2rem",
            }}
            onChange={(val) => setRole(val)}
            // filterOption={(input, option) =>
            //   (option?.label ?? "")
            //     .toLowerCase()
            //     .includes(input.toLowerCase())
            // }
            disabled
            value={role}
            size="middle"
            options={[
              {
                value: "Manager",
                label: "Manager",
              },
              {
                value: "Financial",
                label: "Financial",
              },
              {
                value: "Sales Clerk",
                label: "Sales Clerk",
              },
              {
                value: "Customer Support",
                label: "Customer Support",
              },

              {
                value: "Staff",
                label: "Staff",
              },
              {
                value: "Operations",
                label: "Operations",
              },
            ]}
          />
        </div>
        <h2 className="text-[#596F96] text-[1rem] font-semibold mb-4">
          Assign Privileges for Head Quarter
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-4 mb-[3.125rem]">
          {definedPrivilegesMerchant &&
            definedPrivilegesMerchant.map((privilege) => (
              <div
                key={privilege}
                className="p-2 flex items-center border shadow-sm rounded-md hover:bg-gray-100 transition duration-300 ease-in-out bg-white"
              >
                <Checkbox
                  type="checkbox"
                  className="text-slate-500 text-sm font-semibold text-gray-700 flex items-center"
                  checked={selectedPrivileges.includes(privilege)}
                  onChange={() => togglePrivilege(privilege)}
                >
                  {privilege}
                </Checkbox>
              </div>
            ))}
        </div>
        <h2 className="text-[#596F96] text-[1rem] font-semibold mb-2">
          Signature
        </h2>
        <div className="flex flex-col items-center bg-gray-50 p-10 rounded-md">
          {/* <label htmlFor="special-input" className={` cursor-pointer relative`}>
            <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
              <div>
                <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
              </div>
              <p className="text-[#9CA6C2] ml-2">Signature</p>
            </div>
            <input
              type="file"
              className="hidden w-[100%] cursor-poniter"
              id="special-input"
              accept="image/png, image/gif, image/jpeg"
              onInput={imageHandler}
            />
          </label> */}
          <div className="flex flex-col items-center">
            <div className="w-[25rem] h-[13rem]">
              {url && (
                <img
                  className={`w-[25rem] h-[13rem]`}
                  src={url}
                  alt="signature"
                />
              )}
            </div>
          </div>
        </div>
        <div className="lg:mt-[3rem] flex mt-4 items-center  justify-center ">
          <button
            onClick={assignedPrivileges}
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 mb-6 w-full sm:w-[50%] text-center`}
          >
            Update
          </button>
          <div
            className={`${
              show ? "" : "hidden"
            } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
          >
            <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
              <div
                className="absolute top-[-11%] left-[42%]"
                onClick={() => setShow(false)}
              >
                <img src={across} alt="" />
              </div>
              <p className="text-center mt-[3rem] mb-5">{err}</p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShow(false);
                }}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      {showSuccessModal && (
        <SuccessModal
          setShow={setShowSuccessModal}
          err="Updated Successfully"
        />
      )}
    </div>
  );
};

export default Headqaurters;

import React from "react";
import { useNavigate } from "react-router-dom";
import logo12 from "../../../imgs/logo12.png";
const SuccessModal = ({ setShow, holiday }) => {
  const router = useNavigate();
  return (
    <div
      className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-10`}
    >
      <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
        <img src={logo12} alt="logo" className="w-[5rem] h-[5rem] mt-5" />
        <p className="text-center  mb-5 mt-2 text-[1rem] font-semibold text-gray-600">
          {holiday}
        </p>
        <button
          onClick={() => {
            setShow(false);
          }}
          className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
        >
          Ok
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;

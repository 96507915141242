import { Select, Pagination, Popover } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import ErrorModal from "../../Merchant/Modals/errorModal";

const Paget = () => {
  const selectedProgram = useSelector((state) => state.selectedProgram);
  const router = useNavigate();
  const dispatch = useDispatch();
  const [allData, setAllData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const distributionCenter = useSelector((state) => state?.distributionCenter);
  const [show, setShow] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [search, setSearch] = useState("");
  const [prompt, setPrompt] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [status, setStatus] = useState(null);
  const [searchDob, setSearchDob] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const options = [
    {
      value: null,
      label: "All",
    },
    {
      value: "handicapped/disabled",
      label: "Handicapped/Disabled",
    },
    {
      value: "travel-time",
      label: "Travel Time",
    },
    {
      value: "health-issues",
      label: "Health Issues",
    },
    {
      value: "shut-in",
      label: "Shut In",
    },
  ];
  useEffect(() => {
    setShowSearchModal(true);
  }, []);

  useEffect(() => {
    dispatch({
      type: "SELECTED_MERCHANT_FOR_APPROVAL_BANK_INFO",
      num: null,
    });
    dispatch({
      type: "SELECTED_MERCHANT_FOR_APPROVAL_CHEQUE_INFO",
      num: null,
    });
    dispatch({
      type: "SELECTED_MERCHANT_FOR_APPROVAL_EWALLET_INFO",
      num: null,
    });
  }, []);
  const columnHeaders = [
    "SR.",
    "Pensioner Name",
    "GID",
    "Gender",
    "DOB",
    "Phone",
    "Email",
    "Created On",
    "Approved On",
    "Priority condition",
  ];
  useEffect(() => {
    const delay = 500;
    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);
      const params = {
        limit: 6,
        skip:
          search || searchDob || status || countryCode || phoneNoo
            ? 0
            : (page - 1) * 6,
        page: search || status ? pagewithsearch : page, // Separate page for search and non-search scenarios
        sortBy: "createdAt:desc",
        ...(search && { search: search }),
        ...(status && { category: status }),
        status: "pending",
        distributioncoverageId: distributionCenter?.id,
        programName: "senior-citizen-Pension",
        // ...(searchDob && { paymentDate: searchDob }),
        // ...(countryCode &&
        //   phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
      };
      fetchEmployees(params, newCancelTokenSource);
    }, delay);
    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page, search, searchDob, countryCode, status, phoneNoo, pagewithsearch]);
  const fetchEmployees = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/priority-request/verification-priority-list`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((data) => {
        setAllData(data?.data?.rows);
        setTotalPages(data?.data?.totalPages);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const pageHandler = (e) => {
    if (search || status) {
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };

  const fetchPenioners = (gid) => {
    const api = `v1/user-verifications/list-pensioners?gid=${gid}&programName=${selectedProgram}`;
    const tok = dataUser?.data?.tokens?.access?.token;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${tok}`,
        },
        // params,
      })
      .then((res) => {
        const data = res?.data?.rows?.[0];
        dispatch({
          type: "VERI_SELECTED_PENSIONER",
          num: { data, id: data.id },
        });
        dispatch({
          type: "PEN_VER_QUEUE",
          num:
            data?.verificationqueues?.length > 0
              ? data?.verificationqueues[0]
              : [],
        });
        dispatch({
          type: "PEN_VER_STATUS",
          num:
            data?.verificationStatus?.status === "pending"
              ? "Verification Pending"
              : data?.verificationStatus?.status,
        });
        router("/pensionerVerification/PriorityRequests/Detail");
      })
      .catch((err) => {});
  };

  return (
    <>
      <section className="w-full pl-4 pr-4">
        <div className="mx-auto max-w-screen-xl ">
          <div className="relative sm:rounded-lg overflow-hidden ">
            <div className="w-full flex sm:flex-row flex-col items-center justify-end gap-2 mb-4">
              <div className="sm:w-[16rem] md:w-[20rem] w-full flex items-center justify-end">
                <div className="flex sm:w-[16rem] md:w-full w-[20rem] items-center">
                  <p className="mr-4 text-[0.95rem] font-semibold text-gray-600 w-[5rem]">
                    Filter By
                  </p>
                  <Select
                    placeholder="Select Status"
                    optionFilterProp="children"
                    allowClear
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    value={status}
                    onChange={(value) => setStatus(value)}
                    size="middle"
                    className="w-full wow"
                    options={options}
                  />
                </div>
              </div>
              {/* <div className="sm:w-[16rem] md:w-[20rem] w-full flex items-center justify-end">
                <form className="flex sm:w-[16rem] md:w-full w-[15rem] items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 "
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      className="w-full bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-3     "
                      placeholder="Search"
                      required=""
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </form>
              </div> */}
            </div>

            {/* table */}
            <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg custom-scrollbar">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                  <tr>
                    {columnHeaders.map((columnHeader, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                      >
                        {columnHeader}
                      </th>
                    ))}
                  </tr>
                </thead>
                {allData.length === 0 ? (
                  <tbody>
                    <tr>
                      <td
                        colSpan="10"
                        className="text-center text-gray-500 text-lg"
                      >
                        <p className="p-10">No data found</p>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {allData.map((data, index) => (
                      <tr
                        onClick={() => {
                          dispatch({ type: "VERI_PRIORITY", num: data });
                          fetchPenioners(data?.pensionerUser?.gid);
                        }}
                        key={index}
                        className="odd:bg-white even:bg-[#f2fcfc] border-b border-[1px] border-[#d9d9d970] hover:bg-[#1BA397] hover:text-white text-md cursor-pointer"
                      >
                        <th
                          scope="row"
                          className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap"
                        >
                          <p>{index + 1 + (page - 1) * 6}.</p>
                        </th>
                        <td className="px-6 py-6 whitespace-nowrap">
                          {data?.pensionerUser?.person?.firstName}{" "}
                          {data?.pensionerUser?.person?.lastName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {data?.pensionerUser?.gid}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {data?.pensionerUser?.person?.gender}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {dayjs(
                            data?.pensionerUser?.person?.dateOfBirth
                          ).format("MM/DD/YYYY")}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {data?.pensionerUser?.countryCode
                            ? data?.pensionerUser?.countryCode
                            : "--"}
                          {data?.pensionerUser?.phoneNo
                            ? data?.pensionerUser?.phoneNo
                            : "--"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {data?.pensionerUser?.email
                            ? data?.pensionerUser?.email
                            : "----"}
                        </td>
                        <td className="px-4 py-3">
                          <p>
                            {moment(
                              data?.pensionerUser?.person.createdAt
                            ).format("MM/DD/YYYY")}
                          </p>
                          <p>
                            {" "}
                            {moment(
                              data?.pensionerUser?.person.createdAt
                            ).format("hh:mm A")}
                          </p>
                        </td>

                        <td className="px-4 py-3">
                          {data?.pensionerUser?.status === "approved" ? (
                            <div>
                              <p>
                                {moment(
                                  data?.pensionerUser?.statusChangeDate
                                ).format("MM/DD/YYYY")}
                              </p>
                              <p>
                                {moment(
                                  data?.pensionerUser?.statusChangeDate
                                ).format("hh:mm A")}
                              </p>
                            </div>
                          ) : (
                            "-----"
                          )}
                        </td>

                        <td className="px-6 py-5">
                          <Popover>
                            <div
                              className={`border-none ${
                                data?.status === "Program Card Printed"
                                  ? "bg-primary"
                                  : "bg-[#A2AAA9]"
                              } inline-block whitespace-nowrap capitalize p-1 px-5 rounded-xl flex items-center justify-center text-white focus:border-none cursor-pointer capitalize`}
                            >
                              {data?.priorityCategory}
                            </div>
                          </Popover>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            <nav
              className="flex bg-white flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-gray-500">
                Showing{" "}
                <span className="font-semibold text-gray-900 ">
                  Page {search || status ? pagewithsearch : page}{" "}
                </span>
                of{" "}
                <span className="font-semibold text-gray-900 ">
                  {totalPages}
                </span>
              </span>
              <div className={`flex justify-end mt-7`}>
                <Pagination
                  defaultCurrent={1}
                  total={totalPages * 10}
                  showSizeChanger={false}
                  onChange={pageHandler}
                  current={search || status ? pagewithsearch : page}
                />
              </div>
            </nav>
          </div>
        </div>
      </section>
      {showError && <ErrorModal err={error} setShow={setShowError} />}
    </>
  );
};

export default Paget;

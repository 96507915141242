import React from "react";
const Profile = ({ data }) => {
  const Div = ({ title, value }) => (
    <div className="sm:px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm leading-6 text-gray-900 font-semibold">{title}</dt>
      <dd className={`${title === "Industry" || title === "Business Type" ? "capitalize" :""} mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0 capitalize`}>
        {value}
      </dd>
    </div>
  );
  return (
    <div className="p-4 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8">
      <div className="sm:px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-600">
         Profile
        </h3>
       
      </div>
      <div className="mt-6 border-t border-gray-300">
        <dl className="divide-y divide-gray-300">
            <Div title="Title" value={data?.title} />
            <Div title="Full Name" value={data?.firstName + " " + (data?.middleName!==null ? data?.middleName :" " + " ") + data?.lastName} />         
            <Div title="Gender" value={data?.gender} />
            <Div title="Date of Birth" value={data?.dateOfBirth} />
            <Div title="Nationality" value={data?.nationality} />
            <Div title="Ethnicity" value={data?.ethnicity} />
            <Div title="Religion" value={data?.religion} />
            <Div title="Marital Status" value={data?.unionStatus} />
          
        </dl>
      </div>
    </div>
  );
};

export default Profile;

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import across from "../../../../imgs/across.png";
import cross from "../../../../imgs/cross.png";
let data;
let file;
const Identification2 = () => {
  const user = useSelector((state) => state.user);
  const privilege = useSelector((state) => state.myPrivileges);
  const profileData = useSelector((state) => state.profileData);
  console.log(profileData, "Profile Data");
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  const token = useSelector((state) => state.token);
  const benDone = useSelector((state) => state.benDone);
  const [signature, setSignature] = useState(null);
  const [leftThumb, setLeftThumb] = useState(null);
  const [rightThumb, setRightThumb] = useState(null);
  const [rightIndex, setRightIndex] = useState(null);
  const [leftIndex, setLeftIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingx, setIsLoadingx] = useState(false);
  const [show, setShow] = useState(false);
  const [iShow, setIShow] = useState(false);
  const [err, setErr] = useState("");
  const [display, setDisplay] = useState(false);
  const [status, setStatus] = useState("");
  const [test, setTest] = useState(false);
  const [value, setValue] = useState("");
  const [word, setWord] = useState(null);
  console.log(isLoading, isLoadingx);
  const checkHandler = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [data]);

  useEffect(() => {
    if (profileData.pensionerInfo || profileData.beneficiaryInfo) {
      console.log(profileData, "Profile Dataa");
      if (
        dash === "/Dashboard/BeneficiaryAccount" ||
        dash === "/EDashboard/BeneficiaryAccount" ||
        dash === "/superAdminDashboard/BeneficiaryAccount" ||
        dash === "/AdminDashboard/BeneficiaryAccount"
      ) {
        data = profileData.beneficiaryInfo;
      } else {
        data = profileData.pensionerInfo;
      }

      console.log("Data", data);
      if (data?.percentage === null && data?.amount === null) {
        setWord(null);
      } else {
        if (data?.amount !== null) {
          setWord(data?.amount);
          setValue("amount");
        }
        if (data?.percentage !== null) {
          setWord(data?.percentage);
          setValue("percentage");
        }
      }

      const body1 = [data?.signature];
      setSignature(data?.signature);
      if (data?.signature) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
            {
              fileNames: body1,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            setDisplay(data.data[0].url);
          })
          .catch(() => {});
      }
      const body2 = [
        data && data.leftThumbs && data.leftThumbs.length > 0
          ? data.leftThumbs[0]
          : null,
      ];
      setLeftThumb(
        data && data.leftThumbs && data.leftThumbs.length > 0
          ? data.leftThumbs[0]
          : null
      );
      if (data && data.leftThumbs && data.leftThumbs.length > 0) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
            {
              fileNames: body2,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data?.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            // setDisplay1(data?.data[0].url);
          })
          .catch(() => {});
      }
      const body3 = [
        data && data.rightThumbs && data.rightThumbs.length > 0
          ? data.rightThumbs[0]
          : null,
      ];
      setRightThumb(
        data && data.rightThumbs && data.rightThumbs.length > 0
          ? data.rightThumbs[0]
          : null
      );
      if (data && data.rightThumbs && data.rightThumbs.length > 0) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
            {
              fileNames: body3,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data?.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            // setDisplay2(data?.data[0].url);
          })
          .catch(() => {});
      }
      const body4 = [
        data && data.rightIndices && data.rightIndices.length > 0
          ? data.rightIndices[0]
          : null,
      ];
      setRightIndex(
        data && data.rightIndices && data.rightIndices.length > 0
          ? data.rightIndices[0]
          : null
      );
      if (data && data.rightIndices && data.rightIndices.length > 0) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
            {
              fileNames: body4,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data?.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            // setDisplay4(data?.data[0].url);
          })
          .catch(() => {});
      }
      const body5 = [
        data && data.leftIndices && data.leftIndices.length > 0
          ? data.leftIndices[0]
          : null,
      ];
      setLeftIndex(
        data && data.leftIndices && data.leftIndices.length > 0
          ? data.leftIndices[0]
          : null
      );
      if (data && data.leftIndices && data.leftIndices.length > 0) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
            {
              fileNames: body5,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data?.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            // setDisplay3(data?.data[0].url);
          })
          .catch(() => {});
      }
    }
  }, [profileData]);

  const imageHandler = (setState, setUrl, load) => async (e) => {
    load(true);
    file = e.target.files[0];

    const base641 = await convertToBase64(file);
    setState(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setUrl(data?.data.url);
        load(false);
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const updateHandler = () => {
    setIsLoadingx(true);
    if (
      dash === "/Dashboard/BeneficiaryAccount" ||
      dash === "/EDashboard/BeneficiaryAccount" ||
      dash === "/superAdminDashboard/BeneficiaryAccount" ||
      dash === "/AdminDashboard/BeneficiaryAccount"
    ) {
      let wow;
      if (dash === "/Dashboard/BeneficiaryAccount") {
        wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/${profileData.beneficiaryInfo.id}`;
      } else {
        wow = `${process.env.REACT_APP_BASE_URL}/v1/beneficiary-info/${profileData.beneficiaryInfo.id}/update-by-staff`;
      }
      let body = {};
      // if (value !== "") {
      //   if (value === "HTML") {
      //     body.amount = word;
      //   } else if (value === "CSS") {
      //     body.percentage = word;
      //   }
      // }
      if (value === "amount") {
        body = {
          amount: word,
          signature,
          // leftThumbs: [leftThumb],
          // rightThumbs: [rightThumb],
          // leftIndices: [leftIndex],
          // rightIndices: [rightIndex],
        };
      } else if (value === "percentage") {
        body = {
          percentage: word,
          signature,
          // leftThumbs: [leftThumb],
          // rightThumbs: [rightThumb],
          // leftIndices: [leftIndex],
          // rightIndices: [rightIndex],
        };
      }
      axios
        .put(
          wow,
          {
            beneficiaryInfo: body,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          dispatch({ type: "RESET" });
          setIsLoadingx(false);
        })
        .catch((err) => {
          setIsLoadingx(false);
          setErr(err.response.data.message);
          setShow(true);
        });
    } else {
      if (
        dash === "/EDashboard/PensionerAccount" ||
        dash === "/superAdminDashboard/PensionerAccount" ||
        dash === "/AdminDashboard/PensionerAccount"
      ) {
        const checkAllIndentity =
          profileData?.pensionerInfo === null
            ? // (profileData?.pensionerInfo?.leftIndices?.length ?? 0) === 0 &&
              // (profileData?.pensionerInfo?.leftThumbs?.length ?? 0) === 0 &&
              // (profileData?.pensionerInfo?.rightIndices?.length ?? 0) === 0 &&
              // (profileData?.pensionerInfo?.rightThumbs?.length ?? 0) === 0 &&
              // profileData?.pensionerInfo?.signature === null
              false
            : true;

        const api =
          checkAllIndentity === false
            ? `v1/pensioner-info/add-by-staff?pensionerUserId=${profileData?.user?.id}`
            : `v1/pensioner-info/${profileData?.pensionerInfo?.id}/update-by-staff`;
        const method = checkAllIndentity === false ? "post" : "put";
        let body;
        if (checkAllIndentity === false) {
          body = {
            signature: signature,
            // leftThumbs: [leftThumb],
            // rightThumbs: [rightThumb],
            // leftIndices: [leftIndex],
            // rightIndices: [rightIndex],
          };
          if (signature === null) {
            delete body.signature;
          }
          if (leftThumb === null) {
            delete body.leftThumbs;
          }
          if (rightThumb === null) {
            delete body.rightThumbs;
          }
          if (leftIndex === null) {
            delete body.leftIndices;
          }
          if (rightIndex === null) {
            delete body.rightIndices;
          }
        } else {
          body = {
            signature: signature,
            // leftThumbs: [leftThumb],
            // rightThumbs: [rightThumb],
            // leftIndices: [leftIndex],
            // rightIndices: [rightIndex],
          };
        }

        axios[method](
          `${process.env.REACT_APP_BASE_URL}/${api}`,
          {
            pensionerInfo: body,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
          .then(() => {
            dispatch({ type: "RESET" });
            setIsLoadingx(false);
          })
          .catch((err) => {
            // setIsLoading(false);
            setErr(err.response.data.message);
            setShow(true);
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/v1/pensioner-info`,
            {
              signature,
              // leftThumb,
              // rightThumb,
              // leftIndex,
              // rightIndex,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            dispatch({ type: "RESET" });
            setIsLoadingx(false);
          })
          .catch((err) => {
            setIsLoadingx(false);
            setErr(err.response.data.message);
            setShow(true);
          });
      }
    }
  };
  useEffect(() => {
    if (dash === "/Dashboard/PensionerAccount") {
      // setAllDis(true);
    } else {
      // setAllDis(false);
      if (benDone !== "Done") {
        if (profileData?.user?.isDisabled) {
          // setAllDis(true);
        }
      }
    }
    if (benDone !== "Done") {
      setStatus(profileData?.user?.status);
    }
  }, []);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Use regular expression to remove non-numeric characters
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    // Update the state with the sanitized numeric value
    setWord(numericValue);
  };

  return (
    <div>
      {/* {data && ( */}
      <div>
        <div>
          <h2 className="mb-4 text-center text-[1.5rem] font-bold mt-4">
            Signature
          </h2>
          {/* {data && ( */}
          <div
            className={`
                
                ${
                  dash === "/Dashboard/BeneficiaryAccount" ||
                  dash === "/EDashboard/BeneficiaryAccount" ||
                  dash === "/superAdminDashboard/BeneficiaryAccount" ||
                  dash === "/AdminDashboard/BeneficiaryAccount"
                    ? ""
                    : "hidden"
                }`}
          >
            <div className={`flex flex-col `}>
              <label
                htmlFor="input2"
                className="mb-4 text-[1.1rem] font-semibold"
              >
                Payment Amount :
              </label>
              <div className="mb-4 mt-2">
                <input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="HTML"
                  onChange={checkHandler}
                  // defaultChecked={value === "HTML" ? true : false}
                ></input>

                <label htmlFor="css" className=" ml-1 text-[0.9rem]">
                  Percentage
                </label>
              </div>
              <input
                className={`lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                type="text"
                name="input2"
                value={word}
                onChange={(e) => {
                  setWord(e.target.value);
                }}
                // placeholder={value === "HTML" ? "$" : "% Percentage"}
                placeholder="%"
              />
            </div>
            {/* <h2 className="mb-4 text-[1.1rem] font-semibold">
                {value === "amount"
                  ? "Amount"
                  : value === "percentage"
                  ? "Percentage"
                  : "Amount/Percentage"}
              </h2>
              <input
                type="text"
                name=""
                onChange={handleInputChange}
                placeholder={"Monthly Transfer"}
                value={
                  word === null
                    ? ""
                    : value === "amount"
                    ? `$${word}`
                    : `${word}%`
                }
                className={`
                  ${
                    status === "pending"
                      ? "pointer-events-none"
                      : status === "deferred"
                      ? "pointer-events-none"
                      : status === "verified"
                      ? "pointer-events-none"
                      : status === "re-submitted"
                      ? "pointer-events-none"
                      : status === "in-review"
                      ? "pointer-events-none"
                      : ""
                  } lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
              /> */}
          </div>
          {/* )} */}

          {/* <div className={`flex flex-col `}>
            <label
              htmlFor="input2"
              className="mb-4 text-[1.1rem] font-semibold"
            >
              Payment Amount :
            </label>
            <div className="mb-4 mt-2">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="HTML"
                onChange={checkHandler}
                // defaultChecked={value === "HTML" ? true : false}
              ></input>

              <label htmlFor="css" className=" ml-1 text-[0.9rem]">
                Percentage
              </label>
            </div>
            <input
              className={`lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
              type="text"
              name="input2"
              value={word}
              onChange={(e) => {
                setWord(e.target.value);
              }}
              // placeholder={value === "HTML" ? "$" : "% Percentage"}
              placeholder="%"
            />
          </div> */}

          <div>
            <h2 className="mb-4 text-[1.1rem] font-semibold">Signature:</h2>
            <label
              htmlFor="special-input"
              className={`cursor-pointer relative`}
            >
              <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                <div>
                  <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                </div>
                <p className="text-[#9CA6C2] ml-2">Signature</p>
              </div>
              <input
                type="file"
                className="hidden"
                id="special-input"
                accept="image/png, image/gif, image/jpeg"
                onInput={imageHandler(setDisplay, setSignature, setIsLoading)}
              />
            </label>
            <div className={`relative mb-4 ${display ? "" : "hidden"}`}>
              <img
                onClick={() => {
                  setIShow(true);
                }}
                className="w-[25rem] h-[13rem]"
                src={display}
                alt=""
              />

              <div
                className={`${
                  iShow ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display} alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Thumb Print Left:
              </h2>
              <label
                htmlFor="special-input1"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? 
                      "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Thumb Print Left</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input1"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay1,
                    setLeftThumb,
                    setIsLoading1
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display1 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow1(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display1}
                  alt=""
                />
               
              </div>
              <div
                className={`${
                  iShow1 ? "" : "hidden"
                } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
              >
                <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                  <img
                    className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                    src={cross}
                    onClick={() => setIShow1(false)}
                    alt=""
                  />
                  <img className="w-[45rem] h-[28rem]" src={display1} alt="" />
                </div>
              </div>
             
            </div>
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Thumb Print Right:
              </h2>
              <label
                htmlFor="special-input2"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? 
                      "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Thumb Print Right</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input2"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay2,
                    setRightThumb,
                    setIsLoading2
                  )}
                />
              </label>

              <div className={`relative mb-4 ${display2 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow2(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display2}
                  alt=""
                />
               
                <div
                  className={`${
                    iShow2 ? "" : "hidden"
                  } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                >
                  <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                    <img
                      className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                      src={cross}
                      onClick={() => setIShow2(false)}
                      alt=""
                    />
                    <img
                      className="w-[45rem] h-[28rem]"
                      src={display2}
                      alt=""
                    />
                  </div>
                </div>
               
              </div>
              
            </div>
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Index Print Left:
              </h2>
              <label
                htmlFor="special-input3"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? 
                      "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Index Print Left</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input3"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay3,
                    setLeftIndex,
                    setIsLoading3
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display3 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow3(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display3}
                  alt=""
                />
              
                <div
                  className={`${
                    iShow3 ? "" : "hidden"
                  } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                >
                  <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                    <img
                      className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                      src={cross}
                      onClick={() => setIShow3(false)}
                      alt=""
                    />
                    <img
                      className="w-[45rem] h-[28rem]"
                      src={display3}
                      alt=""
                    />
                  </div>
                </div>
             
              </div>
              
            </div>
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold">
                Index Print Right:
              </h2>
              <label
                htmlFor="special-input4"
                className={`${
                  isLoading1 ||
                  isLoading ||
                  isLoading2 ||
                  isLoading3 ||
                  isLoading4
                    ? 
                      "pointer-events-none"
                    : ""
                } cursor-pointer relative`}
              >
                <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                  <div>
                    <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Index Print Right</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input4"
                  accept="image/png, image/gif, image/jpeg"
                  onInput={imageHandler(
                    setDisplay4,
                    setRightIndex,
                    setIsLoading4
                  )}
                />
              </label>
              <div className={`relative mb-4 ${display4 ? "" : "hidden"}`}>
                <img
                  onClick={() => {
                    setIShow4(true);
                  }}
                  className="w-[25rem] h-[13rem]"
                  src={display4}
                  alt=""
                />
                
                <div
                  className={`${
                    iShow4 ? "" : "hidden"
                  } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                >
                  <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                    <img
                      className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                      src={cross}
                      onClick={() => setIShow4(false)}
                      alt=""
                    />
                    <img
                      className="w-[45rem] h-[28rem]"
                      src={display4}
                      alt=""
                    />
                  </div>
                </div>
               
              </div>
              
            </div> */}
        </div>

        {/* <div
          className={`${
            dataUser.data.user.status === "pending"
              ? "hidden"
              : dataUser.data.user.status === "verified"
              ? "hidden"
              : dataUser.data.user.status === "deferred"
              ? "hidden"
              : dataUser.data.user.status === "re-submitted"
              ? "hidden"
              : dataUser.data.user.status === "in-review"
              ? "hidden"
              : ""
          }`}
        >
          {user === "employee" &&
          !privilege?.includes("Edit pensioner") ? null : (
            <button
              onClick={updateHandler}
              className={`relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              <span>Save</span>
            </button>
          )}
        </div> */}

        <div
          className={`${
            dataUser?.data?.user?.roles[0] === "employee" &&
            dataUser?.data?.user?.status === "pending"
              ? "hidden"
              : ""
          }`}
        >
          {user === "employee" &&
          !privilege?.includes("Edit pensioner") ? null : (
            <button
              onClick={updateHandler}
              className={`${
                (status === "pending" ||
                  status === "deferred" ||
                  status === "in-review" ||
                  status === "verified" ||
                  status === "in-review" ||
                  status === "re-submitted" ||
                  status === "approved") &&
                dash === "/Dashboard/PensionerAccount"
                  ? // ||
                    // allDis
                    // isApplicableUser
                    "hidden"
                  : ""
              } relative lg:text-[1.1rem] mt-8 flex items-center justify-center bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              {/* <Spin
            style={{ display: `${isLoading ? "" : "none"}` }}
            indicator={antIcon}
          /> */}
              <span>Save</span>
              {/* <div
                className={`w-[100%] h-[100%] absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
              ></div> */}
            </button>
          )}
        </div>
        <div
          className={`${
            show ? "" : "hidden"
          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
            <div
              className="absolute top-[-11%] left-[42%]"
              onClick={() => setShow(false)}
            >
              <img src={across} alt="" />
            </div>
            <p className="text-center mt-[3rem] mb-5">{err}</p>
            <button
              onClick={() => setShow(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
      {/* )} */}
      {/* <div className={`${data ? "hidden" : ""}`}>No Data</div> */}
    </div>
  );
};

export default Identification2;

import React from "react";
import { Collapse } from "antd";
import Identification from "../Identification";
import Profile from "./Profile";
import ContactInfo from "../Personal/ContactInfo";
const { Panel } = Collapse;

const Index = ({ data, contactPerson, identification, mhhs, gov }) => {
  const callback = (key) => {
    console.log(key, contactPerson);
  };
  // const Header = ({ data }) => {
  //   return (
  //     <div className="">
  //       <div className="flex items-center space-x-4">
  //         <div className="flex-shrink-0">
  //           <img
  //             className="sm:w-16 sm:h-16 w-12 h-12 rounded-full object-cover border-2 border-primary p-1"
  //             src={data?.image}
  //             alt="Profile"
  //           />
  //         </div>
  //         <div className="flex-1 min-w-0">
  //           <div className="flex items-center space-x-2">
  //             <p className="text-lg font-semibold text-gray-900 truncate">
  //               {data?.fullName}
  //             </p>
  //           </div>
  //           <p className="text-md text-gray-500 truncate "></p>
  //           <p
  //             className={`${
  //               data?.position ? "text-sm text-gray-400 truncate " : "hidden"
  //             }`}
  //           >
  //             Position: {data?.position}
  //           </p>
  //         </div>
  //         {/* <div className="inline-flex items-center text-base text-primary text-[0.9rem] font-semibold cursor-pointer">
  //           Edit
  //         </div> */}
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className="sm:p-10">
      <div className="p-4 bg-white rounded-lg border shadow-md sm:p-8   mb-8">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-bold leading-none text-gray-900 ">
             Owner Details
          </h3>
        </div>
        <div className="flex items-center">
          <img
            className="w-24 h-24 rounded-full mr-3"
            src={data?.image}
            alt="jane"
          />
          <div className="text-sm">
            <h4 className="sm:text-lg font-semibold text-primary truncate  leading-none">
              {data?.firstName}&nbsp;
              {data?.middleName && <>{data?.middleName}&nbsp;</>}
              {data?.lastName}
            </h4>
            <p className="mt-1">{data?.emails?.[0]}</p>
          </div>
        </div>

        <div className="sm:p-8 sm:mt-0 mt-6">
          <Collapse
            // defaultActiveKey={["1"]}
            accordion={true}
            onChange={callback}
            style={{ backgroundColor: "white" }}
          >
            <Panel
              header="Profile"
              key="1"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <Profile data={data} />
            </Panel>
            <Panel
              header="Contact Info"
              key="2"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <ContactInfo company={data} />
            </Panel>

            <Panel
              header="Identification"
              key="3"
              className="p-2 text-md text-gray-800 font-medium"
            >
              <Identification identification={identification} mhhs={mhhs} />
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Index;

import React from "react";
import { useNavigate } from "react-router-dom";
import logo1 from "../../../imgs/logo12.png";

const AppointConfirm = ({ setShow, date, time }) => {
  const router = useNavigate();
  return (
    <div
      className={` w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-40`}
    >
      <div className="relative rounded-xl w-[30rem] pb-6 pt-4 flex items-center px-5 bg-white flex-col py-6">
        <div>
          <img src={logo1} alt="" className="w-20 h-20"/>
        </div>
        <p className="text-center mt-[1rem] text-[1rem] text-primary font-bold mb-1">
          Appointment Confirmed
        </p>
        <p className="text-center mt-[1rem]">
          Dear Pensioner, you have successfully scheduled your verification
          appointment for
          <b>
            {" "}
            {date} at {time}
          </b>
        </p>
        <p className="text-center mt-[1rem] mb-5 w-[90%]">
          Please make sure to arrive at the document center 10 minutes before
          your appointment time. If you arrive late, your appointment may be
          canceled. Also, remember to bring your <b> GID or pension book</b>{" "}
          with you
        </p>
        <div className="w-full flex items-center justify-center gap-2">
          <button
            type="button"
            onClick={() => {
              setShow(false);
              router("/Dashboard");
            }}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-6 pr-6 text-white bg-primary w-[40%]"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppointConfirm;

import { Avatar, Collapse } from "antd";
import CompanyDetails from "./CompanyDetails";
import BusinessHours from "./BusinessHours";
import { useSelector } from "react-redux";

const { Panel } = Collapse;

const Company = ({ company }) => {
  const user = useSelector((state) => state.user);
  const callback = (key) => {
    console.log(key);
  };
  // bg-[#F4F5FE]

  return (
    <div className="sm:p-10">
      <div className="p-6 bg-white rounded-lg sm:p-8  mb-8 shadow-md ">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-2xl font-bold leading-none text-gray-900">
            Company Details
          </h3>
        </div>
        <div className="flex items-center">
          <Avatar
            className="sm:w-24 sm:h-24 w-16 h-16 rounded-full mr-4 border-2 border-primary"
            src={company?.logo}
            alt="Profile"
          />
          <div className="text-sm">
            <h4 className="sm:text-xl text-md font-semibold text-primary leading-none mb-2 capitalize">
              {company?.name}
            </h4>
            <p className="text-md text-gray-600 font-medium">
              Since: {company?.yearFounded}
            </p>
          </div>
        </div>
        <div className="sm:p-8 sm:mt-0 mt-6">
          <Collapse
            // defaultActiveKey={["1"]}
            accordion={true}
            onChange={callback}
            style={{ backgroundColor: "white" }}
          >
            <Panel
              header="Company Details"
              key="1"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <CompanyDetails company={company} />
            </Panel>
            <Panel
              header="Emails"
              key="2"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <div className="p-4 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8">
                <div className="sm:px-4 sm:px-0">
                  <h3 className="text-base font-semibold leading-7 text-gray-600">
                    Company Email Addresses
                  </h3>
                </div>
                <div className="mt-6 border-t border-gray-300">
                  <dl className="divide-y divide-gray-300">
                    {company?.emails?.map((con, index) => (
                      <div
                        key={index}
                        className="sm:px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                      >
                        <dt className="text-sm  leading-6 text-gray-900 font-semibold">
                          Email Address {index + 1}
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                          {con}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </Panel>

            <Panel
              header="Social Links"
              key="3"
              className="p-3 text-md text-gray-800 font-medium"
            >
              <div className="p-4 bg-[#F4F5FE] rounded-lg sm:p-8  mb-8">
                <div className="sm:px-4 sm:px-0">
                  <h3 className="text-base font-semibold leading-7 text-gray-600">
                    Company Social Links
                  </h3>
                </div>
                <div className="mt-6 border-t border-gray-300">
                  <dl className="divide-y divide-gray-300">
                    {company?.socialLinks?.map((con, index) => (
                      <div
                        key={index}
                        className="sm:px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                      >
                        <dt className="text-sm font-semibold leading-6 text-gray-900">
                          Social Link {index + 1}
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                          {con}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </Panel>
            <Panel
              header="Business Hours"
              key="4"
              className={`${
                user === "vendor-owner"
                  ? "hidden"
                  : "p-3 text-md text-gray-800 font-medium"
              }`}
            >
              <BusinessHours company={company} />
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Company;

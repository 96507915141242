import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import Nav from "../../vendor/navbar";
import Main from "./main";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
const Listing = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector((state) => state.user);

  const statusesOptions = [
    { value: null, label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Quotation", label: "Quotation" },
    { value: "Cancelled", label: "Cancelled" },
    // { value: "In-Progress", label: "In-Progress" },
    // { value: "Shipped", label: "Shipped" },
    { value: "Delivered", label: "Delivered" },
    { value: "Partial", label: "Partial" },
    { value: "Delivered-Issue-reported", label: "Delivered - Issue Reported" },
  ];

  return (
    <>
      <div>
        <Nav />
        <div className="flex items-center justify-center bg-[#D9EFED] ">
          <div className="w-[95%] mt-[1rem] px-10 rounded-md mb-12">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => {
                  if (user === "vendor-owner") {
                    router("/VendorDashboard");
                    return;
                  }
                  router("/SuperAdmin/InventoryDashboard");
                }}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Inventory Cost
              </h2>
            </div>

            {/* <div className="flex justify-end mt-4 gap-3">
              <div className="flex items-center bg-[#f9fbfb] border border-gray-300 rounded-md px-4 py-2 w-[300px] shadow-sm">
                <input
                  type="text"
                  placeholder="Search Product"
                  className="bg-transparent focus:outline-none text-gray-500 placeholder-gray-400 flex-grow"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-[#1BA397]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.8"
                    d="M11 19a8 8 0 100-16 8 8 0 000 16zm5-1l5 5"
                  />
                </svg>
              </div>
            </div> */}

            <div className="bg-[#F6F4F4] mt-6 rounded-md p-8 mx-10">
              <Main />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;

import React, { useEffect, useState } from "react";
import EmployeeAttendancDashboardeWrapper from "../EmployeeAttendancDashboardeWrapper";
import TopNav from "../components/TopNav";
import onLeave from "../../../imgs/user.png";
import pendingTasks from "../../../imgs/pending.png";
import totalHours from "../../../imgs/total-hours.png";
import absent from "../../../imgs/absent.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Menu, Select, Tag } from "antd";
import AttendanceTable from "../../attendance/attendance/components/AttendanceTable";

const { Option } = Select;

const cardsData = [
  {
    id: 1,
    icon: totalHours,
    title: "254",
    subTitle: "Active hours",
  },
  {
    id: 2,
    icon: onLeave,
    title: "20",
    subTitle: "Present",
  },
  {
    id: 3,
    icon: absent,
    title: "20",
    subTitle: "Absents",
  },
  {
    id: 4,
    icon: pendingTasks,
    title: "4",
    subTitle: "Leaves",
  },
];
const columnHeaders = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Check-in Time",
    dataIndex: "checkinTime",
    key: "checkinTime",
  },
  {
    title: "Check-in Device",
    dataIndex: "checkinDeviceType",
    key: "checkinDeviceType",
    render: (checkinDeviceType) => {

      const checkinDeviceColors = {
        FP: "green",
        FR: "yellow",
        HPV: "orange",
        NFC: "pink",
        QRC: "red",
        PIN: "cyan",
      };

      return (
        <Tag
          color={checkinDeviceColors[checkinDeviceType] || "gray"}
          className="text-xs font-normal px-1 py-0.5">
          {checkinDeviceType}
        </Tag>
      );
    },
  },
  {
    title: "Check-out Time",
    dataIndex: "checkoutTime",
    key: "checkoutTime",
  },
  {
    title: "Check-out Device",
    dataIndex: "checkoutDeviceType",
    key: "checkoutDeviceType",
    render: (checkoutDeviceType) => {
      const checkoutDeviceColors = {
        FP: "green",
        FR: "yellow",
        HPV: "orange",
        NFC: "pink",
        QRC: "red",
        PIN: "cyan",
      };

      return (
        <Tag
          color={checkoutDeviceColors[checkoutDeviceType] || "gray"}
          className="text-xs font-normal px-1 py-0.5">
          {checkoutDeviceType}
        </Tag>
      );
    },
  },
  {
    title: "Working Hours",
    dataIndex: "hours",
    key: "hours",
  },
  {
    title: "Month",
    dataIndex: "month",
    key: "month",
  },
];

const dummyData = [
  {
    id: "1",
    name: "John Doe",
    checkinTime: "08:00 AM",
    checkinDeviceType: "FP",
    checkoutTime: "02:00 PM",
    checkoutDeviceType: "FP",
    hours: "6",
    month: "January",
  },
  {
    id: "2",
    name: "Jane Smith",
    checkinTime: "09:15 AM",
    checkinDeviceType: "FR",
    checkoutTime: "03:30 PM",
    checkoutDeviceType: "FR",
    hours: "5",
    month: "January",
  },
  {
    id: "3",
    name: "Michael Johnson",
    checkinTime: "07:45 AM",
    checkinDeviceType: "HPV",
    checkoutTime: "03:45 PM",
    checkoutDeviceType: "HPV",
    hours: "8",
    month: "January",
  },
  {
    id: "4",
    name: "Alice Williams",
    checkinTime: "08:30 AM",
    checkinDeviceType: "NFC",
    checkoutTime: "04:30 PM",
    checkoutDeviceType: "NFC",
    hours: "7",
    month: "February",
  },
  {
    id: "5",
    name: "David Brown",
    checkinTime: "09:00 AM",
    checkinDeviceType: "QRC",
    checkoutTime: "03:00 PM",
    checkoutDeviceType: "QRC",
    hours: "6",
    month: "February",
  },
  {
    id: "6",
    name: "Sarah Davis",
    checkinTime: "07:30 AM",
    checkinDeviceType: "PIN",
    checkoutTime: "03:30 PM",
    checkoutDeviceType: "PIN",
    hours: "8",
    month: "March",
  },
  {
    id: "7",
    name: "Emma Wilson",
    checkinTime: "08:00 AM",
    checkinDeviceType: "FP",
    checkoutTime: "04:00 PM",
    checkoutDeviceType: "FP",
    hours: "7",
    month: "March",
  },
  {
    id: "8",
    name: "Olivia Taylor",
    checkinTime: "09:15 AM",
    checkinDeviceType: "FR",
    checkoutTime: "03:45 PM",
    checkoutDeviceType: "FR",
    hours: "6",
    month: "March",
  },
  {
    id: "9",
    name: "James Anderson",
    checkinTime: "07:45 AM",
    checkinDeviceType: "HPV",
    checkoutTime: "04:45 PM",
    checkoutDeviceType: "HPV",
    hours: "9",
    month: "April",
  },
  {
    id: "10",
    name: "Robert Miller",
    checkinTime: "08:30 AM",
    checkinDeviceType: "NFC",
    checkoutTime: "04:30 PM",
    checkoutDeviceType: "NFC",
    hours: "8",
    month: "April",
  },
];


const EmployeeAttendance = () => {
  const dispatch = useDispatch();
  const router = useNavigate();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "3" });
  }, []);

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const handleMonthChange = (value) => setSelectedMonth(value);
  const handleDeviceChange = (value) => setSelectedDevice(value);

  const filteredData = dummyData.filter((record) => {
    const matchesDevice = selectedDevice ? record.checkinDevice === selectedDevice : true;
    const matchesMonth = selectedMonth ? record.month === selectedMonth : true;
    return matchesDevice && matchesMonth;
  });

  const handleAction = (action, id) => {
    switch (action) {
      case "view":
        router(`/AttendanceDetails`);
        break;
      case "edit":
        console.log(`Edit details for employee ID: ${id}`);
        break;
      case "remove":
        console.log(`Remove employee ID: ${id}`);
        break;
      default:
        console.log("Unknown action");
    }
  };

  // Action menu for each row
  const actionMenu = (id) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleAction("view", id)}>
        View
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleAction("edit", id)}>
        Edit
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleAction("remove", id)}>
        Remove
      </Menu.Item>
    </Menu>
  );

  return (
    <EmployeeAttendancDashboardeWrapper>
      <div className="">
        <div className="">
          <TopNav title="Attendance" cardData={cardsData} />
        </div>
        <div className="grid gap-4 grid-cols-5 mb-4 mt-5">
          <Select
            placeholder="Select Check-in Device"
            onChange={handleDeviceChange}
            className="w-full h-[32px]"
            allowClear>
            <Option value="FP">Fingerprint</Option>
            <Option value="FR">Facial Recognition</Option>
            <Option value="HPV">Hand Palm Vein</Option>
            <Option value="NFC">Near Field Card</Option>
            <Option value="QRC">QR Code</Option>
            <Option value="PIN">Pin Code</Option>
          </Select>
          <Select
            placeholder="Select Month"
            onChange={handleMonthChange}
            className="w-full h-[32px]"
            allowClear>
            <Option value="January">January</Option>
            <Option value="February">February</Option>
            <Option value="March">March</Option>
            <Option value="April">April</Option>
            <Option value="May">May</Option>
            <Option value="June">June</Option>
            <Option value="July">July</Option>
            <Option value="August">August</Option>
            <Option value="September">September</Option>
            <Option value="October">October</Option>
            <Option value="November">November</Option>
            <Option value="December">December</Option>
          </Select>
        </div>
        <AttendanceTable
          columns={columnHeaders}
          data={filteredData}
          pageSize={10}
          // actions={actionMenu}
        />
      </div>
    </EmployeeAttendancDashboardeWrapper>
  );
};

export default EmployeeAttendance;

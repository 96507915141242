import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import moment from "moment";
import ben from "../../../../imgs/ben.png";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import across from "../../../../imgs/across.png";
import back5 from "../../../../imgs/back5.png";
import edit1 from "../../../../imgs/edit1.png";
import document from "../../../../imgs/document.png";
import { Select } from "antd";
const EmployeeProfile = () => {
  const employeeId = useSelector((state) => state.employeeId);
  const dataUser = useSelector((state) => state.dataUser);
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const employeeInformation =
    location?.state && location?.state?.employeeInformation;
  const employeeId1 = location?.state && location?.state?.employeeId;
  const dispatch = useDispatch();
  const router = useNavigate();
  const [firstName, setFirstName] = useState("---");
  const [lastName, setLastName] = useState("---");
  const [assignRoleModal, setAssignRoleModal] = useState(true);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [role, setRole] = useState(null);
  const [roles, setRoles] = useState();
  const [employeeData, setEmployeeData] = useState();
  const [refetch, setRefetch] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [reason, setReason] = useState("");
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [show1, setShow1] = useState("");
  const [roleC, setRoleC] = useState("");
  console.log("employeeData", employeeData);
  const optopt = [
    { label: "Approved", value: "approved" },
    { label: "Rejected", value: "rejected" },
  ];
  console.log("employeeInformation", employeeInformation);
  useEffect(() => {
    if (
      employeeInformation?.employeeinfos &&
      employeeInformation?.employeeinfos[0]?.role !== null
    ) {
      setRole(employeeInformation?.employeeinfos[0]?.role);
    }
  }, []);

  useEffect(() => {
    if (employeeInformation?.roles[0] === "admin") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/users/admin-profile/${employeeInformation?.id}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          setEmployeeData(data.data);

          setRoleC(data.data?.user?.roles[0]);
          setReason(data.data?.user?.statusReason);
          setSelectedStatus(data.data?.user?.status);
          setFirstName(data.data?.person?.firstName);
          setLastName(data.data?.person?.lastName);
          // setRoles(data.data.roles);
          // setAllb(data.data);
        })
        .catch(() => {});
    } else {
      const api = `v1/users/employee-profile/${employeeId1}`;
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        })
        .then((data) => {
          setEmployeeData(data.data);
          setRole(data.data?.employeeInfo?.role);
          setRoleC(data.data?.employeeInfo?.role);
          setReason(data.data?.user?.statusReason);
          setSelectedStatus(data.data?.user?.status);
          setFirstName(data.data?.person?.firstName);
          setLastName(data.data?.person?.lastName);
          // setRoles(data.data.roles);
          // setAllb(data.data);
        })
        .catch(() => {});
    }
  }, [refetch, employeeId1]);

  useEffect(() => {
    const api =
      user === "admin"
        ? "v1/privileges/list-roles-privileges/by-admin"
        : "v1/privileges/list-roles-privileges/by-superadmin";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then((data) => {
        setRoles(data.data.roles);
        // setAllb(data.data);
      })
      .catch(() => {});
  }, []);

  const AssignRole = () => {
    // if(employeeData?.user?.status === "pending"){
    //   setErr("This employee's status is pending, and the role cannot be assigned until the Employee status is approved.");
    //   setShow(true);
    // }
    if (employeeData?.user?.status === "rejected") {
      setErr(
        "This employee's status has been rejected, so the role cannot be assigned."
      );
      setShow(true);
    } else if (role === "") {
      setErr("Please Select Role");
      setShow(true);
    } else {
      const api =
        user === "admin"
          ? "v1/employee-info/by-admin?employeeUserId"
          : "v1/employee-info/by-superadmin?employeeUserId";
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/${api}=${employeeId1}`,
          {
            role: role,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          setShow1(true);
          setErr("Role Assigned Successfully");
          setRefetch(!refetch);
        })
        .catch((err) => {
          if (err.response.data.message === "no employee info found") {
            setErr("Cant Assign Role: Incomplete Employee Profile");
            setShow(true);
          } else {
            setErr(err.response.data.message);
            setShow(true);
          }
        });
    }
  };

  const ChangeStatus = (e) => {
    e.preventDefault();
    const api =
      user === "admin"
        ? `v1/users/${employeeId1}/employee/status/by-admin`
        : `v1/users/${employeeId1}/employee/status/by-superadmin`;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/${api}`,
        {
          status: selectedStatus,
          statusReason: reason,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        setShow1(true);
        setErr("Status Changed Successfully");
        setRefetch(!refetch);
      })
      .catch((err) => {
        if (
          err.response.data.message ===
            '"statusReason" is not allowed to be empty' ||
          err.response.data.message === '"statusReason" must be a string'
        ) {
          setErr("Please enter a reason for the status change");
          setShow(true);
        } else if (err.response.data.message === "no employee info found") {
          setErr("Status Change Not Allowed: Incomplete Employee Profile");
          setShow(true);
        } else if (
          err.response.data.message ===
          '"status" must be one of [approved, rejected]'
        ) {
          setErr("Select Status from the dropdown");
          setShow(true);
        } else {
          setErr(err.response.data.message);
          setShow(true);
        }
      });
  };

  const AllowAccess = () => {
    // if(employeeData?.user?.status === "pending"){
    //   setErr("This employee's status is pending, and the role cannot be assigned until the Employee status is approved.");
    //   setShow(true);
    // }
    if (employeeData?.user?.status === "rejected") {
      setErr(
        "This employee's status has been rejected, so the role cannot be assigned."
      );
      setShow(true);
    } else if (employeeData?.user?.status === "pending") {
      setErr(
        "This employee's status is pending, and the role cannot be assigned until the Employee status is approved."
      );
      setShow(true);
    } else if (role === "") {
      setErr("Please Select Role");
      setShow(true);
    } else {
      const api =
        user === "admin"
          ? "v1/employee-info/by-admin?employeeUserId"
          : "v1/employee-info/by-superadmin?employeeUserId";
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/${api}=${employeeId1}`,
          {
            role: role,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          setShow1(true);
          setErr("Role Assigned Successfully");
          setRefetch(!refetch);
        })
        .catch((err) => {
          if (err.response.data.message === "no employee info found") {
            setErr("Cant Assign Role: Incomplete Employee Profile");
            setShow(true);
          } else {
            setErr(err.response.data.message);
            setShow(true);
          }
        });
    }
  };

  const handler = (key) => {
    if (key === 1) {
      setChangeStatusModal(false);
      setAssignRoleModal(true);
    }
    if (key === 2) {
      setAssignRoleModal(false);
      setChangeStatusModal(true);
    }
  };
  const handleCancel = () => {
    setSelectedStatus(null);
    setReason("");
  };
  return (
    <div className="sm:p-8 sm:pl-[7rem] sm:pr-[7rem] p-6 pl-[3rem] pr-[3rem]">
      <div className=" flex items-center mb-[2rem]">
        <div
          onClick={() => {
            user === "admin"
              ? router("/AdminDashboard/allEmployees")
              : router("/superAdminDashboard/allEmployees");
          }}
          className="cursor-pointer"
        >
          <img className="w-[1.7rem]" src={back5} alt="" />
        </div>
        <h2 className="font-bold text-[1.4rem] sm:text-[1.8rem] ml-4">
          Employee
        </h2>
      </div>

      <div className="flex w-full mb-[3rem] sm:flex-row flex-col">
        <div
          className={`relative sm:w-[45%] w-[100%] bg-[#F2F4F8] flex flex-col items-center pt-8 pb-8 pl-8 pr-8 shadow-lg`}
        >
          {/* <div className="absolute top-5 right-5">
            <button className="bg-primary text-white p-2 rounded-md">
              Allow Access
            </button>
          </div> */}
          <div className="w-[3rem] h-[3rem] rounded-full flex justify-center items-center">
            <img
              src={employeeData?.person?.image}
              className="w-[3rem] h-[3rem] rounded-full border-[1px] border-black"
              alt=""
            />
          </div>
          <h2 className="font-semibold mt-1 capitalize mb-1">{`${
            firstName === undefined ? "----" : firstName
          } ${lastName === undefined ? "----" : lastName}`}</h2>
          {/* <h3   className="capitalize"  style={{color:'white', backgroundColor:'#57D38C', padding:'6px', borderRadius:'5px'}}>{selectedStatus}</h3> */}
          <p
            className={`text-[0.9rem] text-white p-1 ${
              employeeData?.user?.status === "pending"
                ? "bg-[#2F84F1]"
                : employeeData?.user?.status === "approved"
                ? "bg-primary"
                : "bg-[#d64242]"
            } 
            pl-4 pr-4 rounded-md text-white focus:border-none
            font-bold capitalize`}
          >
            {employeeData?.user?.status}
          </p>
          <p
            className={`pl-4 pr-4 mt-1.5 rounded-md text-black focus:border-none font-semibold capitalize`}
          >
            {roleC}
          </p>
          <p
            className={`text-[0.9rem] text-gray-500 p-1 ${
              employeeData?.user?.status === "approved"
                ? "text-primary"
                : "hidden"
            } 
            pl-4 pr-4 
            font-semibold`}
          >
            Approved-
            {moment(employeeData?.user?.statusChangeDate).format(
              "MM/DD/YYYY"
            )}{" "}
            {moment(employeeData?.user?.statusChangeDate).format("hh:mm A")}
          </p>
          <div className="w-[100%] flex items-center mt-[1rem]">
            {/* <div
              onClick={() => {
                alert("Profile")
                //   dispatch({ type: "universalPenSet", num: id });
                //   router("/EDashboard/PcardView");
                //   dispatch({ type: "gidFilterSet", num: "" });
                //   dispatch({ type: "whichViewSet", num: "pensioner" });
              }}
              className="bg-primary w-[100%] text-center pt-2 pb-2 text-white cursor-pointer"
            >
              Profile
            </div>  */}
          </div>
          <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={edit1} className="w-[2.5rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">
                <b>View Profile of Employee</b>
              </p>
              <p
                className="text-[0.65rem] text-primary cursor-pointer"
                onClick={() => {
                  dispatch({ type: "universalPenSet", num: "" });
                  user === "admin"
                    ? router("/adminDashboard/ECardView", {
                        state: {
                          emp:
                            employeeInformation?.roles[0] === "admin"
                              ? employeeInformation?.id
                              : employeeId1,
                          empRole: employeeInformation?.roles[0],
                        },
                      })
                    : router("/superAdminDashboard/ECardView", {
                        state: {
                          emp:
                            employeeInformation?.roles[0] === "admin"
                              ? employeeInformation?.id
                              : employeeId1,
                          empRole: employeeInformation?.roles[0],
                        },
                      });
                }}
              >
                Click Here
              </p>
            </div>
          </div>

          {/* <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={dis} className="w-[2.5rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">
                Edit and modify Employee Detail
              </p>
              <p
                className="text-[0.65rem] text-primary cursor-pointer"
                onClick={() => {
                  if (checkPerson === "is null") {
                    setErr(
                      "Personal data is missing or does not exist in profile"
                    );
                    setShow(true);
                  } else if (checkPerson === "not null") {
                    dispatch({ type: "universalPenSet", num: id });
                    router("/EDashboard/distributionsPage");
                  }
                }}
              >
                Click Here
              </p>
            </div>
          </div>
          <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={edit} className="w-[2.5rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">Upload documents for pensioner</p>
              <p
                onClick={() => {
                  dispatch({ type: "universalPenSet", num: id });
                  router("/EDashboard/additionalDocs");
                }}
                className="text-[0.65rem] text-primary cursor-pointer"
              >
                Click Here
              </p>
            </div>
          </div> */}

          <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={ben} className="w-[2.5rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">
                <b> Edit and Update Employee Details</b>
              </p>
              <p
                className="text-[0.65rem] text-primary cursor-pointer"
                onClick={() => {
                  if (user === "admin") {
                    dispatch({ type: "universalPenSet", num: employeeId1 });
                    dispatch({ type: "Employee" });
                    router("/AdminDashboard/editEmployee", {
                      state: {
                        emp: employeeId1,
                        role: "admin",
                      },
                    });
                    dispatch({ type: "PENSIONER" });
                    dispatch({ type: "editSet", num: "editEmployee" });
                  } else {
                    dispatch({ type: "universalPenSet", num: employeeId1 });
                    dispatch({ type: "Employee" });
                    router("/superAdminDashboard/editEmployee", {
                      state: {
                        emp: employeeId1,
                        role: "superadmin",
                      },
                    });
                    dispatch({ type: "PENSIONER" });
                    dispatch({ type: "editSet", num: "editEmployee" });
                  }
                }}
              >
                Click Here
              </p>
            </div>
          </div>

          <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={document} className="w-[2.5rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">
                <b> Revoke platform access restrictions</b>
              </p>
              <p
                className="text-[0.65rem] text-primary cursor-pointer"
                onClick={() => {
                  dispatch({ type: "universalPenSet", num: employeeId1 });
                  dispatch({ type: "Employee" });

                  router("/superAdminDashboard/Revoke/AccessSetting", {
                    state: {
                      emp: employeeId,
                    },
                  });
                }}
              >
                Click Here
              </p>
            </div>
          </div>
        </div>

        {employeeInformation?.roles[0] === "admin" ? (
          ""
        ) : (
          <div className="sm:w-[45%] w-[100%] sm:ml-[4rem] pt-8 sm:pb-8 sm:pl-8 sm:pr-8">
            <div className="flex justify-between items-center">
              <h2 className="text-[1rem] font-bold capitalize">{user}</h2>
              {/* <img className="w-[1.5rem] h-[1.5rem]" src={dod} alt="" /> */}
            </div>
            {/* <h2 className="mt-6 text-[1rem] font-medium capitalize">
            {user}
          </h2> */}
            <div className="flex mt-4 mb-6 items-center">
              <div className="bg-[#DDE1E6] w-[3rem] h-[3rem] rounded-full">
                <img
                  src={dataUser?.data?.user?.person?.image}
                  className="w-[3rem] h-[3rem] rounded-full"
                  alt="profile"
                />
              </div>
              <div className="ml-3">
                <h2 className="text-[0.9rem] font-semibold">
                  {dataUser?.data?.user?.person?.firstName}{" "}
                  {dataUser?.data?.user?.person?.lastName}
                </h2>
                {/* <p className="text-[0.8rem] text-[#878D96]">22/04/03</p> */}
              </div>
            </div>
            <div className="w-[100%] flex items-center mt-[1rem] gap-3 mb-5">
              <div
                onClick={() => {
                  handler(1);
                }}
                className="bg-primary w-[100%] text-center pt-2 pb-2 text-white cursor-pointer hover:bg-hover-primary"
              >
                Assign Role
              </div>
              <div
                onClick={() => {
                  handler(2);
                }}
                disabled={true}
                //   ${employeeData?.employeeInfo?.role === null ? "bg-gray-300 text-gray-500 pointer-events-none" : "bg-primary text-white hover:bg-green-400"
                // }
                className={`w-full text-center pt-2 pb-2 cursor-pointer ${
                  employeeData?.employeeInfo?.role === null ||
                  employeeData?.employeeInfo === null
                    ? "bg-gray-300 text-gray-500 pointer-events-none"
                    : "bg-primary text-white hover:bg-hover-primary"
                }`}
              >
                Change Status
              </div>
            </div>

            {(employeeData?.employeeInfo?.role === null ||
              employeeData?.employeeInfo === null) && (
              <p className="text-sm text-red-500 mb-2">
                Note: First, you need to assign a role, and then change the
                status.
              </p>
            )}

            {assignRoleModal ? (
              <div
                className={`
           w-[100%] bg-slate-50 flex justify-center items-center`}
              >
                <div className="relative rounded-xl sm:w-[30rem] w-[35rem] pb-6 flex  sm:p-7 p-5 flex-col bg-[#F2F4F8]">
                  <div>
                    <p className="font-bold mb-2 text-[1rem]">Employee Roles</p>
                    <div className="grid grid-cols-2 gap-4  mb-2 p-3 ">
                      {roles &&
                        roles.map((rol, key) => (
                          <label
                            key={key}
                            className="inline-flex items-center justify-between"
                          >
                            <div className="flex items-center">
                              <input
                                type="radio"
                                className="form-radio text-indigo-600 h-4 w-4"
                                value={rol}
                                checked={role === rol}
                                onChange={() => setRole(rol)}
                                // disabled={role !== "" ? true : false}
                              />
                              <span className="ml-2 text-gray-700 capitalize">
                                {rol}
                              </span>
                            </div>
                          </label>
                        ))}
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <button
                      onClick={() => {
                        setAssignRoleModal(false);
                        setRole("");
                      }}
                      className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
                    >
                      Cancel
                    </button>
                    <button
                      className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
                      onClick={() => {
                        AssignRole();
                      }}
                    >
                      Assign
                    </button>
                  </div>
                </div>
              </div>
            ) : changeStatusModal ? (
              <form className="mt-8" onSubmit={ChangeStatus}>
                <Select
                  placeholder={
                    employeeData?.user?.status === "pending"
                      ? "Pending"
                      : employeeData?.user?.status === "approved"
                      ? "Approved"
                      : "Rejected"
                  }
                  showSearch
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e)}
                  optionFilterProp="children"
                  className="wow1 capitalize"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={optopt}
                />
                <textarea
                  onChange={(e) => setReason(e.target.value)}
                  value={reason}
                  type="text"
                  placeholder="Enter Reason"
                  className="sqw lg:text-[1.1rem] h-[6rem] w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-2 mt-2"
                />

                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={handleCancel}
                    className={`lg:text-[1rem] bg-[#F2F4F8] text-black rounded-[0.5rem] p-2  pr-8 pl-8 mt-1`}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={` relative lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white ml-2 mt-1 rounded-[0.5rem] p-2  pr-8 pl-8`}
                  >
                    {/* <Spin
                      style={{ display: `${isLoading ? "" : "none"}` }}
                      indicator={antIcon}
                    /> */}
                    <span>Done</span>
                    <div
                      className={`w-[100%] h-[100%] "hidden"
                          } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
                    ></div>
                  </button>
                </div>
              </form>
            ) : (
              ""
            )}
          </div>
        )}
      </div>

      {/* Error Modal */}
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>

      <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          {/* <div
                        className="absolute top-[-11%] left-[42%]"
                        onClick={() => setShow1(false)}
                    >
                        <img src={across} alt="" />
                    </div> */}
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow1(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 bg-gradient-to-tr ml-4 bg-primary text-white w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeProfile;

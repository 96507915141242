import React from "react";
import { Form, Input, Row, Col, Upload, Select, DatePicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";

const { RangePicker } = DatePicker;

const typeOptions = [
  { label: "Leave Request", value: "LeaveRequest" },
  { label: "Device Request", value: "deviceRequest" },
  { label: "Software Access Request", value: "softwareAccessRequest" },
  { label: "Administrative Request", value: "administrativeRequest" },
];

const leaveTypeOptions = [
  { label: "Sick Leave", value: "sick" },
  { label: "Casual Leave", value: "casual" },
  { label: "Maternity Leave", value: "maternity" },
  { label: "Paternity Leave", value: "paternity" },
  { label: "Vacation Leave", value: "vacation" },
  { label: "Death Leave", value: "death" },
  { label: "Bereavement Leave", value: "bereavement" },
  { label: "Injury Leave", value: "injury" },
  { label: "Family Leave", value: "family" },
  { label: "Cultural Leave", value: "cultural" },
  { label: "Religious Leave", value: "religion" },
  { label: "Jury Duty Leave", value: "jury" },
  { label: "Military Leave", value: "military" },
];

const deviceTypeOptions = [
  { label: "Mobile", value: "mobile" },
  { label: "Laptop", value: "laptop" },
  { label: "Tablet", value: "tab" },
];

const softwareRequestToOptions = [
  { label: "HR", value: "HR" },
  { label: "Admin", value: "Admin" },
  { label: "Manager", value: "Manager" },
];

const departmentOptions = [
  { label: "IT", value: "IT" },
  { label: "Medical", value: "Medical" },
  { label: "Staff", value: "Staff" },
  { label: "HR", value: "HR" },
];

const requestToOptions = [
  { label: "Manager", value: "Manager" },
  { label: "Admin", value: "Admin" },
  { label: "HR", value: "HR" },
];

const EditRequest = ({ initialValues = {}, onSave }) => {
  const [form] = Form.useForm();
  const router = useNavigate();

  const handleFinish = (values) => {
    console.log("Form values:", values);
    router("/Devices");
    onSave && onSave(values);
  };

  const onCancel = () => {
    router("/ERequests");
  };

  return (
    <div className="p-6 bg-white">
      <Form
        form={form}
        layout="vertical"
        initialValues={{ type: "LeaveRequest", ...initialValues }}
        onFinish={handleFinish}>
        <Row gutter={16}>

          <Col span={12}>
            <Form.Item label="Type" name="type">
              <Select placeholder="Select type" options={typeOptions} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Department" name="department">
              <Select placeholder="Select department" options={departmentOptions} />
            </Form.Item>
          </Col>

          {/* Conditional Fields based on Request Type */}
          <Col span={24}>
            <div className="w-full">
              <Form.Item
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.type !== currentValues.type
                }>
                {({ getFieldValue }) => {
                  const requestType = getFieldValue("type");

                  return (
                    <Row gutter={16}>
                      {requestType === "LeaveRequest" && (
                        <>
                          <Col span={12}>
                            <Form.Item
                              label="Leave Type"
                              name="leaveType"
                              rules={[{ required: true, message: "Please select leave type" }]}>
                              <Select placeholder="Select leave type" options={leaveTypeOptions} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Start Date"
                              name="startDate"
                              rules={[{ required: true, message: "Please select start date" }]}>
                              <DatePicker
                                placeholder="Select start date"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="End Date"
                              name="endDate"
                              rules={[{ required: true, message: "Please select end date" }]}>
                              <DatePicker placeholder="Select end date" style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Note" name="note">
                              <Input placeholder="Enter note" />
                            </Form.Item>
                          </Col>

                          {/* Pay Code */}
                          <Col span={12}>
                            <Form.Item
                              label="Pay Code"
                              name="payCode"
                              rules={[{ required: true, message: "Please select pay code" }]}>
                              <Select
                                placeholder="Select pay code"
                                options={[
                                  { label: "Vacation", value: "vacation" },
                                  { label: "Sick", value: "sick" },
                                  { label: "Holiday", value: "holiday" },
                                  { label: "PTO", value: "pto" },
                                  { label: "UTO", value: "uto" },
                                ]}
                              />
                            </Form.Item>
                          </Col>
                          {/* Start Time */}
                          <Col span={12}>
                            <Form.Item
                              label="Start Time"
                              name="startTime"
                              rules={[{ required: true, message: "Please enter start time" }]}>
                              <Input placeholder="Enter start time (e.g., HH:MM)" />
                            </Form.Item>
                          </Col>
                          {/* Duration */}
                          <Col span={12}>
                            <Form.Item
                              label="Duration"
                              name="duration"
                              rules={[{ required: true, message: "Please enter duration" }]}>
                              <Input placeholder="Enter duration (e.g., hours or days)" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Contact" name="contact">
                              <Input placeholder="Enter contact" />
                            </Form.Item>
                          </Col>
                          {/* Phone Number */}
                          <Col span={12}>
                            <Form.Item
                              label="Phone Number"
                              name="phoneNumber"
                              rules={[{ required: true, message: "Please enter phone number" }]}>
                              <Input placeholder="Enter phone number" />
                            </Form.Item>
                          </Col>
                          {/* Email */}
                          <Col span={12}>
                            <Form.Item
                              label="Email"
                              name="email"
                              rules={[{ type: "email", message: "Please enter a valid email" }]}>
                              <Input placeholder="Enter email" />
                            </Form.Item>
                          </Col>
                          {/* Approved By */}
                          <Col span={12}>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="Approved By: Date"
                                  name="approvedDate"
                                  rules={[
                                    { required: true, message: "Please select approval date" },
                                  ]}>
                                  <DatePicker placeholder="Select date" style={{ width: "100%" }} />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label="Approved By: Time"
                                  name="approvedTime"
                                  rules={[
                                    { required: true, message: "Please enter approval time" },
                                  ]}>
                                  <Input placeholder="Enter time (e.g., HH:MM)" />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  label="Approved By: Signature"
                                  name="approvedSignature"
                                  rules={[{ required: true, message: "Please enter signature" }]}>
                                  <Input placeholder="Enter signature" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={12}>
                            <p className="text-lg font-semibold">Accural as of Octobar 30th 2024</p>
                            <div className="grid grid-cols-2 mt-2">
                              <div className="text-sm font-medium">Vacation</div>
                              <div className="">10</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="text-sm font-medium">Sick</div>
                              <div className="">5</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="text-sm font-medium">Holiday</div>
                              <div className="">10</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="text-sm font-medium">PTO</div>
                              <div className="">10</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="text-sm font-medium">UTO</div>
                              <div className="">10</div>
                            </div>
                          </Col>
                        </>
                      )}
                      {requestType === "deviceRequest" && (
                        <>
                          <Col span={12}>
                            <Form.Item
                              label="Device Type"
                              name="deviceType"
                              rules={[{ required: true, message: "Please select device type" }]}>
                              <Select
                                placeholder="Select device type"
                                options={deviceTypeOptions}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Request Date"
                              name="requestDate"
                              rules={[{ required: true, message: "Please select request date" }]}>
                              <DatePicker
                                placeholder="Select request date"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Request To"
                              name="requestTo"
                              rules={[{ required: true, message: "Please select request to" }]}>
                              <Select
                                placeholder="Select person to request to"
                                options={requestToOptions}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Note" name="note">
                              <Input placeholder="Enter note" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Total Devices" name="totalDevices">
                              <Input placeholder="Enter No of Devices" />
                            </Form.Item>
                          </Col>
                        </>
                      )}

                      {requestType === "softwareAccessRequest" && (
                        <>
                          <Col span={12}>
                            <Form.Item
                              label="Software Name"
                              name="softwareName"
                              rules={[{ required: true, message: "Please enter software name" }]}>
                              <Input placeholder="Enter software name" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Request To"
                              name="requestTo"
                              rules={[{ required: true, message: "Please select request to" }]}>
                              <Select
                                placeholder="Select person to request to"
                                options={softwareRequestToOptions}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Request Date"
                              name="requestDate"
                              rules={[{ required: true, message: "Please select request date" }]}>
                              <DatePicker
                                placeholder="Select request date"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Note" name="note">
                              <Input placeholder="Enter note" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Contact" name="contact">
                              <Input placeholder="Enter contact" />
                            </Form.Item>
                          </Col>
                        </>
                      )}

                      {requestType === "administrativeRequest" && (
                        <>
                          <Col span={12}>
                            <Form.Item
                              label="Request Note"
                              name="requestNote"
                              rules={[{ required: true, message: "Please enter request note" }]}>
                              <Input placeholder="Enter request note" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Request To"
                              name="requestTo"
                              rules={[{ required: true, message: "Please select request to" }]}>
                              <Select
                                placeholder="Select person to request to"
                                options={softwareRequestToOptions}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Received Date"
                              name="receivedDate"
                              rules={[{ required: true, message: "Please select received date" }]}>
                              <DatePicker
                                placeholder="Select received date"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Row>
                  );
                }}
              </Form.Item>
            </div>
          </Col>
        </Row>

        <div className="flex justify-end gap-3 mt-4">
          <Button transparent onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditRequest;

import { Collapse } from "antd";
import Store from "./Store";
import { useSelector } from "react-redux";
const { Panel } = Collapse;
const Stores = ({ stores }) => {
  const user = useSelector((state) => state.user);
  const callback = (key) => {
    console.log(key);
  };
  return (
    <div className="sm:p-10">
      {stores?.length === 0 ? (
        <div className="p-6 bg-[#F4F5FE] rounded-md sm:p-8  mb-8 ">
          <h3 className="text-[1rem] font-semibold leading-none text-gray-600 text-center">
            No Record Found
          </h3>
        </div>
      ) : (
        <Collapse defaultActiveKey={["1"]} onChange={callback} accordion={true}>
          {stores?.map((store, index) => {
            return (
              <Panel
                header={
                  user === "vendor-owner" ? store?.name : store?.customStoreName
                }
                key={index + 1}
                className="sm:p-3 text-md text-gray-800 font-medium"
              >
                <Store
                  title={
                    user === "vendor-owner"
                      ? store?.name
                      : store?.customStoreName
                  }
                  data={store}
                />
              </Panel>
            );
          })}
        </Collapse>
      )}
    </div>
  );
};

export default Stores;

import React, { useState, useEffect } from "react";
import { Rate, Tabs } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import bar from "../../../imgs/bar.webp";
import { useNavigate } from "react-router-dom";
const { TabPane } = Tabs;
const EventDetails = ({ data }) => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const cart = useSelector((state) => state.cartData);
  const [localCart, setCart] = useState(cart);
  const [mainImage, setMainImage] = useState(data.coverPhotos[0]);
  const fav = useSelector((state) => state.fav);
  const [favorite, setFavorite] = useState(fav);
  const [flag, setFlag] = useState("n");
  const initial = true;
  const initialFav = true;
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const thumbnailsToShow = 3;
  const handlePrev = () => {
    setThumbnailIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : data.coverPhotos.length - thumbnailsToShow
    );
  };
  const handleNext = () => {
    setThumbnailIndex((prevIndex) =>
      prevIndex < data.coverPhotos.length - thumbnailsToShow ? prevIndex + 1 : 0
    );
  };
  const ratingsData = {
    0: [{ name: "John Doe", comment: "No stars given." }],
    1: [{ name: "Jane Smith", comment: "Poor quality." }],
    2: [{ name: "Alice Johnson", comment: "Not great, but okay." }],
    3: [{ name: "Chris Lee", comment: "Average experience." }],
    4: [{ name: "Emily Davis", comment: "Good, but not perfect." }],
    5: [{ name: "Michael Brown", comment: "Excellent! Highly recommended." }],
  };
  const displayedThumbnails = data.coverPhotos.slice(
    thumbnailIndex,
    thumbnailIndex + thumbnailsToShow
  );
  useEffect(() => {
    setCart(cart);
  }, [cart]);
  useEffect(() => {
    setFavorite(fav);
  }, [fav]);
  useEffect(() => {
    if (flag === "buy") {
      router("/Inventory-Cart");
      setFlag("sd");
    }
  }, [flag]);
  useEffect(() => {
    console.log("OkKK", localCart);
    if (!initial) {
      dispatch({ type: "Cart", num: localCart });
    }
  }, [localCart]);
  useEffect(() => {
    console.log("fav", favorite);
    if (!initialFav) {
      dispatch({ type: "Fav", num: favorite });
    }
  }, [favorite]);
  // const onDecrement = (id) => {
  //   setinitial(false);
  //   setCart(
  //     localCart.map((item) =>
  //       item.id === id && item.quantity > 1
  //         ? { ...item, quantity: item.quantity - 1 }
  //         : item
  //     )
  //   );
  // };

  // const addToCart = (event) => {
  //   setinitial(false);
  //   const cartItem = {
  //     id: event.id,
  //     image: event.coverPhotos[0],
  //     name: event.name,
  //     price: event.price,
  //     amount: event.amount,
  //   };
  //   const updatedCart = [...localCart, cartItem];
  //   setCart(updatedCart);
  // };
  // const addToFav = (event) => {
  //   setinitialFav(false);
  //   const favItems = {
  //     id: event.id,
  //     coverPhotos: event.coverPhotos,
  //     name: event.name,
  //     quantity: event.quantity,
  //     price: event.price,
  //     amount: event.amount,
  //   };

  //   const updatedFav = [...favorite, favItems];
  //   setFavorite(updatedFav);
  // };

  // const removeFromFav = (eventId) => {
  //   setinitialFav(false);
  //   const updatedFav = favorite.filter((item) => item.id !== eventId);
  //   setFavorite(updatedFav);
  // };

  // const isAddedToFav = (eventId) => {
  //   return favorite.some((item) => item.id === eventId);
  // };

  // const removeFromCart = (eventId) => {
  //   setinitial(false);
  //   const updatedCart = localCart.filter((item) => item.id !== eventId);
  //   setCart(updatedCart);
  // };

  // const isAddedToCart = (eventId) => {
  //   return localCart.some((item) => item.id === eventId);
  // };

  return (
    <div className="flex w-full pb-[5rem] pt-[3rem] items-center justify-center">
      <div className="w-[80%] flex flex-col">
        <div className="w-full h-full flex flex-col">
          <div
            key={data.id}
            className="w-full items-center justify-center flex flex-row gap-12 mt-2"
          >
            <div className="flex-shrink-0 w-[80%] h-[300px] mb-2">
              <img
                className="w-full h-[300px] rounded-[1.2rem]"
                src={mainImage}
                alt={data.name}
              />
            </div>
            <div className="relative flex flex-col items-center">
              <button
                onClick={handlePrev}
                className="absolute top-0 z-10 p-2 rounded-full"
              >
                <UpOutlined />
              </button>
              <div className="flex flex-col gap-2 justify-between items-center overflow-hidden">
                {displayedThumbnails.map((coverPhoto, index) => (
                  <div
                    key={index}
                    className="w-full h-[100px] border rounded-[0.8rem] cursor-pointer"
                    onMouseEnter={() => setMainImage(coverPhoto)}
                  >
                    <img
                      className="w-full h-full rounded-[0.8rem]"
                      src={coverPhoto}
                      alt={`${data.name}-${index}`}
                    />
                  </div>
                ))}
              </div>
              <button
                onClick={handleNext}
                className="absolute bottom-0 z-10 p-2 rounded-full"
              >
                <DownOutlined />
              </button>
            </div>
          </div>

          <div className="w-full flex flex-col border-b mt-5 w-full">
            <div className="w-full flex items-center justify-between pb-4 border-b">
              <div className="flex flex-col w-[40%]">
                <div className="flex gap-4 mb-2 items-center">
                  <h1 className="text-3xl font-semibold">{data.name}</h1>
                </div>
                <div className="flex gap-4 items-center w-full justify-between">
                  <h1 className="text-lg font-semibold">SKU:</h1>
                  <h1 className="font-semibold text-gray-500">12345</h1>
                </div>
                <div className="flex gap-4 items-center w-full justify-between">
                  <h1 className="text-lg font-semibold">Item Code:</h1>
                  <h1 className="font-semibold text-gray-500">1asf5</h1>
                </div>
                <div className="flex gap-4 items-center w-full justify-between">
                  <h1 className="text-lg font-semibold">Inventory:</h1>
                  <h1 className="font-semibold text-gray-500">Grocery</h1>
                </div>
                <div className="flex gap-4 items-center w-full justify-between">
                  <h1 className="text-lg font-semibold">Category :</h1>
                  <h1 className="font-semibold text-gray-500">Fruits</h1>
                </div>
              </div>
              <div className="flex-shrink-0 w-[20%] h-[150px] mb-2">
                <img
                  className="w-full h-[150px] rounded-[1.2rem]"
                  src={bar}
                  alt={data.name}
                />
              </div>
            </div>
            <div className="flex flex-col w-[40%] gap-2 pb-4">
              <div className="flex gap-4 mt-4 items-center w-full justify-between">
                <h1 className="font-semibold text-xl text-red-600">Price :</h1>
                <p className="font-semibold text-xl text-red-600">
                  ${data.price}
                  {/* /{data.amount} */}
                </p>
              </div>
              <div className="flex gap-4 items-center w-full justify-between">
                <h1 className="text-lg font-semibold  line-through">
                  Price : 100
                </h1>
                <p className="font-semibold text-gray-500">-20%</p>
              </div>
              <div className="flex gap-4 items-center w-full justify-between">
                <h1 className="text-lg font-semibold">Category :</h1>
                <h1 className="font-semibold text-gray-500">Fruits</h1>
              </div>
              <div className="flex gap-4 items-center w-full justify-between">
                <h1 className="text-lg font-semibold">Low Limit :</h1>
                <h1 className="font-semibold text-gray-500">4</h1>
              </div>
              <div className="flex gap-4 items-center w-full justify-between">
                <h1 className="text-lg font-semibold">Quantity :</h1>
                <h1 className="font-semibold text-gray-500">14</h1>
              </div>
              <div className="flex gap-4 items-center w-full justify-between">
                <h1 className="text-lg font-semibold">Warranty :</h1>
                <h1 className="font-semibold text-gray-500">No Warranty</h1>
              </div>
              <div className="flex gap-4 items-center w-full justify-between">
                <h1 className="text-lg font-semibold">Vendor :</h1>
                <h1 className="font-semibold text-gray-500">
                  Semule's Grocery Store
                </h1>
              </div>
              <div className="flex gap-4 items-center w-full justify-between">
                <h1 className="text-lg font-semibold">Location :</h1>
                <h1 className="font-semibold text-gray-500">ABC</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex gap-4">
          <div className="mt-6 w-full">
            <h1 className="font-semibold text-gray-800 text-xl">Description</h1>
            <p className="mb-10 mt-2">
              Indulge in the succulent sweetness of our premium pears, nature's
              gift wrapped in a delicate green skin. Each bite unveils a juicy,
              tender flesh bursting with refreshing flavor, a testament to the
              orchard's care and expertise. Whether enjoyed fresh or
              incorporated into culinary delights, our pears promise a
              delightful sensory experience, elevating any occasion with their
              unparalleled taste and texture. Savor the crisp, aromatic essence
              of our meticulously selected pears, a symbol of purity and
              wholesomeness
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2 mt-2">
          <h1 className="font-semibold text-gray-800 text-xl">
            Product Ratings
          </h1>
          <div className="mt-2 items-center flex gap-4">
            <Rate allowHalf defaultValue={4.5} />
            <h1 className="font-semibold text-primary mt-2">14 Ratings</h1>
          </div>
          <Tabs defaultActiveKey="0" tabBarStyle={{ marginBottom: 0 }}>
            {Object.keys(ratingsData).map((key) => (
              <TabPane
                tab={
                  <span className="border p-2 ml-2  rounded-md bg-gray-200 hover:bg-gray-300">
                    <span className="text-semibold p-2">{key} Stars</span>
                  </span>
                }
                key={key}
              >
                {ratingsData[key].map((rating, index) => (
                  <div key={index} className="p-2">
                    <p className="font-bold">{rating.name}</p>
                    <p>{rating.comment}</p>
                  </div>
                ))}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;

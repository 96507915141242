import React, { useState, useEffect } from "react";
import { Button } from "antd";
import back5 from "../../../imgs/back5.png";
import ConfirmOrder from "../../../imgs/CartDone.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";

const EventDetails = () => {
  const router = useNavigate();
  const cart = useSelector((state) => state.cartData);
  const [localCart, setCart] = useState(cart);
  const [show, setShow] = useState(false);
  const [initial, setinitial] = useState(true);
  const [showDone, setShowDone] = useState(false);

  const CartItem = ({ item, onIncrement, onDecrement, onDelete }) => {
    return (
      <div className="flex items-center justify-between w-full items-center rounded-xl mt-2 bg-white p-4 border-b border-gray-300 relative">
        <div className="flex items-center gap-4">
          <img
            src={item.image}
            alt={item.name}
            className="w-24 h-24 rounded-md "
          />
          <div className="ml-4 flex-grow">
            <h2 className="text-lg font-semibold">{item.name}</h2>
            <p className="text-gray-600">
              {item.price} / {item.amount}
            </p>
          </div>
        </div>

        <div className="flex items-center mt-2 gap-3">
          <p className="font-semibold">Quantity</p>
          <p
            onClick={() => onDecrement(item.id)}
            className="font-semibold text-xl text-black cursor-pointer px-[10px] rounded-md"
          >
            -
          </p>
          <span className="mx-2 border p-2 font-semibold px-3 rounded-md">
            {item.quantity}
          </span>
          <p
            onClick={() => onIncrement(item.id)}
            className="font-semibold text-xl cursor-pointer text-primary px-2 rounded-md"
          >
            +
          </p>
          <p className="font-semibold">Dozen</p>
        </div>
        <div></div>
        <Button
          onClick={() => onDelete(item.id)}
          className="absolute top-2 flex items-center justify-center right-2 text-red-600"
          icon={<DeleteOutlined />}
        />
        <div className="absolute bottom-[14px] flex gap-4 items-center right-2">
          <p className="">Amount:-</p>
          <p className="font-semibold">$ {item.price}</p>
        </div>
      </div>
    );
  };
  useEffect(() => {
    setCart(cart);
  }, [cart]);

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setShow(false);
        router("/Inventory-Product");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [show]);

  const handleIncrement = (id) => {
    setinitial(false);
    setCart(
      localCart.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrement = (id) => {
    setinitial(false);
    setCart(
      localCart.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };
  return (
    <div className="flex bg-[#D9EFED] w-full pb-[5rem] pt-[5rem] items-center justify-center">
      <div className="sm:w-[80%] flex flex-col items-center">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => {
                router("/Inventory-Payment");
              }}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Order Summary
              </h2>
            </div>
          </div>
        </div>
        <div className="w-full mt-6 p-6 rounded-xl flex flex-col gap-4 bg-white">
          <div className="flex flex-col w-full gap-2">
            <div className="w-full bg-gray-100 rounded-lg">
              {localCart.length > 0 && (
                <div className="mx-auto w-full flex flex-col gap-2  my-8 px-[5rem]">
                  <div className="space-y-6">
                    {localCart?.map((item) => (
                      <CartItem
                        item={item}
                        onIncrement={handleIncrement}
                        onDecrement={handleDecrement}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full grid grid-cols-2 bg-white mt-4 gap-4">
              <div className="w-full w-full bg-gray-100 p-6 rounded-lg">
                <div className="space-y-4">
                  <div className="space-y-2">
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="font-semibold">Delivery Method</dt>
                      <dd className="text-base font-medium text-gray-900 dark:text-white">
                        Standard
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4">
                      <dt className="font-semibold">Payment Option</dt>
                      <dd className="text-base font-medium text-gray-900 dark:text-white">
                        Cash on Delivery
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="w-full bg-gray-100 p-6 rounded-lg">
                <div className="space-y-4">
                  <div className="space-y-2">
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="font-semibold">Total Items:</dt>
                      <dd className="text-base font-medium text-gray-900 dark:text-white">
                        2
                      </dd>
                    </dl>
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="font-semibold">Total:</dt>
                      <dd className="text-base font-medium text-gray-900 dark:text-white">
                        $5.00
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4">
                      <dt className="font-semibold">Shipping:</dt>
                      <dd className="text-base font-medium text-gray-900 dark:text-white">
                        $4.65
                      </dd>
                    </dl>
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="font-semibold">Discount:</dt>
                      <dd className="text-base font-medium text-gray-900 dark:text-white">
                        $2.00
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4">
                      <dt className="font-bold">SubTotal:</dt>
                      <dd className="text-base font-medium text-gray-900 dark:text-white">
                        $10.90
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex mt-[3rem] items-center justify-center gap-4">
              <Button
                onClick={() => router("/Inventory-Payment")}
                className="bg-red-600 rounded-xl font-semibold cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white"
              >
                Cancel Order
              </Button>
              <Button
                onClick={() => setShowDone(true)}
                className="bg-primary rounded-xl font-semibold cursor-pointer pl-8 pr-8 h-[2.5rem] text-semibold text-white"
              >
                Confirm Order
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          showDone ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[22rem] pb-6 flex items-center justify-center p-3 bg-white flex-col">
          <img
            onClick={() => setShowDone(false)}
            src={ConfirmOrder}
            alt=""
            className="w-20 h-20"
          />
          <p className="font-bold text-lg mt-[1.5rem] text-primary">
            Order sent
          </p>
          <p className="text-center mt-5 mb-5">
            Your quotation request has been successfully submitted.
          </p>
          <button
            onClick={() => setShowDone(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;

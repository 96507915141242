import Eregistration from "./Parts/Eregistration";
import Eidentification from "./Parts/Eidentification";
import { useState, useEffect } from "react";
import logo1 from "../../imgs/logo12.png";
import back5 from "../../imgs/back5.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import across from "../../imgs/across.png";

const Eaccount = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const employeeData = location?.state && location?.state?.employeeData;
  const role = location?.state && location?.state?.role;
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const employeeId = location?.state && location?.state?.emp;
  const profileData = useSelector((state) => state.profileData);
  const [wow, setWow] = useState(0);
  const [show, setShow] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const Show = [Eregistration, Eidentification];
  const Dis = Show[wow];
  const changeHandler = (e) => {
    if (e === "R") {
      setWow(0);
    } else {
      setWow(1);
    }
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/employee-profile/${employeeId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        dispatch({ type: "setProfileData", num: data.data });
      })
      .catch(() => {});
  }, []);
  const makeAdmin = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/users/${employeeId}/convert-employee-to-admin`,
        {
          consumerInfo: {
            ip: "132.43.4.2",
            location: "12.23434, 23.434342",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        router("/superAdminDashboard/allEmployees");
      })
      .catch(() => {});
  };
  return (
    <div className="pt-[4rem] pb-[5rem] md:pl-[10rem] md:pr-[10rem] pl-5 pr-5">
      {profileData && (
        <div>
          <div className="flex md:items-center md:flex-row flex-col md:justify-between justify-left mb-[3rem] ml-0">
            <div className="flex flex-row items-center md:mb-0 mb-4 md:justify-center">
              <div
                onClick={() => {
                  role === "admin"
                    ? router("/AdminDashboard/employeeProfile", {
                        state: {
                          employeeId: employeeId,
                        },
                      })
                    : role === "superadmin"
                    ? router("/superAdminDashboard/employeeProfile", {
                        state: {
                          employeeId: employeeId,
                        },
                      })
                    : router("/EDashboard");
                }}
                className="mr-2 cursor-pointer"
                // className="absolute top-[1] cursor-pointer sm:left-[-4%] left-[-8%]"
              >
                <img className="sm:w-[1.7rem] w-[1.4rem]" src={back5} alt="" />
              </div>
              <div className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                {employeeId
                  ? "Edit Employee"
                  : employeeData
                  ? "Employee Account"
                  : dataUser.data.user.roles[0] === "admin"
                  ? "Admin Account"
                  : dataUser.data.user.roles[0] === "superadmin"
                  ? "Super Admin Account"
                  : "Employee Account"}
              </div>
            </div>
            <div className="font-bold sm:text-[1.8rem] text-[1.4rem]">
              {role === "superadmin" && employeeId && (
                <button
                  onClick={() => {
                    if (profileData?.user?.status === "rejected") {
                      setErr(
                        "Unable to assign the admin role to the rejected employee."
                      );
                      setShowErr(true);
                    } else if (profileData?.user?.status === "pending") {
                      setErr(
                        "Unable to assign the admin role to the pending employee."
                      );
                      setShowErr(true);
                    } else {
                      setShow(true);
                    }
                  }}
                  className={`text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
                >
                  Assign Admin Role
                </button>
              )}
            </div>
          </div>
          <div className="lg:mb-[4rem] flex mb-[2rem] sm:pr-0 sm:pl-0 pr-2 pl-2">
            <div className="cursor-pointer" onClick={() => changeHandler("R")}>
              <h2
                className={`lg:text-[1.1rem] ${
                  wow === 0 ? "font-semibold" : ""
                } `}
              >
                Registration Details
              </h2>
              <div
                className={`lg:w-[9.5rem] lg:h-[2px] mt-2 w-10 h-[2px] ${
                  wow === 0 ? "bg-primary" : "bg-[#E8E8E8]"
                } `}
              ></div>
            </div>

            {/* <div className="cursor-pointer" onClick={() => changeHandler("I")}>
              <h2
                className={`lg:text-[1.1rem] ml-6 ${
                  wow === 1 ? "font-semibold" : ""
                } `}
              >
                Identification Details
              </h2>
              <div
                className={`lg:w-[9.5] lg:h-[2px]  ml-6 mt-2  h-[2px]  ${
                  wow === 1 ? "bg-primary" : "bg-[#E8E8E8]"
                }  `}
              ></div>
            </div> */}
          </div>
          <div>
            <Dis />
          </div>
        </div>
      )}
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
          // className="absolute top-[-11%] left-[42%]"
          // onClick={() => setShow1(false)}
          >
            <img src={logo1} alt="" className="w-20 h-20" />
          </div>
          <p className="text-center mt-[1rem] mb-5">
            Do you want to make employee as admin?
          </p>
          <div className=" w-[80%] flex items-center justify-between">
            <button
              type="button"
              onClick={() => setShow(false)}
              className="cursor-pointer rounded-xl flex items-center justify-center text-center pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[48%]"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={makeAdmin}
              className="cursor-pointer text-center flex items-center justify-center rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[48%]"
            >
              Assign
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showErr ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShowErr(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShowErr(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Eaccount;

import React, { useEffect, useState } from "react";
import PageHeader from "../pageHeader/pageHeader";
import { useSelector } from "react-redux";
import moment from "moment";
import ErrorModal from "../../Merchant/Modals/errorModal";
import logo from "../../../imgs/logo12.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
let dob;
let formattedDOB;
let age;
const SelectProgram = () => {
  const router = useNavigate();
  const appDetail = useSelector((state) => state.appDetail);
  const dataUser = useSelector((state) => state.dataUser);
  const distributionCenter = useSelector((state) => state.distributionCenter);
  const [showCancel, setShowCancel] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [err, setErr] = useState("");
  const [reason, setReason] = useState("");
  const calculateAge = (dob) => {
    return moment().diff(moment(dob, "YYYY-MM-DD"), "years");
  };
  useEffect(() => {
    dob = appDetail.bookForUser?.person?.dateOfBirth;
    formattedDOB = moment(dob).format("DD/MM/YYYY");
    age = calculateAge(dob);
  }, [appDetail]);
  useEffect(() => {
    console.log("dob", dob, "formatteddate", formattedDOB, "Age", age);
  }, []);
  const isDateTimePassed = (date, time) => {
    const dateTime = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss");
    const now = moment();
    return dateTime.isBefore(now);
  };
  console.log("APP",appDetail, distributionCenter)
  const cancel = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/cancel-bulk/by-mhsss`,
        {
          ids: [appDetail?.id],
          cancelReason: reason,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then(() => {
        setShowCancel(false);
        router("/pensionerVerification/AllAppointments");
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErr(true);
      });
  };
  return (
    <div className="bg-[#ebfff3]  flex flex-col w-full p-4 min-h-screen">
      <PageHeader
        header={true}
        title={"Appointment Detail"}
        route={"/pensionerVerification/AllAppointments"}
      />

      <div className="flex justify-center w-full">
        <div
          className={`bg-gray-100 shadow-lg rounded-sm mx-4 m-5 p-6 w-full sm:w-[90%] w-full`}
        >
          <div className="border p-3 gap-1 sm:w-[80%] md:w-[50%] w-full bg-white sm:px-6 px-2 rounded-md shadow-sm">
            <div className="w-full flex sm:flex-row flex-col gap-1 mb-4 justify-between">
              <p className="font-semibold text-gray-500">Slot booked For</p>
              <div className="px-4 py-1 bg-primary rounded-md text-white">
                {moment(appDetail?.date).format("YYYY/MM/DD")}
                {", "}
                {moment(appDetail?.fromTime, "HH:mm:ss").format("hh:mm A")}
              </div>
            </div>
            <div className="mb-3 flex gap-3">
              <img
                src={appDetail?.bookForUser?.person?.image}
                alt="Profile"
                className="w-20 h-20 rounded-full"
              />
              <div>
                <h1 className="font-bold text-lg">
                  {appDetail?.bookForUser?.person?.firstName}{" "}
                  {appDetail?.bookForUser?.person?.lastName}
                </h1>
                <p className="font-semibold text-gray-500">
                  <span className="font-semibold text-gray-500">GID: </span>
                  {appDetail?.bookForUser?.gid}
                </p>
                <p className="font-semibold text-gray-500">
                  {moment(appDetail.bookForUser?.person?.dateOfBirth).format(
                    "DD/MM/YYYY"
                  )}{" "}
                  {`(${calculateAge(
                    appDetail.bookForUser?.person?.dateOfBirth
                  )} years)`}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between mb-1 px-3">
              <p className="font-semibold text-gray-600">Status</p>
              <p
                className={`font-semibold text-right ${
                  appDetail?.status === "cancelled"
                    ? "text-red-500"
                    : !isDateTimePassed(
                        appDetail?.schedulercentertimemanager?.date,
                        appDetail?.toTime
                      )
                    ? "text-primary"
                    : "text-gray-500"
                }`}
              >
                {!isDateTimePassed(
                  appDetail?.schedulercentertimemanager?.date,
                  appDetail?.toTime
                ) && appDetail?.status === "booked"
                  ? "Upcoming"
                  : appDetail?.status === "cancelled"
                  ? "Cancelled"
                  : "Completed"}
              </p>
            </div>
            <div className="bg-gray-100 p-3 py-5 mb-4 rounded-md flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <p className="font-semibold text-gray-600">Program</p>
                <p className="font-semibold">
                  {appDetail?.schedulercentertimemanager?.program ===
                  "senior-citizen-Pension"
                    ? "Senior Citizen Pension"
                    : appDetail?.schedulercentertimemanager?.program}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-semibold text-gray-600">
                  Distribution Centre
                </p>
                <p className="font-semibold">{distributionCenter?.coverage}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-semibold text-gray-600">Gender</p>
                <p className="font-semibold capitalize">
                  {appDetail.bookForUser?.person?.gender}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-semibold text-gray-600">Phone</p>
                <p className="font-semibold">
                  {appDetail?.bookForUser?.phoneNo
                    ? appDetail?.bookForUser?.countryCode +
                      appDetail?.bookForUser?.phoneNo
                    : "----"}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-semibold text-gray-600">Email</p>
                <p className="font-semibold">
                  {appDetail?.bookForUser?.email
                    ? appDetail?.bookForUser?.email
                    : "----"}
                </p>
              </div>
            </div>
            {!isDateTimePassed(
              appDetail?.schedulercentertimemanager?.date,
              appDetail?.toTime
            ) &&
              appDetail?.status === "booked" && (
                <div className="w-full flex items-center justify-center mb-6">
                  <button
                    onClick={() => setShowCancel(true)}
                    className={`bg-red-600 font-semibold text-white cursor-pointer flex w-[70%] items-center py-3 rounded-md justify-center mt-4 gap-2}`}
                  >
                    Cancel Appointment
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
      <div
        className={`${
          showCancel ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[26rem]  flex items-center p-5 bg-white flex-col">
          <div className="w-16 h-16" onClick={() => setShowCancel(false)}>
            <img src={logo} alt="" className="w-16 h-16" />
          </div>
          <p className="text-center mt-6 mb-5 font-semibold">
            Are you sure to you want to cancel the appointment?
          </p>
          <textarea
            type="text"
            name=""
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            placeholder="Note"
            className=" lg:text-[1.1rem] w-[85%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <div className="w-full flex gap-2 items-center justify-center">
            <button
              onClick={() => {
                setShowCancel(false);
                setReason("");
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[40%]"
            >
              No
            </button>
            <button
              onClick={() => {
                if (reason === "") {
                  setErr("Please Enter a specific reason.");
                  setShowErr(true);
                } else cancel();
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
    </div>
  );
};

export default SelectProgram;

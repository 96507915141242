import { Avatar, Dropdown, Menu, Input, Pagination, Select, Tag } from "antd";
import { useMemo, useState } from "react";
import Card from "../dash/Card";
import totalEmp from "../../../imgs/totalEmp.png";
import empLeft from "../../../imgs/empOff.png";
import empAct from "../../../imgs/currEmp.png";
import { useNavigate } from "react-router-dom";
import AttendanceCards from "./AttendanceCards";
import AttendanceTable from "../attendance/components/AttendanceTable";
import Button from "../components/Button";

const { Option } = Select;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name, row) => (
      <div className="flex items-center">
        <Avatar src={row?.profile} className="mr-2 w-10 h-10" />
        <p className="text-primary capitalize">{name}</p>
      </div>
    ),
  },
  {
    title: "Position",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (type) => {
      const typeColors = {
        Leave: "green",
        "Short Leave": "gold",
        "IOS Device": "blue",
        Laptop: "purple",
        "Extra Time": "orange",
        "Patrol Card": "red",
      };

      return (
        <Tag color={typeColors[type] || "gray"} className="text-xs font-normal px-1 py-0.5">
          {type}
        </Tag>
      );
    },
  },
  {
    title: "Note",
    dataIndex: "note",
    key: "note",
  },
  {
    title: "Request To",
    dataIndex: "requestTo",
    key: "requestTo",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    key: "contact",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => (
      <Tag color={status === "Approved" ? "green" : status === "Pending" ? "orange" : "red"}>
        {status}
      </Tag>
    ),
  },
];

const staticData = [
  {
    id: "1",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "John Doe",
    position: "Manager",
    department: "IT",
    type: "Leave",
    note: "123 Main St, Springfield, USA",
    requestTo: "Manager",
    contact: "9876543210",
    status: "Approved",
  },
  {
    id: "2",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Jane Smith",
    position: "Developer",
    type: "IOS Device",
    department: "IT",
    note: "456 Elm St, Springfield, USA",
    requestTo: "Admin",
    contact: "8765432109",
    status: "Pending",
  },
  {
    id: "3",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Michael Johnson",
    position: "Designer",
    type: "Short Leave",
    department: "Medical",
    note: "789 Oak St, Springfield, USA",
    requestTo: "Manager",
    contact: "7654321098",
    status: "Denied",
  },
  {
    id: "4",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Emily Davis",
    position: "Product Manager",
    type: "Laptop",
    department: "Staff",
    note: "101 Maple St, Springfield, USA",
    requestTo: "Admin",
    contact: "6543210987",
    status: "Approved",
  },
  {
    id: "5",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Emily Davis",
    position: "Product Manager",
    type: "Extra Time",
    department: "Staff",
    note: "101 Maple St, Springfield, USA",
    requestTo: "Admin",
    contact: "6543210987",
    status: "Pending",
  },
  {
    id: "6",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Emily Davis",
    position: "Product Manager",
    type: "Patrol Card",
    department: "Staff",
    note: "101 Maple St, Springfield, USA",
    requestTo: "HR",
    contact: "4321098765",
    status: "Denied",
  },
];
const cardData = [
  {
    label: "Total Employees",
    count: 420,
    icon: totalEmp,
  },
  {
    label: "Current Employees",
    count: 400,
    icon: empAct,
  },
  {
    label: "Employees Left",
    count: 20,
    icon: empLeft,
  },
];

const StaticTable = () => {
  const router = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [positionSelected, setPositionSelected] = useState("");
  const [departmentSelected, setDepartmentSelected] = useState("");

  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handlePositionChange = (value) => setPositionSelected(value);
  const handleDepartmentChange = (value) => setDepartmentSelected(value);

  // Memoize filtered data to optimize performance
  const filteredData = useMemo(() => {
    return (staticData || []).filter((record) => {
      const matchesPosition = positionSelected ? record.position === positionSelected : true;
      const matchesDepartment = departmentSelected
        ? record.department === departmentSelected
        : true;
      const matchesSearch = record.name.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesPosition && matchesDepartment && matchesSearch;
    });
  }, [searchTerm, positionSelected, departmentSelected]);

  const handleAction = (action, id) => {
    switch (action) {
      case "view":
        router(`/ERequests/ERequestDetail`);
        break;
      case "edit":
        console.log(`Edit details for employee ID: ${id}`);
        break;
      case "remove":
        console.log(`Remove employee ID: ${id}`);
        break;
      default:
        console.log("Unknown action");
    }
  };

  // Action menu for each row
  const actionMenu = (id) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleAction("view", id)}>
        View
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleAction("edit", id)}>
        Edit
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleAction("remove", id)}>
        Remove
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="h-[78vh] overflow-auto px-2">
      <div className="flex w-full justify-between">
        <h2 className="text-xl font-semibold">Employee Requests</h2>
        <Button className="rounded-md" onClick={() => router("/ERequests/AddERequest")}>
          Add E-Request
        </Button>
      </div>
      <div style={{ margin: "20px 0px 20px 0px" }}>
        <AttendanceCards />
      </div>
      <div className="grid gap-4 grid-cols-5 mb-4 mt-5">
        <Input
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearch}
          className="w-full h-[32px]"
        />
        <Select
          placeholder="Position"
          onChange={handlePositionChange}
          className="w-full h-[32px]"
          allowClear>
          {Array.from(new Set(staticData.map((data) => data.position))).map((position) => (
            <Option key={position} value={position}>
              {position}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Department"
          onChange={handleDepartmentChange}
          className="w-full h-[32px]"
          allowClear>
          {Array.from(new Set(staticData.map((data) => data.department))).map((department) => (
            <Option key={department} value={department}>
              {department}
            </Option>
          ))}
        </Select>
      </div>
      <div>
        <AttendanceTable
          columns={columns}
          data={filteredData}
          pageSize={10}
          rowKey="id"
          actions={actionMenu}
        />
      </div>
    </div>
  );
};

export default StaticTable;

import { Checkbox, DatePicker, Pagination, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import moment from "moment/moment";
import axios from "axios";
import profile from "../../../../imgs/profile.png";

const PensionerTable = () => {
  const PlaceOrderCheck = useSelector((state) => state.PlaceOrderCheck);
  const router = useNavigate();
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.dataUser);
  const [pensioner, setPensioner] = useState([]);
  const user = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [show, setShow] = useState("");
  const [search, setSearch] = useState("");
  const [dob, setDob] = useState("");
  const [prompt, setPrompt] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [searchDob, setSearchDob] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);

  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [status, setStatus] = useState(false);

  //   useEffect(() => {
  //     const delay = 500;

  //     const timeoutId = setTimeout(() => {
  //       // Cancel the previous request if it exists
  //       if (cancelTokenSource) {
  //         cancelTokenSource.cancel("Operation canceled by the user.");
  //       }

  //       // Create a new cancel token source for this request
  //       const newCancelTokenSource = axios.CancelToken.source();
  //       setCancelTokenSource(newCancelTokenSource);

  //       const params = {
  //         limit: 6,
  //         skip:
  //           search || searchDob || countryCode || phoneNoo ? 0 : (page - 1) * 6,
  //         page: search ? pagewithsearch : page, // Separate page for search and non-search scenarios
  //         sortBy: "createdAt:desc",
  //         ...(search && { search }),
  //         ...(searchDob && { dateOfBirth: searchDob }),
  //         ...(countryCode &&
  //           phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
  //         ...(status && { status: "ceased" }),
  //       };

  //       fetchEmployees(params, newCancelTokenSource);
  //     }, delay);

  //     return () => {
  //       clearTimeout(timeoutId);

  //       // Cancel the request if the component is unmounted
  //       if (cancelTokenSource) {
  //         cancelTokenSource.cancel("Operation canceled by the user.");
  //       }
  //     };
  //   }, [page, search, searchDob, countryCode, phoneNoo, pagewithsearch, status]);

  //   const fetchEmployees = (params, cancelTokenSource) => {
  //     axios
  //       .get(`${process.env.REACT_APP_BASE_URL}/v1/users/pensioners`, {
  //         headers: {
  //           Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
  //         },
  //         params,
  //         cancelToken: cancelTokenSource.token,
  //       })
  //       .then((data) => {
  //         setPensioner(data.data.rows);
  //         setTotalPages(data.data.totalPages);
  //         setShow(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  const pageHandler = (e) => {
    if (search) {
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };

  const columnHeaders = [
    "Sr. No",
    "Vendor Name",
    "Phone",
    "Email",
    "Address",
    "Total Orders",
    "Registered On",
  ];

  const vendorData = [
    {
      srNo: 1,
      vendorName: "John's Supplies",
      phone: "123-456-7890",
      email: "john@supplies.com",
      address: "123 Market St, New York, NY",
      totalOrders: 150,
      registeredDate: "4/12/2022",
      registeredTime: "06:20pm",
    },
    {
      srNo: 2,
      vendorName: "Smith Tools",
      phone: "987-654-3210",
      email: "smith@tools.com",
      address: "456 Tool Ave, Los Angeles, CA",
      totalOrders: 98,
      registeredDate: "10/23/2021",
      registeredTime: "06:20pm",
    },
    {
      srNo: 3,
      vendorName: "Baker Essentials",
      phone: "456-123-7890",
      email: "info@bakeressentials.com",
      address: "789 Bakery Rd, Chicago, IL",
      totalOrders: 203,
      registeredDate: "1/18/2023",
      registeredTime: "06:20pm",
    },
    {
      srNo: 4,
      vendorName: "FastTech Solutions",
      phone: "321-654-9870",
      email: "support@fasttech.com",
      address: "321 Innovation Blvd, San Francisco, CA",
      totalOrders: 75,
      registeredDate: "8/5/2020",
      registeredTime: "06:20pm",
    },
    {
      srNo: 5,
      vendorName: "Green Grocers",
      phone: "789-321-6540",
      email: "sales@greengrocers.com",
      address: "654 Green Valley St, Miami, FL",
      totalOrders: 120,
      registeredDate: "12/30/2022",
      registeredTime: "06:20pm",
    },
    {
      srNo: 6,
      vendorName: "Green Grocers",
      phone: "789-321-6540",
      email: "sales@greengrocers.com",
      address: "654 Green Valley St, Miami, FL",
      totalOrders: 120,
      registeredDate: "12/30/2022",
      registeredTime: "06:20pm",
    },
  ];

  return (
    <section className="p-2">
      {/* {user === "superadmin" && (
        <div className="flex justify-end mt-4 gap-3 mb-3">
          <div className="flex items-center bg-[#f9fbfb] border border-gray-300 rounded-md px-4 py-2 w-[300px] shadow-sm">
            <input
              type="text"
              placeholder="Search Vendor"
              className="bg-transparent focus:outline-none text-gray-500 placeholder-gray-400 flex-grow"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-[#1BA397]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.8"
                d="M11 19a8 8 0 100-16 8 8 0 000 16zm5-1l5 5"
              />
            </svg>
          </div>
          <button
            type="button"
            onClick={() => {
              
              router("/SuperAdmin/Inventory/AddVendor")
            }}
            className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
          >
            Add Vendor
          </button>
        </div>
      )} */}
      <div className="mx-auto max-w-screen-xl">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-gray-50 ">
          {/* table */}
          <div className="overflow-x-auto custom-scrollbar">
            {vendorData.length === 0 ? (
              <h1 className="text-center text-[1.1rem] p-5 h-40">
                No Data Found
              </h1>
            ) : (
              <table className="w-full text-sm text-left text-gray-500  ">
                <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                  <tr>
                    {columnHeaders.map((columnHeader, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 font-semibold tracking-wider"
                      >
                        {columnHeader}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {vendorData.map((pen, index) => (
                    <tr
                      key={index}
                      className={`border-b border-[1px] border-[#d9d9d970] hover:bg-gray-200 text-md cursor-pointer ${
                        index % 2 === 0 ? "bg-white" : "bg-[#f2faf9]"
                      }`}
                      onClick={() => {
                        if (PlaceOrderCheck) {
                          router("/Inventory-Products");
                        }
                      }}
                    >
                      <th
                        scope="row"
                        className="px-3 py-5 font-medium text-gray-900  whitespace-nowrap"
                      >
                        {pen?.srNo}.
                      </th>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <img
                              className="h-10 w-10 rounded-full border-2 border-primary"
                              src="https://static.vecteezy.com/system/resources/thumbnails/007/209/020/small_2x/close-up-shot-of-happy-dark-skinned-afro-american-woman-laughs-positively-being-in-good-mood-dressed-in-black-casual-clothes-isolated-on-grey-background-human-emotions-and-feeligs-concept-photo.jpg"
                              alt=""
                            />
                          </div>
                          <div className="ml-4">{pen?.vendorName}</div>
                        </div>
                      </td>

                      <td className="px-4 py-4">
                        {pen?.phone === null ? "----" : `${pen?.phone}`}
                      </td>

                      <td className="px-4 py-4">{pen?.email}</td>
                      <td className="px-4 py-4">{pen?.address}</td>
                      <td className="px-4 py-4">{pen?.totalOrders}</td>
                      <td className="px-4 py-4">
                        {pen?.registeredDate}
                        <p>{pen?.registeredTime}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <nav
            className="flex flex-col md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={search ? pagewithsearch : page}
              />
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default PensionerTable;

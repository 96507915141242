import React, { useState, useEffect } from "react";
import back5 from "../../imgs/back5.png";
import { Select, DatePicker } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import ErrorModal from "../Merchant/Modals/errorModal";
import logo from "../../imgs/logo12.png";
import SuccessModal from "../modals/successModal";
import { useNavigate } from "react-router-dom";
import holiday from "../../imgs/holiday.svg";
import days from "dayjs";
import deleteicon from "../../imgs/deleteicon.png";
import moment from "moment/moment";
import company from "../../imgs/company.svg";
import government from "../../imgs/Governemnt.svg";
import national from "../../imgs/Nation.svg";
import public1 from "../../imgs/Public.svg";
import other from "../../imgs/Holidays.svg";

const Main = () => {
  const router = useNavigate();
  const [name, setName] = useState("");
  const [holidayType, setHolidayType] = useState(null);
  const [otherType, setOtherType] = useState("");
  const [date, setDate] = useState(null);
  const [holidayId, setHolidayId] = useState(null);
  const [data, setData] = useState([]);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const [addModal, setAddModal] = useState(false);
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [show1, setShow1] = useState("");
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedHolidays, setSelectedHolidays] = useState([]);
  const [count, setCount] = useState("");

  //Get Listing
  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        // limit: 9,
        // skip: (page - 1) * 9,
        // page: page,
        // sortBy: "createdAt:desc",
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [refetch, page]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/scheduler-holidays`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        setData(res?.data);
        setCount(res?.data?.length);
        // setTotalPages(res?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddHoliday = () => {
    setAddModal(false);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-holidays/add`,
        {
          name: name,
          type: holidayType === "Other" ? otherType : holidayType,
          date: date,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((res) => {
        setRefetch(!refetch);
        setMessage("Holiday Added Successfully");
        setShow(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow1(true);
      });
  };

  const DeleteHoliday = (id) => {
    setChangeStatusModal(false);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-holidays/${id}/remove`,

        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((res) => {
        setRefetch(!refetch);
        setMessage(`Holiday Deleted Successfully`);
        setShow(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow1(true);
      });
  };


  return (
    <div className="flex items-center justify-center mt-4 flex-col">
      <div className="md:w-[85%] w-full p-4 ">
        {/* header and button */}
        <div
          className="pl-2 pr-2  mt-[1rem] sm:mt-[0rem]"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div className="flex justify-between items-center mt-4">
              <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
                <img
                  className="sm:w-[1.7rem] w-[1.2rem]"
                  src={back5}
                  alt=""
                  onClick={() => {
                    router("/superAdminDashboard");
                  }}
                />
                <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                  Holidays Setting
                </h2>
              </div>

              <button
                className={` text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md`}
                onClick={() => {
                  setName("");
                  setHolidayType(null);
                  setDate(null);
                  setAddModal(true);
                }}
              >
                Add Holiday
              </button>
            </div>
          </div>
        </div>

        <div className="lg:p-12 bg-slate-50 sm:ml-0 w-full  w-full mt-[2rem] mb-8 bg-slate-50 p-[35px] rounded-2xl relative">
          <div className="bg-white flex items-center justify-between px-4">
            <h1 className="text-[1.1rem] text-gray-700 font-medium px-4 ">
              <span className="text-primary">{count} holidays</span> pending for
              current year
            </h1>
            <img src={holiday} alt="" className="w-[9rem] h-[9rem] mx-auto" />
          </div>
          <div className="flex items-center justify-between mt-6">
            <h1 className="text-[1.1rem] text-gray-400 font-medium ">
              Holidays List 2024
            </h1>
            {/* <button className="text-primary font-semibold cursor-pointer">
              Delete
            </button> */}
          </div>

          <div className="grid grid-cols-1 w-full sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 mt-4">
            {data?.map((data, index) => (
              <div
                key={index}
                className={`flex items-center justify-between p-3 rounded-md cursor-pointer  shadow-sm relative
                 ${
                   selectedHolidays.some((item) => item.Name === data.Name)
                     ? "bg-primary text-white"
                     : "bg-white"
                 }`}
                // onClick={() => {
                //   // Check if the holiday is already selected using the Name property
                //   if (
                //     selectedHolidays.some((item) => item.Name === data.Name)
                //   ) {
                //     setSelectedHolidays(
                //       selectedHolidays.filter((item) => item.Name !== data.Name)
                //     );
                //   } else {
                //     setSelectedHolidays([...selectedHolidays, data]);
                //   }
                // }}
              >
                <div className="flex items-center">
                  <img
                    src={
                      data?.type === "Public"
                        ? public1
                        : data?.type === "National"
                        ? national
                        : data?.type === "Govt"
                        ? government
                        : data?.type === "Company"
                        ? company
                        : other
                    }
                    alt="vc"
                    className="w-9 h-9 rounded-full"
                  />
                  <div className="ml-4">
                    <h3 className={`text-[0.95rem] font-semibold capitalize`}>
                      {data.name}
                    </h3>
                    <p className="text-[0.8rem] capitalize">
                      {data?.type === "Govt" ? "Government" : data?.type}
                    </p>
                  </div>
                </div>
                <div className="flex items-center mt-6">
                  <h3 className={"text-[0.85rem] font-semibold mb-1"}>
                    {moment(data?.date).format("MM/DD/YYYY")}
                  </h3>
                </div>
                <div
                  className="absolute top-3 right-4 cursor-pointer"
                  onClick={() => {
                    setHolidayId(data.id);
                    setChangeStatusModal(true);
                  }}
                >
                  <img src={deleteicon} alt="" className="w-4 h-4" />
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div className={`flex justify-center mt-7`}>
          <Pagination
            defaultCurrent={1}
            total={totalPages * 10}
            showSizeChanger={false}
            onChange={pageHandler}
            current={page}
          />
        </div> */}
      </div>

      {/* Modal for Add Holiday */}
      <div
        className={`${
          addModal ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[24rem]  pb-6 pt-6 flex flex-col  p-6 bg-white">
          <label className="text-[#596F96] text-[1.2rem] font-semibold mb-2 text-center">
            Add Holiday
          </label>

          {/* Status Select */}
          <div className="w-full mb-4">
            <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
              Choose Date <span className="text-red-500">*</span>
            </label>
            <DatePicker
              className="date-class"
              onChange={(date, dateString) => {
                setDate(dateString);
              }}
              value={date ? days(date, "YYYY-MM-DD") : null}
            />
            {/* <div className="w-full flex items-center justify-center">
              <Calendar

              // value={selectedDates}
              // onChange={(dates) => {
              //   const formattedDates = dates.map((date) => date.toDate()); // or date.format() for string format
              //   setSelectedDates(formattedDates);
              // }}
              // minDate={today}
              />
            </div> */}
          </div>
          <div className="w-full mb-4">
            <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
              Holiday Name <span className="text-red-500">*</span>
            </label>
            <form className="flex items-center justify-between w-full bg-white border-[0.3px] border-gray-300 rounded-md text-black text-center py-1 px-2">
              <input
                type="text"
                placeholder="Holiday Name"
                className="p-2 rounded-l focus:outline-none"
                style={{ border: "none" }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </form>
          </div>

          <div className="w-full mb-4">
            <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
              Type <span className="text-red-500">*</span>
            </label>
            <Select
              placeholder="Select Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              className="wow"
              onChange={(e) => setHolidayType(e)}
              value={holidayType}
              options={[
                { label: "Public", value: "Public" },
                { label: "National", value: "National" },
                { label: "Government", value: "Govt" },
                { label: "Company", value: "Company" },
                { label: "Other", value: "Other" },
              ]}
            />
            {holidayType === "Other" && (
              <div className="w-full mb-4 mt-4">
                <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
                  Specify Type <span className="text-red-500">*</span>
                </label>
                <form className="flex items-center justify-between w-full bg-white border-[0.3px] border-gray-300 rounded-md text-black text-center py-1 px-2">
                  <input
                    type="text"
                    placeholder="Specify Type"
                    className="p-2 rounded-l focus:outline-none"
                    style={{ border: "none" }}
                    value={otherType}
                    onChange={(e) => setOtherType(e.target.value)}
                  />
                </form>
              </div>
            )}
          </div>

          {/* Buttons */}
          <div className="flex justify-end">
            <button
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              onClick={() => {
                setAddModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
              onClick={() => {
                if (date === null) {
                  setErr("Please select a date");
                  setShow1(true);
                  return;
                }
                if (name === "") {
                  setErr("Please enter a name");
                  setShow1(true);
                  return;
                }
                if (holidayType === null) {
                  setErr("Please select a type");
                  setShow1(true);
                  return;
                }

                if (holidayType === "Other" && otherType === "") {
                  setErr("Please specify the type");
                  setShow1(true);
                  return;
                }

                AddHoliday();
                setAddModal(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      {/* Change Status Modal */}
      <div
        className={`${
          changeStatusModal ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[22rem] pb-6 flex items-center p-3 bg-white flex-col">
          <img src={logo} alt="" className="w-20 h-20 mt-4" />
          <p className="text-center text-[0.95rem] mt-3 mb-5 w-[85%]">
            Are you sure you want to delete ?
          </p>
          <div className="flex">
            <button
              onClick={() => setChangeStatusModal(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              No
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
              onClick={() => {
                DeleteHoliday(holidayId);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      {/* Error Modal */}

      {show1 && <ErrorModal show={show1} setShow={setShow1} err={err} />}
      {show && <SuccessModal show={show} setShow={setShow} message={message} />}
    </div>
  );
};

export default Main;

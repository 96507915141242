import { Select, DatePicker, Button } from "antd";
import { useState } from "react";
import across from "../../../imgs/across.png";
import logo from "../../../imgs/logo12.png";
import back5 from "../../../imgs/back5.png";
import dayjs from "dayjs";
import axios from "axios";
import { useSelector } from "react-redux";
import { DeleteFilled } from "@ant-design/icons";
import { EnvironmentOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
let file;
const Mid = () => {
  const location = useLocation();
  const dash = location?.pathname;
  const router = useNavigate();
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, []);
  const [display, setDisplay] = useState("");
  const [test, setTest] = useState(false);
  const [banner, setBanner] = useState("");
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);
  const [showDone, setShowDone] = useState(false);
  const [lName, setLName] = useState("");
  const [address, setAddress] = useState(null);
  const [regionNo, setRegionNo] = useState(null);
  const [regionName, setRegionName] = useState(null);
  const [city, setCity] = useState(null);
  const [ward, setWard] = useState(null);
  const [allRegions, setAllRegions] = useState("");
  const [allCities, setAllCities] = useState("");
  const [allWards, setAllWards] = useState("");
  const [description, setDescription] = useState("");
  const [lot, setLot] = useState("");
  const [type, setType] = useState(null);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [peoples, setPeoples] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [locationt, setLocationt] = useState("");
  const token = useSelector((state) => state.token);
  const eventId = useSelector((state) => state.eventId);
  const subEventId = useSelector((state) => state.subEventId);
  const handleLocateMe = (setState) => {
    if (navigator.geolocation) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyD09CYCGjr-h6gCq3ImZe6pnglvuCsGcMU`;

            fetch(apiUrl)
              .then((response) => response.json())
              .then((data) => {
                if (data.results && data.results.length > 0) {
                  const locationName = data.results[0].formatted_address;
                  setLocationt(`${latitude}, ${longitude}`);
                  setState(locationName);
                } else {
                  console.error("Could not retrieve location name", locationt);
                }
              })
              .catch((error) => {
                console.error("Error getting location:", error.message);
              });
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );
      }
    }
  };
  useEffect(() => {
    if (locationt) {
      const [latitude, longitude] = locationt
        .split(", ")
        .map((coord) => parseFloat(coord));

      const fetchAddress = async () => {
        try {
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyD09CYCGjr-h6gCq3ImZe6pnglvuCsGcMU`
          );
          const address = response.data.results[0]?.formatted_address;
          if (address) {
            setLName(address);
          }
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      };

      fetchAddress();
    }
  }, [locationt]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/gy-addresses`)
      .then((data) => {
        console.log("data", data);
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setAllRegions(wow);
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    if (regionNo) {
      getRegionName(regionNo);
    } else if ((regionNo, regionName)) {
      getCities(regionNo && regionName);
    } else if (regionNo && regionName && city) {
      getWards(regionNo, regionName, city);
    }
  }, [regionNo, regionName, city, ward]);
  const getRegionName = (regionNum) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNum}`
      )
      .then((data) => {
        setRegionName(data.data.data[0]);
      })
      .catch(() => {});
  };
  const getCities = (regionNum, regionName) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNum}&regionName=${regionName}`
      )
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setAllCities(wow);
        // getWards(regionNum, regionName, wow[0].value, index)
        // setCityOptions(wow);
      })
      .catch(() => {});
  };
  const getWards = (regionNum, regionName, city) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/gy-addresses?region=${regionNum}&regionName=${regionName}&city=${city}`
      )
      .then((data) => {
        const wow = data.data.data.map((region) => {
          return {
            label: `${region}`,
            value: `${region}`,
          };
        });
        setAllWards(wow);
        // setAllWards(wow);
      })
      .catch(() => {});
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current < disabledBeforeDate1;
  };
  const imageHandler = async (e) => {
    file = e.target.files[0];
    console.log("Fileee", file);
    const base641 = await convertToBase64(file);
    setDisplay(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=eventImage`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setBanner(data.data.url);
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  useEffect(() => {
    console.log("Type", type);
  }, [type]);
  useEffect(() => {
    let url;
    if (dash === "/eventUpdate") {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/get-event/${eventId}`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/get-event/${subEventId}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        setBanner(data?.data?.event?.coverImage);
        setDisplay(data?.data?.event?.coverImage);
        setName(data?.data?.event?.name);
        setStartDate(data?.data?.event?.startDateTime);
        setEndDate(data?.data?.event?.endDateTime);
        setType(data?.data?.event?.type);
        setPeoples(data?.data?.event?.allowedPerson);
        setDescription(data?.data?.event?.detail);
        setLot(data?.data?.event?.lot);
        setLocationt(data?.data?.event?.location);
        setAddress(data?.data?.event?.address);
        setRegionNo(data?.data?.event?.regionNo);
        setRegionName(data?.data?.event?.regionName);
        setCity(data?.data?.event?.city);
        setWard(data?.data?.event?.village);
        setState(data?.data?.event?.state);
        setZip(data?.data?.event?.zip);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {}, [regionNo]);
  const AddHandler = () => {
    let body;
    if (banner === "") {
      setErr("Banner is Required");
      setShow(true);
    } else if (name === "") {
      setErr("Event name is Required");
      setShow(true);
    } else if (startDate === null || startDate === "") {
      setErr("Start Date is Required");
      setShow(true);
    } else if (endDate === null || endDate === "") {
      setErr("End Date is Required");
      setShow(true);
    } else if (type === null) {
      setErr("Type is Required");
      setShow(true);
    } else if (peoples === null) {
      setErr("Enter Number of person allowed in Event.");
      setShow(true);
    }
    // else if (lot === "") {
    //   setErr("Lot number is Required");
    //   setShow(true);
    // }
    else if (address === "") {
      setErr("Address is Required");
      setShow(true);
    } else if (regionNo === null) {
      setErr("Region Number is Required");
      setShow(true);
    } else if (regionName === "") {
      setErr("RegionName is Required");
      setShow(true);
    } else if (city === null) {
      setErr("Town/City is Required");
      setShow(true);
    } else if (ward === null) {
      setErr("Village/Ward is Required");
      setShow(true);
    } else if (description === "") {
      setErr("Description is Required");
      setShow(true);
    } else if (peoples < 1) {
      setErr("Number of persons must be greater then 0.");
      setShow(true);
    }
    // else if (state === "") {
    //   setErr("State . Province is Required");
    //   setShow(true);
    // } else if (zip === "") {
    //   setErr("Zip / Postal Code is Required");
    //   setShow(true);
    // }
    else {
      body = {
        regionNo: regionNo,
        regionName: regionName,
        address: address,
        type: type,
        // lot: lot,
        city: city,
        name: name,
        coverImage: banner,
        location: locationt,
        startDateTime: startDate,
        endDateTime: endDate,
        village: ward,
        // state: state,
        // zip: zip,
        detail: description,
        allowedPerson: peoples,
      };
      if (lot !== "") {
        body.lot = lot;
      }
      let url;
      if (dash === "/eventUpdate") {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event/${eventId}`;
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event/${subEventId}`;
      }
      axios
        .put(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((data) => {
          setShowDone(true);
        })
        .catch((err) => {
          console.log("errrrr", err);
          setErr(err.response.data.message);
          setShow(true);
        });
    }
  };
  const handleStartDateChange = (date, dateString) => {
    if (endDate && dayjs(dateString).isAfter(dayjs(endDate))) {
      setErr("Start date cannot be after end date");
      setShow(true);
    } else setStartDate(dateString);
  };

  const handleEndDateChange = (date, dateString) => {
    if (startDate && dayjs(dateString).isBefore(dayjs(startDate))) {
      setErr("End date cannot be before start date");
      setShow(true);
    } else setEndDate(dateString);
  };
  return (
    <div className=" w-full">
      <div className="w-full flex items-center justify-center">
        <div className="w-full flex items-center sm:w-[70%] w-[80%]">
          <div className="flex mt-12 w-full items-center">
            <div
              onClick={() =>
                dash === "/eventUpdate"
                  ? router("/EventDetails")
                  : router("/SubEventDetails")
              }
              className="cursor-pointer flex items-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                {dash === "/eventUpdate" ? "Update Event" : "Update Sub Event"}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <form action="" className="mt-10 sm:mt-[5rem] mb-[5rem]" onSubmit={""}>
        <div className="flex items-center justify-center mb-4 flex-col">
          <div className="relative w-[18.5rem] h-[8.5rem] overflow-hidden border-2 border-[#686262] rounded-md mb-4">
            {display ? (
              <>
                <img
                  className="w-[18.5rem] h-[9rem]"
                  src={display}
                  alt="Event Banner"
                />
                <div
                  className="absolute bottom-2 right-2 text-white"
                  onClick={() => setDisplay("")}
                >
                  <DeleteFilled className="text-2xl shadow-md cursor-pointer text-red-600" />
                </div>
              </>
            ) : (
              <label
                htmlFor="special-input"
                className="cursor-pointer w-full h-full flex items-center justify-center relative"
              >
                <div
                  type="button"
                  className="px-4 py-1 rounded-xl bg-primary text-white"
                >
                  Upload Event Banner
                </div>
                <input
                  id="special-input"
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => imageHandler(e)}
                />
              </label>
            )}
          </div>

          <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
            Upload Event Cover Photo <span className="text-red-600">*</span>
          </h2>
        </div>
        <div className="flex justify-center flex-col items-center">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div className="w-[100%]">
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Event Name <span className="text-red-600">*</span>
                </label>
                <input
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  type="text"
                  value={name}
                  placeholder="Event Name"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Start Date <span className="text-red-600">*</span>
                </label>
                <DatePicker
                  placeholder="Start Date"
                  showTime
                  value={
                    startDate ? dayjs(startDate, "YYYY-MM-DD HH:mm:ss") : null
                  }
                  disabledDate={disabledDate1}
                  className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                  onChange={(date, dateString) => {
                    handleStartDateChange(date, dateString);
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  End Date <span className="text-red-600">*</span>
                </label>
                <DatePicker
                  placeholder="End Date"
                  showTime
                  value={endDate ? dayjs(endDate, "YYYY-MM-DD HH:mm:ss") : null}
                  disabledDate={disabledDate1}
                  className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4`}
                  onChange={(date, dateString) => {
                    handleEndDateChange(date, dateString);
                  }}
                />
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Event Type <span className="text-red-600">*</span>
                </label>
                <Select
                  placeholder="Event Type"
                  optionFilterProp="children"
                  className="wow"
                  value={type}
                  onChange={(value) => {
                    setType(value);
                  }}
                  style={{ marginBottom: "1rem" }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  options={[
                    {
                      value: "public",
                      label: "Public",
                    },
                    {
                      value: "private",
                      label: "Private",
                    },
                  ]}
                />
              </div>

              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Number of persons Allowed
                  <span className="text-red-600">*</span>
                </label>
                <input
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setPeoples(value);
                    }
                  }}
                  value={peoples}
                  type="text"
                  placeholder="Number of persons Allowed"
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Description
                  <span className="text-red-600">*</span>
                </label>
                <textarea
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  type="text"
                  placeholder="Description"
                  className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center  sm:w-[70%] w-[80%] justify-center flex-col ">
            <Button
              onClick={() => handleLocateMe(setLName)}
              className="flex items-center justify-center bg-gray-300 w-[13rem] h-12 font-semibold text-[1rem] text-gray-700"
              icon={<EnvironmentOutlined />}
            >
              Locate Me <span className="text-red-600">*</span>
            </Button>
            <input
              type="text"
              placeholder="Location"
              value={lName}
              className=" lg:text-[1.1rem] w-[100%] bg-white mt-3 border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
          </div>
          <div className="flex items-center justify-center  sm:w-[70%] w-[80%] flex-col">
            <h2 className="mb-4 text-[1.1rem] w-full font-bold">Address:</h2>
            <div className="w-full grid sm:grid-cols-2 gap-4 grid-cols-1">
              <div className="w-full">
                <div className="w-full flex flex-col">
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Lot #
                  </h2>
                  <input
                    type="text"
                    name=""
                    value={lot}
                    placeholder="Lot #"
                    onChange={(e) => setLot(e.target.value)}
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  />
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Address
                  </h2>
                  <input
                    type="text"
                    name=""
                    // value={address.lot}
                    // placeholder="Lot #"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    placeholder="Address"
                    // onChange={addHadler}
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  />
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Region No
                  </h2>
                  <Select
                    placeholder="Region No"
                    optionFilterProp="children"
                    className="wow"
                    value={regionNo}
                    style={{ marginBottom: "1.2rem" }}
                    onChange={(value) => {
                      setRegionNo(value);
                      getRegionName(value);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size="middle"
                    options={allRegions}
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="w-full flex flex-col">
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Region Name
                  </h2>
                  <input
                    type="text"
                    name=""
                    value={regionName}
                    placeholder="Region Name"
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  />
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Town/City
                  </h2>
                  <Select
                    placeholder="Town/City"
                    optionFilterProp="children"
                    className="wow"
                    style={{ marginBottom: "1.1rem" }}
                    onChange={(value) => {
                      setCity(value);
                      getWards(regionNo, regionName, value);
                    }}
                    value={city}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size="middle"
                    options={allCities}
                  />
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Ward/Village
                  </h2>
                  <Select
                    placeholder="Ward/Village"
                    optionFilterProp="children"
                    className="wow"
                    style={{ marginBottom: "1.2rem" }}
                    value={ward}
                    showSearch
                    onChange={(value) => setWard(value)}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size="middle"
                    options={allWards}
                  />
                </div>
                {/* <div className="w-full flex flex-col">
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    State . Province
                  </h2>
                  <input
                    type="text"
                    name=""
                    onChange={(e) => setState(e.target.value)}
                    value={state}
                    placeholder="State . Province"
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  />
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Zip / Postal Code
                  </h2>
                  <input
                    type="text"
                    name=""
                    onChange={(e) => setZip(e.target.value)}
                    value={zip}
                    placeholder="Zip / Postal Code"
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-center">
          <div
            className={`lg:mt-[3rem] flex mt-4 items-center justify-center sm:w-[80%]`}
          >
            <button
              onClick={AddHandler}
              type="button"
              className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
            >
              Update Event
            </button>
          </div>
        </div>
      </form>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          showDone ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[20rem] pb-6 flex items-center justify-center p-3 bg-white flex-col">
          <img
            onClick={() => setShowDone(false)}
            src={logo}
            alt=""
            className="w-20 h-20"
          />
          <p className="text-center mt-[1.5rem] mb-5">
            Event Succesfully Updated.
          </p>
          <button
            onClick={() =>
              dash === "/eventUpdate"
                ? router("/EventDetails")
                : router("/SubEventDetails")
            }
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Mid;

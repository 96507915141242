import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Pagination } from "antd";
import moment from "moment";
const Mid = () => {
  const [active, setActive] = useState("1");
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const token = useSelector((state) => state.token);
  const ProductId = useSelector((state) => state.ProductId);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [afterApi, setAfterApi] = useState(false);

  const pageHandler = (e) => {
    setPage(e);
  };

  //Get Listing
  useEffect(() => {
    // const delay = 1000;
    setAfterApi(false);
    const delay = 0;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 9,
        skip: (page - 1) * 9,
        page: page,
        sortBy: "createdAt:desc",
      };

      fetchAllDiscounts(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page]);

  const fetchAllDiscounts = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-product/${ProductId}/query-discounts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        setData(res?.data?.rows);
        setTotalPages(res?.data?.totalPages);
        setAfterApi(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DiscountCard = ({ item }) => {
    return (
      <div className="  p-4 py-5 rounded-lg bg-[#EEEDED] shadow relative">
        <div className="flex justify-between items-center">
          <h3 className="text-gray-800 font-bold text-[1.1rem]">
            {item?.name}
          </h3>
          <h4 className="font-semibold text-primary text-[0.96rem]">
            {item?.type === "percent" ? `${item?.value}%` : `$${item?.value}`}
          </h4>
        </div>
        <p>{item?.type === "percent" ? "Percentage" : item?.type}</p>
        <div className="mt-1 text-gray-600">
          <div className="flex items-center justify-between w-[65%]">
            <span className="text-gray-600">Start Date:</span>
            <span className="text-gray-700 font-semibold">
              {moment(item?.startDate).format("MM/DD/YYYY")}
            </span>
          </div>
          <div className="flex items-center justify-between w-[65%]">
            <span className="text-gray-600">End Date:</span>
            <span className="text-gray-700 font-semibold">
              {moment(item?.endDate).format("MM/DD/YYYY")}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-8  bg-white rounded-md mt-[1rem] mb-3 ">
      {data?.length === 0 && afterApi ? (
        <div className="flex justify-center items-center h-[50vh]">
          <h3 className="text-gray-600 text-center font-medium">
            No Discounts
          </h3>
        </div>
      ) : (
        <>
          <div className="grid md:grid-cols-3 gap-5">
            {data?.map((item, index) => (
              <DiscountCard key={index} item={item} />
            ))}
          </div>
          <div className="flex justify-center mt-7">
            <Pagination
              defaultCurrent={1}
              total={totalPages * 10}
              showSizeChanger={false}
              onChange={pageHandler}
              current={page}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Mid;

export const EmployeeData = [
  {
    id: "1",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "John Doe",
    position: "Manager",
    department: "IT",
    rate: "$40/hr",
    address: "123 Main St, Springfield, USA",
    contact: "(123) 456-7890",
    gender: "Male",
    maritalStatus: "Single",
  },
  {
    id: "2",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Jane Smith",
    position: "Developer",
    rate: "$350/month",
    department: "IT",
    gender: "Female",
    address: "456 Elm St, Springfield, USA",
    contact: "(987) 654-3210",
    maritalStatus: "Married",
  },
  {
    id: "3",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Michael Johnson",
    position: "Designer",
    rate: "$30/hr",
    gender: "Male",
    department: "Medical",
    address: "789 Oak St, Springfield, USA",
    contact: "(555) 123-4567",
    maritalStatus: "Single",
  },
  {
    id: "4",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Emily Davis",
    position: "Product Manager",
    rate: "$450/month",
    gender: "Female",
    department: "Finance",
    address: "101 Maple St, Springfield, USA",
    contact: "(555) 765-4321",
    maritalStatus: "Married",
  },
  {
    id: "5",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Daniel Wilson",
    position: "Sales Executive",
    rate: "$45/hr",
    department: "Medical",
    gender: "Male",
    address: "202 Pine St, Springfield, USA",
    contact: "(555) 987-6543",
    maritalStatus: "Single",
  },
  {
    id: "6",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Sophia Martinez",
    position: "Marketing Specialist",
    rate: "$38/hr",
    department: "Finance",
    gender: "Female",
    address: "303 Birch St, Springfield, USA",
    contact: "(555) 321-0987",
    maritalStatus: "Married",
  },
];

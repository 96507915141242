import { Tag } from "antd";
import React from "react";

const holidays = [
  {
    date: "Mon 1st Jan 2024",
    name: "New Year's Day",
    color: "orange",
  },
  {
    date: "Mon 25th Mar 2024",
    name: "Phagwah",
    color: "orange",
  },
  {
    date: "Fri 29th Mar 2024",
    name: "Good Friday",
    color: "orange",
  },
  {
    date: "Wed 1st May 2024",
    name: "Labour Day",
    color: "orange",
  },
  {
    date: "Mon 1st Jul 2024",
    name: "Caricom Day",
    color: "orange",
  },
  {
    date: "Sun 15th Sep 2024",
    name: "Youmun Nabi",
    color: "orange",
  },
  {
    date: "Tue 24th Dec 2024",
    name: "Christmas Eve",
    color: "green",
  },
  {
    date: "Wed 25th Dec 2024",
    name: "Christmas",
    color: "green",
  },
  {
    date: "Tue 26th Dec 2024",
    name: "Boxing Day",
    color: "green",
  },
  {
    date: "Sat 1st Jan 2025",
    name: "New Year",
    color: "green",
  },
];

const UpcomingHoliday = () => {
  return (
    <div className="border border-border shadow bg-[#fff] rounded-md p-5 pb-8 ">
      <h2 className="text-xl font-medium">Upcoming Holidays</h2>
      <div className="grid mt-4">
        {holidays.map((holiday, index) => (
          <div
            key={index}
            className="grid grid-cols-2 items-center border-b-2 border-gray-100 px-2 py-3">
            <div className="flex gap-2 items-center">
              <div
                className={`w-2 h-2 rounded-full`}
                style={{
                  backgroundColor: holiday.color === "orange" ? "#D2631D" : "#1BA397",
                }}></div>
              <h6 className="text-base">{holiday.date}</h6>
            </div>
            <h4 className="text-lg font-semibold">{holiday.name}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpcomingHoliday;

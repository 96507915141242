import React from "react";
import DetailCard from "../../card/DetailCard";
import arrowIcon from "../../../imgs/left-arrow.png";
import { useNavigate } from "react-router-dom";

const TopNav = ({ title, cardData, back }) => {
  const router = useNavigate();

  return (
    <div>
      <div className="flex items-center gap-2">
        {back && ( // Conditionally render the arrow if arrowVisible is true
          <img
            src={arrowIcon}
            alt="arrow-left"
            onClick={() => router("/EmployeeDashboard")}
            className="h-4 cursor-pointer hover:opacity-80"
          />
        )}
        {title && <h2 className="text-xl font-semibold">{title}</h2>}
      </div>
      <div className="my-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 place-items-center">
          {cardData.map((card) => (
            <DetailCard
              key={card.id}
              icon={card.icon}
              title={card.title}
              subTitle={card.subTitle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopNav;

export const optionReducer = (state = "Pensioner", action) => {
  switch (action.type) {
    case "PENSIONER":
      return "Pensioner";
    case "EMPLOYEE":
      return "Employee";
    case "MERCHANT":
      return "Merchant";
    case "SAP MONEY":
      return "SAP Money";
    case "VENDOR":
      return "Vendor";
    default:
      return state;
  }
};
export const benCreate = (state = "false", action) => {
  switch (action.type) {
    case "benCreating":
      return "true";
    case "Reverse":
      return "false";
    default:
      return state;
  }
};
export const option2Reducer = (state = "", action) => {
  switch (action.type) {
    case "BENEFICIARY":
      return "Beneficiary";
    case "APPLICATION":
      return "Application";
    case "PENSIONER_HISTORY":
      return "Pensioner_History";
    default:
      return state;
  }
};
export const statusReducer = (state = "", action) => {
  switch (action.type) {
    case "inreview":
      return "inreview";
    case "deferred":
      return "deferred";
    case "resubmitted":
      return "resubmitted";
    case "rejected":
      return "rejected";
    case "pending":
      return "pending";
    case "verified":
      return "verified";
    case "approved":
      return "approved";
    default:
      return state;
  }
};

export const gidFilterReducer = (state = "", action) => {
  switch (action.type) {
    case "gidFilterSet":
      return action.num;
    default:
      return state;
  }
};
export const phoneFilterReducer = (state = "", action) => {
  switch (action.type) {
    case "phoneFilterSet":
      return action.num;
    default:
      return state;
  }
};
export const emailFilterReducer = (state = "", action) => {
  switch (action.type) {
    case "emailFilterSet":
      return action.num;
    default:
      return state;
  }
};
export const universalPenReducer = (state = "", action) => {
  switch (action.type) {
    case "universalPenSet":
      return action.num;
    default:
      return state;
  }
};
export const universalBenReducer = (state = "", action) => {
  switch (action.type) {
    case "universalBenSet":
      return action.num;
    default:
      return state;
  }
};
export const universalEmpReducer = (state = "", action) => {
  switch (action.type) {
    case "universalEmpSet":
      return action.num;
    default:
      return state;
  }
};

import Gal from "../components/events/eventGallary/gallary";
import Nav from "../components/Nav/nav";

const Gallery = () => {
  return (
    <div>
      <Nav />
      <Gal />
    </div>
  );
};

export default Gallery;

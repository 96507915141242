import React from "react";
import EmployeeAttendancDashboardeWrapper from "./EmployeeAttendancDashboardeWrapper";
import PiCard from "../card/PiCard";
import profile from "../../imgs/profile-img.png";
import AttendanceChart from "../attendance/AttendanceDashBoard/components/AttendanceChart";
import UpcomingHoliday from "../attendance/AttendanceDashBoard/components/UpcomingHoliday";
import TopNav from "./components/TopNav";
import totalEmp from "../../imgs/total-employee.png";
import onLeave from "../../imgs/user.png";
import workingEmp from "../../imgs/working-employee.png";
import pendingTasks from "../../imgs/pending.png";

const cardsData = [
  {
    id: 1,
    icon: totalEmp,
    title: "254",
    subTitle: "Total Working Days",
  },
  {
    id: 2,
    icon: onLeave,
    title: "20",
    subTitle: "Present",
  },
  {
    id: 3,
    icon: workingEmp,
    title: "20",
    subTitle: "Absents",
  },
  {
    id: 4,
    icon: pendingTasks,
    title: "4",
    subTitle: "Leaves",
  },
];

const EmployeeDashboard = () => {
  return (
    <EmployeeAttendancDashboardeWrapper>
      <div className="">
        <div className="">
          <TopNav cardData={cardsData} />
        </div>
        <div className="grid grid-cols-[3fr_2fr] gap-3 mt-8">
          <AttendanceChart />
          <UpcomingHoliday />
        </div>
      </div>
    </EmployeeAttendancDashboardeWrapper>
  );
};

export default EmployeeDashboard;

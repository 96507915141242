import React, { useEffect, useState } from "react";
import PageHeader from "../pageHeader/pageHeader";
import AppointConfirm from "../modal/appointmentConfirmed";
import { useSelector } from "react-redux";
import axios from "axios";
import { Calendar } from "react-multi-date-picker";
import calander from "../../../imgs/calendar.svg";
import moment from "moment";
import logo from "../../../imgs/logo12.png";
import ErrorModal from "../../Merchant/Modals/errorModal";
import HolidayModal from "../../schduleVerification/modal/showHoliday";
import { useNavigate } from "react-router-dom";

const SetSlot = () => {
  const router = useNavigate();
  const PesnionerLinkedCenter = useSelector(
    (state) => state.PesnionerLinkedCenter
  );
  const PensionerBookSlotState = useSelector(
    (state) => state.PensionerBookSlot
  );
  const [appointmentBooked, setAppointmentBooked] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [active, setActive] = useState("1");
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const dataUser = useSelector((state) => state.dataUser);
  const [afterApi, setAfterApi] = useState(false);
  const [slots, setSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const today = new Date();
  const [scheduler, setScheduler] = useState(null);
  const [bookAppointmentCheck, setBookAppointmentCheck] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [err, setErr] = useState("");
  const [month, setMonth] = useState(moment().format("MM"));
  const [year, setYear] = useState(moment().format("YYYY"));
  const [holidays, setHolidays] = useState([]);
  const [showHoliday, setShowHoliday] = useState(false);
  const [holiday, setHoliday] = useState(null);
  const id = PensionerBookSlotState
    ? PesnionerLinkedCenter?.id
    : PesnionerLinkedCenter?.distributioncoverageId;

  const tabs = [
    {
      key: "1",
      title: "Morning",
    },
    {
      key: "2",
      title: "Afternoon",
    },
  ];

  //Get Listing
  useEffect(() => {
    if (selectedDate === null) {
      return;
    }
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        distributioncoverageId: id,
        program: "senior-citizen-Pension",
        date: moment(selectedDate)?.format("YYYY-MM-DD"),

        // ...{ program: `${selectedProgram}` },
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [selectedDate]);

  const fetchAllAccess = async (params, cancelTokenSource) => {
    setSlots([]);
    setAfterApi(false);

    try {
      // Fetch available slots
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/available-slots-for-date`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      );

      const data = res?.data;

      // Fetch already booked slots
      const bookedSlots = await GetAlreadyBookedSlots();

      // Compare available slots with booked slots and update their status
      const morningSlots = data?.morningSlots.map((slot) => {
        const isBooked = bookedSlots.some(
          (bookedSlot) =>
            bookedSlot.fromTime === `${slot.fromTime}:00` &&
            bookedSlot.toTime === `${slot.toTime}:00` &&
            bookedSlot.status !== "cancelled"
        );

        return {
          ...slot,
          status: isBooked ? "booked" : "available",
        };
      });

      const afternoonSlots = data?.afternoonSlots.map((slot) => {
        const isBooked = bookedSlots.some(
          (bookedSlot) =>
            bookedSlot.fromTime === `${slot.fromTime}:00` &&
            bookedSlot.toTime === `${slot.toTime}:00`&&
            bookedSlot.status !== "cancelled"
        );

        return {
          ...slot,
          status: isBooked ? "booked" : "available",
        };
      });

      setSlots(
        res?.data?.morningSlots?.length > 0
          ? {
              morningSlots,
              afternoonSlots,
            }
          : []
      );

      setScheduler(res?.data?.schedulerCenterTimeManager);
      setAfterApi(true);
    } catch (err) {
      setErr(err?.response?.data?.message);
      // setShowErr(true);
      console.log(err);
      setAfterApi(true);
    }
  };

  const GetAlreadyBookedSlots = async () => {
    const params = {
      distributioncoverageId: id,
      program: "senior-citizen-Pension",
      day: moment(selectedDate)?.format("DD"),
      month: moment(selectedDate)?.format("MM"),
      year: moment(selectedDate)?.format("YYYY"),
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/booked-slots-by-month`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
        }
      );

      return res?.data?.rows || []; // Returning booked slots data
    } catch (err) {
      setErr(err?.response?.data?.message);
      setShowErr(true);
      console.log(err);
      return [];
    }
  };

  const filteredAppointments = (key) => {
    if (key === "1") {
      return slots?.morningSlots;
    } else if (key === "2") {
      return slots?.afternoonSlots;
    }
    return [];
  };

  const PensionerBookSlot = () => {
    setBookAppointmentCheck(false);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/by-self`,
        {
          fromTime: selectedAppointment?.fromTime,
          toTime: selectedAppointment?.toTime,
          schedulercentertimemanagerId: scheduler?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((res) => {
        setAppointmentBooked(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErr(true);
      });
  };

  //Holidays API
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/scheduler-holidays`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((res) => {
        const Dates = res?.data?.map((item) =>
          moment(item?.date).format("YYYY-MM-DD")
        );
        setHolidays(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [month, year]);

  const isHoliday = (date) => {
    const dateString = date.format("YYYY-MM-DD"); // Format the date properly

    // Find the holiday object that matches the dateString
    const holiday = holidays.find((holiday) => holiday.date === dateString);

    console.log(holiday, "Hold"); // For debugging

    return holiday; // Return the holiday object or undefined if not found
  };

  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4  min-h-screen">
      <PageHeader title={"Book Slot"} route={-1} header={true} />
      <div className="flex justify-center flex-col items-center">
        <div
          onClick={() => router("/pensionerVerification/AllSlots")}
          className="w-full flex items-center gap-3 cursor-pointer justify-end mt-3 mx-4 sm:w-[90%] w-full"
        >
          <h2 className="font-semibold text-gray-600 text-lg">
            Appointment History
          </h2>
          <img src={calander} alt="" className="w-8 h-8" />
        </div>
        <div
          className={`  flex-col bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-2 p-6 sm:w-[90%] w-full`}
        >
          <div className={`flex items-center justify-between`}>
            <h3 className=" text-[1rem] mb-2 text-semibold text-gray-700">
              Distribution Center
            </h3>
          </div>
          <div className="bg-white p-3 rounded-md mt-1 mb-4">
            {PensionerBookSlotState
              ? PesnionerLinkedCenter?.coverage
              : PesnionerLinkedCenter?.distributioncoverage?.coverage}{" "}
            {PensionerBookSlotState
              ? PesnionerLinkedCenter?.distributioncenter?.distributionlocation
                  ?.distributionregion?.regionNo
              : PesnionerLinkedCenter?.distributioncoverage?.distributioncenter
                  ?.distributionlocation?.distributionregion?.regionNo}
          </div>
          {/* Choose Slot */}
          <h1 className="text-[1rem] mb-3 text-semibold text-gray-700">
            Choose a Slot
          </h1>
          <div className="flex gap-4 md:flex-row flex-col">
            <Calendar
              style={{ zIndex: 10 }}
              className="custom-calendar"
              value={selectedDate}
              onChange={(date) => {
                if (date instanceof Array) {
                  setSelectedDate(date[0].toDate()); // For some cases returning array, just take first element
                } else {
                  setSelectedDate(date.toDate ? date.toDate() : date); // Set the correct DateObject or JS Date
                }
              }}
              minDate={today}
              mapDays={({ date }) => {
                let props = {};
                const holiday = isHoliday(date); // Returns the holiday object or undefined

                if (holiday) {
                  // If it's only a holiday, apply holiday styling
                  props.disabled = true;
                  props.style = {
                    // backgroundColor: "lightgreen", // Optional holiday style
                    marginTop: "0.5em",
                    borderRadius: "50%",
                    cursor: "pointer",
                  };
                  props.children = (
                    <div>
                      {date.day}
                      <div
                        style={{ fontSize: "0.6em" }}
                        className="text-gray-500"
                      >
                        Holiday
                      </div>
                    </div>
                  );
                  props.onClick = () => {
                    setHoliday(holiday?.name);
                    setShowHoliday(true);
                  };
                }

                return props;
              }}
              onMonthChange={(month) => {
                setMonth(month.format("MM"));
              }}
              onYearChange={(year) => {
                setYear(year.format("YYYY"));
              }}
            />

            {selectedDate === null ? (
              <div className="flex items-center justify-center sm:p-10 py-2 bg-[#F6F4F4] w-full">
                <h1 className="text-[1rem] text-gray-700 font-semibold text-center w-full">
                  Select a date to view available slots
                </h1>
              </div>
            ) : (
              <div className="flex flex-col w-full">
                <ul className="flex text-center border-gray-200 text-gray-700 w-full">
                  {tabs.map((tab) => (
                    <li
                      key={tab.key}
                      onClick={() => setActive(tab.key)}
                      className={`flex justify-center border-b-[2.5px] cursor-pointer text-md gap-2 px-4 mr-4 ${
                        active === tab.key
                          ? "border-primary text-primary font-semibold text-[1rem]"
                          : "border-transparent font-semibold"
                      } hover:text-primary hover:border-primary py-2`}
                    >
                      {tab.title}
                    </li>
                  ))}
                </ul>

                <div className="sm:p-10 py-2 bg-[#F6F4F4] w-full flex-grow">
                  {tabs.find((tab) => tab.key === active)?.component}

                  <div className={`${afterApi ? "w-full" : "hidden"}`}>
                    {filteredAppointments(active)?.length > 0 ? (
                      <div className="flex flex-wrap p-2 gap-4 p-2">
                        {filteredAppointments(active)?.map(
                          (appointment, index) => (
                            console.log("APP", appointment),
                            (
                              <div
                                key={index}
                                className={`${
                                  appointment?.status === "booked"
                                    ? "pointer-events-none"
                                    : ""
                                } bg-white rounded-lg shadow-md text-center w-40 mb-2 cursor-pointer`}
                                onClick={() => {
                                  setSelectedAppointment(appointment);
                                  setBookAppointmentCheck(true);
                                }}
                              >
                                <div
                                  className={`${
                                    appointment?.status === "booked"
                                      ? "bg-gray-300 text-white"
                                      : "bg-[#E6F1F0] text-primary"
                                  } rounded-t-lg py-2 font-semibold`}
                                >
                                  {appointment?.status === "booked"
                                    ? "Booked"
                                    : "Available"}
                                </div>
                                <div className="py-4 text-black font-semibold">
                                  {moment(
                                    appointment?.fromTime,
                                    "HH:mm"
                                  ).format("hh:mm A")}{" "}
                                  –{" "}
                                  {moment(appointment?.toTime, "HH:mm").format(
                                    "hh:mm A"
                                  )}
                                </div>
                              </div>
                            )
                          )
                        )}
                      </div>
                    ) : (
                      <div className="flex items-center justify-center sm:p-10 py-2 bg-[#F6F4F4] w-full h-full">
                        <h1 className="text-[1rem] text-gray-700 font-semibold text-center w-full">
                          No Available Slots
                        </h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Button */}
      {/* <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            setAppointmentBooked(true);
            // router("/pensionerVerification/selectProgram");
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[14rem]`}
        >
          Book Appointment
        </button>
      </div> */}
      {appointmentBooked && (
        <AppointConfirm
          setShow={setAppointmentBooked}
          date={moment(selectedDate).format("MM/DD/YYYY")}
          time={` ${moment(selectedAppointment?.fromTime, "HH:mm").format(
            "hh:mm A"
          )} - ${moment(selectedAppointment?.toTime, "HH:mm").format(
            "hh:mm A"
          )}`}
        />
      )}
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}

      {showHoliday && (
        <HolidayModal setShow={setShowHoliday} holiday={holiday} />
      )}
      {/* Change Status Modal */}
      <div
        className={`${
          bookAppointmentCheck ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[24rem] pb-6 flex items-center p-3 bg-white flex-col">
          <img src={logo} alt="" className="w-20 h-20 mt-4" />
          <p className="text-center text-[0.95rem] mt-3 mb-5 w-[85%]">
            Are you sure you want to book appointment for{" "}
            {` ${moment(selectedAppointment?.fromTime, "HH:mm").format(
              "hh:mm A"
            )} - ${moment(selectedAppointment?.toTime, "HH:mm").format(
              "hh:mm A"
            )}`}{" "}
            ?
          </p>
          <div className="flex">
            <button
              onClick={() => setBookAppointmentCheck(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              No
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
              onClick={() => {
                PensionerBookSlot();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetSlot;

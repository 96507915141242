import { Checkbox, DatePicker, Pagination, Popover } from "antd";
import { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import btn from "../../../../imgs/btnicon.png";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";

const Paget = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [search, setSearch] = useState("");

  const [searchDop, setSearchDop] = useState("");
  const [balance, setBalance] = useState(0);
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [agencies, setAgencies] = useState([]);
  const [paymentDate, setPaymentDate] = useState("");
  const [prompt, setPrompt] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [hasLowBalance, setHasLowBalance] = useState(false);
  const [checkLimit, setCheckLimit] = useState(false);
  const columnHeaders = [
    "Sr No.",
    "Govt. Agency",
    // "Admin",
    "Address",
    "Email",
    "Phone",

    "Balance",
    // "Balance Status",
  ];

  useEffect(() => {
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      // Create a new cancel token source for this request
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 6,
        skip:
          search || searchDop || countryCode || phoneNoo ? 0 : (page - 1) * 6,
        page: search ? pagewithsearch : page,
        sortBy: "createdAt:desc",
        ...(hasLowBalance === true && { lowLimit: hasLowBalance }),
        ...(search && { search }),
        ...(countryCode &&
          phoneNoo && { phoneNo: `+${countryCode + phoneNoo}` }),
      };

      fetchEmployees(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [
    page,
    search,
    searchDop,
    countryCode,
    phoneNoo,
    pagewithsearch,
    hasLowBalance,
  ]);

  const fetchEmployees = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-user/list-govt-agency-merch/for-mhsss?status=approved`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((data) => {
        setAgencies(data?.data?.rows);
        setTotalPages(data?.data?.totalPages);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
    } else {
      setState(event.target.value);
    }
  };

  const pageHandler = (e) => {
    if (search) {
      // If searching, update searchPage
      setPagewithsearch(e);
    } else {
      // If not searching, update page
      setPage(e);
    }
  };
  const showHandler = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const disabledDate1 = (current) => {
    // Can not select days after today
    return current && current > dayjs().endOf("day");
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/bank-balance-info/govt-agency-merch/sum-of-all-balances`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setBalance(data?.data);
      })
      .catch(() => {});
  }, []);

  return (
    <section className="">
      <div className="mx-auto max-w-screen-xl relative  h-auto">
        <div className="relative sm:rounded-lg overflow-hidden sm:bg-[#D9EFED]">
          <div className="w-full flex flex-row items-center justify-end mb-2 sm:bg-[#D9EFED] sm:mt-0 mt-5">
            <p className="font-semibold mr-3">Available Balance</p>
            <div className="md:w-[10rem] border w-[30%]">
              <div className="flex items-center w-full">
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2 font-bold text-black">
                    $
                  </span>
                  <p className="pl-9 pr-6 w-full bg-gray-50 border border-gray-300 text-gray-900 font-semibold rounded-lg p-2">
                    {balance ? balance : 0}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="w-full  md:w-auto md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <div className="flex items-center ml-3 w-full md:w-auto">
                  <div className="w-full md:w-auto flex items-center justify-center py-2 px-6 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200">
                    2024
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="w-full flex sm:flex-row flex-col items-center justify-end mb-4 gap- sm:bg-[#D9EFED]">
            <div className="sm:w-[45%] w-full  md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="w-full  md:w-auto md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <div className="flex items-center space-x-3 w-full md:w-auto">
                  <div className="relative w-full">
                    <button
                      id="filterDropdownButton"
                      data-dropdown-toggle="filterDropdown"
                      className="mr-3 w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200      "
                      type="button"
                      onClick={showHandler}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="h-4 w-4 mr-2 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Filter By
                      <svg
                        className="-mr-1 ml-1.5 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          clipRule="evenodd"
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        />
                      </svg>
                    </button>

                    {show && (
                      <div
                        id="filterDropdown"
                        className="z-50 absolute top-full right-0 mt-2 sm:w-[18rem] w-full p-3 bg-white rounded-lg shadow "
                      >
                        <div className="mt-5">
                          <PhoneInput
                            placeholder="Enter phone number"
                            defaultCountry="GY"
                            value={prompt}
                            onChange={handleChange(setPrompt, "tt")}
                            international
                            countryCallingCodeEditable={false}
                          />
                        </div>
                        <div className="mt-5">
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked) {
                                setCheckLimit(true);
                              } else {
                                setCheckLimit(false);
                              }
                            }}
                            checked={checkLimit}
                          >
                            Govt. Agency with low balance
                          </Checkbox>
                        </div>

                        <div className="mt-4 w-full flex gap-x-2 items-center justify-center">
                          <button
                            onClick={() => {
                              if (checkLimit) {
                                setHasLowBalance(true);
                              }
                              if (checkLimit === false) {
                                setHasLowBalance(false);
                              }
                              if (prompt) {
                                const wow = parsePhoneNumber(prompt);

                                let count = wow.countryCallingCode;
                                let num = wow.nationalNumber;
                                setCountryCode(count);
                                setPhoneNoo(num);
                              }
                              if (paymentDate !== null && paymentDate !== "")
                                setSearchDop(paymentDate);
                              setShow(false);
                            }}
                            className={` bg-gradient-to-tr bg-primary text-white mr-3 rounded-[0.5rem] p-2  pr-8 pl-8`}
                          >
                            Apply
                          </button>
                          <button
                            onClick={() => {
                              setPhoneNoo(null);
                              setPrompt("");
                              setCountryCode(null);
                              setShow(false);
                              setHasLowBalance(false);
                            }}
                            className={` bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="md:w-[26rem] w-full sm:mt-0 mt-3">
              <form className="flex gap-2 items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative sm:w-[65%] w-[60%]">
                  <input
                    type="text"
                    id="simple-search"
                    className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-4 p-2 "
                    placeholder="Search"
                    required=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
                <div className="sm:w-[35%] w-[40%]">
                  <button
                    className="bg-primary flex gap-2 sm:ml-1 text-white w-full sm:w-auto px-2 rounded-md p-2 text-[0.9rem]"
                    onClick={() => router("/MHSSS/GovernmentAgency/Requests")}
                  >
                    <img src={btn} alt="" className="w-6 h-6" />
                    <p className="whitespace-nowrap mr-1">View Request</p>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg custom-scrollbar">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders.map((columnHeader) => (
                    <th
                      scope="col"
                      className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                    >
                      {columnHeader}
                    </th>
                  ))}
                </tr>
              </thead>
              {agencies?.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      colSpan="11"
                      className="text-center text-gray-500 text-lg bg-white"
                    >
                      <p className="p-10 py-20">No data found</p>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {agencies?.map((data, index) => (
                    <tr
                      onClick={
                        () => {
                          dispatch({
                            type: "SELECTED_GOV_AGENCY",
                            num: {
                              ...data,
                              merchId: data?.merchUser?.id,
                              image: data?.merchUser?.merchcompany?.logo,
                              name: data?.merchUser?.merchcompany?.name,
                              phone:
                                data?.merchUser?.merchheadquarter?.primaryPhone,
                              email:
                                data?.merchUser?.merchheadquarter?.primaryEmail,
                              location:
                                data?.merchUser?.merchheadquarter?.city +
                                ", " +
                                data?.merchUser?.merchheadquarter?.region,
                              balance:
                                data?.bankBalanceInfo !== null
                                  ? data?.bankBalanceInfo?.totalBalance
                                  : 0,
                              lowBalance: data?.hasLowBalance,
                            },
                          });
                          router("/MHSSS/GovernmentAgency/Details");
                        }
                        // }
                      }
                      className="border-b border-[1px] bg-white border-[#d9d9d970] bg-gray-50 hover:bg-gray-300 text-md cursor-pointer"
                    >
                      <td className="py-4 whitespace-nowrap capitalize px-4">
                        {index + 1 + (page - 1) * 6}.
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap font-semibold text-primary capitalize">
                        <div>{data?.merchUser?.merchcompany?.name}</div>
                      </td>
                      {/* <td className="px-4 py-4 text-primary font-semibold whitespace-nowrap">
                        <div>{"-----"}</div>
                      </td> */}
                      <td className="px-4 py-4 whitespace-nowrap">
                        {data?.merchUser?.merchheadquarter !== null
                          ? data?.merchUser?.merchheadquarter?.city +
                            ", " +
                            data?.merchUser?.merchheadquarter?.region
                          : "-----"}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap">
                        {data?.merchUser?.merchheadquarter !== null
                          ? data?.merchUser?.merchheadquarter?.primaryEmail
                          : "-----"}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap">
                        {data?.merchUser?.merchheadquarter !== null
                          ? data?.merchUser?.merchheadquarter?.primaryPhone
                          : "-----"}
                      </td>

                      <td className="px-4 py-4 whitespace-nowrap">
                        <div
                          className={`border-none ${
                            data?.hasLowBalance === true
                              ? "text-[#EF4D5C]"
                              : "text-primary"
                          } text-center font-semibold cursor-pointer`}
                        >
                          $
                          {data?.bankBalanceInfo !== null
                            ? data?.bankBalanceInfo?.totalBalance
                            : 0}
                        </div>
                      </td>
                      {/* <td className="px-4 py-3 whitespace-nowrap">
                        <Popover placement="bottomLeft">
                          <div
                            className={`border-none ${
                              data?.BalanceStatus === "Average"
                                ? "bg-blue-500"
                                : data?.BalanceStatus === "Low"
                                ? "bg-[#F35929]"
                                : "bg-primary"
                            }  p-1 pl-5 pr-5 rounded-md text-center text-white focus:border-none cursor-pointer capitalize`}
                          >
                            {data?.BalanceStatus}
                          </div>
                        </Popover>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>

          <nav
            className="bg-gray-50 flex flex-col h-[5.5rem] md:flex-row w-full justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500">
              Showing{" "}
              <span className="font-semibold text-gray-900">Page {page}</span>{" "}
              of{" "}
              <span className="font-semibold text-gray-900">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={search ? pagewithsearch : page}
              />
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default Paget;

import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import eye from "../../../../imgs/eyee.png";
import ReactInputVerificationCode from "react-input-verification-code";
import axios from "axios";
import cross from "../../../../imgs/cross.png";
import logo1 from "../../../../imgs/logo12.png";
import across from "../../../../imgs/across.png";
import { useLocation } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { parsePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import { useDispatch } from "react-redux";
let data;
const LoginCredentials1 = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state.profileData);
  console.log("DataUser", profileData);
  const user = useSelector((state) => state.user);
  const [confirmPass, setConfirmPass] = useState("");
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [showVPass, setShowVPass] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [showS, setShowS] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [pass, setPass] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [pin, setPin] = useState("");
  const [prompt, setPrompt] = useState("");
  const [showPopReset, setShowPopReset] = useState(false);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [showEditPhone, setShowEditPhone] = useState(false);
  const [showPopRPin, setShowPopRPin] = useState(false);
  const [showPV, setShowPv] = useState(false);
  const [test, setTest] = useState(true);
  // const handleChange = (setState) => (event) => {
  //   setState(event.target.value);
  // };
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
    } else {
      setState(event.target.value);
    }
  };
  const togglePasswordVisibility = (setter) => {
    setter((prev) => !prev);
  };
  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [data]);
  useEffect(() => {
    if (profileData.user) {
      data = profileData.user;
      if (!test) {
        setTest(true);
      } else {
        setTest(false);
      }
    }
  }, [profileData]);
  const resetHandler = () => {
    if (newPass !== confirmPass) {
      setShow(true);
      setErr("Please confirm password again");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/auth/change-password`,
          {
            oldPassword: oldPass,
            newPassword: newPass,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          setShowPopReset(false);
          setNewPass("");
          setOldPass("");
          setConfirmPass("");
          setErr("PassWord Changed Sucessfully.");
          setShowS(true);
          setTimeout(() => {
            setShowS(false);
          }, 1000);
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow(true);
        });
    }
  };
  console.log("DataUser", dataUser);
  console.log("User", user);
  console.log("ProfileData", profileData);
  const UpdateEmail = () => {
    if (!newEmail) {
      setShow(true);
      setErr("Please Enter New Email...");
    } else {
      if (user === "pensioner") {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/v1/users/add-email`,
            {
              email: newEmail,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            dispatch({ type: "RESET" });
            setShowEditEmail(false);
            setNewEmail("");
            setErr("Email Updated Sucessfully.");
            setShowS(true);
            setTimeout(() => {
              setShowS(false);
            }, 1000);
          })
          .catch((err) => {
            setErr(err.response.data.message);
            setShow(true);
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/v1/users/update-email/${profileData?.user?.id}/from-mhsss`,
            {
              email: newEmail,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            dispatch({ type: "RESET" });
            setShowEditEmail(false);
            setNewEmail("");
            setErr("Email Updated Sucessfully.");
            setShowS(true);
            setTimeout(() => {
              setShowS(false);
            }, 1000);
          })
          .catch((err) => {
            setErr(err.response.data.message);
            setShow(true);
          });
      }
    }
  };
  const UpdatePhone = () => {
    if (!prompt) {
      setShow(true);
      setErr("Please Enter New Phone Number...");
    } else {
      const wow = parsePhoneNumber(prompt);
      let countryCode = wow.countryCallingCode;
      let number = wow.nationalNumber;
      if (user === "pensioner") {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/v1/users/add-phone`,
            {
              countryCode: `+${countryCode}`,
              phoneNo: number,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            dispatch({ type: "RESET" });
            setShowEditPhone(false);
            setPrompt(null);
            setErr("Phone Updated Sucessfully.");
            setShowS(true);
            setTimeout(() => {
              setShowS(false);
            }, 1000);
          })
          .catch((err) => {
            setErr(err.response.data.message);
            setShow(true);
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/v1/users/update-phone/${profileData?.user?.id}/from-mhsss`,
            {
              countryCode: `+${countryCode}`,
              phoneNo: number,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            dispatch({ type: "RESET" });
            setShowEditPhone(false);
            setPrompt(null);
            setErr("Phone Updated Sucessfully.");
            setShowS(true);
            setTimeout(() => {
              setShowS(false);
            }, 1000);
          })
          .catch((err) => {
            setErr(err.response.data.message);
            setShow(true);
          });
      }
    }
  };
  const VerifyPass = () => {
    if (pass === "") {
      setShow(true);
      setErr("Please enter your password");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/pensioner-info/my-pincode`,
          {
            password: pass,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          setPin(data?.data?.pinCode);
          setShowPv(false);
          setShowPopRPin(true);
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow(true);
        });
    }
  };
  const ReSetPin = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/pensioner-info/reset-pincode`,
        {
          pinCode: pin,
          password: pass,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        setShowVPass(false);
        setPass("");
        setPin("");
        setShowPopRPin(false);
        setErr("Pin Reset Sucessfully.");
        setShowS(true);
        setTimeout(() => {
          setShowS(false);
        }, 1000);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  };
  const completeHandler = (e) => {
    setPin(e);
  };
  return (
    <div>
      {data && (
        <div>
          <h2 className="mb-4 text-center text-[1.5rem] font-bold">
            Login Credentials
          </h2>
          <h2 className="mb-4 text-[1.1rem] font-semibold flex items-center">
            Mobile:
            {data?.phoneNo && user === "pensioner"
              ? ""
              : (dataUser?.data?.user?.roles[0] === "pensioner" ||
                  profileData?.user?.roles[0] === "pensioner") && (
                  <span
                    onClick={() => setShowEditPhone(true)}
                    className="ml-auto cursor-pointer text-blue-500 hover:text-blue-700"
                  >
                    {user === "pensioner" ? "Add" : "Edit"}
                  </span>
                )}
          </h2>
          {}
          <input
            type="phone"
            readOnly
            placeholder={"Phone"}
            value={
              data.phoneNo === null ? "" : `${data.countryCode} ${data.phoneNo}`
            }
            className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4"
          />
          {/* <h2 className="mb-4 text-[1.1rem] font-semibold">Email:</h2> */}
          <h2 className="mb-4 text-[1.1rem] font-semibold flex items-center">
            Email:
            {data?.email && user === "pensioner"
              ? ""
              : (dataUser?.data?.user?.roles[0] === "pensioner" ||
                  profileData?.user?.roles[0] === "pensioner") && (
                  <span
                    onClick={() => setShowEditEmail(true)}
                    className="ml-auto cursor-pointer text-blue-500 hover:text-blue-700"
                  >
                    {user === "pensioner" ? "Add" : "Edit"}
                  </span>
                )}
          </h2>
          <input
            type="email"
            readOnly
            value={data.email}
            placeholder={"Email"}
            className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 pointer-events-none"
          />
          <h2 className="mb-4 text-[1.1rem] font-semibold">GID:</h2>
          <input
            type="text"
            readOnly
            value={data.gid}
            placeholder={"GID"}
            className={`lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 pointer-events-none`}
          />
          {dash === "/Dashboard/PensionerAccount" && (
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold flex items-center">
                Password:
                <span
                  onClick={() => setShowPopReset(true)}
                  className="ml-auto cursor-pointer text-blue-500 hover:text-blue-700"
                >
                  Change Password
                </span>
              </h2>
              <input
                type="password"
                readOnly
                value={"*******"}
                placeholder={"Password"}
                className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 pointer-events-none"
              />
            </div>
          )}
          {dash === "/Dashboard/PensionerAccount" && (
            <div>
              <h2 className="mb-4 text-[1.1rem] font-semibold flex items-center">
                PIN:
                <span
                  onClick={() => setShowPv(true)}
                  className="ml-auto cursor-pointer text-blue-500 hover:text-blue-700"
                >
                  Reset PIN
                </span>
              </h2>
              <input
                type="pin"
                readOnly
                value={"****"}
                placeholder={"PIN"}
                className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 pointer-events-none"
              />
            </div>
          )}
        </div>
      )}
      {showPopReset === true && (
        <div
          className={`w-[100%] z-[10%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
            <h2 className="mb-[1rem] text-[1.5rem] font-bold">
              Reset Password
            </h2>
            <div
              className="absolute top-3 right-4 cursor-pointer"
              onClick={() => {
                setShowPopReset(false);
                setNewPass("");
                setOldPass("");
                setConfirmPass("");
              }}
            >
              <img className="scale-[0.8]" src={cross} alt="Close" />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="oldPassword"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Current Password *
              </label>
              <div className="flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                <input
                  type={showOldPass ? "text" : "password"}
                  placeholder="Current Password"
                  onChange={handleChange(setOldPass)}
                  value={oldPass}
                  className="w-[100%]"
                  autoComplete="new-password"
                />
                <img
                  className="w-[1.6rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(setShowOldPass)}
                  src={eye}
                  alt="Toggle Password Visibility"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="newPassword"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                New Password *
              </label>
              <div className="flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                <input
                  type={showNewPass ? "text" : "password"}
                  placeholder="New Password"
                  onChange={handleChange(setNewPass)}
                  value={newPass}
                  className="w-[100%]"
                  autoComplete="new-password"
                />
                <img
                  className="w-[1.6rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(setShowNewPass)}
                  src={eye}
                  alt="Toggle Password Visibility"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="confirmPassword"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Confirm Password *
              </label>
              <div className="flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                <input
                  type={showConfirmPass ? "text" : "password"}
                  placeholder="Confirm Password"
                  onChange={handleChange(setConfirmPass)}
                  value={confirmPass}
                  className="w-[100%]"
                  autoComplete="new-password"
                />
                <img
                  className="w-[1.6rem] cursor-pointer"
                  onClick={() => togglePasswordVisibility(setShowConfirmPass)}
                  src={eye}
                  alt="Toggle Password Visibility"
                />
              </div>
            </div>
            <button
              onClick={resetHandler}
              className="w-full bg-primary text-white p-3 mb-4 text-medium text-[1.1rem] rounded-lg mt-5"
            >
              Reset
            </button>
          </div>
        </div>
      )}
      {showPV === true && (
        <div
          className={`w-[100%] z-[10%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[22rem] pb-6 flex items-center p-3 bg-white flex-col">
            <div className="flex items-center justify-center flex-col sm:w-[18rem]">
              <h2 className="mb-[1rem] text-[12px] mt-6 font-bold">
                Please verify your password to View/Reset PIN.
              </h2>
              <div
                className="absolute top-3 right-4 cursor-pointer"
                onClick={() => {
                  setShowPv(false);
                  setPass("");
                }}
              >
                <img className="scale-[0.8]" src={cross} alt="Close" />
              </div>
              <div className="flex flex-col w-">
                <div className="flex mt-2 justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                  <input
                    type={showVPass ? "text" : "password"}
                    placeholder="Enter Password"
                    onChange={handleChange(setPass)}
                    value={pass}
                    className="w-[100%]"
                    autoComplete="new-password"
                  />
                  <img
                    className="w-[1.6rem] cursor-pointer"
                    onClick={() => togglePasswordVisibility(setShowVPass)}
                    src={eye}
                    alt="Toggle Password Visibility"
                  />
                </div>
                <button
                  onClick={VerifyPass}
                  className="w-full bg-primary text-white p-3 mb-4 text-medium text-[1.1rem] rounded-lg mt-5"
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPopRPin === true && (
        <div
          className={`w-[100%] z-[10%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[22rem] pb-6 flex items-center p-3 bg-white flex-col">
            <div className="flex items-center justify-center flex-col sm:w-[18rem]">
              <div
                className="absolute top-3 right-4 cursor-pointer"
                onClick={() => {
                  setShowPopRPin(false);
                  setPass("");
                  setPin("");
                }}
              >
                <img className="scale-[0.8]" src={cross} alt="Close" />
              </div>
              <div className="flex flex-col mt-2 w-[80%] items-center justify-center">
                <div className="flex mt-6 justify-between lg:text-[1.1rem] w-[100%] bg-white rounded-[0.3rem] p-2">
                  <ReactInputVerificationCode
                    className="--ReactInputVerificationCode-itemWidth "
                    length={4}
                    onCompleted={completeHandler}
                    value={pin}
                    onChange={(code) => setPin(code)}
                  />
                </div>
                <h2 className="mb-[1rem] text-[12px] font-bold">
                  <span className="text-red-500">Note:</span> Please do not
                  share your pin with anyone.
                </h2>
                <button
                  onClick={ReSetPin}
                  className="w-full bg-primary text-white p-3 mb-4 text-medium text-[1.1rem] rounded-lg mt-3"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${
          showS ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div>
            <img src={logo1} alt="" className="w-20 h-20"/>
          </div>
          <div className="text-center mt-[1rem] mb-5">
            <p>
              <strong> {err} </strong>
            </p>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] z-[9999] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          showEditEmail ? "" : "hidden"
        } w-[100%] z-20 h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[0.5rem] right-[1rem] cursor-pointer"
            onClick={() => setShowEditEmail(false)}
          >
            <CloseOutlined style={{ fontSize: "15px", color: "red" }} />
          </div>
          <div
            className="absolute top-[5%] left-[37%]"
            onClick={() => setShowEditEmail(false)}
          >
            <img src={logo1} alt="" className="w-20 h-20"/>
          </div>
          <div className=" mt-[5rem] p-3 mb-[0.5rem] w-full">
            <h2 className="mb-2 text-[0.9rem] font-semibold">New Email:</h2>
            <input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder={"Email"}
              className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4"
            />
          </div>
          <button
            onClick={UpdateEmail}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          showEditPhone ? "" : "hidden"
        } w-[100%] z-20 h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[0.5rem] right-[1rem] cursor-pointer"
            onClick={() => setShowEditPhone(false)}
          >
            <CloseOutlined style={{ fontSize: "15px", color: "red" }} />
          </div>
          <div
            className="absolute top-[5%] left-[37%]"
            onClick={() => setShowEditPhone(false)}
          >
            <img src={logo1} alt="" className="w-20 h-20"/>
          </div>
          <div className=" mt-[5rem] p-3 mb-[0.5rem] w-full">
            <h2 className="mb-2 text-[0.9rem] font-semibold">New Phone:</h2>
            <PhoneInput
              placeholder="Enter New phone number"
              defaultCountry="GY"
              id="phone"
              // value={prompt}
              onChange={handleChange(setPrompt, "tt")}
              international
              countryCallingCodeEditable={false}
              className=".PhoneInputCountryIcon mb-[5px]"
            />
          </div>
          <button
            onClick={UpdatePhone}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      {/* <button
        className={`lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
      >
        Save
      </button> */}
    </div>
  );
};

export default LoginCredentials1;

import React from "react";

import totalEmp from "../../../../imgs/total-employee.png";
import onLeave from "../../../../imgs/user.png";
import workingEmp from "../../../../imgs/working-employee.png";

const cardsData = [
  {
    id: 1,
    icon: totalEmp,
    title: "80",
    subTitle: "Total Devices",
  },
  {
    id: 2,
    icon: workingEmp,
    title: "60",
    subTitle: "In Use",
  },
  {
    id: 3,
    icon: onLeave,
    title: "15",
    subTitle: "Pending",
  },
  {
    id: 4,
    icon: onLeave,
    title: "05",
    subTitle: "Not In Use",
  },
];

const DevicesCards = () => {
  return (
    <div className="">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:ml-0 place-items-center ">
        {cardsData.map((card) => (
          <div
            key={card.id}
            className="flex w-full flex-row items-center gap-4 border border-border shadow bg-[#f8f8f8] rounded-md px-5 py-8">
            <div className="w-11 h-1w-11">
              <img className="w-full h-full object-contain" src={card.icon} alt="" />
            </div>
            <div className="flex flex-col gap-1">
              <h2 className="text-3xl font-bold">{card.title}</h2>
              <p className="text-lg font-normal">{card.subTitle}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DevicesCards;

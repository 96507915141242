import React, { useState } from "react";
import PageHeader from "./pageHeader/pageHeader";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ErrorModal from "../Merchant/Modals/errorModal";
import { useDispatch, useSelector } from "react-redux";
import calendar from "../../imgs/calendar.svg";
import pdf from "../../imgs/pdf.png";
let searchTimeout;
const SelectLocation = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [allData, setAllData] = useState();
  const [items, setItems] = useState([]);
  // const [selectedData, setSelectedData] = useState([]);
  const [err, setErr] = useState("");
  const user = useSelector((state) => state.user);
  const [showErr, setShowErr] = useState(false);
  const [regionName, setRegionName] = useState("");
  const [regionNo, setRegionNo] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationCode, setLocationCode] = useState("");
  const [centerId, setCenterId] = useState("");
  const [centerName, setCenterName] = useState("");
  const [coverage, setCoverage] = useState(null);
  //Search Coverage
  const handleSearch = (query) => {
    // Clear previous timeouts to ensure only one request is sent
    clearTimeout(searchTimeout);

    // Check if the query length is greater than or equal to 3
    if (query.length >= 3) {
      // Set a timeout to delay the API call
      searchTimeout = setTimeout(() => {
        // Make the API call
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/v1/distribution-coverages/search?search=${query}`
          )
          .then((data) => {
            let wow = [];

            // Inserting a default option at the beginning
            if (data?.data?.rows?.length !== 0) {
              wow.push({
                value: 0,
                label: "Select coverage", // Adjust the label as needed
              });
            }

            data.data.rows.map((ww, index) => {
              wow.push({
                value: ww?.id, // Adjust the index to start from 1
                label: `${ww.coverage}`,
              });
            });

            setItems(wow);
            setAllData(data.data.rows);
          })
          .catch(() => {
            // Handle error
          });
      }, 500); // 500 milliseconds delay (adjust as needed)
    }
  };

  const Label = ({ text }) => (
    <label className="text-gray-700 text-[0.88rem] font-semibold mb-2">
      {text}
    </label>
  );
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4  min-h-screen">
      <PageHeader
        title={"Pensioner Verification"}
        route={
          user === "employee"
            ? "/EDashboard"
            : user === "admin"
            ? "/adminDashboard"
            : "/superAdminDashboard"
        }
        header={true}
      />
      <h1 className="text-center font-normal sm:text-[1.3rem] text-[0.9rem]">
        Select Location to start with
      </h1>
      <h1 className="font-bold text-center  sm:text-[1.5rem] text-[0.95rem]">
        Pensioner <span className="text-primary">Verification Process</span>
      </h1>

      <div className="flex justify-center flex-col items-center">
        {/* History */}
        {/* <div
          className={` ${
            user === "employee"
              ? "hidden"
              : "flex justify-end items-center gap-2 cursor-pointer sm:w-[80%] w-full"
          }`}
        >
          <h1 className="font-semibold text-[0..95rem]">
            View Existing Schedule
          </h1>
          <img src={calendar} alt="" className="w-6 h-6" />
        </div> */}

        {/* Coveragae */}
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%] w-[95%] relative`}
        >
          {/* <a
            href="https://sap-api-staging.jabcomp.gy/public/office-distribution-location-list-v18.pdf"
            target="_blank"
            className="cursor-pointer absolute top-4 right-3"
            rel="noreferrer"
          >
            <img className="sm:w-[2.8rem] w-[2rem]" src={pdf} alt="" />
          </a> */}

          <div
            className="cursor-pointer absolute top-4 right-3 flex items-center gap-2"
            onClick={() => {
              router("/pensionerVerification/searchDistribution");
            }}
          >
            <p className="text-primary font-semibold">Find Coverages</p>
            <p className="text-white font-semibold bg-primary w-4 h-4 flex items-center justify-center rounded-full p-2 ">
              i
            </p>
          </div>
          <form action="">
            <div className="flex justify-center items-center p-4 flex-col">
              <div className="w-[100%]">
                <div className="flex flex-col">
                  {/* <Label text={"Coverage"} /> */}
                  <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                    Coverage{" "}
                    <span className="text-red-600">
                      ( Insert atleast 3 letters to search )
                    </span>
                  </label>
                  <Select
                    placeholder="Select Coverage"
                    onChange={(e, w) => {
                      console.log(e, w);
                      if (e) {
                        const wow = allData.filter((item) => {
                          return item.id === e;
                          // return w.label === item.coverage;
                        });

                        // setSelectedData(wow);
                        setCoverage(wow[0].coverage);
                        setRegionNo(
                          wow[0].distributioncenter.distributionlocation
                            .distributionregion.regionNo
                        );
                        setRegionName(
                          wow[0].distributioncenter.distributionlocation
                            .distributionregion.regionName
                        );
                        setLocationCode(
                          wow[0].distributioncenter.distributionlocation
                            .locationCode
                        );
                        setLocationName(
                          wow[0].distributioncenter.distributionlocation
                            .locationName
                        );
                        setCenterId(wow[0].distributioncenter.centerCode);
                        setCenterName(wow[0].distributioncenter.centerName);
                        // setDistributionlocationId(wow[0].id);
                        dispatch({
                          type: "DISTRIBUTION_Center",
                          num: wow[0],
                        });
                      }
                    }}
                    allowClear
                    onClear={() => {
                      setItems([]);
                      setCoverage(null);
                      setRegionNo("");
                      setRegionName("");
                      setLocationCode("");
                      setLocationName("");
                      setCenterId("");
                      setCenterName("");
                    }}
                    onSearch={(e) => handleSearch(e)}
                    value={coverage}
                    showSearch
                    optionFilterProp="children"
                    style={{ marginBottom: "0.5rem" }}
                    className="wow"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size="middle"
                    options={items}
                  />
                  
                </div>
              </div>

              <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1  w-full">
                <div className="w-[100%]">
                  <div className="flex flex-col">
                    <Label text={"Region No"} />
                    <input
                      type="text"
                      name=""
                      value={regionNo}
                      placeholder="Region No"
                      className={` lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                    />
                   
                  </div>
                  <div className="flex flex-col">
                    <Label text={"District Code"} />
                    <input
                      type="text"
                      name=""
                      value={locationCode}
                      placeholder="District Code"
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                    />
                  </div>
                  <div className="flex flex-col">
                    <Label text={"Center ID"} />
                    <input
                      type="text"
                      name=""
                      value={centerId}
                      placeholder="Center ID"
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex flex-col">
                    <Label text={"Region Name"} />
                    <input
                      type="text"
                      name=""
                      value={regionName}
                      placeholder="Region Name"
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                    />
                  </div>
                  <div className="flex flex-col">
                    <Label text={"District Name"} />
                    <input
                      type="text"
                      name=""
                      value={locationName}
                      placeholder="District Name"
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                    />
                  </div>
                  <div className="flex flex-col">
                    <Label text={"Center Name"} />
                    {/* <Select
                      placeholder="Center Name"
                      optionFilterProp="children"
                      className="wow"
                      style={{ marginBottom: "1.1rem" }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      size="middle"
                      // onChange={handleChange(setUnionStatus, "tg")}
                      options={[]}
                    /> */}
                    <input
                      type="text"
                      name=""
                      placeholder=" Center Name"
                      value={centerName}
                      className={`  lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            if (coverage === null) {
              setErr("Please select coverage");
              setShowErr(true);
              return;
            }
            router("/pensionerVerification/selectProgram");
            // user === "employee"
            //   ? router("/pensionerVerification/selectProgram")
            //   : router("/scheduleVerification/selectProgram");
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
        >
          Next
        </button>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
    </div>
  );
};

export default SelectLocation;

import React, { useState, useEffect } from "react";
import back5 from "../../imgs/back5.png";
import TimePicker from "react-time-picker";
import axios from "axios";
import { useSelector } from "react-redux";
import ErrorModal from "../Merchant/Modals/errorModal";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import logo from "../../imgs/logo12.png";
import SuccessModal from "../modals/successModal";
import { useNavigate } from "react-router-dom";
import { CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
const Main = () => {
  const router = useNavigate();
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [show1, setShow1] = useState("");
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [message, setMessage] = useState("");
  const [workingHoursStart, setWorkingHoursStart] = useState(null);
  const [workingHoursEnd, setWorkingHoursEnd] = useState(null);
  const [breakTimeStart, setBreakTimeStart] = useState(null);
  const [breakTimeEnd, setBreakTimeEnd] = useState(null);
  const [update, setUpdate] = useState(false);
  const [duration, setDuration] = useState(10);

  //Get Listing
  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        // limit: 9,
        // skip: (page - 1) * 9,
        // page: page,
        // sortBy: "createdAt:desc",
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [refetch, page]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-default-time-manager`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        if (
          res?.data?.openTime &&
          res?.data?.closeTime &&
          res?.data?.breakStart &&
          res?.data?.breakEnd &&
          res?.data?.slotDuration
        ) {
          setUpdate(true);
          setWorkingHoursStart(res?.data?.openTime);
          setWorkingHoursEnd(res?.data?.closeTime);
          setBreakTimeStart(res?.data?.breakStart);
          setBreakTimeEnd(res?.data?.breakEnd);
          setDuration(res?.data?.slotDuration);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Setting = () => {
    const api = update
      ? `v1/scheduler-default-time-manager/update`
      : `v1/scheduler-default-time-manager/add`;

    const method = update ? "put" : "post";

    const formattedOpenTime = moment(workingHoursStart, [
      "HH:mm:ss",
      "hh:mm A",
    ]).isValid()
      ? moment(workingHoursStart, ["HH:mm:ss", "hh:mm A"]).format("HH:mm")
      : null;
    const formattedCloseTime = moment(workingHoursEnd, [
      "HH:mm:ss",
      "hh:mm A",
    ]).isValid()
      ? moment(workingHoursEnd, ["HH:mm:ss", "hh:mm A"]).format("HH:mm")
      : null;
    const formattedBreakStart = moment(breakTimeStart, [
      "HH:mm:ss",
      "hh:mm A",
    ]).isValid()
      ? moment(breakTimeStart, ["HH:mm:ss", "hh:mm A"]).format("HH:mm")
      : null;
    const formattedBreakEnd = moment(breakTimeEnd, [
      "HH:mm:ss",
      "hh:mm A",
    ]).isValid()
      ? moment(breakTimeEnd, ["HH:mm:ss", "hh:mm A"]).format("HH:mm")
      : null;

    const body = {
      openTime: formattedOpenTime,
      closeTime: formattedCloseTime,
      breakStart: formattedBreakStart,
      breakEnd: formattedBreakEnd,
      slotDuration: duration,
    };

    axios[method](`${process.env.REACT_APP_BASE_URL}/${api}`, body, {
      headers: {
        Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
      },
    })
      .then((res) => {
        setRefetch(!refetch);
        setMessage(`Scheduler Time ${update ? "Updated" : "Added"}`);
        setShow(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow1(true);
      });
  };

  return (
    <div className="flex items-center justify-center mt-4 flex-col">
      <div className="md:w-[85%] w-full p-4 ">
        {/* header and button */}
        <div
          className="pl-2 pr-2  mt-[1rem] sm:mt-[0rem]"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div className="flex justify-between items-center mt-4">
              <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
                <img
                  className="sm:w-[1.7rem] w-[1.2rem]"
                  src={back5}
                  alt=""
                  onClick={() => {
                    router("/superAdminDashboard");
                  }}
                />
                <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                  Scheduler Time Management
                </h2>
              </div>

              {/* <button
                className={` text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md`}
                onClick={() => {
                  setName("");
                  setHolidayType(null);
                  setDate(null);
                  setAddModal(true);
                }}
              >
                Add Holiday
              </button> */}
            </div>
          </div>
        </div>

        <div className="lg:p-12 bg-slate-50 sm:ml-0 w-full  w-full mt-[2rem] mb-8 bg-slate-50  rounded-2xl relative">
          <div className="w-full rounded-md bg-white p-6">
            <div>
              <>
                <div
                  key="workingHours"
                  className="flex items-center  flex-col w-full bg-white "
                  style={{ marginBottom: "8px" }}
                >
                  <p className="font-medium w-full text-[0.95rem] py-2 capitalize">
                    Working Time
                  </p>
                  <div className="w-full grid sm:grid-cols-2 grid-cols-1 gap-4">
                    <div>
                      <label>Open Time:</label>
                      <div className="relative mt-1">
                        <TimePicker
                          // disableClock={true}
                          value={workingHoursStart}
                          onChange={setWorkingHoursStart}
                          format="h:mm a"
                          className="w-full"
                          // clearIcon={null}
                        />
                        {/* {workingHoursStart && (
                          <CloseCircleOutlined
                            className="absolute top-1/2 right-3 transform text-gray-500 -translate-y-1/2 cursor-pointer"
                            onClick={() => setWorkingHoursStart(null)}
                          />
                        )} */}
                      </div>
                    </div>
                    <div>
                      <label>Close Time:</label>
                      <div className="relative mt-1">
                        <TimePicker
                          // disableClock={true}
                          value={workingHoursEnd}
                          onChange={setWorkingHoursEnd}
                          format="h:mm a"
                          className="w-full"
                          // clearIcon={null}
                        />
                        {/* {workingHoursEnd && (
                          <CloseCircleOutlined
                            className="absolute top-1/2 right-3 transform text-gray-500 -translate-y-1/2 cursor-pointer"
                            onClick={() => setWorkingHoursEnd(null)}
                          />
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  key="breakTime"
                  className="flex items-center  flex-col w-full"
                  style={{ marginBottom: "8px" }}
                >
                  <p className="font-medium w-full text-[0.95rem] py-2 capitalize">
                    Break Time
                  </p>
                  <div className="w-full  grid sm:grid-cols-2 grid-cols-1 gap-4 bg-white">
                    <div>
                      <label>Start Time:</label>
                      <div className="relative mt-1">
                        <TimePicker
                          // disableClock={true}
                          value={breakTimeStart}
                          onChange={setBreakTimeStart}
                          format="h:mm a"
                          className="w-full"
                          // clearIcon={null}
                        />
                        {/* {breakTimeStart && (
                          <CloseCircleOutlined
                            className="absolute top-1/2 right-3 transform text-gray-500 -translate-y-1/2 cursor-pointer"
                            onClick={() => setBreakTimeStart(null)}
                          />
                        )} */}
                      </div>
                    </div>
                    <div>
                      <label>End Time:</label>
                      <div className="relative mt-1">
                        <TimePicker
                          // disableClock={true}
                          value={breakTimeEnd}
                          onChange={setBreakTimeEnd}
                          format="h:mm a"
                          className="w-full"
                          // clearIcon={null}
                        />
                        {/* {breakTimeEnd && (
                          <CloseCircleOutlined
                            className="absolute top-1/2 right-3 transform text-gray-500 -translate-y-1/2 cursor-pointer"
                            onClick={() => setBreakTimeEnd(null)}
                          />
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`flex items-center gap-x-4 mt-7
                  `}
                >
                  <h1 className="font-semibold whitespace-nowrap">
                    Verification slot duration (Min)
                  </h1>
                  <div className="flex gap-2 bg-[#F6F4F4] px-2">
                    <button
                      onClick={() => {
                        if (duration > 10) setDuration(duration - 5);
                      }}
                      className="text-primary font-bold text-[1.5rem] text-center w-[1.5rem]"
                    >
                      -
                    </button>
                    <input
                      type="text"
                      value={duration}
                      className="w-[4rem] h-[2rem] px-2 rounded-lg text-center bg-[#F6F4F4] text-[1rem]"
                      readOnly
                    />
                    <button
                      onClick={() => {
                        setDuration(duration + 5);
                      }}
                      className="text-primary font-bold text-[1.5rem] text-center w-[1.5rem]"
                    >
                      +
                    </button>
                  </div>
                </div>
              </>
            </div>
          </div>
          <div className="flex items-center justify-center mt-5">
            <button
              type="button"
              onClick={() => {
                Setting();
              }}
              className={`lg:text-[1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-6 pl-6 mt-2 w-[10rem]`}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      {/* Change Status Modal */}
      <div
        className={`${
          changeStatusModal ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[22rem] pb-6 flex items-center p-3 bg-white flex-col">
          <img src={logo} alt="" className="w-20 h-20 mt-4" />
          <p className="text-center text-[0.95rem] mt-3 mb-5 w-[85%]">
            Are you sure you want to delete ?
          </p>
          <div className="flex">
            <button
              onClick={() => setChangeStatusModal(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              No
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
              onClick={() => {}}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      {/* Error Modal */}

      {show1 && <ErrorModal show={show1} setShow={setShow1} err={err} />}
      {show && <SuccessModal show={show} setShow={setShow} message={message} />}
    </div>
  );
};

export default Main;

import React from "react";
import Chart from "./Chart";
// import Chart from "../../../MHSSS Accounting/Reports/lineChart";

const AttendanceChart = () => {
  return (
    <div className="border border-border shadow bg-[#ffffff] rounded-md p-5 ">
      <div className="flex w-full justify-between items-center">
        <h2 className="text-xl font-medium">Attendence Statistics</h2>

        <div className="flex gap-2">
          <button
            onClick={() => {
              console.log("Today");
            }}
            className={` text-primary bg-transparent px-5 py-2 text-base font-medium hover:bg-[#1ba397] hover:text-white`}>
            Today
          </button>
          <button
            onClick={() => {
              console.log("Today");
            }}
            className={` text-primary bg-transparent px-5 py-2 text-base font-medium hover:bg-[#1ba397] hover:text-white`}>
            Month
          </button>
          <button
            onClick={() => {
              console.log("Today");
            }}
            className={` text-white bg-[#1ba397] hover:text-[#1ba397] hover:bg-white px-5 py-2 text-base font-medium hover:bg-primary`}>
            Weekly
          </button>
        </div>
      </div>
      <div className="">
        <Chart />
      </div>
    </div>
  );
};

export default AttendanceChart;

export const eventId = (state = null, action) => {
  switch (action.type) {
    case "EVENT_ID":
      return action.num;
    default:
      return state;
  }
};
export const subEventId = (state = null, action) => {
  switch (action.type) {
    case "SUB-EVENT_ID":
      return action.num;
    default:
      return state;
  }
};
export const batchDetails = (state = null, action) => {
  switch (action.type) {
    case "BATCH_DETAILS":
      return action.num;
    default:
      return state;
  }
};
export const eventCreator = (state = [], action) => {
  switch (action.type) {
    case "EVENT_CREATOR":
      return action.num;
    default:
      return state;
  }
};
export const eventPage = (state = "", action) => {
  switch (action.type) {
    case "SETPAGE":
      return action.num;
    default:
      return state;
  }
};
export const subId = (state = "", action) => {
  switch (action.type) {
    case "SUBID":
      return action.num;
    default:
      return state;
  }
};

import React, { useEffect } from "react";
import AttendanceDashboardWrapper from "../components/AttendanceWrapper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditEmployees from "./components/EditEmployees";

const AddEmployee = () => {
  const dispatch = useDispatch();
  const router = useNavigate();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "2" });
  }, []);

  const handleSave = (data) => {
    // Logic to add a new device
    router(`/Devices`);
    console.log("Device added:", data);
  };

  return (
    <AttendanceDashboardWrapper>
      <div className="">
        <div className="h-full px-2">
          <div className="flex w-full justify-between">
            <h2 className="text-xl font-semibold">Add Employee </h2>
          </div>
        </div>
        <div>
          <EditEmployees onSave={handleSave} onCancel={() => console.log("Add cancelled")} />
        </div>
      </div>
    </AttendanceDashboardWrapper>
  );
};

export default AddEmployee;

import React, { useEffect, useState } from "react";
import PageHeader from "../../pensionerVerification/pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../Merchant/Modals/errorModal";
import SuccessModal from "../../modals/successModal";
import axios from "axios";
import { Select } from "antd";
import moment, { months } from "moment/moment";
// import { TimePicker } from "antd";
import TimePicker from "react-time-picker";
import calendar from "../../../imgs/calendar.svg";

const SelectProgram = () => {
  const { Option } = Select;
  const SelectedSlots = useSelector(
    (state) => state.scheduleVerificationSelectedSlot
  );
  const SelectedSlot = useSelector((state) => state.SelectedSlot);
  console.log(SelectedSlot);
  const router = useNavigate();
  const dispatch = useDispatch();
  const distributionCenter = useSelector((state) => state.distributionCenter);
  const dataUser = useSelector((state) => state.dataUser);
  const [selectedProgram, setSelectedProgram] = useState(
    "senior-citizen-Pension"
  );
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const today = new Date();
  const [workingHoursStart, setWorkingHoursStart] = useState(null);
  const [workingHoursEnd, setWorkingHoursEnd] = useState(null);
  const [breakTimeStart, setBreakTimeStart] = useState(null);
  const [breakTimeEnd, setBreakTimeEnd] = useState(null);
  const [duration, setDuration] = useState(0);
  const [date, setDate] = useState(null);
  const [selectedDateandTime, setSelectedDateandTime] = useState([]);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [month, setMonth] = useState(moment().format("MM"));
  const [refetch, setRefetch] = useState(false);
  const [year, setYear] = useState(moment().format("YYYY"));
  const [disabledDates, setDisabledDates] = useState([]);
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    setWorkingHoursStart(SelectedSlot?.openTime);
    setWorkingHoursEnd(SelectedSlot?.closeTime);
    setBreakTimeStart(SelectedSlot?.breakStart);
    setBreakTimeEnd(SelectedSlot?.breakEnd);
    setDuration(SelectedSlot?.slotDuration);
    setDate(SelectedSlot?.date);
  }, []);

  useEffect(() => {
    setSelectedDateandTime(SelectedSlots);
    if (SelectedSlots.length > 0) {
      setSelectedDates(
        SelectedSlots.map((item) => moment(item.date.split("T")[0]))
      );
    }
    dispatch({ type: "SELECTED_MONTH", num: moment().format("MM") });
    dispatch({ type: "SELECTED_YEAR", num: moment().format("YYYY") });
    dispatch({ type: "SELECTED_PROGRAM", num: "senior-citizen-Pension" });
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pension-programs/names-and-icons`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setPrograms(data?.data);
      })
      .catch(() => {});
  }, []);

  const Setting = () => {
    const api = `v1/scheduler-center-time-manager/add`;

    const method = "post";

    const formattedOpenTime = moment(workingHoursStart, [
      "HH:mm:ss",
      "hh:mm A",
    ]).isValid()
      ? moment(workingHoursStart, ["HH:mm:ss", "hh:mm A"]).format("HH:mm")
      : null;
    const formattedCloseTime = moment(workingHoursEnd, [
      "HH:mm:ss",
      "hh:mm A",
    ]).isValid()
      ? moment(workingHoursEnd, ["HH:mm:ss", "hh:mm A"]).format("HH:mm")
      : null;
    const formattedBreakStart = moment(breakTimeStart, [
      "HH:mm:ss",
      "hh:mm A",
    ]).isValid()
      ? moment(breakTimeStart, ["HH:mm:ss", "hh:mm A"]).format("HH:mm")
      : null;
    const formattedBreakEnd = moment(breakTimeEnd, [
      "HH:mm:ss",
      "hh:mm A",
    ]).isValid()
      ? moment(breakTimeEnd, ["HH:mm:ss", "hh:mm A"]).format("HH:mm")
      : null;

    const body = {
      openTime: formattedOpenTime,
      closeTime: formattedCloseTime,
      breakStart: formattedBreakStart,
      breakEnd: formattedBreakEnd,
      slotDuration: duration,
      program: selectedProgram,
      distributioncoverageId: distributionCenter?.id,
      date: moment(date).format("YYYY-MM-DD"),
    };

    axios[method](`${process.env.REACT_APP_BASE_URL}/${api}`, [body], {
      headers: {
        Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: "SCHEDULE_VERIFICATION_SELECTED_SLOT",
          num: res?.data,
        });
        dispatch({
          type: "SELECTED_SLOT",
          num: res?.data?.[0],
        });

        // router("/scheduleVerification/selectedEmployees");
        setMessage(
          `Schedule slots for all the selected dates has been created successfully. If you wish to link any employee to ${distributionCenter?.coverage}, you can do in next step`
        );
        setShow(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErr(true);
      });
  };

  //Get Listing
  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 30,
        skip: 0,
        sortBy: "createdAt:desc",
        distributioncoverageId: distributionCenter?.id,
        month: month,
        year: year,
        ...{ program: `${selectedProgram}` },
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [month, year, selectedProgram]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-center-time-manager`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        const Dates = res?.data?.rows?.map((item) =>
          moment(item?.date).format("YYYY-MM-DD")
        );
        setDisabledDates(Dates);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/scheduler-holidays`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((res) => {
        const Dates = res?.data?.map((item) =>
          moment(item?.date).format("YYYY-MM-DD")
        );
        setHolidays(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [month, year]);

  const isDisabled = (date) => {
    const dateString = date.format("YYYY-MM-DD");
    return disabledDates.includes(dateString);
  };

  // const isHoliday = (date) => {
  //   const dateString = date.format("YYYY-MM-DD");
  //   return holidays.includes(dateString);
  // };

  const isHoliday = (date) => {
    const dateString = date.format("YYYY-MM-DD"); // Format the date properly

    // Find the holiday object that matches the dateString
    const holiday = holidays.find((holiday) => holiday.date === dateString);

    console.log(holiday, "Hold"); // For debugging

    return holiday; // Return the holiday object or undefined if not found
  };

  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4 min-h-screen">
      <PageHeader
        header={true}
        title={"Change Schedule"}
        route={"/scheduleVerification/schedulesDetails"}
      />

      <div className="flex justify-center">
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%] w-full`}
        >
          <div className={`flex items-center justify-between`}>
            <h3 className="font-medium mb-1 text-[0.93rem]"> Center Name</h3>
            {/* <p
              className="text-primary cursor-pointer font-medium"
              onClick={() => {
                router("/pensionerVerificationScheduling/selectLocation");
              }}
            >
              Change
            </p> */}
          </div>
          <div className="bg-white p-3 rounded-md mt-1 mb-4">
            {distributionCenter?.coverage}{" "}
            {
              distributionCenter?.distributioncenter?.distributionlocation
                ?.distributionregion?.regionNo
            }
          </div>

          <div>
            <h3 className="font-medium mb-1 text-[0.93rem]">Selected Date</h3>

            <div className="flex gap-5 mt-3">
              <div
                className={`bg-white w-full p-4
                `}
              >
                <div className="gap-3 mb-3">
                  <button
                    // onClick={() => setSelectedDate(data?.date)}
                    className={`px-6 py-2 rounded-lg bg-primary text-white
                       
                     font-medium text-md`}
                  >
                    {moment(SelectedSlot?.date).format("DD") + " "}
                    {moment(SelectedSlot?.date).format("MMM")}
                  </button>
                </div>

                <div className="space-y-3">
                  <div>
                    <div
                      className="flex items-center  flex-col w-full bg-white"
                      style={{ marginBottom: "8px" }}
                    >
                      <div className="w-full grid sm:grid-cols-2 grid-cols-1 gap-4">
                        <label>
                          Open Time:
                          <div className="relative">
                            {/* <TimePicker
                              showNow={false}
                              value={
                                SelectedSlot?.openTime
                                  ? moment(SelectedSlot?.openTime, "hh:mm A")
                                  : null
                              }
                              format="hh:mm A"
                              onOk={(value) => {
                                if (value) {
                                  setWorkingHoursStart(value.format("hh:mm A"));
                                }
                              }}
                              allowClear={false}
                            /> */}
                            <TimePicker
                              value={workingHoursStart}
                              format="hh:mm a"
                              onChange={(value) => {
                                if (value) {
                                  setWorkingHoursStart(value);
                                }
                              }}
                              className="w-full"
                              clearIcon={null}
                            />
                          </div>
                        </label>
                        <label>
                          Close Time:
                          <div className="relative">
                            {/* <TimePicker
                              showNow={false}
                              value={
                                workingHoursEnd
                                  ? moment(workingHoursEnd, "hh:mm A")
                                  : null
                              }
                              format="hh:mm A"
                              onOk={(value) => {
                                if (value) {
                                  setWorkingHoursEnd(value.format("hh:mm A"));
                                }
                              }}
                              allowClear={false}
                            /> */}
                            <TimePicker
                              value={workingHoursEnd}
                              format="hh:mm a"
                              onChange={(value) => {
                                if (value) {
                                  setWorkingHoursEnd(value);
                                }
                              }}
                              className="w-full"
                              clearIcon={null}
                            />
                          </div>
                        </label>
                      </div>
                    </div>

                    <div
                      className="flex items-center flex-col w-full"
                      style={{ marginBottom: "8px" }}
                    >
                      <p className="font-medium w-full text-[0.95rem] capitalize">
                        Break Time
                      </p>
                      <div className="w-full grid sm:grid-cols-2 grid-cols-1 gap-4 bg-white mt-2">
                        <label>
                          Start Time:
                          <div className="relative">
                            {/* <TimePicker
                              showNow={false}
                              value={
                                breakTimeStart
                                  ? moment(breakTimeStart, "hh:mm A")
                                  : null
                              }
                              format="hh:mm A"
                              onOk={(value) => {
                                if (value) {
                                  setBreakTimeStart(value.format("hh:mm A"));
                                }
                              }}
                              allowClear={false}
                            /> */}
                            <TimePicker
                              value={breakTimeStart}
                              format="hh:mm a"
                              onChange={(value) => {
                                if (value) {
                                  setBreakTimeStart(value);
                                }
                              }}
                              className="w-full"
                              clearIcon={null}
                            />
                          </div>
                        </label>
                        <label>
                          End Time:
                          <div className="relative">
                            {/* <TimePicker
                              showNow={false}
                              value={
                                breakTimeEnd
                                  ? moment(breakTimeEnd, "hh:mm A")
                                  : null
                              }
                              format="hh:mm A"
                              onOk={(value) => {
                                setBreakTimeEnd(value.format("hh:mm A"));
                              }}
                              allowClear={false}
                            /> */}
                            <TimePicker
                              value={breakTimeEnd}
                              format="hh:mm a"
                              onChange={(value) => {
                                if (value) {
                                  setBreakTimeEnd(value);
                                }
                              }}
                              className="w-full"
                              clearIcon={null}
                            />
                          </div>
                        </label>
                      </div>
                    </div>

                    <div className="flex items-center gap-x-4 mt-7">
                      <h1 className="font-semibold whitespace-nowrap">
                        Verification slot duration (Min)
                      </h1>
                      <div className="flex gap-2 bg-[#F6F4F4] px-2">
                        <button
                          onClick={() => {
                            if (duration > 10) {
                              setDuration(duration - 5);
                            }
                          }}
                          className="text-primary font-bold text-[1.5rem] text-center sm:w-[1.5rem]"
                        >
                          -
                        </button>
                        <input
                          type="text"
                          value={duration}
                          className="sm:w-[4rem] h-[2rem] w-[3rem] px-2 rounded-lg text-center bg-[#F6F4F4] text-[1rem]"
                          readOnly
                        />
                        <button
                          onClick={() => {
                            setDuration(duration + 5);
                          }}
                          className="text-primary font-bold text-[1.5rem] text-center sm:w-[1.5rem]"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mb-2 mt-4">
              <div className="flex text-[0.8rem]">
                <div className="text-gray-600 flex items-center">
                  {/* Voucher cashing ID:{" "} */}
                  <p className="mr-2 w-4 h-4 text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer">
                    i
                  </p>
                </div>
                <div className="flex items-center text-gray-500 text-[0.85rem]">
                  You can set a custom schedule for selected dates. If not, the
                  employee will follow the standard MHSSS schedule.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            Setting();
            // router("/scheduleVerification/selectEmployees");
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
        >
          Save
        </button>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
      {show && (
        <SuccessModal
          show={show}
          setShow={setShow}
          message={message}
          route="/scheduleVerification/selectedEmployees"
        />
      )}
    </div>
  );
};

export default SelectProgram;

import back5 from "../../../imgs/back5.png";
import Cards from "./listing";

const ListingGROAdmin = ({ setState, setSubId }) => {
  return (
    <div className="flex items-center justify-center flex-col">
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-2">
            <div
              onClick={() => setState("event")}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Sub Events
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <Cards setState={setState}  setSubId={setSubId}/>
      </div>
    </div>
  );
};

export default ListingGROAdmin;

import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import Nav from "../../vendor/navbar";

import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
const Listing = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector((state) => state.user);

  const statusesOptions = [
    { value: null, label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Quotation", label: "Quotation" },
    { value: "Cancelled", label: "Cancelled" },
    // { value: "In-Progress", label: "In-Progress" },
    // { value: "Shipped", label: "Shipped" },
    { value: "Delivered", label: "Delivered" },
    { value: "Partial", label: "Partial" },
    { value: "Delivered-Issue-reported", label: "Delivered - Issue Reported" },
  ];

  const items = [
    {
      id: 1,
      coverPhotos: [
        "https://images.unsplash.com/photo-1588165171080-c89acfa5ee83?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3RyYXdiZXJyeXxlbnwwfHwwfHx8MA%3D%3D",
        "https://i.pinimg.com/originals/c3/5c/84/c35c84121aaea699769bf2623bb8bd56.png",
        "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Garden_strawberry_%28Fragaria_%C3%97_ananassa%29_single2.jpg/1200px-Garden_strawberry_%28Fragaria_%C3%97_ananassa%29_single2.jpg",
      ],
      name: "Strawberry",
      price: 23,
      quantity: 1,
      items: 23,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 2,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZdYAUXvlwNIrC2A_4oIhFUyBtKtLNHm0buQ&s",
        "https://images.unsplash.com/photo-1571771894821-ce9b6c11b08e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmFuYW5hc3xlbnwwfHwwfHx8MA%3D%3D",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuFJ45D9B5tq0e9ijztRbX_y7YTbGZzn0T1w&s",
      ],
      name: "Banana",
      price: 22,
      items: 12,
      routeV: "/Inventory-Vendor-Product",
      quantity: 1,
      amount: "dozen",
      route: "/Inventory-Product",
    },
    {
      id: 3,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuIg-NlWsGyHPdsYHRMPHoynmyVvtx9GLNRA&s",
        "https://cdn.britannica.com/24/174524-050-A851D3F2/Oranges.jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoYq2s3xqHMWVzlAUXGh-LnB20Z3nhC8Y46liCklv90f0ZSaxThNe1EFCAETyg5vnHlK8&usqp=CAU",
      ],
      name: "Orange",
      price: 43,
      items: 97,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "dozen",
      route: "/Inventory-Product",
    },
    {
      id: 4,
      coverPhotos: [
        "https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Mangos_-_single_and_halved.jpg/640px-Mangos_-_single_and_halved.jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmgfExstNMpVm0dVH-xKurYdLlQpZpgJxHaw&s",
        "https://www.finedininglovers.com/sites/g/files/xknfdk626/files/styles/article_1200_800_fallback/public/2021-06/best-mango-varieties%C2%A9iStock.jpg?itok=KHYB1X__",
      ],
      name: "Mango",
      price: 33,
      items: 56,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 5,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgg1d49uY8Qx_SA9IgB4VC_30POp9wq_Vpig&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnfVxPeSWw5EY2KodFM3M8qUIEbTeS5fjt4AucycvBPT9R9JSF3GZGD3zKJV8OaCFlYpQ&usqp=CAU",
        "https://us.123rf.com/450wm/spyrakot/spyrakot1901/spyrakot190100061/116630376-single-bunch-grapes-on-vine-close-up-of-bunches-of-ripe-wine-grapes-on-vine-blue-grapes-in-a.jpg?ver=6",
      ],
      name: "Grapes",
      price: 89,
      items: 9,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 5,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgg1d49uY8Qx_SA9IgB4VC_30POp9wq_Vpig&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnfVxPeSWw5EY2KodFM3M8qUIEbTeS5fjt4AucycvBPT9R9JSF3GZGD3zKJV8OaCFlYpQ&usqp=CAU",
        "https://us.123rf.com/450wm/spyrakot/spyrakot1901/spyrakot190100061/116630376-single-bunch-grapes-on-vine-close-up-of-bunches-of-ripe-wine-grapes-on-vine-blue-grapes-in-a.jpg?ver=6",
      ],
      name: "Grapes",
      price: 89,
      items: 9,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 5,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgg1d49uY8Qx_SA9IgB4VC_30POp9wq_Vpig&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnfVxPeSWw5EY2KodFM3M8qUIEbTeS5fjt4AucycvBPT9R9JSF3GZGD3zKJV8OaCFlYpQ&usqp=CAU",
        "https://us.123rf.com/450wm/spyrakot/spyrakot1901/spyrakot190100061/116630376-single-bunch-grapes-on-vine-close-up-of-bunches-of-ripe-wine-grapes-on-vine-blue-grapes-in-a.jpg?ver=6",
      ],
      name: "Grapes",
      price: 89,
      items: 9,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 5,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgg1d49uY8Qx_SA9IgB4VC_30POp9wq_Vpig&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnfVxPeSWw5EY2KodFM3M8qUIEbTeS5fjt4AucycvBPT9R9JSF3GZGD3zKJV8OaCFlYpQ&usqp=CAU",
        "https://us.123rf.com/450wm/spyrakot/spyrakot1901/spyrakot190100061/116630376-single-bunch-grapes-on-vine-close-up-of-bunches-of-ripe-wine-grapes-on-vine-blue-grapes-in-a.jpg?ver=6",
      ],
      name: "Grapes",
      price: 89,
      items: 9,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
  ];

  return (
    <>
      <div>
        <Nav />
        <div className="flex items-center justify-center bg-[#D9EFED] ">
          <div className="w-[95%] mt-[1rem] px-10 rounded-md mb-12">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => {
                  if (user === "vendor-owner") {
                    router("/VendorDashboard");
                    return;
                  }
                  router("/SuperAdmin/InventoryDashboard");
                }}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Purchased Products
              </h2>
            </div>

            <div className="flex justify-end mt-4 gap-3">
              <div className="sm:w-[16rem] md:w-[20rem] w-full flex items-center justify-end">
                <div className="flex sm:w-[14rem] w-[14rem] items-center">
                  <p className="mr-4 text-[0.95rem] font-semibold text-gray-600 w-[3rem]">
                    Filter
                  </p>
                  <Select
                    placeholder="Filter"
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    // value={status}
                    // onChange={(value) => setStatus(value)}
                    size="middle"
                    className="w-full wow"
                    options={statusesOptions}
                  />
                </div>
              </div>
              <div className="flex items-center bg-[#f9fbfb] border border-gray-300 rounded-md px-4 py-2 w-[300px] shadow-sm">
                <input
                  type="text"
                  placeholder="Search Product"
                  className="bg-transparent focus:outline-none text-gray-500 placeholder-gray-400 flex-grow"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-[#1BA397]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.8"
                    d="M11 19a8 8 0 100-16 8 8 0 000 16zm5-1l5 5"
                  />
                </svg>
              </div>
            </div>

            <div className="bg-[#F6F4F4] mt-3 rounded-md p-8">
              <div className="px-6 py-5 rounded-sm w-full grid grid-cols-5  justify-center items-center gap-8 md:gap-x-4 gap-y-10 sm:min-h-[200px]">
                {items?.map((item, index) => (
                  <div
                    className=" bg-white border border-gray-200 rounded-lg shadow cursor-pointer"
                    onClick={() => {
                      router("/SuperAdmin/Inventory/PurchasedProducts/Detail");
                    }}
                  >
                    <img
                      className="rounded-t-lg w-full h-24 object-cover"
                      src={item?.coverPhotos?.[0]}
                      alt=""
                    />

                    <div className="p-2">
                      <div className="flex items-center justify-between">
                        <h5 className="mb-1 text-[0.98rem] font-semibold tracking-tight text-gray-700 ">
                          {item?.name}
                        </h5>
                      </div>
                      <div className="flex items-center justify-between">
                        <p>Inventory</p>
                        <div className="inline-flex items-center px-8 py-1 text-sm font-medium text-center text-white bg-primary rounded-lg ">
                          120
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;

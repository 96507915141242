import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import dis from "../../../../imgs/diss.png";
import his from "../../../../imgs/clock.png";
import edit from "../../../../imgs/edit.png";
import securityQ from "../../../../imgs/securityQuestion.svg";
import moment from "moment";
import ben from "../../../../imgs/ben.png";
import bm from "../../../../imgs/bm.png";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import across from "../../../../imgs/across.png";
import placeholder from "../../../../imgs/placeholder.png";
import back5 from "../../../../imgs/back5.png";
import edit1 from "../../../../imgs/edit1.png";
import logo1 from "../../../../imgs/logo12.png";
import cross from "../../../../imgs/cross.png";
import { MessageOutlined, PhoneOutlined } from "@ant-design/icons";
import { Select, Switch } from "antd";
import { FiVideo } from "react-icons/fi";
import { Popover } from "antd";
let optopt1;
const Prof = () => {
  const user = useSelector((state) => state.user);
  const privilege = useSelector((state) => state.myPrivileges);
  const universalPenId = useSelector((state) => state.universalPenId);
  const dataUser = useSelector((state) => state.dataUser);
  const reset = useSelector((state) => state.reset);
  const dispatch = useDispatch();
  const router = useNavigate();
  const [firstName, setFirstName] = useState("---");
  const [middleName, setMiddleName] = useState("---");
  const [lastName, setLastName] = useState("---");
  const [isDisabled, setIsDisabled] = useState(false);
  const [img, setImg] = useState(placeholder);
  const [status, setStatus] = useState("");
  const [id, setId] = useState("");
  const [checkPerson, setCheckPerson] = useState("");
  const [notes, setNotes] = useState("");
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [showMsg, setShowMsg] = useState("");
  const [showQuestions, setShowQuestions] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState("");
  const [able, setAble] = useState(false);
  const [status1, setStatus1] = useState(null);
  const [other, setOther] = useState(false);
  const [test, setTest] = useState(false);
  const [pnum, setPnum] = useState("");
  const [empNum, setEmpNum] = useState("");
  const token = useSelector((state) => state.token);
  const [checkCall, setCheckCall] = useState(false);
  const [otherChoice, setOtherChoice] = useState("");
  const [programs, setPrograms] = useState([]);
  const [beneficiary, setBeneficiary] = useState(false);
  const [optopt, setOptopt] = useState([
    { label: "Approved", value: "approved" },
    { label: "Rejected", value: "rejected" },
    { label: "Deferred", value: "deferred" },
    { label: "Verified", value: "verified" },
  ]);
  const location = useLocation();
  const dash = location.pathname;
  const [reason, setReason] = useState("");
  const [note, setNote] = useState("");
  const [profileComplete, setProfileComplete] = useState(false);
  const [appComplete, setAppComplete] = useState(false);
  const [benComplete, setBenComplete] = useState(false);
  const [approved, setApproved] = useState("");
  const [financialComplete, setFinancialComplete] = useState(false);
  const [profileCompleteModal, setProfileCompleteModal] = useState(false);
  const [appCompleteModal, setAppCompleteModal] = useState(false);
  const [benCompleteModal, setBenCompleteModal] = useState(false);
  const [financialCompleteModal, setFinancialCompleteModal] = useState(false);
  const [incompleteProfile, setIncompleteProfile] = useState(false);
  const [penData, setPenData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [disable, setDisable] = useState(true);
  const subrole = localStorage.getItem("subrole");

  useEffect(() => {
    // dispatch({ type: "setProfileData", num: [] });
    // dispatch({ type: "pensionerId", num: "" });
    if (beneficiary) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/pensioner-info/programs-beneficiaries/${universalPenId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          const updatedPrograms = data.data.map((i) => ({
            value: i?.program?.id,
            label:
              i?.program?.name === "senior-citizen-Pension"
                ? "Senior Citizen Pensioner"
                : i?.program?.name.charAt(0).toUpperCase() +
                  i?.program?.name?.slice(1),
          }));
          // To set the updated array in the state, you can use the setPrograms function
          setPrograms(updatedPrograms);
        })

        .catch(() => {});
    }
  }, [beneficiary]);

  useEffect(() => {
    if (universalPenId === "Add") {
      setOptopt([
        { label: "Active", value: "active" },
        { label: "Approved", value: "approved" },
        { label: "Rejected", value: "rejected" },
        { label: "Inactive", value: "in-active" },
      ]);
    } else {
      setOptopt([
        { label: "Approved", value: "approved" },
        { label: "Deferred", value: "deferred" },
        { label: "Rejected", value: "rejected" },
        { label: "Verified", value: "verified" },
      ]);
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/users/pensioner-profile/${universalPenId}`,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((data) => {
        setDisable(false);
        setPenData(data.data);
        if (data?.data?.pensionerInfo === null) {
          setIncompleteProfile(true);
        }
        setApproved(data?.data?.user?.statusChangeDate);
        const a = data.data.user.countryCode + data.data.user.phoneNo;
        setPnum(a);
        const n = localStorage.getItem("EPhone");
        setEmpNum(n);

        dispatch({ type: "setProfileData", num: data?.data });
        dispatch({ type: "pensionerId", num: data?.data?.user?.id });
        setStatus(data.data.user.status);
        setIsDisabled(data.data.user.isDisabled);

        setId(data.data.user.id);
        if (data.data.user.isDisabled) {
          optopt1 = [
            {
              label: "Remigrated",
              value: "Remigrated",
            },
            {
              label: "Other",
              value: "Other",
            },
          ];
          setAble(false);
        } else {
          optopt1 = [
            {
              label: "Out of Country",
              value: "Out of Country",
            },
            {
              label: "Hospitalized",
              value: "Hospitalized",
            },
            {
              label: "Migrated",
              value: "Migrated",
            },
            {
              label: "Education",
              value: "Education",
            },
            {
              label: "Other",
              value: "Other",
            },
          ];
          setAble(true);
        }
        if (data.data.person) {
          setFirstName(data.data.person.firstName);
          setMiddleName(data.data.person.middleName);
          setLastName(data.data.person.lastName);
          setImg(data.data.person.image);
          dispatch({
            type: "pensionerName",
            num: `${data.data.person.firstName} ${data.data.person.lastName}`,
          });
          setCheckPerson("not null");
        } else {
          setCheckPerson("is null");
        }
      })
      .catch(() => {});
  }, [reset]);

  useEffect(() => {
    if (checkPerson === "not null") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/users//pensioners/status-pre-checks?pensionerUserId=${universalPenId}`,
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then((data) => {
          setProfileComplete(data?.data?.isProfileCompleted);
          setAppComplete(data?.data?.oneApplicationApprovedForEachProgram);
          setBenComplete(data?.data?.onebeneficiaryCompletedForEachProgram);
          setFinancialComplete(data?.data?.pensionbookOrBankFound);
        })
        .catch((err) => {
          console.error("Error in catch block:", err);
        });
    }
  }, [reset, checkPerson]);

  const changeHandler = (e) => {
    setStatus1(e);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (incompleteProfile === true && status1 === "approved") {
      setErr("Profile of this Pensioner is Incomplete");
      setShowError(true);
    } else if (profileComplete === false && status1 === "approved") {
      // setProfileCompleteModal(true);
      setShowError(true);
      setErr(
        "Profile details are not completed for the pensioner, Please provide all details before approving pensioner"
      );
    } else if (benComplete === false && status1 === "approved") {
      // setBenCompleteModal(true);
      setShowError(true);
      setErr(
        "Beneficiary details are not available for the pensioner, Please provide all details before approving pensioner"
      );
    } else if (financialComplete === false && status1 === "approved") {
      // setFinancialCompleteModal(true);
      setShowError(true);
      setErr(
        "Pension book/Bank details are not available for the pensioner, Please provide either pension book or bank detail before approving pensioner"
      );
    } else if (appComplete === false && status1 === "approved") {
      setAppCompleteModal(true);
      setErr(
        "We don't find any approved application for pensioner, Please add/approve the application first. \n Do you want to provide this information for the pensioner?"
      );
    } else {
      if (status1 === "") {
        setErr("Please select status");
        setShow(true);
      } else if (reason === "") {
        setErr("Please enter a reason for the status change");
        setShow(true);
      } else {
        const data = {
          status: status1,
          reason: reason,
          note: note,
        };

        if (note === "") {
          delete data?.note;
        }
        if (dash === "/EDashboard/profPage") {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/users/${universalPenId}/pensioner/status`,
              {
                statusInfo: data,
                consumerInfo: {
                  ip: "999.010.2.4",
                  location: "88.01010101, 10.43492932",
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then(() => {
              dispatch({ type: "RESET" });
              setErr(`Pensioner is successfully ${status1}`);
              setShowMsg(true);
            })
            .catch((err) => {
              if (
                err.response.data.message ===
                  '"reason" is not allowed to be empty' ||
                err.response.data.message === '"statusReason" must be a string'
              ) {
                setErr("Please enter a reason for the status change");
                setShow(true);
              } else {
                setErr(err?.response?.data?.message);
                setShow(true);
              }
            });
        }
        if (dash === "/AdminDashboard/profPage") {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/users/${universalPenId}/pensioner/status/by-admin`,
              {
                statusInfo: data,
                consumerInfo: {
                  ip: "999.010.2.4",
                  location: "88.01010101, 10.43492932",
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then(() => {
              dispatch({ type: "RESET" });
              setErr(`Pensioner is successfully ${status1}`);
              setShowMsg(true);
            })
            .catch((err) => {
              if (
                err.response.data.message ===
                  '"reason" is not allowed to be empty' ||
                err.response.data.message === '"statusReason" must be a string'
              ) {
                setErr("Please enter a reason for the status change");
                setShow(true);
              } else {
                setErr(err?.response?.data?.message);
                setShow(true);
              }
            });
        }
        if (dash === "/superAdminDashboard/profPage") {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/v1/users/${universalPenId}/pensioner/status/by-superadmin`,
              {
                statusInfo: data,
                consumerInfo: {
                  ip: "999.010.2.4",
                  location: "88.01010101, 10.43492932",
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
                },
              }
            )
            .then(() => {
              dispatch({ type: "RESET" });
              setErr(`Pensioner is successfully ${status1}`);
              setShowMsg(true);
            })
            .catch((err) => {
              if (
                err.response.data.message ===
                  '"reason" is not allowed to be empty' ||
                err.response.data.message === '"statusReason" must be a string'
              ) {
                setErr("Please enter a reason for the status change");
                setShow(true);
              } else {
                setErr(err?.response?.data?.message);
                setShow(true);
              }
            });
        }
      }
    }
  };

  const ableHandler = () => {
    setShow2(true);
  };
  const ableSubmitHandler = () => {
    if (checkPerson === "is null") {
      setErr("Personal data is missing or does not exist in profile");
      setShow(true);
    }
    if (notes === "") {
      setShow(true);
      setErr("Please enter notes");
    } else if (other && otherChoice === "") {
      setShow(true);
      setErr("Please enter a reason");
    } else {
      let wow;
      let wow1;
      if (isDisabled) {
        wow = "This pensioner was mistakenly disabled";
        wow1 = "enable";
      } else {
        wow = "This pensioner has no sufficient documents";
        wow1 = "disable";
      }
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/v1/users/${universalPenId}/${wow1}`,
          {
            reason: wow,
            note: notes,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          if (isDisabled) {
            setAble(true);
          } else {
            setAble(false);
          }
          setOther(false);
          setOtherChoice("");
          dispatch({ type: "RESET" });
          setNotes("");
          setShow2(false);
        })
        .catch((err) => {
          console.log(err);
          setErr(err?.response?.data?.message);
          setShow(true);
        });
    }
  };

  useEffect(() => {
    if (test) {
      setTest(false);
    } else {
      setTest(false);
    }
  }, [able]);
  const content = (
    <div>
      <p>You will Receive call on your mobile device shortly</p>
    </div>
  );

  const handleCall = () => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/v1/whatsapp/voice-call?to=${pnum}&from=${empNum}`;
    axios({
      method: "post",
      url: apiUrl,
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then(() => {
        // console.log("Response:", response.data);
        setCheckCall(true);
        setTimeout(() => {
          setCheckCall(false);
        }, 3000);
        // Handle the response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle the error
      });
  };
  const ResetQ = () => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/v1/security-question-config?userId=${universalPenId}&canBeReset=true`,
        null,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        setShowQuestions(false);
        setShowMsg(true);
        setErr("Security Questions Reset Successfully.");
      })
      .catch((err) => {
        setShowQuestions(false);
        setErr(err?.response?.data?.message);
        setShow(true);
      });
  };
  return (
    <div className="md:p-8 md:pl-[7rem] md:pr-[7rem] p-8 pl-[2rem] pr-[2rem]">
      <div className="relative flex items-center mb-[2rem]">
        <div className="cursor-pointer">
          <img
            onClick={() => {
              if (universalPenId === "Add") {
                router("/EDashboard/allEmployees");
              } else {
                // router("/EDashboard/Pensioner_HistoryP");
                user === "employee"
                  ? router("/EDashboard/Pensioner_HistoryP")
                  : user === "admin"
                  ? router("/AdminDashboard/Pensioner_HistoryP")
                  : router("/superAdminDashboard/Pensioner_HistoryP");
              }
            }}
            className="sm:w-[1.7rem] w-[1.4rem]"
            src={back5}
            alt=""
          />
        </div>
        <h2 className="font-bold text-[1.4rem] sm:text-[1.8rem] ml-[1rem] ">
          {universalPenId === "Add" ? "Employee" : "Pensioner"}
        </h2>
      </div>
      <div className="flex w-full mb-[3rem] sm:flex-row flex-col">
        <div
          className={`${
            universalPenId === "Add" ? "pointer-events-none" : ""
          } relative sm:w-[45%] w-[100%] bg-[#F2F4F8] flex flex-col items-center pt-8 pb-8 pl-8 pr-8 tab:pl-6 tab:pr-6`}
        >
          {user === "superadmin" && status === "approved" && (
            <div className="absolute top-5 left-5">
              <button
                onClick={() => setShowQuestions(true)}
                className="text-primary font-semibold hover:underline"
              >
                {/* Reset Questions */}
                <img src={securityQ} alt="" className="w-8 h-8" />
              </button>
            </div>
          )}
          {user === "employee" &&
          privilege &&
          !privilege?.includes("Disable/Enable pensioner") ? null : (
            <div className="absolute top-5 right-5">
              <Switch
                size="small"
                style={{ backgroundColor: able ? "#1BA397" : "#c3c6c9" }}
                checked={able}
                onChange={ableHandler}
              />
            </div>
          )}

          <div className="w-[3rem] h-[3rem] rounded-full flex justify-center items-center">
            <img
              src={img}
              className="w-[3rem] h-[3rem] rounded-full border-[1px] border-black"
              alt=""
            />
          </div>
          <div className="flex items-center">
            <h2 className="font-semibold mt-1 capitalize `text-[1rem]">{`${firstName} ${
              middleName ? middleName : ""
            } ${lastName}`}</h2>
            <div className={`${pnum ? "" : "hidden"}`}>
              <Popover
                open={checkCall}
                content={content}
                trigger="click"
                placement="bottom"
              >
                <PhoneOutlined className="myCall" onClick={handleCall} />
              </Popover>
            </div>
            <div className={`${pnum ? "" : "hidden"}`}>
              {/* <img src={mess} alt="mess" className="myCall" onClick={()=>{
                  router(`/superAdminDashboard/chatPanel/${pnum}`)
                }}/> */}
              <MessageOutlined
                className="myCall"
                onClick={() => {
                  router(`/superAdminDashboard/chatPanel/${pnum}`);
                  dispatch({ type: "CHAT_SELECTED_NEW_USER", data: penData });
                }}
              />
            </div>
            <div
              onClick={() => {
                router("/meeting");
              }}
            >
              <FiVideo className="myCall" />
            </div>
          </div>
          <p
            className={`text-[0.9rem] ${
              isDisabled ? "text-[red]" : "text-primary"
            } font-bold capitalize`}
          >
            {isDisabled ? "Disabled" : status}
          </p>
          <p
            className={`text-[0.9rem] p-1 ${
              status === "approved" ? "text-gray-500" : "hidden"
            } 
            pl-4 pr-4 font-semibold`}
          >
            Approved On: {moment(approved).format("MM/DD/YYYY")}{" "}
            {moment(approved).format("hh:mm A")}
          </p>
          {incompleteProfile === true && (
            <span className="text-red-600 font-semibold flex items-center justify-center">
              Incomplete pensioner profile
            </span>
          )}
          <div className="w-[100%] flex items-center mt-[1rem]">
            {user === "employee" &&
            privilege &&
            !privilege?.includes("View pensioner") ? null : (
              <div
                onClick={() => {
                  dispatch({ type: "universalPenSet", num: id });
                  user === "employee"
                    ? router("/EDashboard/PcardView")
                    : user === "admin"
                    ? router("/AdminDashboard/PcardView")
                    : router("/superAdminDashboard/PcardView");
                  // router("/EDashboard/PcardView");
                  dispatch({ type: "gidFilterSet", num: "" });
                  dispatch({ type: "whichViewSet", num: "pensioner" });
                }}
                className={`bg-primary  ${
                  privilege?.includes("View pensioner") &&
                  !privilege?.includes("View application")
                    ? "w-[100%]"
                    : "w-[50%]"
                }  text-center pt-2 pb-2 text-white cursor-pointer`}
              >
                Profile
              </div>
            )}
            {user === "employee" &&
            privilege &&
            !privilege?.includes("View application") ? null : (
              <div
                onClick={() => {
                  if (checkPerson === "is null") {
                    setErr(
                      "Personal data is missing or does not exist in profile"
                    );
                    setShow(true);
                  } else if (checkPerson === "not null") {
                    dispatch({ type: "universalPenSet", num: id });
                    user === "admin"
                      ? router("/AdminDashboard/allApplications")
                      : user === "superadmin"
                      ? router("/superAdminDashboard/allApplications")
                      : router("/EDashboard/allApplications");
                  }
                }}
                className="bg-primary w-[50%] text-center ml-[1px] pt-2 pb-2 text-white cursor-pointer"
              >
                Application
              </div>
            )}
          </div>
          <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={edit1} className="w-[2.5rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">
                {user === "employee" &&
                privilege &&
                !privilege?.includes("Edit pensioner")
                  ? "View Account details for pensioner"
                  : "Edit and modify account details for pensioner"}
              </p>
              <p
                className="text-[0.65rem] text-primary cursor-pointer"
                onClick={() => {
                  user === "employee"
                    ? router("/EDashboard/PensionerAccount")
                    : user === "admin"
                    ? router("/AdminDashboard/PensionerAccount")
                    : router("/superAdminDashboard/PensionerAccount");
                  dispatch({ type: "universalPenSet", num: id });
                  dispatch({ type: "gidFilterSet", num: "" });
                  dispatch({ type: "whichViewSet", num: "pensioner" });
                }}
              >
                Click Here
              </p>
            </div>
          </div>

          {(user === "employee" && privilege?.includes("View distribution")) ||
          (privilege?.includes("View distribution") &&
            (privilege?.includes("Edit distribution") ||
              privilege?.includes("Add distribution"))) ? (
            <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
              <div>
                <img src={dis} className="w-[2.5rem]" alt="" />
              </div>
              <div className="ml-3 w-[18rem]">
                <p className="text-[0.75rem]">
                  {privilege &&
                  privilege?.includes("View distribution") &&
                  (privilege?.includes("Edit distribution") ||
                    (privilege && privilege?.includes("Add distribution")))
                    ? "Upload and view distribution details for pensioner"
                    : privilege && privilege?.includes("View distribution")
                    ? "View distribution details for pensioner"
                    : ""}
                </p>
                <p
                  className="text-[0.65rem] text-primary cursor-pointer"
                  onClick={() => {
                    if (checkPerson === "is null") {
                      setErr(
                        "Personal data is missing or does not exist in profile"
                      );
                      setShow(true);
                    } else if (checkPerson === "not null") {
                      dispatch({ type: "universalPenSet", num: id });
                      router("/EDashboard/distributionsPage");
                    }
                  }}
                >
                  Click Here
                </p>
              </div>
            </div>
          ) : user !== "employee" ? (
            <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
              <div>
                <img src={dis} className="w-[2.5rem]" alt="" />
              </div>
              <div className="ml-3 w-[18rem]">
                <p className="text-[0.75rem]">
                  Upload and view distribution details for pensioner
                </p>
                <p
                  className="text-[0.65rem] text-primary cursor-pointer"
                  onClick={() => {
                    if (checkPerson === "is null") {
                      setErr(
                        "Personal data is missing or does not exist in profile"
                      );
                      setShow(true);
                    } else if (checkPerson === "not null") {
                      dispatch({ type: "universalPenSet", num: id });
                      user === "admin"
                        ? router("/AdminDashboard/distributionsPage")
                        : router("/superAdminDashboard/distributionsPage");
                    }
                  }}
                >
                  Click Here
                </p>
              </div>
            </div>
          ) : null}

          <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={edit} className="w-[2.5rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">Upload documents for pensioner</p>
              <p
                onClick={() => {
                  user === "employee"
                    ? router("/EDashboard/additionalDocs")
                    : user === "admin"
                    ? router("/AdminDashboard/additionalDocs")
                    : router("/superAdminDashboard/additionalDocs");
                  dispatch({ type: "universalPenSet", num: id });
                }}
                className="text-[0.65rem] text-primary cursor-pointer"
              >
                Click Here
              </p>
            </div>
          </div>

          {(user === "employee" && privilege?.includes("View beneficiary")) ||
          (privilege?.includes("View beneficiary") &&
            privilege?.includes("Edit beneficiary")) ? (
            <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
              <div>
                <img src={ben} className="w-[2.5rem]" alt="" />
              </div>
              <div className="ml-3 w-[18rem]">
                <p className="text-[0.75rem]">
                  {privilege && privilege?.includes("Edit beneficiary")
                    ? "View and update Beneficiary "
                    : privilege && privilege?.includes("View beneficiary")
                    ? "View Beneficiary"
                    : ""}
                </p>
                <p
                  className="text-[0.65rem] text-primary cursor-pointer"
                  onClick={() => {
                    if (checkPerson === "is null") {
                      setErr(
                        "Personal data is missing or does not exist in profile"
                      );
                      setShow(true);
                    } else if (checkPerson === "not null") {
                      setBeneficiary(true);
                      setShow1(true);
                    }
                  }}
                >
                  Click Here
                </p>
              </div>
            </div>
          ) : user !== "employee" ? (
            <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
              <div>
                <img src={ben} className="w-[2.5rem]" alt="" />
              </div>
              <div className="ml-3 w-[18rem]">
                <p className="text-[0.75rem]">View and update Beneficiary</p>
                <p
                  className="text-[0.65rem] text-primary cursor-pointer"
                  onClick={() => {
                    if (checkPerson === "is null") {
                      setErr(
                        "Personal data is missing or does not exist in profile"
                      );
                      setShow(true);
                    } else if (checkPerson === "not null") {
                      setBeneficiary(true);
                      setShow1(true);
                    }
                  }}
                >
                  Click Here
                </p>
              </div>
            </div>
          ) : null}

          {user === "employee" &&
          privilege &&
          !privilege?.includes("View history") ? null : (
            <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
              <div>
                <img src={his} className="w-[2.5rem]" alt="" />
              </div>
              <div className="ml-3 w-[18rem]">
                <p className="text-[0.75rem]">View history for all changes</p>
                <p
                  className="text-[0.65rem] text-primary cursor-pointer"
                  onClick={() => {
                    user === "employee"
                      ? router("/EDashboard/history")
                      : user === "admin"
                      ? router("/AdminDashboard/history")
                      : router("/superAdminDashboard/history");
                    // dispatch({ type: "universalPenSet", num: id });
                    // dispatch({ type: "gidFilterSet", num: "" });
                    // dispatch({ type: "whichViewSet", num: "pensioner" });
                  }}
                >
                  Click Here
                </p>
              </div>
            </div>
          )}

          <div className="w-[100%] flex items-center mt-[2rem] bg-white h-[4rem] pl-2 pr-2">
            <div>
              <img src={bm} className="w-[2.5rem] h-[2.8rem]" alt="" />
            </div>
            <div className="ml-3 w-[18rem]">
              <p className="text-[0.75rem]">Biometric</p>
              <p
                className="text-[0.65rem] text-primary cursor-pointer"
                onClick={() => {
                  user === "employee"
                    ? router("/Edashboard/PensionerAccount/Biometric")
                    : user === "admin"
                    ? router("/AdminDashboard/PensionerAccount/Biometric")
                    : router("/superAdminDashboard/PensionerAccount/Biometric");
                  dispatch({ type: "universalPenSet", num: id });
                  dispatch({ type: "gidFilterSet", num: "" });
                  dispatch({ type: "whichViewSet", num: "pensioner" });
                }}
              >
                Click Here
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            pointerEvents: `${
              user === "employee" &&
              !privilege.includes("Change pensioner status")
                ? "none"
                : "auto"
            }`,
          }}
          className={`sm:w-[45%] w-[100%] md:ml-[4rem] sm:ml-[2rem] pt-8 sm:pb-8 md:pl-8 md:pr-8 pl-2 pr-3 sm:pl-2 sm:pr-2 `}
        >
          <div className="flex justify-between items-center">
            <h2 className="mt-6 text-[1rem] font-medium capitalize">{user}</h2>
            {/* <img className="w-[1.5rem] h-[1.5rem]" src={dod} alt="" /> */}
          </div>
          {user === "employee" && (
            <h2 className="mt-6 text-[1rem] font-medium capitalize">
              {subrole}
            </h2>
          )}
          {/* <h2 className="mt-6 text-[1rem] font-medium capitalize">
            {user}
          </h2> */}
          <div className="flex justify-between mt-4 mb-6 items-center">
            <div className="ml-3">
              <div className="flex mt-4 mb-6 items-center">
                <div className="bg-[#DDE1E6] w-[3rem] h-[3rem] rounded-full">
                  <img
                    src={dataUser?.data?.user?.person?.image}
                    className="w-[3rem] h-[3rem] rounded-full"
                    alt="profile"
                  />
                </div>
                <div className="sm:ml-3 ml-1">
                  <h2 className="sm:text-[0.9rem] text-[0.84rem] font-semibold">
                    {dataUser?.data?.user?.person?.firstName}{" "}
                    {dataUser?.data?.user?.person?.lastName}
                  </h2>
                </div>
              </div>
            </div>
            <button
              onClick={() =>
                router(
                  dash === "/EDashboard/profPage"
                    ? "/EDashboard/historyStatus"
                    : dash === "/AdminDashboard/profPage"
                    ? "/AdminDashboard/historyStatus"
                    : "/superAdminDashboard/historyStatus"
                )
              }
              className="flex items-center justify-center bg-primary text-white p-2 rounded-md ml-1"
            >
              View status history
            </button>
          </div>

          <form onSubmit={submitHandler}>
            <Select
              placeholder="Decision"
              showSearch
              onChange={changeHandler}
              value={status1}
              optionFilterProp="children"
              className="wow1 mb-[0.3rem]"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              options={optopt}
            />
            <div>
              <textarea
                type="text"
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                placeholder="Enter Reason"
                className="lg:text-[1.1rem] m-0 h-[4rem] w-[100%] bg-[#F2F4F8] rounded-[0.3rem] border-none mt-1 p-3"
              />
              <textarea
                type="text"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                placeholder="Enter Notes"
                className="lg:text-[1.1rem] m-0 h-[4rem] w-[100%] bg-[#F2F4F8] border-none rounded-[0.3rem] mt-1 p-3"
              />
            </div>
            {/* <div className="flex">
              <input
                type="text"
                placeholder="Payment Amount"
                className="lg:text-[1.1rem] mt-2 w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-1 mb-4"
              />
              <DatePicker
                type="text"
                placeholder="Payment Date"
                className="ml-4 lg:text-[1.1rem] mt-2 w-[100%] bg-custom-color rounded-[0.3rem] p-1 mb-4"
                disabledDate={disabledDate}
              />
            </div> */}
            {/* <div className="flex">
              <label
                htmlFor="special-input"
                className={` cursor-pointer relative w-[100%]`}
              >
                <div className=" lg:text-[1.1rem] w-[100%] bg-[#F2F4F8] rounded-[0.5rem] p-1 mt-2 flex items-center">
                  <div>
                    <img className="w-[1rem]" src={plusp} alt="" />
                  </div>
                  <p className="text-[#9CA6C2] ml-2">Signature</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  id="special-input"
                  accept="image/png, image/gif, image/jpeg"
                />
              </label>
              <DatePicker
                type="text"
                placeholder="Payment Date"
                className="ml-4 lg:text-[1.1rem] mt-2 w-[100%] bg-custom-color rounded-[0.3rem] p-1 mb-4"
                disabledDate={disabledDate}
              />
            </div> */}
            {user === "employee" &&
            privilege &&
            !privilege.includes("Change pensioner status") ? null : (
              <div className="flex justify-end">
                <button
                  type="button"
                  className={`lg:text-[1rem] bg-[#F2F4F8] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
                  onClick={() => {
                    setStatus1(null);
                    setReason("");
                    setNote("");
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={disable === true}
                  type="submit"
                  className={` relative lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white ml-2 rounded-[0.5rem] p-2  pr-8 pl-8`}
                >
                  {/* <Spin
                  style={{ display: `${isLoading ? "" : "none"}` }}
                  indicator={antIcon}
                /> */}
                  <span>Done</span>
                  {/* <div
                    className={`w-[100%] h-[100%] ${isLoading ? "" : "hidden"
                      } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
                  ></div> */}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>

      <div
        className={`${
          show2 ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl sm:w-[30rem] w-[20rem] flex items-center p-3 bg-white flex-col">
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">
            Are you sure you want to {isDisabled ? "enable " : "disable"} this
            pensioner?
          </p>
          <Select
            placeholder="Reason"
            onChange={(e) => {
              if (e === "Other") {
                setOther(true);
              } else {
                setOther(false);
              }
            }}
            optionFilterProp="children"
            style={{ marginBottom: "0.5rem" }}
            className="wow1"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size="middle"
            options={optopt1}
          />
          <input
            type="text"
            placeholder="Enter Reason"
            value={otherChoice}
            onChange={(e) => {
              setOtherChoice(e.target.value);
            }}
            className={`lg:text-[1.1rem] ${
              other ? "" : "hidden"
            } mt-2 w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-1 mb-4`}
          />
          <textarea
            type="text"
            placeholder="Enter Notes"
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            className="sqw lg:text-[1.1rem] h-[6rem] w-[100%] bg-[#F2F4F8] rounded-[0.3rem] p-1 mt-2"
          />
          <div className="flex mt-4">
            <button
              onClick={() => {
                setShow2(false);
                setOther(false);
                setOtherChoice("");
                setNotes("");
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-black bg-[#F2F4F8] w-[60%]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                ableSubmitHandler();
              }}
              className="cursor-pointer rounded-xl ml-4 pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
            >
              {isDisabled ? "Enable" : "Disable"}
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`fixed ${
          show1 ? "" : "hidden"
        } top-0 left-0 w-full m-2  h-full bg-slate-500/50 flex justify-center items-center z-[100]`}
      >
        <div className="relative rounded-xl bg-white p-4 flex items-center flex-col">
          <div
            className="absolute top-3 right-4 cursor-pointer"
            onClick={() => setShow1(false)}
          >
            <img className="scale-[0.8]" src={cross} alt="Close" />
          </div>
          <p className="text-center text-[1.1rem] mt-[1rem] font-semibold mb-5">
            Please select a program to move forward
          </p>
          {/* <div className="flex"> */}
          <div className="grid sm:grid-cols-2 grid-cols-1 w-full gap-4">
            {programs.map((program) => (
              <div
                key={program.value}
                onClick={() => {
                  dispatch({ type: "penId", num: program.value });
                  // router("/Dashboard/listing")
                  dispatch({ type: "allBenIdSet", num: id });
                  user === "employee"
                    ? router("/EDashboard/listing")
                    : user === "admin"
                    ? router("/AdminDashboard/listing")
                    : router("/superAdminDashboard/listing");
                  // Handle click event (you can dispatch actions, etc.)
                }}
                className={`flex items-center cursor-pointer bg-[#E8E8E8]  rounded-md shadow-lg p-3 w-full mb-2`}
              >
                <div>
                  <img className="w-[3rem]" src={logo1} alt="" />
                </div>
                <p className="ml-2 font-semibold text-[1rem]">
                  {program.label}
                </p>
              </div>
            ))}
          </div>
          {/* </div> */}
        </div>
      </div>

      {/* Complete Profile Modal */}
      <div
        className={`${
          profileCompleteModal ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem]  pb-6 pt-6 flex flex-col items-center p-6 bg-white">
          <label className="text-[#596F96] text-[1.2rem] font-semibold mb-2">
            Approve Pensioner
          </label>
          <p className="text-center text-[1.1rem] mt-[0.5rem] mb-[1rem]">
            {err}
          </p>
          <div className="flex justify-end">
            <button
              onClick={() => {
                setProfileCompleteModal(false);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Cancel
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[100%]"
              // onClick={()=>{
              //   user==="employee"?router("/EDashboard/PensionerAccount",{
              //     state:{

              //     }
              //   }):user==="admin"?router("/AdminDashboard/PensionerAccount"):router("/superAdminDashboard/PensionerAccount")
              //   dispatch({ type: "universalPenSet", num: id });
              //   dispatch({ type: "gidFilterSet", num: "" });
              //   dispatch({ type: "whichViewSet", num: "pensioner" });
              // }}
            >
              Complete Profile
            </button>
          </div>
        </div>
      </div>

      {/* Complete Application Modal */}
      <div
        className={`${
          appCompleteModal ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem]  pb-6 pt-6 flex flex-col items-center p-6 bg-white">
          <label className="text-[#596F96] text-[1.2rem] font-semibold mb-2">
            Approve Pensioner
          </label>
          <p className="text-center text-[1.1rem] mt-[0.5rem] mb-[1rem]">
            We couldn't find any approved applications for the pensioner. Please
            add or approve an application first.
            {"\n"}
            Alternatively, would you like to provide this information for the
            pensioner?
          </p>

          <div className="flex justify-end">
            <button
              onClick={() => {
                setAppCompleteModal(false);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Cancel
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
              onClick={() => {
                dispatch({ type: "universalPenSet", num: id });
                user === "admin"
                  ? router("/AdminDashboard/allApplications")
                  : user === "superadmin"
                  ? router("/superAdminDashboard/allApplications")
                  : router("/EDashboard/allApplications");
              }}
            >
              Approve
            </button>
          </div>
        </div>
      </div>

      {/* Complete Beneficary Info Modal */}
      <div
        className={`${
          benCompleteModal ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem]  pb-6 pt-6 flex flex-col items-center p-6 bg-white">
          <label className="text-[#596F96] text-[1.2rem] font-semibold mb-2">
            Approve Pensioner
          </label>
          <p className="text-center text-[1.1rem] mt-[0.5rem] mb-[1rem]">
            {err}
          </p>
          <div className="flex justify-end">
            <button
              onClick={() => {
                setBenCompleteModal(false);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Cancel
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[100%]"
              onClick={() => {
                setBeneficiary(true);
                setShow1(true);
              }}
            >
              Add Details
            </button>
          </div>
        </div>
      </div>

      {/* Complete Bank Info Modal */}
      <div
        className={`${
          financialCompleteModal ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[30rem]  pb-6 pt-6 flex flex-col items-center p-6 bg-white">
          <label className="text-[#596F96] text-[1.2rem] font-semibold mb-2">
            Approve Pensioner
          </label>
          <p className="text-center text-[1.1rem] mt-[0.5rem] mb-[2rem]">
            {err}
          </p>
          <div className="flex justify-end">
            <button
              onClick={() => {
                setFinancialCompleteModal(false);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Cancel
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[100%]"
              onClick={() => {
                user === "employee"
                  ? router("/EDashboard/PensionerAccount", {
                      state: {
                        finance: true,
                      },
                    })
                  : user === "admin"
                  ? router("/AdminDashboard/PensionerAccount", {
                      state: {
                        finance: true,
                      },
                    })
                  : router("/superAdminDashboard/PensionerAccount", {
                      state: {
                        finance: true,
                      },
                    });
                dispatch({ type: "universalPenSet", num: id });
                dispatch({ type: "gidFilterSet", num: "" });
                dispatch({ type: "whichViewSet", num: "pensioner" });
              }}
            >
              Add Detail
            </button>
          </div>
        </div>
      </div>

      <div
        className={`${
          showError ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 flex items-center p-3 bg-white flex-col">
          {/* <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShowError(false)}
          >
            <img src={across} alt="" />
          </div> */}
          <label className="text-[#596F96] text-[1.2rem] font-semibold mb-[0.1rem] mt-[0.5rem]">
            Approve Pensioner
          </label>
          <p className="text-center text-[1.1rem] mt-[0.5rem] mb-5 pl-[1rem] pr-[1rem]">
            {err}
          </p>
          {/* <p className="text-center text-justify text-[1.1rem] mt-12 mb-12">{err}</p> */}

          <button
            onClick={() => setShowError(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          showQuestions ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
          // className="absolute top-[-11%] left-[42%]"
          // onClick={() => setShow1(false)}
          >
            <img src={logo1} alt="" className="w-16 h-16" />
          </div>
          <p className="text-center mt-[0.5rem] mb-[2rem] text-[18px]">
            Are you sure you want to reset security questions?
          </p>
          <div className="w-full flex items-center justify-center gap-2">
            <button
              type="button"
              onClick={() => setShowQuestions(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[40%]"
            >
              NO
            </button>
            <button
              type="submit"
              onClick={ResetQ}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              YES
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showMsg ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
          // className="absolute top-[-11%] left-[42%]"
          // onClick={() => setShow1(false)}
          >
            <img src={logo1} alt="" className="w-20 h-20" />
          </div>
          <p className="text-center mt-[1rem] mb-[1.5rem] text-[18px]">{err}</p>

          <button
            type="submit"
            onClick={() => setShowMsg(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Prof;

import React, { useEffect } from "react";
import AttendanceDashboardWrapper from "../components/AttendanceWrapper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import profile from "../../../imgs/device-green.png";
import D1 from "../../../imgs/D2.jpg";
import arrowIcon from "../../../imgs/left-arrow.png";
import mapIcon from "../../../imgs/direction-directions.png";
import linkExternal from "../../../imgs/external-link.png";

import {
  UserOutlined,
  HomeOutlined,
  PhoneOutlined,
  MailOutlined,
  IdcardOutlined,
  TeamOutlined,
  ContactsOutlined,
  DeliveredProcedureOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";

const DeviceDetails = () => {
  const dispatch = useDispatch();
  const router = useNavigate();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "4" });
  }, []);

  const deviceData = {
    id: "1",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "John Doe",
    ipAddress: "192.168.1.1",
    location: "New York",
    department: "IT",
    address: "123 Main St, New York, NY",
    contact: "9876543210",
    phone: "9876543210",
    email: "john.doe@example.com",
    biometric: "FP",
    longitude: "-74.0060",
    latitude: "40.7128",
  };

  const handleMapLinkClick = () => {
    if (deviceData.longitude && deviceData.latitude) {
      const url = `https://www.google.com/maps?q=${deviceData.latitude},${deviceData.longitude}`;
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <AttendanceDashboardWrapper>
      <div className="">
        <div
          onClick={() => router("/Devices")}
          className="flex items-center mt-1 ml-5 gap-2 cursor-pointer hover:opacity-70 text-xl font-medium text-[#1ba397]">
          <img src={arrowIcon} alt="arrow-left" className="h-4 " />
          Back
        </div>
        <div className="flex w-full flex-row items-center gap-6 px-5 py-8">
          <div className="w-20 h-20">
            <img className="w-full h-full object-contain" src={D1} alt={`Profile-img`} />
          </div>
          <div className="flex flex-col justify-between h-full gap-1">
            <div className="">
              <h2 className="text-3xl font-semibold">Facial Recognition</h2>
              <p className="text-base font-normal mt-3">
                <span className="font-bold text-[#1ba397] tracking-wide">Serial:</span> #9879sdf79s
              </p>
            </div>
          </div>
        </div>
        <div className="w-full">
          {/* Profile Section */}
          <div className="px-5 mt-5">
            <div className="mt-3">
              <div className="flex justify-between items-center border-b-2 border-gray-100 px-2 py-3">
                <h6 className="text-base flex gap-2 items-center">
                  <IdcardOutlined className="text-primary text-xl" />
                  IP Address
                </h6>
                <h4 className="text-base font-semibold">{deviceData.ipAddress || "Not Defined"}</h4>
              </div>
            </div>
            <div className="mt-3">
              <div className="flex justify-between items-center border-b-2 border-gray-100 px-2 py-3">
                <h6 className="text-base flex gap-2 items-center">
                  <HomeOutlined className="text-primary text-xl" />
                  {/* <img src={mapIcon} alt="" className="w-7 h-7" /> */}
                  Location
                </h6>
                <h4 className="text-base font-semibold">{deviceData.location || "Not Defined"}</h4>
              </div>
            </div>
            <div className="mt-3">
              <div className="flex justify-between items-center border-b-2 border-gray-100 px-2 py-3">
                <h6 className="text-base flex gap-2 items-center text-gray-700">
                  <img src={mapIcon} alt="" className="w-7 h-7" />
                  Map Location
                </h6>
                <div
                  onClick={handleMapLinkClick}
                  className="cursor-pointer hover:opacity-80 flex gap-2 text-base font-semibold text-gray-800">
                  {deviceData.longitude && deviceData.latitude
                    ? `Latitude: ${deviceData.latitude}, Longitude: ${deviceData.longitude}`
                    : "Not Defined"}{" "}
                  <img src={linkExternal} alt="" className="w-5 h-5" />
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="flex justify-between items-center border-b-2 border-gray-100 px-2 py-3">
                <h6 className="text-base flex gap-2 items-center">
                  <TeamOutlined className="text-primary text-xl" />
                  Department
                </h6>
                <h4 className="text-base font-semibold">
                  {deviceData.department || "Not Defined"}
                </h4>
              </div>
            </div>
            <div className="mt-3">
              <div className="flex justify-between items-center border-b-2 border-gray-100 px-2 py-3">
                <h6 className="text-base flex gap-2 items-center">
                  <ContactsOutlined className="text-primary text-xl" />
                  Contact
                </h6>
                <h4 className="text-base font-semibold">{deviceData.contact || "Not Defined"}</h4>
              </div>
            </div>
            <div className="mt-3">
              <div className="flex justify-between items-center border-b-2 border-gray-100 px-2 py-3">
                <h6 className="text-base flex gap-2 items-center">
                  <PhoneOutlined className="text-primary text-xl" />
                  Phone
                </h6>
                <h4 className="text-base font-semibold">{deviceData.phone || "Not Defined"}</h4>
              </div>
            </div>
            <div className="mt-3">
              <div className="flex justify-between items-center border-b-2 border-gray-100 px-2 py-3">
                <h6 className="text-base flex gap-2 items-center">
                  <MailOutlined className="text-primary text-xl" />
                  Email
                </h6>
                <h4 className="text-base font-semibold">{deviceData.email || "Not Defined"}</h4>
              </div>
            </div>
          </div>

          {/* Current Status Section */}
          <div className="px-5 mt-5">
            <div className="mt-3">
              <div className="flex justify-between items-center border-b-2 border-gray-100 px-2 py-3">
                <h6 className="text-base flex gap-2 items-center">
                  <UserOutlined className="text-primary text-xl" />
                  Status
                </h6>
                <h4 className="text-base font-semibold">Active</h4>
              </div>
            </div>
          </div>
          <div className="px-5 mt-5">
            <div className="mt-3">
              <div className="flex justify-between items-center border-b-2 border-gray-100 px-2 py-3">
                <h6 className="text-base flex gap-2 items-center">
                  <DeliveredProcedureOutlined className="text-primary text-xl" />
                  Biometric
                </h6>
                <Tag color={"green"} className="text-lg font-normal px-2 py-1">
                  QRC
                </Tag>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AttendanceDashboardWrapper>
  );
};

export default DeviceDetails;

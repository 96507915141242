import back5 from "../../../../imgs/back5.png";
import first from "./steps/firstStepV";
import second from "./steps/secondStep";
import third from "./steps/thirdStep";
import fourth from "./steps/fourthStep";
import fifth from "./steps/fifthStep";
import sixth from "./steps/sixthStep";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddVendor = () => {
  const icurrent = useSelector((state) => state.icurrent);
  const router = useNavigate();
  let Dis;
  const dispatch = useDispatch();
  const Show = [first, second, third, fourth, fifth, sixth];
  Dis = Show[icurrent];
  return (
    <div className="bg-[#D9EFED]">
      <div className="relative flex justify-between sm:px-[7rem] px-[2rem] sm:mt-[5rem] mt-[2rem] mb-[2rem]">
        <div className="flex items-center">
          <div
            onClick={() => {
              dispatch({ type: "ISET", num: 0 });
              router("/SuperAdmin/Inventory/VendorListing");
            }}
            //   className="cursor-pointer pt-[5rem] mr-5"
          >
            <img
              className="sm:w-[1.7rem] w-[1.2rem] cursor-pointer"
              src={back5}
              alt=""
            />
          </div>
          <h2 className="font-bold ml-[1rem] sm:text-[1.8rem] text-[1.4rem]">
            Vendor Registration
          </h2>
        </div>
      </div>
      <div className=" mb-[5rem] ">
        <Dis />
      </div>
    </div>
  );
};

export default AddVendor;

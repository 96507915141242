import Listing from "../components/inventory/vendor/register/register";
import Nav from "../components/inventory/vendor/navbar";
const Reg = () => {
  return (
    <div className="bg-[#D9EFED] min-h-screen h-screen">
      <Nav />
      <Listing />
    </div>
  );
};

export default Reg;

import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PiCard = ({ percentage, title, subTitle }) => {
  // Calculate the remaining percentage
  const remaining = 100 - percentage;

  // Set up data for the pie chart
  const chartData = {
    labels: ['Completed', 'Remaining'],
    datasets: [
      {
        data: [percentage, remaining], // Progress and remaining segments
        backgroundColor: ['#1ba397', '#e0e0e0'], // Colors for segments
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    cutout: '80%', // Makes it look like a ring/donut chart
    plugins: {
      legend: {
        display: false, // Hide legend for a cleaner look
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          }
        }
      },
    },
  };

  return (
    <div className="flex w-full flex-row items-center gap-4 px-5 py-8">
      <div className="w-24 h-24 relative flex items-center justify-center">
        <Pie data={chartData} options={options} />
        <span className="absolute text-2xl font-bold text-gray-700">
          {percentage}%
        </span>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-lg font-normal">{title}</p>
        <h2 className="text-4xl font-medium">{percentage}%</h2>
      </div>
    </div>
  );
};

export default PiCard;

import List from "../components/events/eventDetails/inviteeList/index";
import Nav from "../components/Nav/nav";

const Gallery = () => {
  return (
    <div>
      <Nav />
      <List />
    </div>
  );
};

export default Gallery;

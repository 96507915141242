import React, { useEffect, useState } from "react";
import PageHeader from "../pensionerVerification/pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../Merchant/Modals/errorModal";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import SuccessModal from "../modals/successModal";
import axios from "axios";
import { Select } from "antd";
import { Calendar } from "react-multi-date-picker";
import moment, { months } from "moment/moment";
// import { TimePicker } from "antd";
import HolidayModal from "./modal/showHoliday";
import calendar from "../../imgs/calendar.svg";

const SelectProgram = () => {
  const { Option } = Select;
  const SelectedSlots = useSelector(
    (state) => state.scheduleVerificationSelectedSlot
  );
  console.log(SelectedSlots);
  const router = useNavigate();
  const dispatch = useDispatch();
  const distributionCenter = useSelector((state) => state.distributionCenter);
  const dataUser = useSelector((state) => state.dataUser);
  const [selectedProgram, setSelectedProgram] = useState(
    "senior-citizen-Pension"
  );
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const today = new Date();
  const [workingHoursStart, setWorkingHoursStart] = useState(null);
  const [workingHoursEnd, setWorkingHoursEnd] = useState(null);
  const [breakTimeStart, setBreakTimeStart] = useState(null);
  const [breakTimeEnd, setBreakTimeEnd] = useState(null);
  const [duration, setDuration] = useState(0);
  const [selectedDateandTime, setSelectedDateandTime] = useState([]);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [month, setMonth] = useState(moment().format("MM"));
  const [refetch, setRefetch] = useState(false);
  const [year, setYear] = useState(moment().format("YYYY"));
  const [disabledDates, setDisabledDates] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [showHoliday, setShowHoliday] = useState(false);
  const [holiday, setHoliday] = useState(null);

  useEffect(() => {
    setSelectedDateandTime(SelectedSlots);
    if (SelectedSlots.length > 0) {
      setSelectedDates(
        SelectedSlots.map((item) => moment(item.date.split("T")[0]))
      );
    }
    dispatch({ type: "SELECTED_MONTH", num: moment().format("MM") });
    dispatch({ type: "SELECTED_YEAR", num: moment().format("YYYY") });
    dispatch({ type: "SELECTED_PROGRAM", num: "senior-citizen-Pension" });
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pension-programs/names-and-icons`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setPrograms(data?.data);
      })
      .catch(() => {});
  }, []);

  const options = programs?.map((item) => ({
    value: item?.name,
    label: item?.name,
    imageUrl: item?.icon,
  }));

  const formatProgramNames = (data) => {
    return data
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };

  const renderOption = (option) => (
    <Option key={option?.value} value={option?.value}>
      <div className="flex items-center">
        <img
          src={option?.imageUrl}
          alt={option?.label}
          style={{ width: 24, marginRight: 8 }}
        />
        <span>
          {formatProgramNames(
            option?.label === "plwd"
              ? "PLWD"
              : option?.label === "pa-medical"
              ? "PA Medical"
              : option?.label === "pa-economical"
              ? "PA Economical"
              : option?.label
          )}
        </span>
      </div>
    </Option>
  );

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-default-time-manager`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((res) => {
        if (
          res?.data?.openTime &&
          res?.data?.closeTime &&
          res?.data?.breakStart &&
          res?.data?.breakEnd &&
          res?.data?.slotDuration
        ) {
          setWorkingHoursStart(res?.data?.openTime);
          setWorkingHoursEnd(res?.data?.closeTime);
          setBreakTimeStart(res?.data?.breakStart);
          setBreakTimeEnd(res?.data?.breakEnd);
          setDuration(res?.data?.slotDuration);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Setting = () => {
    const api = `v1/scheduler-center-time-manager/add`;

    const method = "post";

    const dataAfterFormat = selectedDateandTime.map((data) => {
      const formattedOpenTime = moment(data?.openTime, "hh:mm A").format(
        "HH:mm"
      );
      const formattedCloseTime = moment(data?.closeTime, "hh:mm A").format(
        "HH:mm"
      );
      const formattedBreakStart = moment(data?.breakStart, "hh:mm A").format(
        "HH:mm"
      );
      const formattedBreakEnd = moment(data?.breakEnd, "hh:mm A").format(
        "HH:mm"
      );

      return {
        // ...data,
        openTime: formattedOpenTime,
        closeTime: formattedCloseTime,
        breakStart: formattedBreakStart,
        breakEnd: formattedBreakEnd,
        program: selectedProgram,
        distributioncoverageId: distributionCenter?.id,
        date: moment(data?.date).format("YYYY-MM-DD"),
        slotDuration: data?.slotDuration,
      };
    });

    // console.log(dataAfterFormat);
    // return;

    axios[method](`${process.env.REACT_APP_BASE_URL}/${api}`, dataAfterFormat, {
      headers: {
        Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: "SCHEDULE_VERIFICATION_SELECTED_SLOT",
          num: res?.data,
        });
        dispatch({
          type: "SELECTED_SLOT",
          num: res?.data?.[0],
        });

        // router("/scheduleVerification/selectedEmployees");
        setMessage(
          `Schedule slots for all the selected dates has been created successfully. If you wish to link any employee to ${distributionCenter?.coverage}, you can do in next step`
        );
        setShow(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErr(true);
      });
  };

  //Get Listing
  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 30,
        skip: 0,
        sortBy: "createdAt:desc",
        distributioncoverageId: distributionCenter?.id,
        month: month,
        year: year,
        ...{ program: `${selectedProgram}` },
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [month, year, selectedProgram]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-center-time-manager`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        const Dates = res?.data?.rows?.map((item) =>
          moment(item?.date).format("YYYY-MM-DD")
        );
        setDisabledDates(Dates);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/scheduler-holidays`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((res) => {
        const Dates = res?.data?.map((item) =>
          moment(item?.date).format("YYYY-MM-DD")
        );
        setHolidays(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [month, year]);

  const isDisabled = (date) => {
    const dateString = date.format("YYYY-MM-DD");
    return disabledDates.includes(dateString);
  };

  // const isHoliday = (date) => {
  //   const dateString = date.format("YYYY-MM-DD");
  //   return holidays.includes(dateString);
  // };

  const isHoliday = (date) => {
    const dateString = date.format("YYYY-MM-DD"); // Format the date properly

    // Find the holiday object that matches the dateString
    const holiday = holidays.find((holiday) => holiday.date === dateString);

    console.log(holiday, "Hold"); // For debugging

    return holiday; // Return the holiday object or undefined if not found
  };

  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4 min-h-screen">
      <PageHeader
        header={true}
        title={"Set Schedule"}
        route={"/pensionerVerificationScheduling/selectLocation"}
      />
      <div
        onClick={() => {
          router("/scheduleVerification/schedules");
        }}
        className={`flex justify-end items-center gap-2 cursor-pointer sm:w-[90%] w-full
        }`}
      >
        <h1 className="font-semibold text-[0..95rem]">
          View Existing Schedule
        </h1>
        <img src={calendar} alt="" className="w-6 h-6" />
      </div>
      <div className="flex justify-center">
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%] w-full`}
        >
          <div className={`flex items-center justify-between`}>
            <h3 className="font-medium mb-1 text-[0.93rem]">Selected Center</h3>
            <p
              className="text-primary cursor-pointer font-medium"
              onClick={() => {
                router("/pensionerVerificationScheduling/selectLocation");
              }}
            >
              Change
            </p>
          </div>
          <div className="bg-white p-3 rounded-md mt-1 mb-4">
            {distributionCenter?.coverage}{" "}
            {
              distributionCenter?.distributioncenter?.distributionlocation
                ?.distributionregion?.regionNo
            }
          </div>
          <div>
            <h3 className="font-medium mb-1 text-[0.93rem]">Program</h3>

            <Select
              placeholder="Select Program"
              // className="wow w-[100%] mb-4"
              className="w-[100%] woww mb-4"
              value={selectedProgram}
              renderOption={renderOption}
              onChange={
                (value) => {
                  setSelectedProgram(value);
                  dispatch({ type: "SELECTED_PROGRAM", num: value });
                }
                // console.log(value)
              }
            >
              {options?.map(renderOption)}
            </Select>
          </div>
          <div>
            <h3 className="font-medium mb-1 text-[0.93rem]">
              Choose the dates
            </h3>

            <div className="flex gap-5 mt-3 md:flex-row flex-col">
              <Calendar
                style={{
                  zIndex: 10,
                }}
                className="custom-calendar"
                multiple
                value={selectedDates}
                // onChange={(dates) => {
                //   const formattedDates = dates.map((date) => date.toDate());
                //   setSelectedDates(formattedDates);

                //   const updatedDateAndTime = formattedDates.map((date) => ({
                //     openTime: workingHoursStart,
                //     closeTime: workingHoursEnd,
                //     breakStart: breakTimeStart,
                //     breakEnd: breakTimeEnd,
                //     slotDuration: duration,
                //     distributioncoverageId: distributionCenter?.id,
                //     program: selectedProgram,
                //     date: date,
                //   }));

                //   setSelectedDateandTime(updatedDateAndTime);

                //   setSelectedDate(formattedDates[0]);
                // }}

                onChange={(dates) => {
                  const formattedDates = dates.map((date) => date.toDate());
                  setSelectedDates(formattedDates);

                  // Create or update the array for the updated dates and time slots
                  const updatedDateAndTime = formattedDates.map((date) => ({
                    openTime: workingHoursStart,
                    closeTime: workingHoursEnd,
                    breakStart: breakTimeStart,
                    breakEnd: breakTimeEnd,
                    slotDuration: duration,
                    distributioncoverageId: distributionCenter?.id,
                    program: selectedProgram,
                    date: date,
                  }));

                  // If SelectedSlot exists and has entries, combine them
                  const combinedDateAndTime =
                    SelectedSlots && SelectedSlots.length > 0
                      ? [
                          ...SelectedSlots,
                          ...updatedDateAndTime.filter(
                            (entry) =>
                              !SelectedSlots.some(
                                (slot) =>
                                  new Date(slot.date).toDateString() ===
                                  new Date(entry.date).toDateString()
                              )
                          ),
                        ]
                      : updatedDateAndTime;

                  setSelectedDateandTime(combinedDateAndTime); // Update with the combined results

                  // Set the selected date to the first one, or handle it based on your preference
                  setSelectedDate(formattedDates[0]);
                }}
                // onChange={(dates) => {
                //   // Convert the selected dates to plain JS Date format
                //   const formattedDates = dates.map((date) => date.toDate());

                //   setSelectedDates(formattedDates); // Set the selected dates in state

                //   // Create or update the array for the updated dates and time slots
                //   const updatedDateAndTime = formattedDates.map((date) => {
                //     // Check if the date already exists in SelectedDateandTime array
                //     const existingEntry = selectedDateandTime.find(
                //       (entry) =>
                //         new Date(entry.date).toDateString() ===
                //         new Date(date).toDateString()
                //     );

                //     // If it exists, keep the existing entry, otherwise create a new entry
                //     return existingEntry
                //       ? existingEntry
                //       : {
                //           openTime: workingHoursStart,
                //           closeTime: workingHoursEnd,
                //           breakStart: breakTimeStart,
                //           breakEnd: breakTimeEnd,
                //           slotDuration: duration,
                //           distributioncoverageId: distributionCenter?.id,
                //           program: selectedProgram,
                //           date: date, // Add the new date
                //         };
                //   });

                //   // Merge new entries with existing ones, avoiding overwrites
                //   const mergedDateAndTime = [
                //     ...selectedDateandTime.filter(
                //       (entry) =>
                //         !formattedDates.some(
                //           (date) =>
                //             new Date(entry.date).toDateString() ===
                //             new Date(date).toDateString()
                //         )
                //     ),
                //     ...updatedDateAndTime,
                //   ];

                //   // Update state with the merged result
                //   setSelectedDateandTime(mergedDateAndTime);

                //   // Set the selected date to the first date or last date, based on preference
                //   setSelectedDate(formattedDates[0]); // For first selected date
                //   // setSelectedDate(formattedDates[formattedDates.length - 1]); // For last selected date
                // }}

                // onChange={(dates) => {
                //   // Convert the selected dates to plain JS Date format
                //   const formattedDates = dates.map((date) => date.toDate());

                //   setSelectedDates(formattedDates); // Update the selected dates in state

                //   // Remove dates that were deselected from selectedDateandTime
                //   const filteredDateAndTime = selectedDateandTime.filter(
                //     (entry) =>
                //       formattedDates.some(
                //         (date) =>
                //           new Date(entry.date).toDateString() ===
                //           new Date(date).toDateString()
                //       )
                //   );

                //   // Create or update the array for the updated dates and time slots
                //   const updatedDateAndTime = formattedDates.map((date) => {
                //     // Check if the date already exists in SelectedDateAndTime array
                //     const existingEntry = selectedDateandTime.find(
                //       (entry) =>
                //         new Date(entry.date).toDateString() ===
                //         new Date(date).toDateString()
                //     );

                //     // If it exists, keep the existing entry, otherwise create a new entry
                //     return existingEntry
                //       ? existingEntry
                //       : {
                //           openTime: workingHoursStart,
                //           closeTime: workingHoursEnd,
                //           breakStart: breakTimeStart,
                //           breakEnd: breakTimeEnd,
                //           slotDuration: duration,
                //           distributioncoverageId: distributionCenter?.id,
                //           program: selectedProgram,
                //           date: date, // Add the new date
                //         };
                //   });

                //   // Merge the remaining entries with the updated ones
                //   const mergedDateAndTime = [
                //     ...filteredDateAndTime,
                //     ...updatedDateAndTime,
                //   ];

                //   // Check if SelectedSlots exists and has length > 0, then add to it
                //   if (SelectedSlots && SelectedSlots.length > 0) {
                //     const updatedSelectedSlots = [
                //       ...SelectedSlots,
                //       ...mergedDateAndTime,
                //     ];
                //     setSelectedDateandTime(updatedSelectedSlots); // Update SelectedSlots with the new data
                //   } else {
                //     setSelectedDateandTime(mergedDateAndTime); // Update state if no SelectedSlots
                //   }

                //   // Set the selected date to the first date or last date, based on preference
                //   setSelectedDate(formattedDates[0]); // For first selected date
                //   // setSelectedDate(formattedDates[formattedDates.length - 1]); // For last selected date
                // }}
                minDate={today}
                mapDays={({ date }) => {
                  let props = {};
                  const holiday = isHoliday(date); // Returns the holiday object or undefined
                  const disabled = isDisabled(date); // Check if the date is disabled

                  if (holiday && disabled) {
                    // If the date is both a holiday and disabled, show the disabled styling
                    props.disabled = true;
                    props.style = {
                      color: "red",
                      textDecoration: "line-through",
                    };
                  } else if (holiday) {
                    // If it's only a holiday, apply holiday styling
                    props.disabled = true;
                    props.style = {
                      // backgroundColor: "lightgreen", // Optional holiday style
                      marginTop: "0.5em",
                      borderRadius: "50%",
                      cursor: "pointer",
                    };
                    props.children = (
                      <div>
                        {date.day}
                        <div
                          style={{ fontSize: "0.6em" }}
                          className="text-gray-500"
                        >
                          Holiday
                        </div>
                      </div>
                    );
                    props.onClick = () => {
                      setHoliday(holiday?.name);
                      setShowHoliday(true);
                    };
                  } else if (disabled) {
                    // If it's only disabled, apply the disabled styling
                    props.disabled = true;
                    props.style = {
                      color: "red",
                      textDecoration: "line-through",
                    };
                  }

                  return props;
                }}
                onMonthChange={(month) => {
                  setMonth(month.format("MM"));
                  dispatch({ type: "SELECTED_MONTH", num: month.format("MM") });
                }}
                onYearChange={(year) => {
                  setYear(year.format("YYYY"));
                  dispatch({ type: "SELECTED_YEAR", num: year.format("YYYY") });
                }}
              />

              <div
                className={`${
                  selectedDateandTime.length === 0
                    ? "hidden"
                    : "bg-white w-full p-4"
                }`}
              >
                <p className="mb-4">Set Schedule</p>
                <div className="grid md:grid-cols-7 sm:grid-cols-4 grid-cols-3 gap-3 mb-3">
                  {selectedDateandTime?.map((data, index) => (
                    <button
                      key={index} // Using index as key is fine here
                      onClick={() => setSelectedDate(data?.date)}
                      className={`px-4 py-2 rounded-lg ${
                        selectedDate === data?.date
                          ? "bg-primary text-white"
                          : "bg-gray-100"
                      } font-medium text-md`}
                    >
                      {moment(data?.date).format("DD") + " "}
                      {moment(data?.date).format("MMM")}
                    </button>
                  ))}
                </div>

                <div className="space-y-3">
                  {selectedDateandTime?.map((slot, dataIndex) =>
                    slot.date === selectedDate ? (
                      <div key={dataIndex}>
                        <div
                          className="flex items-center  flex-col w-full bg-white"
                          style={{ marginBottom: "8px" }}
                        >
                          <div className="w-full grid sm:grid-cols-2 grid-cols-1 gap-4">
                            <label>
                              Open Time:
                              <div className="relative">
                                <TimePicker
                                  value={slot?.openTime}
                                  format="h:mm a"
                                  onChange={(value) => {
                                    if (value) {
                                      setSelectedDateandTime((prev) => {
                                        const newSlots = [...prev];
                                        newSlots[dataIndex].openTime = value;
                                        return newSlots;
                                      });
                                    }
                                  }}
                                  className="w-full"
                                  clearIcon={null}
                                  // disableClock={true}
                                  // clearIcon={null}
                                />
                              </div>
                            </label>
                            <label>
                              Close Time:
                              <div className="relative">
                                <TimePicker
                                  value={slot?.closeTime}
                                  format="hh:mm a"
                                  onChange={(value) => {
                                    if (value) {
                                      setSelectedDateandTime((prev) => {
                                        const newSlots = [...prev];
                                        newSlots[dataIndex].closeTime = value;
                                        return newSlots;
                                      });
                                    }
                                  }}
                                  className="w-full"
                                  clearIcon={null}
                                />
                              </div>
                            </label>
                          </div>
                        </div>

                        <div
                          className="flex items-center flex-col w-full"
                          style={{ marginBottom: "8px" }}
                        >
                          <p className="font-medium w-full text-[0.95rem] capitalize">
                            Break Time
                          </p>
                          <div className="w-full grid sm:grid-cols-2 grid-cols-1 gap-4 bg-white mt-2">
                            <label>
                              Start Time:
                              <div className="relative">
                                <TimePicker
                                  value={slot?.breakStart}
                                  format="hh:mm a"
                                  onChange={(value) => {
                                    if (value) {
                                      setSelectedDateandTime((prev) => {
                                        const newSlots = [...prev];
                                        newSlots[dataIndex].breakStart = value;
                                        return newSlots;
                                      });
                                    }
                                  }}
                                  className="w-full"
                                  clearIcon={null}
                                />
                              </div>
                            </label>
                            <label>
                              End Time:
                              <div className="relative">
                                <TimePicker
                                  value={slot?.breakEnd}
                                  format="hh:mm a"
                                  onChange={(value) => {
                                    if (value) {
                                      setSelectedDateandTime((prev) => {
                                        const newSlots = [...prev];
                                        newSlots[dataIndex].breakEnd = value;
                                        return newSlots;
                                      });
                                    }
                                  }}
                                  className="w-full"
                                  clearIcon={null}
                                />
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="flex items-center sm:gap-x-4 gap-x-2 mt-7">
                          <h1 className="font-semibold whitespace-nowrap">
                            Verification slot duration (Min)
                          </h1>
                          <div className="flex gap-2 bg-[#F6F4F4] px-2">
                            <button
                              onClick={() => {
                                if (slot?.slotDuration > 10) {
                                  setSelectedDateandTime((prev) => {
                                    const newSlots = [...prev];
                                    newSlots[dataIndex].slotDuration =
                                      slot?.slotDuration - 5;
                                    return newSlots;
                                  });
                                }
                              }}
                              className="text-primary font-bold text-[1.5rem] text-center sm:w-[1.5rem]"
                            >
                              -
                            </button>
                            <input
                              type="text"
                              value={slot?.slotDuration}
                              className="sm:w-[4rem] h-[2rem] w-[3rem]  px-2 rounded-lg text-center bg-[#F6F4F4] text-[1rem]"
                              readOnly
                            />
                            <button
                              onClick={() => {
                                setSelectedDateandTime((prev) => {
                                  const newSlots = [...prev];
                                  newSlots[dataIndex].slotDuration =
                                    slot?.slotDuration + 5;
                                  return newSlots;
                                });
                              }}
                              className="text-primary font-bold text-[1.5rem] text-center sm:w-[1.5rem]"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>

                {/* Add Time Slot */}
                {/* <div className="flex items-center mt-4">
                  <button
                    onClick={addTimeSlot}
                    className="flex items-center space-x-2 text-teal-500 font-semibold"
                  >
                    <span>Add time slot</span>
                  </button>
                </div> */}
              </div>
            </div>
            <div className="w-full mb-2 mt-4">
              <div className="flex text-[0.8rem]">
                <div className="text-gray-600 flex items-center">
                  {/* Voucher cashing ID:{" "} */}
                  <p className="mr-2 w-4 h-4 text-center flex items-center justify-center bg-primary text-semibold text-white rounded-full cursor-pointer">
                    i
                  </p>
                </div>
                <div className="flex items-center text-gray-500 text-[0.85rem]">
                  You can set a custom schedule for selected dates. If not, the
                  employee will follow the standard MHSSS schedule.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            if (selectedProgram === null) {
              setErr("Please select a program");
              setShowErr(true);
              return;
            }
            if (selectedDateandTime.length === 0) {
              setErr("Please select a date");
              setShowErr(true);
              return;
            }

            Setting();
            // router("/scheduleVerification/selectEmployees");
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
        >
          Save
        </button>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
      {show && (
        <SuccessModal
          show={show}
          setShow={setShow}
          message={message}
          route="/scheduleVerification/selectedEmployees"
        />
      )}
      {showHoliday && (
        <HolidayModal setShow={setShowHoliday} holiday={holiday} />
      )}
    </div>
  );
};

export default SelectProgram;

import React from "react";

const DetailCard = ({ icon, title, subTitle }) => {
  return (
    <div className="flex w-full flex-row items-center gap-4 border border-border shadow bg-[#f8f8f8] rounded-md px-5 py-8">
      <div className="w-11 h-11">
        <img className="w-full h-full object-contain" src={icon} alt={title} />
      </div>
      <div className="flex flex-col gap-1">
        <h2 className="text-3xl font-bold">{title}</h2>
        <p className="text-lg font-normal">{subTitle}</p>
      </div>
    </div>
  );
};

export default DetailCard;

import React, { useEffect, useState } from "react";
import PageHeader from "../pageHeader/pageHeader";
import { useSelector, useDispatch } from "react-redux";
import ErrorModal from "../../Merchant/Modals/errorModal";
import { Calendar } from "react-multi-date-picker";
import axios from "axios";
import { Checkbox, Select } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import HolidayModal from "../../schduleVerification/modal/showHoliday";
import logo from "../../../imgs/logo12.png";
import SearchModal from "./searchModal";

const SelectProgram = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [selectedRecords, setSelectedRecords] = useState([]);
  const today = new Date();
  const distributionCenter = useSelector((state) => state.distributionCenter);
  const dataUser = useSelector((state) => state.dataUser);
  const [err, setErr] = useState("");
  const [reason, setReason] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [showHoliday, setShowHoliday] = useState(false);
  const [holiday, setHoliday] = useState(null);
  const [month, setMonth] = useState(moment().format("MM"));
  const [showCancel, setShowCancel] = useState(false);
  const [year, setYear] = useState(moment().format("YYYY"));
  const [selectedDate, setSelectedDate] = useState(today);
  const [search, setSearch] = useState("");
  const [dob, setDob] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [searchDob, setSearchDob] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [fetch, setFetch] = useState(false);
  const [gid, setGid] = useState("");
  const [lastName, setLastName] = useState("");
  const [records, setRecords] = useState([]);

  useEffect(() => {
    setSelectAll(false);
    setSelectedRecords([]);
    const GetSlots = async () => {
      const params = {
        distributioncoverageId: distributionCenter?.id,
        program: "senior-citizen-Pension",
        day: moment(selectedDate)?.format("DD"),
        month: moment(selectedDate)?.format("MM"),
        year: moment(selectedDate)?.format("YYYY"),
      };
      if (status) {
        params.status = status;
      }
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/booked-slots-by-month`,
          {
            headers: {
              Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
            },
            params,
          }
        );
        setRecords(res?.data?.rows);
      } catch (err) {}
    };
    GetSlots();
  }, [selectedDate, status, fetch]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/scheduler-holidays`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((res) => {
        setHolidays(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [month, year]);

  const isHoliday = (date) => {
    const dateString = date.format("YYYY-MM-DD"); // Format the date properly

    // Find the holiday object that matches the dateString
    const holiday = holidays.find((holiday) => holiday.date === dateString);

    console.log(holiday, "Hold"); // For debugging

    return holiday; // Return the holiday object or undefined if not found
  };
  const handleCheckboxChange = (id) => {
    setSelectedRecords((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((recordId) => recordId !== id)
        : [...prevSelected, id]
    );
  };
  const Search = async () => {
    const params = {
      distributioncoverageId: distributionCenter?.id,
      program: "senior-citizen-Pension",
      // day: moment(selectedDate)?.format("DD"),
      month: moment(selectedDate)?.format("MM"),
      year: moment(selectedDate)?.format("YYYY"),
    };
    if (status) {
      params.status = status;
    }
    if (gid !== "") {
      params.gid = gid;
    }
    if (dob !== "") {
      params.dateOfBirth = dob;
    }
    if (lastName !== "") {
      params.lastName = lastName;
    }
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/booked-slots-by-month`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
        }
      );
      dispatch({
        type: "APP_SEARCH",
        num: res?.data?.rows,
      });
      router("/pensionerVerification/SearchAppointments");
    } catch (err) {
      setErr(err?.response?.data?.message);
      setShowErr(true);
    }
  };
  const isDateTimePassed = (date, time) => {
    const dateTime = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss");
    const now = moment();
    return dateTime.isBefore(now);
  };
  const cancel = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/cancel-bulk/by-mhsss`,
        {
          ids: selectedRecords,
          cancelReason: reason,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then(() => {
        setShowCancel(false);
        setFetch(!fetch);
        router("/pensionerVerification/AllAppointments");
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErr(true);
      });
  };
  const handleSelectAllChange = () => {
    setSelectAll((prev) => !prev);
    if (!selectAll) {
      const upcomingIds = records
        .filter((record) => {
          const isUpcoming = !isDateTimePassed(
            record.schedulercentertimemanager.date,
            record.fromTime
          );
          return record.status !== "cancelled" && isUpcoming;
        })
        .map((record) => record.id);
      setSelectedRecords(upcomingIds);
    } else {
      setSelectedRecords([]);
    }
  };
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4 min-h-screen">
      <PageHeader
        header={true}
        title={"Appointments"}
        route={"/pensionerVerification/selectedProgram"}
      />
      <div className="sm:w-[95%] flex sm:flex-row flex-col items-center justify-end gap-2">
        <div className="sm:w-[16rem] md:w-[20rem] w-full flex items-center justify-end">
          <div className="flex sm:w-[16rem] md:w-full w-[20rem] items-center">
            <p className="mr-4 text-[0.95rem] font-semibold text-gray-600 w-[5rem]">
              Filter By
            </p>
            <Select
              placeholder="Select Status"
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              value={status}
              onChange={(value) => setStatus(value)}
              size="middle"
              className="w-full wow"
            >
              <option value="booked">Booked</option>
              <option value="cancelled">Cancelled</option>
            </Select>
          </div>
        </div>
        <div className="sm:w-[16rem] md:w-[20rem] w-full flex items-center justify-end">
          <form className="flex sm:w-[16rem] md:w-full w-[15rem] items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="w-full bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-3     "
                placeholder="Search"
                required=""
                // value={search}
                onClick={() => setShowSearchModal(true)}
                // onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center w-full">
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm md:mx-4 mx-1 mb-5 mt-2 p-6 md:w-[90%] w-full`}
        >
          <div className="flex gap-4 md:flex-row flex-col">
            <div className="md:w-[40%] w-full p-4 bg-white flex items-center flex-col">
              <p className="font-semibold mb-6 w-full">Choose the dates:-</p>
              <Calendar
                style={{ zIndex: 10 }}
                className="custom-calendar"
                value={selectedDate}
                onChange={(date) => {
                  if (date instanceof Array) {
                    setSelectedDate(date[0].toDate()); // For some cases returning array, just take first element
                  } else {
                    setSelectedDate(date.toDate ? date.toDate() : date); // Set the correct DateObject or JS Date
                  }
                }}
                minDate={today}
                mapDays={({ date }) => {
                  let props = {};
                  const holiday = isHoliday(date); // Returns the holiday object or undefined

                  if (holiday) {
                    // If it's only a holiday, apply holiday styling
                    props.disabled = true;
                    props.style = {
                      // backgroundColor: "lightgreen", // Optional holiday style
                      marginTop: "0.5em",
                      borderRadius: "50%",
                      cursor: "pointer",
                    };
                    props.children = (
                      <div>
                        {date.day}
                        <div
                          style={{ fontSize: "0.6em" }}
                          className="text-gray-500"
                        >
                          Holiday
                        </div>
                      </div>
                    );
                    props.onClick = () => {
                      setHoliday(holiday?.name);
                      setShowHoliday(true);
                    };
                  }

                  return props;
                }}
                onMonthChange={(month) => {
                  setMonth(month.format("MM"));
                }}
                onYearChange={(year) => {
                  setYear(year.format("YYYY"));
                }}
              />
            </div>
            <div className="flex flex-col w-full bg-white md:p-4 p-2">
              <div className="w-full flex mb-2 items-center justify-between">
                <div className="mb font-semibold">
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  >
                    Select All
                  </Checkbox>
                </div>
                <p
                  onClick={() => {
                    if (selectedRecords?.length === 0) {
                      setErr("Please Select Appointment to cancel.");
                      setShowErr(true);
                    } else setShowCancel(true);
                  }}
                  className="font-semibold text-red-500 hover:underline cursor-pointer"
                >
                  Cancel Appointments
                </p>
              </div>
              <div className="w-full grid md:grid-cols-2 grid-cols-1 gap-4">
                {records.map((record, index) => {
                  const isUpcoming = !isDateTimePassed(
                    record.date,
                    record.fromTime
                  );
                  const type =
                    record.status === "cancelled"
                      ? "Cancelled"
                      : isUpcoming
                      ? "Upcoming"
                      : "Completed";

                  return (
                    <div
                      key={index}
                      className="border p-3 gap-1 bg-gray-100 rounded-md shadow-sm"
                    >
                      <div className="w-full flex gap-1 mb-4 justify-between">
                        <div>
                          <p className="font-semibold text-gray-500">
                            Slot booked For
                          </p>
                          <div className="px-4 py-1 bg-primary rounded-md text-white">
                            {moment(
                              record?.schedulercentertimemanager?.date
                            ).format("YYYY/MM/DD")}
                            {", "}
                            {moment(record?.fromTime, "HH:mm:ss").format(
                              "hh:mm A"
                            )}
                          </div>
                        </div>
                        <div>
                          {type === "Upcoming" && (
                            <Checkbox
                              className="checkk"
                              checked={selectedRecords.includes(record.id)}
                              onChange={() => handleCheckboxChange(record.id)}
                            />
                          )}
                          <p
                            className={`font-semibold text-right ${
                              type === "Cancelled"
                                ? "text-red-500"
                                : type === "Completed"
                                ? "text-gray-500"
                                : "hidden"
                            }`}
                          >
                            {type}
                          </p>
                          <p className={`font-semibold w-full text-right`}>
                            {type === "Cancelled" || type === "Completed"
                              ? moment(record.updatedAt).format(
                                  "YYYY-MM-DD hh:mm A"
                                )
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="bg-white p-2 py-3 mb-4 rounded-md flex flex-col gap-2">
                        <div className="flex items-center gap-3">
                          <img
                            src={
                              record.schedulercentertimemanager
                                ?.assignedByUserId?.profileImage ||
                              record.bookForUser?.person?.image
                            }
                            alt="Profile"
                            className="w-14 h-14 rounded-full"
                          />
                          <div className="ml-3 w-full">
                            <div className="w-full flex items-center justify-between">
                              <p className="font-semibold text-gray-600">
                                {record.bookForUser?.person?.firstName}{" "}
                                {record.bookForUser?.person?.lastName}
                              </p>
                              <p className="text-gray-600">
                                {record.bookForUser?.person?.gender}
                              </p>
                            </div>
                            <p className="text-gray-600">
                              <span className="font-semibold">GID:</span>{" "}
                              {record.bookForUser?.gid}
                            </p>
                            <div className="w-full flex items-center justify-between">
                              <p className="text-gray-600">
                                <span className="font-semibold">DOB:</span>{" "}
                                {moment(
                                  record.bookForUser?.person?.dateOfBirth
                                ).format("YYYY-MM-DD")}
                              </p>
                            </div>
                            <div className="w-full flex items-center justify-end">
                              <p
                                onClick={() => {
                                  dispatch({
                                    type: "APP_DETAIL",
                                    num: record,
                                  });
                                  router(
                                    "/pensionerVerification/AppointmentDetails"
                                  );
                                }}
                                className="text-primary font-semibold cursor-pointer hover:underline"
                              >
                                View Details
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {type === "Cancelled" && (
                        <div className="bg-white p-2 py-2 mb-2 rounded-md flex flex-col">
                          <p className="font-semibold text-red-500">Reason:</p>
                          <p>{record.cancelReason}</p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
      <div
        className={`${
          showCancel ? "" : "hidden"
        } w-[100%] h-[100%] z-[50] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[26rem]  flex items-center p-5 bg-white flex-col">
          <div className="w-16 h-16" onClick={() => setShowCancel(false)}>
            <img src={logo} alt="" className="w-16 h-16" />
          </div>
          <p className="text-center mt-6 mb-5 font-semibold">
            Are you sure to you want to cancel the appointment?
          </p>
          <textarea
            type="text"
            name=""
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            placeholder="Note"
            className=" lg:text-[1.1rem] w-[85%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <div className="w-full flex gap-2 items-center justify-center">
            <button
              onClick={() => {
                setShowCancel(false);
                setReason("");
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[40%]"
            >
              No
            </button>
            <button
              onClick={() => {
                if (reason === "") {
                  setErr("Please Enter a specific reason.");
                  setShowErr(true);
                } else cancel();
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
      {showSearchModal && (
        <SearchModal
          show={showSearchModal}
          setShow={setShowSearchModal}
          search={search}
          setSearch={setSearch}
          dob={dob}
          setDob={setDob}
          searchDob={searchDob}
          setSearchDob={setSearchDob}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          phoneNoo={phoneNoo}
          setPhoneNoo={setPhoneNoo}
          gid={gid}
          setGid={setGid}
          lastName={lastName}
          setLastName={setLastName}
          searchFunction={Search}
        />
      )}
      {showHoliday && (
        <HolidayModal setShow={setShowHoliday} holiday={holiday} />
      )}
    </div>
  );
};

export default SelectProgram;

import React, { useEffect } from "react";
import AttendanceDashboardWrapper from "../components/AttendanceWrapper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditRequest from "./EditRequest";

const AddERequest = () => {
  const dispatch = useDispatch();
  const router = useNavigate();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "5" });
  }, []);

  const handleSave = (data) => {
    // Logic to add a new device
    router(`/ERequests`);
    console.log("ERequests added:", data);
  };

  return (
    <AttendanceDashboardWrapper>
      <div className="">
        <div className="h-full px-2">
          <div className="flex w-full justify-between">
            <h2 className="text-xl font-semibold">Add E-Request</h2>
          </div>
        </div>
        <div>
          <EditRequest onSave={handleSave} />
        </div>
      </div>
    </AttendanceDashboardWrapper>
  );
};

export default AddERequest;

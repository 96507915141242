import React, { useState, useEffect } from "react";
import back5 from "../../../imgs/back5.png";
import { Select } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import ErrorModal from "../../Merchant/Modals/errorModal";
import logo from "../../../imgs/logo12.png";
import SuccessModal from "../../modals/successModal";
import { useNavigate } from "react-router-dom";
import deleteicon from "../../../imgs/deleteicon.png";
const Main = () => {
  const employeeId = useSelector((state) => state.employeeId);
  const [accessType, setAccessType] = useState(null);
  const router = useNavigate();
  const [data, setData] = useState([]);

  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const [addModal, setAddModal] = useState(false);
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [show1, setShow1] = useState("");
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [message, setMessage] = useState("");
  const [exceptionId, setExceptionId] = useState(null);
  const [afterApi, setAfterApi] = useState(false);
  const [option, setOption] = useState([
    {
      value: "geofencing",
      label: "Geofencing",
    },
    {
      value: "ip",
      label: "IP",
    },
  ]);

  //Get Listing
  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        // limit: 9,
        // skip: (page - 1) * 9,
        // page: page,
        // sortBy: "createdAt:desc",
        userId: employeeId,
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [refetch, page]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/user-access-restriction-exceptions/user-exceptions`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        const initialOptions = [
          {
            value: "geofencing",
            label: "Geofencing",
          },
          {
            value: "ip",
            label: "IP",
          },
        ];
        setData(res?.data);

        const filterType = initialOptions.filter((item) => {
          return !res?.data?.some((i) => i?.accessType === item?.value);
        });

        // Set options based on the filtered type or empty
        setOption(filterType.length > 0 ? filterType : []);

        setAfterApi(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddAccess = () => {
    setAddModal(false);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/user-access-restriction-exceptions/add`,
        {
          userId: employeeId,
          accessType: accessType,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((res) => {
        setRefetch(!refetch);
        setMessage("Access Added Successfully");
        setShow(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow1(true);
      });
  };

  const DeleteException = (id) => {
    setChangeStatusModal(false);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/v1/user-access-restriction-exceptions/${id}/remove`,

        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((res) => {
        setRefetch(!refetch);
        setMessage(`Access Deleted Successfully`);
        setShow(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow1(true);
      });
  };

  return (
    <div className="flex items-center justify-center mt-4 flex-col">
      <div className="md:w-[85%] w-full p-4 ">
        {/* header and button */}
        <div
          className="pl-2 pr-2  mt-[1rem] sm:mt-[0rem]"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div className="flex justify-between items-center mt-4">
              <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
                <img
                  className="sm:w-[1.7rem] w-[1.2rem]"
                  src={back5}
                  alt=""
                  onClick={() => {
                    router("/superAdminDashboard/employeeProfile", {
                      state: {
                        employeeId: employeeId,
                      },
                    });
                  }}
                />
                <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                  Revoke Access
                </h2>
              </div>

              <button
                className={` ${
                  option.length !== 0 && afterApi ? "" : "hidden"
                } text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md`}
                onClick={() => {
                  setAddModal(true);
                }}
              >
                Add Access
              </button>
            </div>
          </div>
        </div>

        <div className="lg:p-12 bg-slate-50 sm:ml-0 w-full  w-full mt-[2rem] mb-8 bg-slate-50 p-[35px] rounded-2xl relative">
          {data?.length === 0 && afterApi ? (
            <div className="flex items-center justify-center h-[200px]">
              <h1 className="text-[1.2rem] text-gray-500 font-semibold">
                No Access Found
              </h1>
            </div>
          ) : (
            <div className="grid grid-cols-1 w-full sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-8">
              {data?.map((data, index) => (
                <div className="bg-white shadow-md relative p-4 rounded-md">
                  <p className="text-gray-500 text-[1.1rem] font-semibold capitalize">
                    {data?.accessType === "ip" ? "IP" : data?.accessType}
                  </p>
                  <h1 className="text-[1.1rem] font-semibold text-primary capitalize">
                    {/* {data?.roleType} */}
                  </h1>
                  <div
                    className="absolute top-4 right-4 cursor-pointer"
                    onClick={() => {
                      setExceptionId(data?.id);
                      setChangeStatusModal(true);
                    }}
                  >
                    <img src={deleteicon} alt="" className="w-5 h-5" />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* <div className={`flex justify-center mt-7`}>
          <Pagination
            defaultCurrent={1}
            total={totalPages * 10}
            showSizeChanger={false}
            onChange={pageHandler}
            current={page}
          />
        </div> */}
      </div>

      {/* Modal for Payment */}
      <div
        className={`${
          addModal ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[22rem]  pb-6 pt-6 flex flex-col  p-6 bg-white">
          <label className="text-[#596F96] text-[1rem] font-semibold mb-4 text-center">
            Please provide the following details
          </label>

          {/* Status Select */}
          <div className="w-full mb-4">
            <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
              Access Type <span className="text-red-500">*</span>
            </label>
            <Select
              placeholder="Access Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              size="middle"
              className="wow"
              onChange={(e) => setAccessType(e)}
              value={accessType}
              options={option}
            />
          </div>

          {/* Buttons */}
          <div className="flex justify-end">
            <button
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              onClick={() => {
                setAddModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
              onClick={() => {
                if (accessType === null) {
                  setErr("Please select access type");
                  setShow1(true);
                  return;
                }
                AddAccess();
                setAddModal(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      {/* Change Status Modal */}
      <div
        className={`${
          changeStatusModal ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[22rem] pb-6 flex items-center p-3 bg-white flex-col">
          <img src={logo} alt="" className="w-20 h-20 mt-4" />
          <p className="text-center text-[0.95rem] mt-3 mb-5 w-[85%]">
            Are you sure you want to delete ?
          </p>
          <div className="flex">
            <button
              onClick={() => setChangeStatusModal(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              No
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
              onClick={() => {
                DeleteException(exceptionId);
                // ChangeStatus();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      {/* Error Modal */}

      {show1 && <ErrorModal show={show1} setShow={setShow1} err={err} />}
      {show && <SuccessModal show={show} setShow={setShow} message={message} />}
    </div>
  );
};

export default Main;

import React, { useState } from "react";
import ImagePreview from "../../Modals/imagePreview";
import { useSelector } from "react-redux";
const Identification = ({ identification, mhhs }) => {
  console.log(identification);
  const [showPreview, setShowPreview] = useState(false);
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const user = useSelector((state) => state.user);
  return (
    <div className="">
      <div
        className={
          mhhs
            ? "bg-[#F4F5FE] grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 ipad-pro:grid-cols-2 gap-4 p-4 rounded-lg ml-4 mr-4 px-5"
            : "bg-[#F4F5FE] grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 ipad-pro:grid-cols-2 gap-4 p-4 rounded-lg ml-4 mr-4 px-5"
        }
      >
        {identification?.map((document, index) => (
          <div
            key={index}
            className="relative flex flex-col border p-5 rounded-md bg-white"
          >
            <div className="">
              <h1 className="font-bold text-sm text-gray-900 mb-2 capitalize">
                {document?.type}
              </h1>
              <h3 className="font-semibold text-sm text-gray-900">
                No : <span>{document?.docIDValue}</span>
              </h3>
              <h3
                className={`font-semibold text-sm text-gray-900 ${
                  document?.countryIssued ? "" : "hidden"
                }`}
              >
                Country Issued : <span>{document?.countryIssued}</span>
              </h3>

              <h3
                className={`font-semibold text-sm text-gray-900 ${
                  document?.expiryDate && document?.expiryDate !== null
                    ? ""
                    : "hidden"
                }`}
              >
                Expiry Date : <span>{document?.expiryDate}</span>
              </h3>
              <h3 className="font-semibold text-sm text-gray-900 mt-2">
                Attachments
              </h3>
            </div>

            <div className="flex gap-2 flex-wrap">
              <div className={""}>
                <h1
                  className={`font-semibold whitespace-nowrap text-primary ${
                    document.merchdocimages?.[0] ||
                    (user === "vendor-owner" && document.vendordocimages?.[0])
                      ? ""
                      : "hidden"
                  }`}
                >
                  Front Side
                </h1>

                <div className={`relative mb-4`}>
                  <img
                    onClick={() => {
                      // setImage(
                      //   mhhs
                      //     ? document.merchdocimages?.[0]?.url
                      //     : document.merchdocimages?.[0]?.imageUrl?.url
                      // );
                      setImage(
                        user === "vendor-owner"
                          ? document.vendordocimages?.[0]?.imageUrl?.url
                          : mhhs
                          ? document.merchdocimages?.[0]?.url
                          : document.merchdocimages?.[0]?.imageUrl?.url || ""
                      );

                      setTitle("Front Side");
                      setShowPreview(true);
                    }}
                    // className="w-full h-24"
                    className={`w-24 h-24 cursor-pointer ${
                      document.merchdocimages?.[0] ||
                      (user === "vendor-owner" && document.vendordocimages?.[0])
                        ? ""
                        : "hidden"
                    }`}
                    src={
                      user === "vendor-owner"
                        ? document.vendordocimages?.[0]?.imageUrl?.url
                        : mhhs
                        ? document.merchdocimages?.[0]?.url
                        : document.merchdocimages?.[0]?.imageUrl?.url || ""
                    }
                    //   src={display}
                    alt=""
                  />
                  {/* <div
                    className="absolute bottom-0 right-0 bg-white cursor-pointer"
                    onClick={() => { }}
                  >
                    <img className="w-[2rem]" src={trash} alt="" />
                  </div> */}
                </div>
              </div>
              <div className={``}>
                <h1
                  className={`font-semibold whitespace-nowrap text-primary ${
                    document.merchdocimages?.[1] ? "" : "hidden"
                  }`}
                >
                  Back Side
                </h1>

                <div className={`relative mb-4`}>
                  <img
                    onClick={() => {
                      setImage(
                        mhhs
                          ? document.merchdocimages?.[1]?.url
                          : document.merchdocimages?.[1]?.imageUrl?.url
                      );
                      setTitle("Back Side");
                      setShowPreview(true);
                    }}
                    // className=""
                    className={`w-24 h-24 cursor-pointer ${
                      document.merchdocimages?.[1] ? "" : "hidden"
                    }`}
                    src={
                      mhhs
                        ? document.merchdocimages?.[1]?.url
                        : document.merchdocimages?.[1]?.imageUrl?.url
                    }
                    //   src={display}
                    alt=""
                  />
                  {/* <div
                    className="absolute bottom-0 right-0 bg-white cursor-pointer"
                    onClick={() => { }}
                  >
                    <img className="w-[2rem]" src={trash} alt="" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {showPreview && (
        <ImagePreview setShow={setShowPreview} img={image} title={title} />
      )}
    </div>
  );
};

export default Identification;

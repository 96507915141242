import { Pagination, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

const Paget = () => {
  const token = useSelector((state) => state.token);
  const totalPages = 1;
  const [page, setPage] = useState(1);
  const [pensioners, setPensioners] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const router = useNavigate();
  const dispatch = useDispatch();
  // const option2 = useSelector((state) => state.option2);
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }
  const pageHandler = (e) => {
    setPage(e);
  };
  const columnHeaders = [
    "Sr. No",
    "Product",
    "Inventory",
    "Units",
    "Rate",
    "Inventory Cost",
  ];

  const vendorData = [
    {
      srNo: 1,
      vendorName: "Apple",
      phone: "2424",
      email: "PC",
      address: "23",
      totalOrders: "$242,24",
    },
    {
      srNo: 2,
      vendorName: "Apple",
      phone: "2424",
      email: "PC",
      address: "23",
      totalOrders: "$242,24",
    },
    {
      srNo: 3,
      vendorName: "Apple",
      phone: "2424",
      email: "PC",
      address: "23",
      totalOrders: "$242,24",
    },
    {
      srNo: 4,
      vendorName: "Apple",
      phone: "2424",
      email: "PC",
      address: "23",
      totalOrders: "$242,24",
    },
    {
      srNo: 5,
      vendorName: "Apple",
      phone: "2424",
      email: "PC",
      address: "23",
      totalOrders: "$242,24",
    },
    {
      srNo: 6,
      vendorName: "Apple",
      phone: "2424",
      email: "PC",
      address: "23",
      totalOrders: "$242,24",
    },
  ];

  return (
    <section className="mt-4">
      <div className="mx-auto max-w-screen-3xl">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-gray-50 ">
          {/* table */}
          <div className="overflow-x-auto custom-scrollbar">
            {vendorData.length === 0 ? (
              <h1 className="text-center text-[1.1rem] p-5 h-40">
                No Data Found
              </h1>
            ) : (
              <table className="w-full text-sm text-center text-gray-500  ">
                <thead className="text-xs  uppercase bg-primary text-white">
                  <tr>
                    {columnHeaders.map((columnHeader, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 font-semibold tracking-wider"
                      >
                        {columnHeader}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {vendorData.map((pen, index) => (
                    <tr
                      key={index}
                      className={`border-b border-[1px] border-[#d9d9d970] hover:bg-gray-200 text-md cursor-pointer ${
                        index % 2 === 0 ? "bg-white" : "bg-[#f2faf9]"
                      }`}
                      onClick={() => {
                        router(
                          "/SuperAdmin/Inventory/PurchasedProducts/Detail"
                        );
                      }}
                    >
                      <th
                        scope="row"
                        className="px-3 py-5 font-medium text-gray-900  whitespace-nowrap"
                      >
                        {pen?.srNo}.
                      </th>

                      <td className="px-2 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <img
                              className="h-10 w-10 rounded-full border-2 border-primary"
                              src="https://static.vecteezy.com/system/resources/thumbnails/007/209/020/small_2x/close-up-shot-of-happy-dark-skinned-afro-american-woman-laughs-positively-being-in-good-mood-dressed-in-black-casual-clothes-isolated-on-grey-background-human-emotions-and-feeligs-concept-photo.jpg"
                              alt=""
                            />
                          </div>
                          <div className="ml-4">{pen?.vendorName}</div>
                        </div>
                      </td>

                      <td className="px-2 py-4">
                        {pen?.phone === null ? "----" : `${pen?.phone}`}
                      </td>

                      <td className="px-2 py-4">{pen?.email}</td>
                      <td className="px-2 py-4">{pen?.address}</td>
                      <td className="px-2 py-4">{pen?.totalOrders}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <nav
            className="flex flex-col md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 ">
              Showing{" "}
              <span className="font-semibold text-gray-900">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                // total={totalPages * 10}
                // showSizeChanger={false}
                // onChange={pageHandler}
                // current={search ? pagewithsearch : page}
              />
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default Paget;

export const vendorStatus = (state = false, action) => {
  switch (action.type) {
    case "VENDOR_STATUS":
      return action.num;
    default:
      return state;
  }
};

export const category = (state = "", action) => {
  switch (action.type) {
    case "Cat":
      return action.num;
    default:
      return state;
  }
};
export const role = (state = "", action) => {
  switch (action.type) {
    case "Role":
      return action.num;
    default:
      return state;
  }
};

export const cartData = (state = [], action) => {
  switch (action.type) {
    case "Cart":
      return action.num;
    default:
      return state;
  }
};
export const favData = (state = [], action) => {
  switch (action.type) {
    case "Fav":
      return action.num;
    default:
      return state;
  }
};
export const productData = (state = [], action) => {
  switch (action.type) {
    case "Product":
      return action.num;
    default:
      return state;
  }
};


export const PlaceOrderCheck = (state = false, action) => {
  switch (action.type) {
    case "PLACE_ORDER_CHECK":
      return action.num;
    default:
      return state;
  }
};

export const StorageType = (state = null, action) => {
  switch (action.type) {
    case "STORAGE_TYPE":
      return action.num;
    default:
      return state;
  }
};

export const ProductId = (state = null, action) => {
  switch (action.type) {
    case "PRODUCT_ID":
      return action.num;
    default:
      return state;
  }
};

export const VendorBranches=(state = [], action) => {
  switch (action.type) {
    case "VENDOR_BRANCHES":
      return action.num;
    default:
      return state;
  }
};



export const editProduct=(state = false, action) => {
  switch (action.type) {
    case "EDIT_PRODUCT":
      return action.num;
    default:
      return state;
  }
};


export const Edit=(state = false, action) => {
  switch (action.type) {
    case "EDIT":
      return action.num;
    default:
      return state;
  }
};

export const Data=(state = null, action) => {
  switch (action.type) {
    case "DATA":
      return action.num;
    default:
      return state;
  }
};
import React, { useEffect } from "react";
import AttendanceDashboardWrapper from "../components/AttendanceWrapper";
import AttendancePage from "./AttendancePage";
import { useDispatch } from "react-redux";

const Attendance = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "3" });
  }, []);


  return (
    <AttendanceDashboardWrapper>
      <AttendancePage />
    </AttendanceDashboardWrapper>
  );
};

export default Attendance;

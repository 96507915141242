import Nav from "../../../../Nav/nav";
import Main from "./main";
import Whatsapp from "../../../../../Whatsapp/whatsapp";

const Programs = () => {
  return (
    <div>
      <Nav />
      <Main />
      <Whatsapp />
    </div>
  );
};

export default Programs;

import PhoneInput from "react-phone-number-input";
// import { parsePhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import eye from "../../../../../imgs/eyee.png";
import across from "../../../../../imgs/across.png";
// import axios from "axios";
const SecondR = () => {
  // const token = useSelector((state) => state.token);
  const [prompt, setPrompt] = useState("");
  const [show, setShow] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [err, setErr] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const option = useSelector((state) => state.option);
  const dispatch = useDispatch();
  const nextHandler = (e) => {
    e.preventDefault();
    // if (prompt) {
    //   const wow = parsePhoneNumber(prompt);
    //   let countryCode = wow.countryCallingCode;
    //   let number = wow.nationalNumber;
    //   axios
    //     .post(
    //       `${process.env.REACT_APP_BASE_URL}/v1/merch-auth/merch-employee/register`,
    //       {
    //         countryCode: `+${countryCode}`,
    //         phoneNo: number,
    //         email: email,
    //         password: password,
    //         employeeType: employeeType,
    //       },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }
    //     )
    //     .then((data) => {
    //       dispatch({ type: "MERCHANT_EMPLOYEE", num: data });
    //       dispatch({ type: "MERCHANT_Pass", num: password });

    //       dispatch({ type: "EINCREMENT" });
    //     })
    //     .catch((err) => {
    //       setErr(err.response.data.message);
    //       setShow(true);
    //     });
    // } else if (prompt === "") {
    //   setErr("please enter a valid phone number");
    //   setShow(true);
    // }
    dispatch({ type: "IINCREMENT" });
  };

  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
    } else {
      setState(event.target.value);
    }
  };
  const passShowHandler = () => {
    setShowPass(!showPass);
  };
  return (
    <div>
      <form>
        <div className="flex justify-center items-center">
          <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 sm:w-[70%] w-[80%]">
            <div>
              <div className="mb-4">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Phone *
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="GY"
                  value={prompt}
                  onChange={handleChange(setPrompt, "tt")}
                  international
                  countryCallingCodeEditable={false}
                  required={option === "Employee" ? true : false}
                  className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
                />
              </div>

              <div className="flex flex-col mb-4">
                <label
                  htmlFor="input1"
                  className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                >
                  {option === "Pensioner" ? "Email" : "Email *"}
                </label>
                <input
                  className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2"
                  type="text"
                  placeholder="Email"
                  onChange={handleChange(setEmail)}
                  value={email}
                  id="input1"
                />
              </div>
            </div>
            <div>
              <div className="flex flex-col justify-center">
                <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                  Password *
                </label>
                <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                  <input
                    type={showPass ? "text" : "password"}
                    placeholder="Password"
                    id="Pass11"
                    onChange={handleChange(setPassword)}
                    value={password}
                    required
                    className="w-[100%]"
                  ></input>
                  <img
                    className="w-[1.6rem] cursor-pointer"
                    onClick={passShowHandler}
                    src={eye}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className={`${
                show ? "" : "hidden"
              } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
            >
              <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
                <div
                  className="absolute top-[-11%] left-[42%]"
                  onClick={() => setShow(false)}
                >
                  <img src={across} alt="" />
                </div>
                <p className="text-center mt-[3rem] mb-5">{err}</p>
                <button
                  onClick={() => setShow(false)}
                  type="button"
                  className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Button */}
        <div className="flex sm:justify-end justify-center sm:w-[85%]">
          <button
            type="button"
            onClick={nextHandler}
            className="relative justify-center flex cursor-pointer mt-10 bg-primary pt-2 pb-2 pl-[2rem] pr-[2rem] text-white rounded-xl"
          >
            <span>Next</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default SecondR;

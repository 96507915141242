import React, { useEffect, useState } from "react";
import PageHeader from "../pensionerVerification/pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { Select } from "antd";

const SelectLocation = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const router = useNavigate();
  const distributionCenter = useSelector((state) => state.distributionCenter);
  const SelectedSlot = useSelector((state) => state.SelectedSlot);
  const dataUser = useSelector((state) => state.dataUser);
  const [graphData, setGraphData] = useState([]);
  const [employeeCount, setEmployeeCount] = useState("----");
  const [pensionerCount, setPensionerCount] = useState("----");
  const [selectedProgram, setSelectedProgram] = useState(
    "senior-citizen-Pension"
  );
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pension-programs/names-and-icons`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setPrograms(data?.data);
      })
      .catch(() => {});
  }, []);

  const options = programs?.map((item) => ({
    value: item?.name,
    label: item?.name,
    imageUrl: item?.icon,
  }));

  const formatProgramNames = (data) => {
    return data
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };

  const renderOption = (option) => (
    <Option key={option?.value} value={option?.value}>
      <div className="flex items-center">
        <img
          src={option?.imageUrl}
          alt={option?.label}
          style={{ width: 24, marginRight: 8 }}
        />
        <span>
          {formatProgramNames(
            option?.label === "plwd"
              ? "PLWD"
              : option?.label === "pa-medical"
              ? "PA Medical"
              : option?.label === "pa-economical"
              ? "PA Economical"
              : option?.label
          )}
        </span>
      </div>
    </Option>
  );

  //Chart Data
  useEffect(() => {
    const api = `v1/scheduler-verification-count/overall-status-counts?program=${selectedProgram}`;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((data) => {
        setGraphData(data?.data);
        setPensionerCount(
          data?.data?.inProcessCount +
            data?.data?.pendingCount +
            data?.data?.rejectedCount +
            data?.data?.verifiedCount
        );
      })
      .catch(() => {});
  }, [selectedProgram]);

  const data1 = [
    {
      title: "Verified",
      value: graphData?.verifiedCount,
      bg: "#1BA397",
    },
    {
      title: "In-process",
      value: graphData?.inProcessCount,
      bg: "#FFCD69",
    },
    {
      title: "Rejected",
      value: graphData?.rejectedCount,
      bg: "#CE1126",
    },
    {
      title: "Pending",
      value: graphData?.pendingCount,
      bg: "#C1C0BC",
    },
  ];

  console.log(data1);

  const LegendComponent = ({ data }) => (
    <div className="flex flex-col items-start">
      {data.map((entry, index) => (
        <div
          key={`legend-${index}`}
          className="flex items-center mb-2 justify-between w-full"
        >
          <div className="flex items-center">
            <div
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: entry.bg }}
            />
            <span className="ml-2">{entry.title}</span>
          </div>
          <div className="flex-grow"></div> {/* Add flexible space here */}
          <div className="font-semibold ml-6">{entry.value}</div>
        </div>
      ))}
    </div>
  );

  // Custom Tooltip component
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { title, value } = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            background: "#fff",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>{`${title}`}</p>
          <p>{`Count: ${value}`}</p>
        </div>
      );
    }
    return null;
  };

  const COLORS = data1.map((item) => item.bg);
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4  min-h-screen">
      <PageHeader
        title={"Schedule Status"}
        route={"/superAdminDashboard"}
        header={true}
      />
      <div className="flex justify-center flex-col items-center">
        <div
          className={`bg-[#F6F4F4] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%] w-[95%]`}
        >
          {/* Select Program */}
          <div>
            <h3 className="font-medium mb-1 text-[0.93rem]">Program</h3>

            <Select
              placeholder="Select Program"
              // className="wow w-[100%] mb-4"
              className="w-[100%] woww mb-4"
              value={selectedProgram}
              renderOption={renderOption}
              onChange={
                (value) => {
                  setSelectedProgram(value);
                  dispatch({ type: "SELECTED_PROGRAM", num: value });
                }
                // console.log(value)
              }
            >
              {options?.map(renderOption)}
            </Select>
          </div>
          <div className="bg-[#FAFAFA] flex p-4 md:flex-row flex-col">
            <div className="flex-1 ">
              <div className="sm:ml-12 mx-3">
                <div className="flex flex-row gap-5 items-center">
                  <span className="font-medium">Total Pensioner</span>
                  <span className="font-semibold bg-white pl-20 pr-20 p-2 text-center">
                    {pensionerCount}
                  </span>
                </div>
                <div className="flex-shrink-0 flex items-center justify-center md:justify-start mt-6">
                  <LegendComponent data={data1} />
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex-grow mr-12">
                <ResponsiveContainer width={300} height={250}>
                  <PieChart>
                    <Pie
                      data={data1}
                      cx="50%"
                      cy="50%"
                      innerRadius={75}
                      outerRadius={100}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {data1.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        {/* Button */}
        <div
          className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5
           `}
        >
          <button
            type="button"
            onClick={() => {
              router("/pensionerVerificationScheduling/selectLocation");
              dispatch({ type: "EDIT_SLOT", num: true });
            }}
            className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[16rem]`}
          >
            Set Verification Schedule
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectLocation;

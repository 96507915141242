import { useLocation, useNavigate } from "react-router-dom";
import { Select } from "antd";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import eye from "../../../../../imgs/eyee.png";
import across from "../../../../../imgs/across.png";
import logo from "../../../../../imgs/logo12.png";
import ErrorModal from "../../../../Merchant/Modals/errorModal";

const FirstB = () => {
  const router = useNavigate();
  const option = useSelector((state) => state.option);

  const token = useSelector((state) => state.token);
  const dataUser = useSelector((state) => state.dataUser);

  // console.log(EmployeeAddBenficiary,"EmployeeAddBenficiary")

  const [prompt, setPrompt] = useState("");

  const [email, setEmail] = useState("");

  const [err, setErr] = useState("");

  const [successModal, setSuccessModal] = useState(false);
  const [companyName, setcompanyName] = useState("");
  const [showErr, setShowErr] = useState(false);

  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setState(event);
    } else {
      setState(event.target.value);
    }
  };
  const nextHandler = () => {
    if (companyName === "") {
      setErr("Please enter company name");
      setShowErr(true);
      return;
    }

    if (email === "") {
      setErr("Please enter email");
      setShowErr(true);
      return;
    }

    console.log(prompt, "prompt");
    if (prompt === "" || prompt === null || prompt === undefined) {
      setErr("Please enter phone number");
      setShowErr(true);
      return;
    }

    const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const symbols = "!@#$%^&*()-=_+[]{}|;:,.<>?";

    // Combine all character sets
    const allChars = lowercaseLetters + uppercaseLetters + numbers + symbols;

    let newPassword = "";

    // Add at least one character from each required set
    newPassword +=
      lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
    newPassword +=
      uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
    newPassword += numbers[Math.floor(Math.random() * numbers.length)];
    newPassword += symbols[Math.floor(Math.random() * symbols.length)];

    // Add remaining characters to complete the length
    for (let i = 0; i < 9 - 4; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      newPassword += allChars[randomIndex];
    }

    // Shuffle the password to ensure randomness
    newPassword = newPassword
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
    let body;

    const wow = parsePhoneNumber(prompt);
    let countryCode = wow.countryCallingCode;
    let number = wow.nationalNumber;
    body = {
      countryCode: `+${countryCode}`,
      phoneNo: number,
      email: email,
      companyName: companyName,
      password: newPassword,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/vendor-auth/register-owner`,
        body,
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then((res) => {
        console.log(res, "red");
        handleShare(res?.data?.vendorUser?.id, newPassword);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErr(true);
      });
  };

  const handleShare = (id, newPassword) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/vendor-auth/send-credentials?vendorUserId=${id}`,
        {
          password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setSuccessModal(true);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShowErr(true);
      });
  };

  return (
    <div>
      <div className="flex justify-center items-center">
        <div className="grid grid-cols-2 gap-4 w-full p-5">
          <div className="mt-3">
            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-4 mt-2">
              Company Name
            </label>
            <input
              type="text"
              placeholder=" Company Name"
              onChange={handleChange(setcompanyName)}
              value={companyName}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
          </div>
          <div>
            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Phone Number
            </label>
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry="GY"
              value={prompt}
              onChange={handleChange(setPrompt, "tt")}
              international
              countryCallingCodeEditable={false}
              required={option === "Employee" ? true : false}
              className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
            />
          </div>
          <div>
            <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Email
            </label>
            <input
              type="text"
              placeholder="Email"
              onChange={handleChange(setEmail)}
              value={email}
              className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
            />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center mb-4">
        <button
          className="cursor-pointer rounded-lg pt-[0.5rem] pb-[0.5rem] pl-12 pr-12 text-white bg-primary"
          onClick={() => {
            nextHandler();
          }}
        >
          {/* Share Credentials */}
          Add Vendor
        </button>
      </div>
      {/* Change Status Modal */}
      <div
        className={`${
          successModal ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[22rem] pb-6 flex items-center p-3 bg-white flex-col">
          <img src={logo} alt="" className="w-20 h-20 mt-4" />
          <p className="text-center text-[0.95rem] mt-3 mb-5 w-[85%]">
            The vendor has been successfully added, and the credentials will be
            shared via the provided email and phone number
          </p>
          <div className="flex">
            {/* <button
              onClick={() => setSuccessModal(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Cancel
            </button> */}
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary"
              onClick={() => {
                setSuccessModal(false);
                router("/SuperAdmin/Inventory/VendorListing");
                // ChangeStatus();
              }}
            >
              Okay
            </button>
          </div>
        </div>
      </div>
      {showErr && <ErrorModal setShow={setShowErr} err={err} />}
    </div>
  );
};

export default FirstB;

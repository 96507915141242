import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import Nav from "../navbar";
import Main from "./main";
import { useDispatch, useSelector } from "react-redux";
const Listing = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const user = useSelector((state) => state.user);

  return (
    <>
      <div className="bg-[#D9EFED] min-h-screen">
        <Nav />
        <div className="flex items-center justify-center  ">
          <div className="w-[95%] mt-[1rem] px-10 rounded-md mb-12">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => {
                  if (user === "vendor-owner") {
                    router("/VendorDashboard");
                    return;
                  }
                  router("/SuperAdmin/InventoryDashboard");
                }}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Delivery Charges
              </h2>
            </div>

            {/* <div className="bg-[#F6F4F4] mt-6 rounded-md p-8 mx-10"> */}
              <Main />
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;

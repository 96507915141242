import React from "react";
import clsx from "clsx";

const Button = ({
  onClick = () => {},
  className = "",
  primary = false,
  transparent = false,
  style = {},
  children,
  ...rest // Spread additional props
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "px-5 py-2 text-base font-medium transition-colors duration-200",
        primary &&
          "text-white bg-[#1ba397] hover:text-[#1ba397] hover:bg-white border border-transparent hover:border-[#1ba397]",
        transparent &&
          "text-[#1ba397] bg-transparent border border-[#1ba397] hover:bg-[#1ba397] hover:text-white",
        !primary &&
          !transparent &&
          "text-white bg-[#1ba397] hover:text-[#1ba397] hover:bg-white border border-transparent hover:border-[#1ba397]",
        className
      )}
      style={style}
      {...rest} // Spread additional props to the button element
    >
      {children}
    </button>
  );
};

export default Button;
